import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


import { appStore } from '../../zustand/store';
import { common, dataLoader, setMyState } from '../Comman';

import { toast } from 'react-toastify';
import svg from '../svg';
import config from '../../config';

function QRCode() {
    let alfaUrl = config.REACT_APP_BUCKET_URL
    let myStore = appStore(state => state);
    const userData = myStore.userData;
  
    let profileDetails = userData?.profile_details;
    let parseProfile = profileDetails && JSON.parse(profileDetails);
    const navigate = useNavigate()
    let { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [state, setQuery] = useState({
      bodyColor : '#F7f7f7',
      themeColor : '#22CCA6',
      textColor: '#4A5251',
      hoverColor: null,
      name: '',
      email: '',
      designation: '',
      about_me: '',
      company_name: '',
      look_to_meet: '',
      services_provide: '',
      userDetail: {},
      formDetails: {},
      showEdit: true,
      pageLoading:false,
      uploadProfileName:''
    });
  
  
    let getHover1 = (e) => {
      setMyState(setQuery, {
        hoverColor: e,
      })
    }
    let getHover2 = (e) => {
      setMyState(setQuery, {
        hoverColor: null,
      })
    }
    useEffect(()=>{
      let c1=document.getElementById("mainfooter")
        console.log(c1)
      
      if(!c1.classList.contains("hidden")){
        c1.classList.add("hidden")
      }
    
    },[])
    useEffect(() => {
  
      if (id) {
        getProfile();
      } else {
        setMyState(setQuery, {
          userDetail: {
            name: userData?.name,
            email: userData?.email,
            designation: userData?.designation,
            profile_img: userData?.profile_img,
            network_user_id: userData?.network_user_id
          }
        });
        const requiredKeys = ['name', 'email', 'designation',];
  
        const missingKeys = requiredKeys.filter(key => !userData[key]);
        console.log(missingKeys, "missingKeysmissingKeysmissingKeysmissingKeys");
  
        if (missingKeys.length) {
          setIsEdit(true);
        }
      }
    }, [id])
  
    const getProfile = () => {
      common.getAPI({
        isLoader: true,
        url: 'get-attendeeprofile',
        method: 'POST',
        data: { network_user_id: id }
      }, (resp) => {
        setIsEdit(true);
        if (resp.success == true) {
          let response = resp.user_data;
          let parsedata = JSON.parse(response.profile_details);
          if (id != userData.network_user_id) {
            setMyState(setQuery, { showEdit: false })
          }
          setMyState(setQuery, {
            userDetail: {
              name: response.name,
              email: response.email,
              designation: response.designation,
              profile_img: response.profile_img,
              network_user_id: response.network_user_id,
            }
          })
  
  
        }
      });
    }

    return (
      <div className='mc_eventBody mc_profileScroller'
        style={{ backgroundColor: state.bodyColor, color: state.textColor }}
      >
        <div className='mc_componentData mc_userProfile_head'>
          
          <div className='mc_userProfileImage_holder'>
          <div className='mc_headTitle_dv' >
              <div className='mc_backBtn_dv'>
                <a onClick={() => navigate('/events/attendees')}>
                  <span style={{ fill: state.themeColor }}>{svg.app.backArrowBtn} </span> <span className="ml-2"></span>
                </a>
              </div>
              
              <h2 className='flex items-center justify-center' style={{ color: state.themeColor }}>Event Ticket Code</h2>
              {/* {userData.email && (state.showEdit) && 
                <a href={void (0)} className='ml-2'
                  style={{ fill: state.themeColor }}
                  onClick={() => setIsEdit(!isEdit)}
                >
                  {svg.app.editProfileIcon}
                </a>
              } */}
            </div>
            <div className='mc_profilePic_holder'>
              {state.userDetail.profile_img ?
                  <div className='mc_alfaProfile_img' 
                    style={{
                      backgroundImage : `url(${state.userDetail.profile_img})`,
                    }}
                  >
                  </div>
                :
                  <div className='mc_alfaProfile_img'>{userData?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
              }
                {/* <img src={state.userDetail.profile_img ? state.userDetail.profile_img : `${alfaUrl}/images/user.png`} alt='Profile Image' width={'100px'} height={'100px'} /> */}
                <h3 className='text-sm font-semibold'>{state.userDetail.name}</h3>
                <p className='text-xs'>{state.userDetail.email}</p>
                <p className='mt-2 text-xs' style={{color : state.themeColor}}>{state.userDetail.designation}</p>
            </div>
           
          </div>
          
          <div className='px-4 mc_profileSection'>
            <div className='mc_eventProfilePage'>
              <div className='mc_profilePic'>
                {/* <div className='mc_picUrl hidden'>
                  
                  <img src={userData.qr_code} alt='Profile Image' width={'150px'} height={'150px'} />
                </div> */}
                <div className='mc_profileContent mt-3'>
                  <div className='mc_manageContent'>
                    <img src={userData.qr_code} alt='QR Code' className='mc_QrCode_img'/>
                  </div>
                </div>
              </div>
            </div>
                
          </div>
        </div>
        {state.pageLoading ? dataLoader.commonLoader : ''}
      </div>
    )
}

export default QRCode
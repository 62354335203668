'use client';
import React from 'react'

export default function Help(){
  return (
    <>
    <button onClick={()=>{
      window.location.href="http://localhost:3000/"
    }}></button>
    <div className="max-container">
      <div className="mx-full w-full px-0 py-8 sm:px-0 sm:py-8 lg:px-0">
      <button onClick={()=>{
        window.location.href="http://localhost:3000/"
      }}>Local URL</button>
        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 mb-3">Help</h2>
      </div>
    </div>
    </>
  )
}

import React, {useEffect, useState} from 'react'
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
  } from "tw-elements-react";

const ActiveUsers = (props) => {
    // console.log(props, 'BirthdayProps');
    const [congratulationsModal, setCongratulationsModal] = useState(false);
    const [Items, setItems] = useState(
        Array.from(Array(5).keys(), (n) => n + 1)
      );
      const [isFetching, setIsFetching] = useState(false);
      function loadMoreItems() {
        setIsFetching(true);
        //mocking an API call
        setTimeout(() => {
          setItems((prevState) => [
            ...prevState,
            ...Array.from(Array(5).keys(), (n) => n + prevState.length + 1),
          ]);
          setIsFetching(false);
        }, 2000);
      }
    return (
        <>
            <div className="mc_whiteBox_bordered p-6 w-full mb-3 mt-6">
                <h4 className='text-black text-sm font-semibold flex items-center '>
                    <span className='mr-2'>
                        {/* {svg.app.birthdayCack} */}
                        <img src='/assets/images/birthdayCake.png' alt='img' width={'35px'}/>
                    </span>
                    {props.activeUser}
                </h4>
                <div className='mc_listAccorfian'>
                    <div className='py-3 mt-2'>
                        {Items.map((item, index) => {
                            if(Items.length == index) {
                            return (
                                <div key={index+1} className='mc_borderes_userList flex flex-1 py-3 mt-5'>
                                    <div className='mc_playBtn flex justify-center items-center left-3'>
                                        <span className='mr-4'>
                                            <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'44px'} height={'44px'}/>
                                        </span>
                                    </div>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex flex-wrap justify-between items-center text-gray-900 w-full'>
                                            <div className=''>
                                                <p className='text-black text-sm font-semibold w-full'>Meera</p>
                                                <span className='text-sm'>100 Members</span>
                                            </div>
                                            {props.activeUser == '12 Birthday' ? 
                                                <a href={() => false} onClick={()=>setCongratulationsModal(true)}>
                                                    <img src={'/assets/images/courseLink.png'} alt='img'/>
                                                </a>
                                                :
                                                <p>3m ago</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                            } else {
                            return (
                                <div key={index} className='mc_borderes_userList flex flex-1 py-3'>
                                    <div className='mc_playBtn flex justify-center items-center left-3'>
                                        <span className='mr-4'>
                                            <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'44px'} height={'44px'}/>
                                        </span>
                                    </div>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex flex-wrap justify-between items-center text-gray-900 w-full'>
                                            <div className=''>
                                                <p className='text-black text-sm font-semibold w-full'>Meera {item}</p>
                                                <span className='text-sm'>100 Members</span>
                                            </div>
                                            {props.activeUser == '12 Birthday' ? 
                                                <a href={() => false} onClick={()=>setCongratulationsModal(true)}>
                                                    <img src={'/assets/images/courseLink.png'} alt='img'/>
                                                </a>
                                                :
                                                <p className='text-black text-sm'>3m ago</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        })}
                    </div>
                </div>

                <div className='flex w-full items-center justify-center mt-5'>
                    {isFetching && <p className='mc_unLink font-bold text-black text-sm'>Fetching items...</p>}
                    {!isFetching && <a href={() => false} className='mc_unLink font-bold text-black text-sm' onClick={loadMoreItems}>View More</a>}
                </div>
            </div>
                
            {/*<div className="mc_whiteBox_bordered p-6 w-full mb-3 mt-6">
                <div className='mc_circleGray mb-3'>
                    <img src={'/assets/images/birthdayCake.png'} alt='img'/>
                </div>
                <p className='mc_widthShort text-gray-700 font-medium text-sm'>There is no upcoming birthday’s</p>
            </div> */}

            {/* <BirthDay Modal/>    */}
            <TEModal show={congratulationsModal} setShow={setCongratulationsModal} className='mc_birthDay_modal mc_modalCongratulations'>
                <TEModalDialog centered className='mc_modalDialog'>
                    <TEModalContent className='mc_modalContent'>
                        <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setCongratulationsModal(false)} aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </a>
                        {/* <!--Modal body--> */}
                        <TEModalBody className='mc_paddZero'>
                            <div className='mc_modalBody'>    
                                <div className='mc_br_img p-0'>
                                    <div className='formImg_congrat w-full v1'>
                                        <img src={'/assets/images/congratulatiionsV2.png'} alt='img' className='mx-auto'/>
                                        {/* <p>For your course completion</p> */}
                                    </div>
                                    <div className='mc_textHolde_mod'>
                                        {/* <h3>Congratulations</h3> */}
                                    </div>

                                    {/* <img src={'/assets/images/party.png'} alt='birthday'/> */}
                                </div>
                                <div className='flex items-center justify-center mc_formList'>
                                    <span>
                                        <img src={'/assets/images/customer1.jpeg'} alt='customer' width={'70px'} height={'70px'} className='rounded-full'/>
                                    </span>
                                    <div className='ml-4'>
                                        <label className='w-full text-gray-800 font-semibold text-sm'>Meera Jasmine</label>
                                        <input type='text' placeholder='Wish him on timeline (Optional)' className='mc_input' />
                                    </div>
                                </div>
                                
                                <div className='mt-5'>
                                    <a href={() => false} className='mc_btn mc_courseBtn text-sm font-medium text-center'>Do Congrats & Earn 2 Points</a>
                                </div>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </>
    )
}

export default ActiveUsers;
'use client';
import React, { useEffect, useState } from 'react'
import svg from '../components/svg';
// import {useForm} from 'react-hook-form';
// import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
// import { Navigation } from 'swiper/modules';
import { setMyState, common, dataLoader } from '../components/Comman';
import { appStore } from '../zustand/store';
import Cookies from "js-cookie";
import { ToastContainer, toast } from 'react-toastify';
// import { useNavigate, } from 'react-router-dom';
// import { Upload, Icon, message } from 'antd';
// import { UploadImage } from '../components/UploadProfile';

const Profile = () => {
  const slugName = window.location.href.split('/')[4];
  
  // const navigate = useNavigate();
  let myStore = appStore(state => state);
  let userData = myStore.userData;
  // const  userName = userData.MembershipUser.name;
  // const userNamedata = Object.keys(userData.MembershipUser.name)
  // let appData = myStore.appData;
  let brandSetting = myStore.brandSetting;
  
  
  // const urlParams = new URLSearchParams(window.location.search);
  // const myParam = urlParams.get('funnel_id');
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamData = urlParams.get('memberId');
    const decoded=atob(urlParamData);
    const decodedData = JSON.parse(decoded)
    const myParam = decodedData.fId;

  const[state, setQuery]=useState({
    upLoadFile : '',
    postUploadFile : '',
    loader : false,
    show : false,
    profileImage : '',
    postType: '',
    postUrl: '',
    profileName: userData.MembershipUser.name,
    profileEmail : userData.MembershipUser.email,
    profileData : {},
    isEdit: false,
    isEditpass: false,
    gender: 'Male',
    contactNo : '+11 9876543210',
    currentAddress : 'Beech Creek, PA, Pennsylvania',
    permanantAddress : 'Arlington Heights, IL, Illinois',
    dob : 'Feb 06, 1998',
    password : userData.MembershipUser.password,
    funnel_id : myParam,
    myPlansData : [],
    confirmPassword : '',
    memberId : userData.MembershipUser.id,
    themeColor : brandSetting.theme_color1,
    themeColor1 : brandSetting.theme_color2,
    buttonColor : null,
    pageLoading : false,
  })
let getColor1 =(type)=>{
    setMyState(setQuery,{buttonColor : type})
}
let getColor2 =(type)=>{
    setMyState(setQuery,{buttonColor : null})
}
// let getColorUpdate1=(e)=>{
//   setMyState(setQuery,{themeColor1 : brandSetting.theme_color1})
// }
// let getColorUpdate2=(e)=>{
//   setMyState(setQuery,{themeColor1 : brandSetting.theme_color2})
// }



  let tokenCookie = Cookies.get(`access_token_${slugName}`)
  ? Cookies.get(`access_token_${slugName}`)
  : false;

  // let authType = 'profile';
  let [isProcess , setIsProcess ] = useState(false);  
  // let [planLoading,setPlanLoading] = useState(false);
  // let [error , setError ] = useState({
  //   name : '',
  //   email : '',
  //   password :'',
  //   funnel_id : '',
  // });
  var myState = {};
  let manageState = () => {
      myState = {
          name : state.profileName,
          email : state.profileEmail,
          password : state.password,
          confirmPassword : state.confirmPassword,
          // funnel_id : state.funnel_id,
          profileImage : state.profileImage,
      }
  }
  useEffect(() => {  
    manageState();        
  }, [state.email , state.password, state.confirmPassword , myState, state.profileImage]);

  const getProfile=()=>{
    let profiledetails = {
        token: tokenCookie,
    }
    common.getAPI({
            method: "Post",
            url: "profile",
            data: profiledetails,
        },
         (resp) => {
          // console.log(resp.profile_img, 'resp.profile_img');
            setMyState(setQuery, {
              ...state,
              // profileData : resp,
              profileName : resp.name,
              profileEmail : resp.email,
              password : resp.password,
              profileImage : resp.profile_img,
            })
        }
        );
  }

  const updateProfile=(e, data)=>{
    e.stopPropagation();
    e.preventDefault();
    // console.log(authType, 'image disappears');
    let profiledetails = {
        name: state.profileName,
        funnel_id: myParam,
        email: state.profileEmail,
        member_id: state.memberId,
        token: tokenCookie,
        profile_img : state.profileImage,
    }
    setIsProcess(true)
    common.getAPI({
            method: "Post",
            url: "profileupdate",
            data: profiledetails,
        },
        async (resp) => {
          
          // console.log(resp, 'ProfileUpdate');
          if(resp.success == true) { 
              setIsProcess(false);
               const updatedUserData = { ...userData, MembershipUser: { ...userData.MembershipUser, name: data.name, profile_img : data.profileImage }};
               myStore.updateStoreData('userData', updatedUserData);
               console.log(updatedUserData, 'updatedUserData');
               setMyState(setQuery, {
                 ...state,
                 profileName : data.name,
                 profileImage : data.profileImage,
                })
                toast.success(resp.message);

              console.log(resp.message, 'profile image disappears');
              // myStore.updateStoreData('userData' , {});
              // getProfile();
            } 
          }
      );
  }

  useEffect(()=>{
    getProfile();
  },[])
  
  // const [image, setImage] = useState(null);

//   const saveAssets = () => {
//     let data = new FormData();

// }

  const handleImageChange = (event) => {
    
    const file = event.target.files[0];
    if(file.type=='image/jpeg' ||  file.type=='image/png' ){
      
    setMyState(setQuery,{  pageLoading : true});
   
    var createElem = document.createElement(`img`);
    console.log({file})
    createElem.src = URL.createObjectURL(file);
    createElem.onload = (e) => {
        // var mediaMeta = {
        //     duration: createElem.duration,
        //     width: createElem.width,
        //     height: createElem.height,
        // };
        if (file) {
          const reader = new FileReader();
            var myHeaders = new Headers();
            myHeaders.append("authorization", `Bearer ${tokenCookie}`);
            var formdata = new FormData();
            formdata.append("file", file, "");
            formdata.append("userId", state.memberId);
            formdata.append("width", createElem.width);
            formdata.append("height", createElem.height);
            formdata.append("type", file.type);
            formdata.append("name", file.name);
            formdata.append("cloud_type", "2");
            formdata.append("upload_type", "2");
            var requestOptions = {      
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
            };
    
        fetch("https://img.myflxfnls.com/upload-img", requestOptions).then(response => response.json())
          .then(result =>{
              console.log({result})
              if(result?.results?.imgUrl){
                setMyState(setQuery, {
                  ...state,
                  profileImage : result?.results?.imgUrl,
                  pageLoading :false,
                })
              }
          })
          .catch(error => console.log('error', error));
                setMyState(setQuery, {
                  profileImage : reader.result
                })
            }
        }
    }else{
      toast.error('File type not allowed.')
    }
  };

  return (
    <>
      <ToastContainer/>
      
      <div className='mc_pageScroll_dv mc_breadCum_page'>
        <div className="max-container mb-5 w-height-vh">
            {/* <div className="mx-full w-full px-0 py-5 sm:px-0 sm:py-8 lg:px-0">
                <h2 className="text-3xl font-semibold tracking-tight text-gray-900 mb-0">Profile Page</h2>
            </div> */}
            <div className="grid mc_grigBox_holder mc_mobileRow mb-5 mt-8">
              <div className="bg-white p-5 shadow-sm rounded-sm mc_moboilCol_12 mb-5">
                  <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-5 mt-5">
                      <span className="mc_keyPageIcon" style={{fill: brandSetting.theme_color2}}>
                          {svg.app.profileMenuIcon}
                      </span>
                      <span className="tracking-wide">My Profile</span>
                  </div>
                  <div className="text-gray-700 mc_profilePage">
                      <div className='mc_btnHolder_edit mb-5 relative'>
                      
                        <a href={() => false} className='mc_btn' onClick={()=> setMyState(setQuery, {isEdit : !state.isEdit})} style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Edit Profile' ? brandSetting.theme_color2 : brandSetting.theme_color1}} onMouseEnter={(e)=> getColor1('Edit Profile')} onMouseLeave={(e)=> getColor2('Edit Profile')}>{state.isEdit == false ? 'Edit Profile' : 'Back' }</a>
                        
                        {/* <a href={() => false} className='mc_btn' onClick={()=> setMyState(setQuery, {isEdit : !state.isEdit})} style={{backgroundColor : state.themeColor}}  onMouseEnter={(e)=> getColor1(e)} onMouseLeave={(e)=> getColor2(e)}>Back</a> */}
                      
                      </div>
                      <div className="grid md:grid-cols-1 text-sm mc_profileGrid">
                        <form onSubmit={(e)=> updateProfile(e, myState)}>
                          <div className="grid grid-cols-4">
                              <div className="px-4 py-2 font-semibold">Full Name</div>
                              <div className="col-span-2 px-4 py-2">
                                {state.isEdit == false ?
                                  state.profileName
                                  : 
                                  <input type='text' value={state.profileName} className='mc_input' onChange={(e)=> setMyState(setQuery, {profileName : e.target.value})} />
                                }
                              </div>
                          </div>
                          <div className="grid grid-cols-4">
                              <div className="px-4 py-2 font-semibold">Email Address</div>
                              <div className="col-span-2 px-4 py-2">
                                {state.isEdit == false ?
                                    <span className="text-blue-800">{state.profileEmail}</span>
                                    
                                  : 
                                  <span className="text-blue-800">{state.profileEmail}</span>
                                }
                              </div>
                          </div>
                          <div className='grid'>
                            {/* <UploadImage/> */}
                          </div>
                          <div className="grid grid-cols-4 uploadprofile_img">
                            {state.isEdit == false ? 
                              <>
                              {state.profileImage != null && 
                                <div className="px-4 py-2 font-semibold ">Profile Image</div>
                              }
                                <div className="p-image">
                                  <div className="circle">
                                    <div className="profile-pic" style={{backgroundImage: `url(${state.profileImage})`}}></div>
                                  </div>
                                </div>
                              </>
                            :
                              <>
                                  <div className="px-4 py-2 font-semibold ">Profile Image</div>
                                  <div className="p-image col-span-2 px-4 py-2">
                                      <label htmlFor='profileImage' className='uploadProfile_Label'>
                                        <input type="file" id='profileImage' accept="image/*" onChange={(e)=>handleImageChange(e)} />
                                        <span className='uploadIcom'>{svg.app.uploadProfileIcon} Upload Profile Image</span>
                                      </label>
                                      {state.profileImage && 
                                        <div className="circle">
                                          <div className="profile-pic" style={{backgroundImage: `url(${state.profileImage})`}}></div>
                                        </div>
                                      }
                                  </div>   
                              </> 
                            }    
                          </div>       
                          <div className='mc_btnHolderDv grid grid-cols-4'>
                            <div className="px-4 py-2 font-semibold"></div>
                            {state.isEdit == true &&
                              <div className="col-span-2 px-4 py-2">
                                <button type="submit" className='mc_btn w-50' 
                                  disabled={isProcess}
                                  style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Update Profile' ? brandSetting.theme_color2 : brandSetting.theme_color1}} 
                                  onMouseEnter={(e)=> getColor1('Update Profile')} 
                                  onMouseLeave={(e)=> getColor2('Update Profile')}>
                                    Update Profile
                                </button>
                              </div>
                            }
                          </div>
                        </form>
                      </div>
                  </div>
                  {state.pageLoading ? dataLoader.commonLoader : ''}
                  {/* <div className="text-gray-700 mc_myplans mt-12">
                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-0">
                        <span className="text-green-500 mc_passwordKey">
                            {svg.app.passwordIcon}
                        </span>
                        <span className="tracking-wide">Change Password</span>
                    </div>
                    <div className="text-gray-700 mc_profilePage">
                      <div className='mc_btnHolder_edit mb-5 relative'>
                      {state.isEditpass == false ?
                        <a href={() => false} className='mc_btn' onClick={()=> setMyState(setQuery, {isEditpass : !state.isEditpass})}>Change Password</a>
                        : 
                        <a href={() => false} className='mc_btn' onClick={()=> setMyState(setQuery, {isEditpass : !state.isEditpass})}>Back</a>
                      }
                      </div>
                      {state.isEditpass == true &&
                      <div className="grid md:grid-cols-1 text-sm">
                        <form onSubmit={(e)=> getChangePassword(e, myState)}>
                          <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">Password</div>
                              <div className="px-4 py-2">
                                <input type='password' value={state.password} className='mc_input' onChange={(e)=> setMyState(setQuery, {password : e.target.value})} />
                              </div>
                          </div>
                          <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">Confirm Password</div>
                              <div className="px-4 py-2">
                                <input type='password' value={state.confirmPassword} className='mc_input' onChange={(e)=> setMyState(setQuery, {confirmPassword : e.target.value})}/>
                              </div>
                          </div>
                          <div className='mc_btnHolderDv grid grid-cols-2'>
                            <div className="px-4 py-2 font-semibold"></div>
                              <div className="px-4 py-2">
                                <button type="submit" className='mc_btn w-50'>Update Password</button>
                              </div>
                          </div>
                        </form>
                      </div>
                      }
                    </div>
                  </div>

                  <div className="text-gray-700 mc_myplans mt-12">
                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-0">
                        <span clas="text-green-500">
                            {svg.app.coursePlans}
                        </span>
                        <span className="tracking-wide">My Plans</span>
                    </div>
                    <div className='mc_tables'>
                      <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                              <table className="min-w-full text-left text-sm font-light">
                                <thead
                                  className="border-b bg-white font-medium dark:border-neutral-500 dark:bg-neutral-600">
                                  <tr>
                                    <th scope="col" className="px-6 py-4">#</th>
                                    <th scope="col" className="px-6 py-4">PRODUCT NAME</th>
                                    <th scope="col" className="px-6 py-4">PRICE</th>
                                    <th scope="col" className="px-6 py-4">PURCHASE DATE</th>
                                    <th scope="col" className="px-6 py-4">MEMBEBERSHIP PRODUCT ID</th>
                                    <th scope="col" className="px-6 py-4">INVOICE</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!planLoading && state.myPlansData.length>0 &&  state.myPlansData.map((items, index)=>{
                                    return (
                                        <tr key={index} className="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700">

                                        <td className="whitespace-nowrap px-6 py-4 font-medium">{index}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{items.product_title}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{items.product_price} {items.currency}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{items.created_at}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{items.membership_product_id}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{items.invoice_id}</td>
                                      </tr>
                                      )
                                  })}
                                
                                </tbody>
                              </table>
                              {planLoading && dataLoader.tableLoader}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Profile
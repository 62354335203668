import React from 'react'
import { appStore } from '../../zustand/store'

function EventProfile() {
    let myStore = appStore(state => state);
    // console.log(myStore, 'My Store');

  return (
    <>
        <h2>{myStore}</h2>
    </>
  )
}

export default EventProfile
import React,{useState} from 'react'
import svg from '../components/svg'
import { TECollapse } from "tw-elements-react";
import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import { members, courseSkills } from '.';
import CourseCreator from '../components/Members/CourseCreator';
import ActiveUsers from '../components/birthday/activeUsers';

const CourseDetails = () => {
  const [activeTab, setActiveTab] = useState("AboutCourse");
  const [activeInnerTab, setInnerActiveTab] = useState("All");
  const [activeElement, setActiveElement] = useState("");
  const handleClick = (value) => {
    if (value == activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };
  const [state, setQuery] = useState({
    // postDataFilter : postDataFilter,
    rating : '',
    assessmentStep : 0,
    postId : '',
    modules : '6',
    currentModules: '1'
  })
  return (
    <>
        <div className='mainBanner_wrapper pb-16'>
            <div className='mc_pagebanner py-24 relative' style={{'backgroundImage': 'url("/assets/images/bg.jpg")', }}>
                <div className='mc_overlayBg'></div>
                <div className='mc_bannerContent_dv relative max-container'>
                  <div className='mc_flexbox_left mc_mobileRow'>
                      <div className='flex justify-between mc_titleRow mc_bannerRow'>
                        <div className='mc_bannerInnerContent mc_moboilCol_12'>
                            <h4 className='text-white text-4xl font-semibold'>Powerful Social Media <br/> Marketing Tricks for Beginners</h4>
                            <div className='flex my-4 mc_moboilCol_12'>
                              <p className='text-white text-opacity-8 font-light'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br/> eiusmod tempor incididunt ut labore et dolore ad minim
                              </p>
                            </div>
                            {/* <div className='flex my-6 mc_moboilCol_12'>
                              <a href={() => false} className='mc_btn flex justify-between w-32 items-center'>
                                Enroll Now
                                <span>{svg.app.whiteArrowIcon}</span>
                              </a>
                            </div>
                            <div className='flex my-6 mc_moboilCol_12'>
                              <a href={() => false} className='mc_unLink text-white mr-5'>Share</a>
                              <a href={() => false} className='mc_unLink text-white'>Gift This Course</a>
                            </div> */}
                            <div className='mc_whiteBox p-5 mx_width_330 mb-3'>
                                <h5 className='text-gray-900 text-base font-extrabold'>Total 6 Modules <span className='text-gray-600 text-sm font-light'> (2h:45 Min)</span></h5>
                                <div className='mc_progressHolder mt-3'>
                                    {/* <div className="mb-0 flex items-center justify-between gap-4">
                                        <p className='text-xs'>17%</p>
                                        <p className='text-xs text-black font-medium '>1<span className='text-gray-400'>/6 Modules</span></p>
                                    </div> */}
                                    <div className='mc_progressHolder mt-4'>
                                      <div className="mb-0 flex items-center justify-between gap-4">
                                          <p className='text-xs font-semibold text-black'>{state.currentModules==state.modules ? '100' : Math.round(100 / state.modules)*state.currentModules}%</p>
                                          <p className='text-xs font-semibold text-black'>{state.currentModules}/6 Modules</p>
                                      </div>
                                      <Progress percent={state.currentModules==state.modules ? '100' : Math.round(100 / state.modules)*state.currentModules}/>
                                    </div>
                                    <a href={() => false} className='mc_btn flex justify-between w-32 items-center text-sm font-medium'>
                                      Continue to Learn
                                      <span>{svg.app.whiteArrowIcon}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <CourseCreator enrollCount={'445'} reviews={'4.5'} modules={'10 Modules'} lessions={'34 Lessons'} timeing={'3h 45 Min'} profileData={''} createDate={'12 Mar, 2023'}/>
                      </div>
                      
                  </div>
                </div>
            </div> 
        </div>  

        <div className='bg-white w-full relative'>
            <div className="max-container">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8 mc_mobileRow">
                    <div className="col-span-3 mc_moboilCol_12">
                        <div className="mc_tabDv_banner">
                            <div className='mc_tabHolder'>
                                <ul
                                    className="flex sm:flex-none sm:w-full rounded-none border-none border-blue-gray-0 bg-transparent p-0"
                                    // indicatorProps={{
                                    // className:
                                    //     "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
                                    // }}
                                >
                                    <li className={`mc_tabList ${activeTab == 'AboutCourse' ? "color-themeColor" : ""}`}>
                                        <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('AboutCourse')}>About Course</a>
                                    </li>
                                    <li className={`mc_tabList ${activeTab == 'Outcomes' ? "color-themeColor" : ""}`}>
                                        <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Outcomes')}>Outcomes</a>
                                    </li>
                                    <li className={`mc_tabList ${activeTab == 'RecentActivities' ? "color-themeColor" : ""}`}>
                                        <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('RecentActivities')}>Recent Activities</a>
                                    </li>
                                    <li className={`mc_tabList ${activeTab == 'Reviews' ? "color-themeColor" : ""}`}>
                                        <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Reviews')}>Reviews</a>
                                    </li>
                                    <li className={`mc_tabList ${activeTab == 'Lederboard' ? "color-themeColor" : ""}`}>
                                        <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Lederboard')}>Lederboard</a>
                                    </li>
                                    {/* <li className={`mc_tabList ${activeTab == 'Notes' ? "color-themeColor" : ""}`}>
                                        <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Notes')}>Notes</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-1 mc_moboilCol_12 mobileD_none'></div>
                </div>
            </div>

            <div className="max-container py-8">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8 mc_mobileRow">
                  {activeTab == 'AboutCourse' ? 
                    <div className='col-span-3 mc_moboilCol_12'>
                        <h4 className='text-base text-black mt-3 font-semibold'>Course Description</h4>
                        <p className='text-gray-900 text-base mt-4 max-w-80 line-height-20'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book</p>
                        <p className='mt-6 text-gray-900 text-base max-w-80 line-height-20'>
                        The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it’s seen all around the web; on templates,
                        </p>
                        
                        <h4 className='text-base text-black mt-12 font-semibold'>Skills Covered in This Course</h4>
                        <ul className='mc_btnList flex flex-wrap items-center mt-4  max-w-80'>
                          {courseSkills.map((skills, index)=>{
                              return (
                                  <li key={index} className='text-black text-base mr-2 mb-3'>
                                      <a href={() => false} className='mc_grayBtn'>{skills.title}</a>
                                  </li>
                              )
                          })}
                        </ul>
                        {/* <a href={() => false} className='mc_lightBtn lightColor flex justify-between items-center mt-4'>Download Resources & templates 
                            <span className='mc_btnIcon'>{svg.app.downloadIcon}</span>
                        </a> */}

                        <div className='w-full mt-12'>
                          <div className='mc_accordain_Holder'>
                            <h4 className='text-base text-black mb-3 font-semibold'>Modules & Syllabus</h4>
                            <div id="accordionExample" className='mb-4'>

                              {/* One Accordian  */}
                              <div className="border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800 mc_borderdAccordian">
                                <h2 className="mb-0" id="headingOne">
                                  <button className={`${ activeElement == "element1" && `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400 dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]`
                                    } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-sm text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
                                    type="button"
                                    onClick={() => handleClick("element1")}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    <span className='flex items-center w-full text-sm justify-between'>
                                      <span>Let's Go</span>
                                      <span className='mr-3'>45 Min</span>
                                    </span>
                                    {/* <span>45 Min</span> */}
                                    <span className={`${ activeElement == "element1" ? `rotate-[-180deg] -mr-1` : `rotate-0 fill-[#212529]  dark:fill-white`
                                      } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                    >
                                      {svg.app.arrowDownIcon}
                                    </span>
                                  </button>
                                </h2>
                                <TECollapse show={activeElement == "element1"} className="!mt-0 !rounded-b-none !shadow-none mc_tabCollapse" >
                                  <div className='py-6'>
                                    <div className="px-5 py-2 flex items-center justify-between w-full">
                                      <div className='mc_playPauseAccordian flex flex-1 items-center w-full'>
                                        <div className='mc_playBtn flex justify-center items-center mr-2'>
                                            <span className='mc_coloredPlay'>{svg.app.playCircle}</span>
                                        </div>
                                        <div className='mc_accordientTxt'>
                                            <p className='text-base text-black text-sm'>Introduction</p>
                                            {/* <span className='text-gray-500'>03 Lessons . 01hr 31 mins</span> */}
                                        </div>
                                      </div>
                                      <div className='ml-3 flex items-center'>
                                        {/* <a href={() => false} className='text-sm text-black mr-6 mc_unLink'>View</a> */}
                                        <p className='text-sm text-black '>04:34</p>
                                      </div>
                                    </div>

                                    <div className="px-5 py-2 flex items-center justify-between w-full">
                                      <div className='mc_playPauseAccordian flex flex-1 items-center w-full'>
                                        <div className='mc_playBtn flex justify-center items-center mr-2'>
                                            <span className='mc_coloredPlay'>{svg.app.playCircle}</span>
                                        </div>
                                        <div className='mc_accordientTxt'>
                                            <p className='text-base text-black text-sm'>Introduction</p>
                                            {/* <span className='text-gray-500'>03 Lessons . 01hr 31 mins</span> */}
                                        </div>
                                      </div>
                                      <div className='ml-3 flex items-center'>
                                        <a href={() => false} className='text-sm text-black mr-6 mc_unLink'>View</a>
                                        <p className='text-sm text-black '>04:34</p>
                                      </div>
                                    </div>

                                    
                                    <div className="px-5 py-2 flex items-center justify-between w-full">
                                      <div className='mc_playPauseAccordian flex flex-1 items-center w-full'>
                                        <div className='mc_playBtn flex justify-center items-center mr-2'>
                                            <span className='mc_coloredPlay'>{svg.app.playCircle}</span>
                                        </div>
                                        <div className='mc_accordientTxt'>
                                            <p className='text-base text-black text-sm'>Introduction</p>
                                            {/* <span className='text-gray-500'>03 Lessons . 01hr 31 mins</span> */}
                                        </div>
                                      </div>
                                      <div className='ml-3 flex items-center'>
                                        <a href={() => false} className='text-sm text-black mr-6 mc_unLink'>View</a>
                                        <p className='text-sm text-black '>04:34</p>
                                      </div>
                                    </div>
                                    <div className="px-5 py-2 flex items-center justify-between w-full">
                                      <div className='mc_playPauseAccordian flex flex-1 items-center w-full'>
                                        <div className='mc_playBtn flex justify-center items-center mr-2'>
                                            <span className='mc_coloredPlay'>{svg.app.playCircle}</span>
                                        </div>
                                        <div className='mc_accordientTxt'>
                                            <p className='text-base text-black text-sm'>Introduction</p>
                                            {/* <span className='text-gray-500'>03 Lessons . 01hr 31 mins</span> */}
                                        </div>
                                      </div>
                                      <div className='ml-3 flex items-center'>
                                        {/* <a href={() => false} className='text-sm text-black mr-6 mc_unLink'>View</a> */}
                                        <p className='text-sm text-black '>04:34</p>
                                      </div>
                                    </div>
                                    <div className="px-5 py-2 flex items-center justify-between w-full">
                                      <div className='mc_playPauseAccordian flex flex-1 items-center w-full'>
                                        <div className='mc_playBtn flex justify-center items-center mr-2'>
                                            <span className='mc_coloredPlay'>{svg.app.playCircle}</span>
                                        </div>
                                        <div className='mc_accordientTxt'>
                                            <p className='text-base text-black text-sm'>Introduction</p>
                                            {/* <span className='text-gray-500'>03 Lessons . 01hr 31 mins</span> */}
                                        </div>
                                      </div>
                                      <div className='ml-3 flex items-center'>
                                        {/* <a href={() => false} className='text-sm text-black mr-6 mc_unLink'>View</a> */}
                                        <p className='text-sm text-black '>04:34</p>
                                      </div>
                                    </div>
                                    <div className="px-5 py-2 flex items-center justify-between w-full">
                                      <div className='mc_playPauseAccordian flex flex-1 items-center w-full'>
                                        <div className='mc_playBtn flex justify-center items-center mr-2'>
                                            <span className='mc_coloredPlay'>{svg.app.playCircle}</span>
                                        </div>
                                        <div className='mc_accordientTxt'>
                                            <p className='text-base text-black text-sm'>Introduction</p>
                                            {/* <span className='text-gray-500'>03 Lessons . 01hr 31 mins</span> */}
                                        </div>
                                      </div>
                                      <div className='ml-3 flex items-center'>
                                        <a href={() => false} className='text-sm text-black mr-6 mc_unLink'>View</a>
                                        <p className='text-sm text-black '>04:34</p>
                                      </div>
                                    </div>
                                  </div>
                                </TECollapse>
                              </div>
                              {/* Two Accordian  */}
                              <div className="border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800 mc_borderdAccordian">
                                <h2 className="mb-0" id="headingTwo">
                                  <button className={`${ activeElement == "element2" ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400 dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]` : `transition-none rounded-b-[15px]`
                                    } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-sm text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
                                    type="button"
                                    onClick={() => handleClick("element2")}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    
                                    <span className='flex items-center w-full text-sm justify-between'>
                                      <span>The passage experienced a surge</span>
                                      <span className='mr-3'>45 Min</span>
                                    </span>
                                    <span className={`${ activeElement == "element2" ? `rotate-[-180deg] -mr-1` : `rotate-0 fill-[#212529] dark:fill-white`
                                      } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                    >
                                      {svg.app.arrowDownIcon}
                                    </span>
                                  </button>
                                </h2>
                                <TECollapse show={activeElement == "element2"} className="!mt-0 !rounded-b-none !shadow-none" >
                                  <div className="px-5 py-4 text-sm">
                                    <strong>This is the second item's accordion body.</strong> Lorem
                                    ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu
                                    rhoncus purus, vitae tincidunt nibh. Vivamus elementum egestas
                                    ligula in varius. Proin ac erat pretium, ultricies leo at, cursus
                                    ante. Pellentesque at odio euismod, mattis urna ac, accumsan metus.
                                    Nam nisi leo, malesuada vitae pretium et, laoreet at lorem.
                                    Curabitur non sollicitudin neque.
                                  </div>
                                </TECollapse>
                              </div>

                              {/* Three Accordian  */}
                              <div className="border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800 mc_borderdAccordian">
                                <h2 className="accordion-header mb-0" id="headingThree">
                                  <button
                                    className={`${activeElement == "element3" ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400 dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]` : `transition-none rounded-b-[15px]`
                                    } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-sm text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
                                    type="button"
                                    onClick={() => handleClick("element3")}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    
                                    <span className='flex items-center w-full text-sm justify-between'>
                                      <span>Popularity during the 1960s</span>
                                      <span className='mr-3'>45 Min</span>
                                    </span>
                                    <span className={`${activeElement == "element3"? `rotate-[-180deg] -mr-1`: `rotate-0 fill-[#212529] dark:fill-white`
                                      } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                    >
                                    {svg.app.arrowDownIcon}
                                  </span>
                                  </button>
                                </h2>
                                <TECollapse show={activeElement == "element3"} className="!mt-0 !shadow-none">
                                  <div className="px-5 py-4 text-sm">
                                    <strong>This is the third item's accordion body.</strong>Lorem ipsum
                                    dolor sit amet, consectetur adipiscing elit. Vestibulum eu rhoncus
                                    purus, vitae tincidunt nibh. Vivamus elementum egestas ligula in
                                    varius. Proin ac erat pretium, ultricies leo at, cursus ante.
                                    Pellentesque at odio euismod, mattis urna ac, accumsan metus. Nam
                                    nisi leo, malesuada vitae pretium et, laoreet at lorem. Curabitur
                                    non sollicitudin neque.
                                  </div>
                                </TECollapse>
                              </div>
                              {/* Four Accordian  */}
                              <div className="border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800 mc_borderdAccordian">
                                <h2 className="accordion-header mb-0" id="headingFour">
                                  <button  className={`${ activeElement == "element4" ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400 dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]` : `transition-none rounded-b-[15px]`
                                    } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-sm text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
                                    type="button"
                                    onClick={() => handleClick("element4")}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    
                                    <span className='flex items-center w-full text-sm justify-between'>
                                      <span>When Letraset used it</span>
                                      <span className='mr-3'>45 Min</span>
                                    </span>
                                    <span className={`${ activeElement == "element4" ? `rotate-[-180deg] -mr-1` : `rotate-0 fill-[#212529] dark:fill-white`
                                      } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                    >
                                    {svg.app.arrowDownIcon}
                                  </span>
                                  </button>
                                </h2>
                                <TECollapse  show={activeElement == "element4"} className="!mt-0 !shadow-none" >
                                  <div className="px-5 py-4 text-sm">
                                    <strong>This is the third item's accordion body.</strong>Lorem ipsum
                                    dolor sit amet, consectetur adipiscing elit. Vestibulum eu rhoncus
                                    purus, vitae tincidunt nibh. Vivamus elementum egestas ligula in
                                    varius. Proin ac erat pretium, ultricies leo at, cursus ante.
                                    Pellentesque at odio euismod, mattis urna ac, accumsan metus. Nam
                                    nisi leo, malesuada vitae pretium et, laoreet at lorem. Curabitur
                                    non sollicitudin neque.
                                  </div>
                                </TECollapse>
                              </div>
                            </div>
                            <a href={() => false} className='text-sm mc_unLink text-black font-medium'>View More</a>

                          </div>
                        </div>
                    </div>
                    : activeTab == 'Outcomes' ? 
                      <div className='col-span-3 mc_moboilCol_12'>
                          <h4 className='text-base text-black mt-3 font-semibold'>Course Outcomes</h4>
                          <p className='text-gray-900 text-base mt-4 max-w-80 line-height-20'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book</p>
                          <p className='mt-6 text-gray-900 text-base max-w-80 line-height-20'>
                          The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it’s seen all around the web; on templates,
                          </p>
                          
                          <h4 className='text-base text-black mt-6 font-semibold'>Key Outcomes</h4>
                          <ul className='mc_ulList px-0 py-3'>
                              <li className='flex items-center text-black text-sm'><span className='mr-3'>{svg.app.greencircleIcon}</span> Lorem ipsum, or lipsum as it is sometimes known, is dummy</li> 
                              <li className='flex items-center text-black text-sm'><span className='mr-3'>{svg.app.greencircleIcon}</span> Text used in laying out print, graphic or web designs. The passage is</li> 
                              <li className='flex items-center text-black text-sm'><span className='mr-3'>{svg.app.greencircleIcon}</span> Attributed to an unknown typesetter in the 15th</li>
                              <li className='flex items-center text-black text-sm'><span className='mr-3'>{svg.app.greencircleIcon}</span> Century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum</li>
                              <li className='flex items-center text-black text-sm'><span className='mr-3'>{svg.app.greencircleIcon}</span> Et Malorum for use in a type specimen book</li>
                          </ul>
                        </div>
                    : activeTab == 'RecentActivities' ? 
                        <div className='col-span-3 mc_moboilCol_12'>
                          <h4 className='text-base text-black mt-3 font-semibold'>Recent Activities [20]</h4>
                          <p className='text-gray-900 text-base mt-4 max-w-80 line-height-20'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown</p>
                          <div className='mc_listAccorfian'>
                            <div className='mc_borderes_userList flex flex-1 py-5 mt-5'>
                              <div className='mc_playBtn flex justify-center items-center'>
                                  <span className='mr-4'>
                                    <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                  </span>
                              </div>
                              <div className='mc_ueserLists_dv w-full'>
                                  <p className='text-black text-sm font-semibold'>John Doe</p>
                                  <span className='flex justify-between items-center text-gray-500 w-full text-sm'>
                                    <span className='text-sm'>bought this course</span>
                                    <span>3m ago</span>
                                  </span>
                              </div>
                            </div>
                            <div className='mc_borderes_userList flex flex-1 py-5'>
                              <div className='mc_playBtn flex justify-center items-center'>
                                  <span className='mr-4'>
                                    <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                  </span>
                              </div>
                              <div className='mc_ueserLists_dv w-full'>
                                  <div className='flex justify-between items-center text-gray-500 w-full text-sm'>
                                    <div className='text-left'>
                                      <p className='text-black text-sm font-semibold'>John Doe</p>
                                      <p className='text-sm'>The Course Has Been Purchased</p>
                                    </div>
                                    <div className='text-right'>
                                      <p className='text-black text-sm font-semibold'>Existing Student</p>
                                      <p className='text-sm'>03:00 Mn Ago</p>
                                    </div>
                                  </div>
                              </div>
                            </div>
                            <div className='mc_borderes_userList flex flex-1 py-5'>
                              <div className='mc_playBtn flex justify-center items-center'>
                                  <span className='mr-4' >
                                    <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                  </span>
                              </div>
                              <div className='mc_ueserLists_dv w-full'>
                                  <div className='flex justify-between items-center text-gray-500 w-full'>
                                    <div className='text-left'>
                                      <p className='text-black text-sm font-semibold'>John Doe</p>
                                      <p className='text-sm'>The Course Has Been Purchased</p>
                                    </div>
                                    <div className='text-right'>
                                      <p className='text-black text-sm font-semibold'>Existing Student</p>
                                      <p className='text-sm'>03:00 Mn Ago</p>
                                    </div>
                                  </div>
                              </div>
                            </div>
                            <div className='mc_borderes_userList flex flex-1 py-5'>
                              <div className='mc_playBtn flex justify-center items-center'>
                                  <span className='mr-4' >
                                    <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                  </span>
                              </div>
                              <div className='mc_ueserLists_dv w-full'>
                                  <div className='flex justify-between items-center text-gray-500 w-full'>
                                    <div className='text-left'>
                                      <p className='text-black text-sm font-semibold'>John Doe</p>
                                      <p className='text-sm'>The Course Has Been Purchased</p>
                                    </div>
                                    <div className='text-right'>
                                      <p className='text-black text-sm font-semibold'>Existing Student</p>
                                      <p className='text-sm'>03:00 Mn Ago</p>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                            <div className='flex w-full items-center justify-center mt-12'>
                              <a href={() => false} className='mc_unLink font-bold text-black text-sm'>View More Activities</a>
                            </div>
                        </div> 
                    : activeTab == 'Reviews' ? 
                      <div className='col-span-3 mc_moboilCol_12'>
                        <h4 className='text-base text-black mt-3 font-semibold'>Reviews</h4>
                      
                        <div className='mc_listAccorfian'>
                          <div className='mc_borseredBox flex flex-wrap py-5 px-4 mt-5'>
                            <div className='mc_playBtn flex justify-center items-center w-full relative'>
                                <span className='mr-4'>
                                  <span className='absolute right-0 top-0 text-black text-sm'>Posted 2 days ago</span>
                                  <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                </span>
                                <div className='mc_ueserLists_dv w-full'>
                                    <p className='text-base text-black font-semibold'>John Doe</p>
                                    <span className='flex justify-between items-center text-gray-500 w-full text-sm'>
                                      <span className='text-sm text-black mt-1'>This class <b>Exceeded</b> my expectations!</span>
                                    </span>
                                </div>
                            </div>
                            <p className='text-sm text-black w-full my-3'>Amazing teacher and great lessons, highly recommend this and any other course by Dan! :)</p>
                            <ul className="flex justify-start w-full">
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                            </ul>
                          </div>
                          
                          <div className='mc_borseredBox flex flex-wrap py-5 px-4 mt-5'>
                            <div className='mc_playBtn flex justify-center items-center w-full relative'>
                                <span className='mr-4'>
                                  <span className='absolute right-0 top-0 text-black text-sm'>Posted 2 days ago</span>
                                  <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                </span>
                                <div className='mc_ueserLists_dv w-full'>
                                    <p className='text-base text-black font-semibold'>John Doe</p>
                                    <span className='flex justify-between items-center text-gray-500 w-full text-sm'>
                                      <span className='text-sm text-black mt-1'>This class <b>Exceeded</b> my expectations!</span>
                                    </span>
                                </div>
                            </div>
                            <p className='text-sm text-black w-full my-3'>Amazing teacher and great lessons, highly recommend this and any other course by Dan! :)</p>
                            <ul className="flex justify-start w-full">
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                            </ul>
                          </div>
                          
                          <div className='mc_borseredBox flex flex-wrap py-5 px-4 mt-5'>
                            <div className='mc_playBtn flex justify-center items-center w-full relative'>
                                <span className='mr-4'>
                                  <span className='absolute right-0 top-0 text-black text-sm'>Posted 2 days ago</span>
                                  <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                </span>
                                <div className='mc_ueserLists_dv w-full'>
                                    <p className='text-base text-black font-semibold'>John Doe</p>
                                    <span className='flex justify-between items-center text-gray-500 w-full text-sm'>
                                      <span className='text-sm text-black mt-1'>This class <b>Exceeded</b> my expectations!</span>
                                    </span>
                                </div>
                            </div>
                            <p className='text-sm text-black w-full my-3'>Amazing teacher and great lessons, highly recommend this and any other course by Dan! :)</p>
                            <ul className="flex justify-start w-full">
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                            </ul>
                          </div>
                          
                          <div className='mc_borseredBox flex flex-wrap py-5 px-4 mt-5'>
                            <div className='mc_playBtn flex justify-center items-center w-full relative'>
                                <span className='mr-4'>
                                  <span className='absolute right-0 top-0 text-black text-sm'>Posted 2 days ago</span>
                                  <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/>
                                </span>
                                <div className='mc_ueserLists_dv w-full'>
                                    <p className='text-base text-black font-semibold'>John Doe</p>
                                    <span className='flex justify-between items-center text-gray-500 w-full text-sm'>
                                      <span className='text-sm text-black mt-1'>This class <b>Exceeded</b> my expectations!</span>
                                    </span>
                                </div>
                            </div>
                            <p className='text-sm text-black w-full my-3'>Amazing teacher and great lessons, highly recommend this and any other course by Dan! :)</p>
                            <ul className="flex justify-start w-full">
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                              <li>{svg.app.ratedStar}</li>
                            </ul>
                          </div>
                        </div>
                        <div className='flex w-full items-center justify-center mt-12'>
                          <a href={() => false} className='mc_unLink font-bold text-black text-sm'>View More</a>
                        </div>
                      </div> 
                    
                    : activeTab == 'Lederboard' ? 
                        <div className='col-span-3 mc_moboilCol_12'>
                          <h4 className='text-base text-black mt-3 font-semibold'>Top 100 Members</h4>
                          <p className='text-gray-900 text-base mt-2 max-w-80 line-height-20'>Do more activities & increase your points | <a href={() => false} className='color-themeColor mc_unLink'>How to increase my points?</a></p>

                          <div className='mc_tabMenu'>
                            <ul className='mc_btnList flex flex-wrap items-center mt-4  max-w-80'>
                              <li className={`text-black text-sm mr-2 mb-3 ${activeInnerTab=='All' ? 'isActiveTab' : ''}`}>
                                  <a href={() => false} className='mc_grayBtn' onClick={() => setInnerActiveTab('All')}>All</a>
                              </li>
                              <li className={`text-black text-sm mr-2 mb-3 ${activeInnerTab=='7Day' ? 'isActiveTab' : ''}`}>
                                  <a href={() => false} className='mc_grayBtn' onClick={() => setInnerActiveTab('7Day')}>Last 7 Days</a>
                              </li> 
                              <li className={`text-black text-sm mr-2 mb-3 ${activeInnerTab=='1Month' ? 'isActiveTab' : ''}`}>
                                  <a href={() => false} className='mc_grayBtn' onClick={() => setInnerActiveTab('1Month')}>Last 1 Month</a>
                              </li> 
                            </ul>
                          </div>
                          <div className='mc_gridRow'>
                            {activeInnerTab=='All' ? 
                              <div className='mt-4 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                {members.map((items, index)=> {
                                  return (
                                    <div key={index} className='mc_userCountList'>
                                      <div className='flex items-center'>
                                        <span className='mc_imgolder relative mr-4'>
                                          <img src={items.profilePic} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                          <span className='mc_countNum'>{items.position}</span>
                                        </span>
                                        <div className='mc_userName_'>
                                          <h5 className='text-base font-bold text-black'>{items.memberName}</h5>
                                          <h6 className='text-sm font-semibold text-black'>Points: {items.points}, <span className='text-gray-500 font-medium'>{items.badges} Badges</span></h6>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            : activeInnerTab=='7Day' ? 
                            <div className='mt-4 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                              <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                              </div>
                            </div>
                            : activeInnerTab == '1Month' ? 
                              <div className='mt-4 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8'>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_userCountList'>
                                  <div className='flex items-center'>
                                    <span className='mc_imgolder relative mr-4'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='user img' className='rounded-full' width={'59px'} height={'59px'}/>
                                      <span className='mc_countNum'>1st</span>
                                    </span>
                                    <div className='mc_userName_'>
                                      <h5 className='text-base font-bold text-black'>Jhone Doe</h5>
                                      <h6 className='text-sm font-semibold text-black'>Points: 350, <span className='text-gray-500 font-medium'>4 Badges</span></h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            :''
                          }

                        </div>

                      </div> 
                  : <></> 
                }
                <div className='col-span-1 mc_moboilCol_12 mt-8'>
                  <ActiveUsers activeUser={'Recent Activities [230]'} userName={'Meera Doen' } followers={'100 Members'} />
                    
                </div>
              </div>
            </div>
        </div>
    </>
  )
}

export default CourseDetails
import  { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";

export default function VimeoVideoPlayer(props) {
  
  const playerRef = useRef();
  const playe= useRef();
  const id = props.id;
  const [currentTimeNew, setCurrentTimeNew] = useState(0);
  const [isloaderOn, setIsloaderOn] = useState(false);
  // window.videoDuration = 0;
  // console.log(currentTimeNew, "Sdsdsd")
  useEffect(() => {
    let options = {
      id: id,
      loop: false,
      autoplay: false,
      controlBar: {
        children: [
          'mediaLoader',
          'posterImage',
          'titleBar',
          'textTrackDisplay',
          'loadingSpinner',
          'bigPlayButton',
          'liveTracker',
          'controlBar',
          'errorDisplay',
          'textTrackSettings',
          'resizeManager',
          'playbackRates',
          "playToggle",
          "progressControl",
          "volumePanel",
          "currentTimeDisplay",
          "timeDivider",
          "durationDisplay",
          "liveDisplay",
          "playbackRateMenuButton",
          "chaptersButton",
          "fullscreenToggle" //ensure we show the remaining time display here
        ],
        // playbackRates: [0.5, 1, 1.5, 2],
        // playsinline: true,
        // plugins: {
        //   foo: {bar: true},
        //   boo: {baz: false}
        // }
      },
    };
    // console.log(props, 'showFloatVideo');
    if(props.showFloatVideo == true){
      setIsloaderOn(true);
      setTimeout(() => {
        setIsloaderOn(false);
      }, 1000);
    }
    if (playerRef.current !== null) {
      
      playe.current = new Player(playerRef.current, options);
      playe.current.on("play", () => {
            playe.current.getCurrentTime().then(function(seconds) {
                window.videoDuration = seconds;
                setCurrentTimeNew(seconds);
            }).catch(function(error) {
            
            })
      });
      
      if( window.videoDuration) playe.current.setCurrentTime(window.videoDuration).then(function(seconds) {
          return playe.current.play();
      }).catch(function(error) {});

      // const interval = setInterval(onTimeUpdate, 1000); // Update every second
      playe.current.on('timeupdate', onTimeUpdate);

      return () => {
        // player.off('timeupdate', onTimeUpdate);
        // clearInterval(interval);
      }

    }
  }, [id]);

  const onTimeUpdate = async () =>  { 
          playe.current.getCurrentTime().then(function(seconds) {
              window.videoDuration = seconds;
              setCurrentTimeNew(seconds);
        }).catch(function(error) {
            // setIsloaderOn(true)
          })

    // let time=await playe.current.getCurrentTime()
    // setCurrentTimeNew(time);
  };
 
  useEffect(()=>{
    window.videoDuration = currentTimeNew
  },[currentTimeNew])
  
  return (
    <div className="spinnerLoader">
      { 
        isloaderOn ? 
          <div
          className="inline-block h-8 w-8 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-current align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
          role="status">
          <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span>
        </div> :
          <div ref={playerRef} id="myViemoIframe"></div>
      }
    </div>
  );
}

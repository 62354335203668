export let emojiObject = [
    {
        title : "Emoticons",
        icon : "😂",
        list : [
            {
                "code": "U+1F601",
                "icon": "😁",
                "title": "grinning face with smiling eyes laughing"
            },
            {
                "code": "U+1F602",
                "icon": "😂",
                "title": "face with tears of joy"
            },
            {
                "code": "U+1F603",
                "icon": "😃",
                "title": "smiling face with open mouth"
            },
            {
                "code": "U+1F604",
                "icon": "😄",
                "title": "smiling face with open mouth and smiling eyes"
            },
            {
                "code": "U+1F605",
                "icon": "😅",
                "title": "smiling face with open mouth and cold sweat"
            },
            {
                "code": "U+1F606",
                "icon": "😆",
                "title": "smiling face with open mouth and tightly-closed eyes"
            },
            {
                "code": "U+1F609",
                "icon": "😉",
                "title": "winking face"
            },
            {
                "code": "U+1F60A",
                "icon": "😊",
                "title": "smiling face with smiling eyes"
            },
            {
                "code": "U+1F60B",
                "icon": "😋",
                "title": "face savouring delicious food"
            },
            {
                "code": "U+1F60C",
                "icon": "😌",
                "title": "relieved face"
            },
            {
                "code": "U+1F60D",
                "icon": "😍",
                "title": "smiling face with heart-shaped eyes"
            },
            {
                "code": "U+1F60F",
                "icon": "😏",
                "title": "smirking face"
            },
            {
                "code": "U+1F612",
                "icon": "😒",
                "title": "unamused face"
            },
            {
                "code": "U+1F613",
                "icon": "😓",
                "title": "face with cold sweat"
            },
            {
                "code": "U+1F614",
                "icon": "😔",
                "title": "pensive face"
            },
            {
                "code": "U+1F616",
                "icon": "😖",
                "title": "confounded face"
            },
            {
                "code": "U+1F618",
                "icon": "😘",
                "title": "face throwing a kiss"
            },
            {
                "code": "U+1F61A",
                "icon": "😚",
                "title": "kissing face with closed eyes"
            },
            {
                "code": "U+1F61C",
                "icon": "😜",
                "title": "face with stuck-out tongue and winking eye"
            },
            {
                "code": "U+1F61D",
                "icon": "😝",
                "title": "face with stuck-out tongue and tightly-closed eyes"
            },
            {
                "code": "U+1F61E",
                "icon": "😞",
                "title": "disappointed face"
            },
            {
                "code": "U+1F620",
                "icon": "😠",
                "title": "angry face"
            },
            {
                "code": "U+1F621",
                "icon": "😡",
                "title": "pouting face"
            },
            {
                "code": "U+1F622",
                "icon": "😢",
                "title": "crying face"
            },
            {
                "code": "U+1F623",
                "icon": "😣",
                "title": "persevering face"
            },
            {
                "code": "U+1F624",
                "icon": "😤",
                "title": "face with look of triumph"
            },
            {
                "code": "U+1F625",
                "icon": "😥",
                "title": "disappointed but relieved face"
            },
            {
                "code": "U+1F628",
                "icon": "😨",
                "title": "fearful face"
            },
            {
                "code": "U+1F629",
                "icon": "😩",
                "title": "weary face"
            },
            {
                "code": "U+1F62A",
                "icon": "😪",
                "title": "sleepy face"
            },
            {
                "code": "U+1F62B",
                "icon": "😫",
                "title": "tired face"
            },
            {
                "code": "U+1F62D",
                "icon": "😭",
                "title": "loudly crying face"
            },
            {
                "code": "U+1F630",
                "icon": "😰",
                "title": "face with open mouth and cold sweat"
            },
            {
                "code": "U+1F631",
                "icon": "😱",
                "title": "face screaming in fear"
            },
            {
                "code": "U+1F632",
                "icon": "😲",
                "title": "astonished face"
            },
            {
                "code": "U+1F633",
                "icon": "😳",
                "title": "flushed face"
            },
            {
                "code": "U+1F635",
                "icon": "😵",
                "title": "dizzy face"
            },
            {
                "code": "U+1F637",
                "icon": "😷",
                "title": "face with medical mask"
            },
            {
                "code": "U+1F638",
                "icon": "😸",
                "title": "grinning cat face with smiling eyes"
            },
            {
                "code": "U+1F639",
                "icon": "😹",
                "title": "cat face with tears of joy"
            },
            {
                "code": "U+1F63A",
                "icon": "😺",
                "title": "smiling cat face with open mouth"
            },
            {
                "code": "U+1F63B",
                "icon": "😻",
                "title": "smiling cat face with heart-shaped eyes"
            },
            {
                "code": "U+1F63C",
                "icon": "😼",
                "title": "cat face with wry smile"
            },
            {
                "code": "U+1F63D",
                "icon": "😽",
                "title": "kissing cat face with closed eyes"
            },
            {
                "code": "U+1F63E",
                "icon": "😾",
                "title": "pouting cat face"
            },
            {
                "code": "U+1F63F",
                "icon": "😿",
                "title": "crying cat face"
            },
            {
                "code": "U+1F640",
                "icon": "🙀",
                "title": "weary cat face"
            },
            {
                "code": "U+1F645",
                "icon": "🙅",
                "title": "face with no good gesture"
            },
            {
                "code": "U+1F646",
                "icon": "🙆",
                "title": "face with ok gesture"
            },
            {
                "code": "U+1F647",
                "icon": "🙇",
                "title": "person bowing deeply"
            },
            {
                "code": "U+1F648",
                "icon": "🙈",
                "title": "see-no-evil monkey"
            },
            {
                "code": "U+1F649",
                "icon": "🙉",
                "title": "hear-no-evil monkey"
            },
            {
                "code": "U+1F64A",
                "icon": "🙊",
                "title": "speak-no-evil monkey"
            },
            {
                "code": "U+1F64B",
                "icon": "🙋",
                "title": "happy person raising one hand"
            },
            {
                "code": "U+1F64C",
                "icon": "🙌",
                "title": "person raising both hands in celebration"
            },
            {
                "code": "U+1F64D",
                "icon": "🙍",
                "title": "person frowning"
            },
            {
                "code": "U+1F64E",
                "icon": "🙎",
                "title": "person with pouting face"
            },
            {
                "code": "U+1F64F",
                "icon": "🙏",
                "title": "person with folded hands"
            }
        ]
    },
    {
        title : "Dingbats",
        icon : "✅",
        list :  [
            {
                "code": "U+2702",
                "icon": "✂",
                "title": "black scissors"
            },
            {
                "code": "U+2705",
                "icon": "✅",
                "title": "white heavy check mark"
            },
            {
                "code": "U+2708",
                "icon": "✈",
                "title": "airplane"
            },
            {
                "code": "U+2709",
                "icon": "✉",
                "title": "envelope"
            },
            {
                "code": "U+270A",
                "icon": "✊",
                "title": "raised fist"
            },
            {
                "code": "U+270B",
                "icon": "✋",
                "title": "raised hand"
            },
            {
                "code": "U+270C",
                "icon": "✌",
                "title": "victory hand"
            },
            {
                "code": "U+270F",
                "icon": "✏",
                "title": "pencil"
            },
            {
                "code": "U+2712",
                "icon": "✒",
                "title": "black nib"
            },
            {
                "code": "U+2714",
                "icon": "✔",
                "title": "heavy check mark"
            },
            {
                "code": "U+2716",
                "icon": "✖",
                "title": "heavy multiplication x"
            },
            {
                "code": "U+2728",
                "icon": "✨",
                "title": "sparkles"
            },
            {
                "code": "U+2733",
                "icon": "✳",
                "title": "eight spoked asterisk"
            },
            {
                "code": "U+2734",
                "icon": "✴",
                "title": "eight pointed black star"
            },
            {
                "code": "U+2744",
                "icon": "❄",
                "title": "snowflake"
            },
            {
                "code": "U+2747",
                "icon": "❇",
                "title": "sparkle"
            },
            {
                "code": "U+274C",
                "icon": "❌",
                "title": "cross mark"
            },
            {
                "code": "U+274E",
                "icon": "❎",
                "title": "negative squared cross mark"
            },
            {
                "code": "U+2753",
                "icon": "❓",
                "title": "black question mark ornament"
            },
            {
                "code": "U+2754",
                "icon": "❔",
                "title": "white question mark ornament"
            },
            {
                "code": "U+2755",
                "icon": "❕",
                "title": "white exclamation mark ornament"
            },
            {
                "code": "U+2757",
                "icon": "❗",
                "title": "heavy exclamation mark symbol"
            },
            {
                "code": "U+2764",
                "icon": "❤",
                "title": "heavy black heart"
            },
            {
                "code": "U+2795",
                "icon": "➕",
                "title": "heavy plus sign"
            },
            {
                "code": "U+2796",
                "icon": "➖",
                "title": "heavy minus sign"
            },
            {
                "code": "U+2797",
                "icon": "➗",
                "title": "heavy division sign"
            },
            {
                "code": "U+27A1",
                "icon": "➡",
                "title": "black rightwards arrow"
            },
            {
                "code": "U+27B0",
                "icon": "➰",
                "title": "curly loop"
            }
        ]
    },
    {
        title : "Transport and map symbols",
        icon : "🚄",
        list :  [
            {
                "code": "U+1F680",
                "icon": "🚀",
                "title": "rocket"
            },
            {
                "code": "U+1F683",
                "icon": "🚃",
                "title": "railway car"
            },
            {
                "code": "U+1F684",
                "icon": "🚄",
                "title": "high-speed train"
            },
            {
                "code": "U+1F685",
                "icon": "🚅",
                "title": "high-speed train with bullet nose"
            },
            {
                "code": "U+1F687",
                "icon": "🚇",
                "title": "metro"
            },
            {
                "code": "U+1F689",
                "icon": "🚉",
                "title": "station"
            },
            {
                "code": "U+1F68C",
                "icon": "🚌",
                "title": "bus"
            },
            {
                "code": "U+1F68F",
                "icon": "🚏",
                "title": "bus stop"
            },
            {
                "code": "U+1F691",
                "icon": "🚑",
                "title": "ambulance"
            },
            {
                "code": "U+1F692",
                "icon": "🚒",
                "title": "fire engine"
            },
            {
                "code": "U+1F693",
                "icon": "🚓",
                "title": "police car"
            },
            {
                "code": "U+1F695",
                "icon": "🚕",
                "title": "taxi"
            },
            {
                "code": "U+1F697",
                "icon": "🚗",
                "title": "automobile"
            },
            {
                "code": "U+1F699",
                "icon": "🚙",
                "title": "recreational vehicle"
            },
            {
                "code": "U+1F69A",
                "icon": "🚚",
                "title": "delivery truck"
            },
            {
                "code": "U+1F6A2",
                "icon": "🚢",
                "title": "ship"
            },
            {
                "code": "U+1F6A4",
                "icon": "🚤",
                "title": "speedboat"
            },
            {
                "code": "U+1F6A5",
                "icon": "🚥",
                "title": "horizontal traffic light"
            },
            {
                "code": "U+1F6A7",
                "icon": "🚧",
                "title": "construction sign"
            },
            {
                "code": "U+1F6A8",
                "icon": "🚨",
                "title": "police cars revolving light"
            },
            {
                "code": "U+1F6A9",
                "icon": "🚩",
                "title": "triangular flag on post"
            },
            {
                "code": "U+1F6AA",
                "icon": "🚪",
                "title": "door"
            },
            {
                "code": "U+1F6AB",
                "icon": "🚫",
                "title": "no entry sign"
            },
            {
                "code": "U+1F6AC",
                "icon": "🚬",
                "title": "smoking symbol"
            },
            {
                "code": "U+1F6AD",
                "icon": "🚭",
                "title": "no smoking symbol"
            },
            {
                "code": "U+1F6B2",
                "icon": "🚲",
                "title": "bicycle"
            },
            {
                "code": "U+1F6B6",
                "icon": "🚶",
                "title": "pedestrian"
            },
            {
                "code": "U+1F6B9",
                "icon": "🚹",
                "title": "mens symbol"
            },
            {
                "code": "U+1F6BA",
                "icon": "🚺",
                "title": "womens symbol"
            },
            {
                "code": "U+1F6BB",
                "icon": "🚻",
                "title": "restroom"
            },
            {
                "code": "U+1F6BC",
                "icon": "🚼",
                "title": "baby symbol"
            },
            {
                "code": "U+1F6BD",
                "icon": "🚽",
                "title": "toilet"
            },
            {
                "code": "U+1F6BE",
                "icon": "🚾",
                "title": "water closet"
            },
            {
                "code": "U+1F6C0",
                "icon": "🛀",
                "title": "bath"
            }
        ]
    },
    {
        title : "Enclosed characters",
        icon : "Ⓜ",
        list :  [
            {
                "code": "U+24C2",
                "icon": "Ⓜ",
                "title": "circled latin capital letter m"
            },
            {
                "code": "U+1F170",
                "icon": "🅰",
                "title": "negative squared latin capital letter a"
            },
            {
                "code": "U+1F171",
                "icon": "🅱",
                "title": "negative squared latin capital letter b"
            },
            {
                "code": "U+1F17E",
                "icon": "🅾",
                "title": "negative squared latin capital letter o"
            },
            {
                "code": "U+1F17F",
                "icon": "🅿",
                "title": "negative squared latin capital letter p"
            },
            {
                "code": "U+1F18E",
                "icon": "🆎",
                "title": "negative squared ab"
            },
            {
                "code": "U+1F191",
                "icon": "🆑",
                "title": "squared cl"
            },
            {
                "code": "U+1F192",
                "icon": "🆒",
                "title": "squared cool"
            },
            {
                "code": "U+1F193",
                "icon": "🆓",
                "title": "squared free"
            },
            {
                "code": "U+1F194",
                "icon": "🆔",
                "title": "squared id"
            },
            {
                "code": "U+1F195",
                "icon": "🆕",
                "title": "squared new"
            },
            {
                "code": "U+1F196",
                "icon": "🆖",
                "title": "squared ng"
            },
            {
                "code": "U+1F197",
                "icon": "🆗",
                "title": "squared ok"
            },
            {
                "code": "U+1F198",
                "icon": "🆘",
                "title": "squared sos"
            },
            {
                "code": "U+1F199",
                "icon": "🆙",
                "title": "squared up with exclamation mark"
            },
            {
                "code": "U+1F19A",
                "icon": "🆚",
                "title": "squared vs"
            },
            {
                "code": "U+1F1E9 U+1F1EA",
                "icon": "🇩🇪",
                "title": "regional indicator symbol letter d + regional indicator symbol letter e"
            },
            {
                "code": "U+1F1EC U+1F1E7",
                "icon": "🇬🇧",
                "title": "regional indicator symbol letter g + regional indicator symbol letter b"
            },
            {
                "code": "U+1F1E8 U+1F1F3",
                "icon": "🇨🇳",
                "title": "regional indicator symbol letter c + regional indicator symbol letter n"
            },
            {
                "code": "U+1F1EF U+1F1F5",
                "icon": "🇯🇵",
                "title": "regional indicator symbol letter j + regional indicator symbol letter p"
            },
            {
                "code": "U+1F1EB U+1F1F7",
                "icon": "🇫🇷",
                "title": "regional indicator symbol letter f + regional indicator symbol letter r"
            },
            {
                "code": "U+1F1F0 U+1F1F7",
                "icon": "🇰🇷",
                "title": "regional indicator symbol letter k + regional indicator symbol letter r"
            },
            {
                "code": "U+1F1EA U+1F1F8",
                "icon": "🇪🇸",
                "title": "regional indicator symbol letter e + regional indicator symbol letter s"
            },
            {
                "code": "U+1F1EE U+1F1F9",
                "icon": "🇮🇹",
                "title": "regional indicator symbol letter i + regional indicator symbol letter t"
            },
            {
                "code": "U+1F1F7 U+1F1FA",
                "icon": "🇷🇺",
                "title": "regional indicator symbol letter r + regional indicator symbol letter u"
            },
            {
                "code": "U+1F1FA U+1F1F8",
                "icon": "🇺🇸",
                "title": "regional indicator symbol letter u + regional indicator symbol letter s"
            },
            {
                "code": "U+1F201",
                "icon": "🈁",
                "title": "squared katakana koko"
            },
            {
                "code": "U+1F202",
                "icon": "🈂",
                "title": "squared katakana sa"
            },
            {
                "code": "U+1F21A",
                "icon": "🈚",
                "title": "squared cjk unified ideograph-7121"
            },
            {
                "code": "U+1F22F",
                "icon": "🈯",
                "title": "squared cjk unified ideograph-6307"
            },
            {
                "code": "U+1F232",
                "icon": "🈲",
                "title": "squared cjk unified ideograph-7981"
            },
            {
                "code": "U+1F233",
                "icon": "🈳",
                "title": "squared cjk unified ideograph-7a7a"
            },
            {
                "code": "U+1F234",
                "icon": "🈴",
                "title": "squared cjk unified ideograph-5408"
            },
            {
                "code": "U+1F235",
                "icon": "🈵",
                "title": "squared cjk unified ideograph-6e80"
            },
            {
                "code": "U+1F236",
                "icon": "🈶",
                "title": "squared cjk unified ideograph-6709"
            },
            {
                "code": "U+1F237",
                "icon": "🈷",
                "title": "squared cjk unified ideograph-6708"
            },
            {
                "code": "U+1F238",
                "icon": "🈸",
                "title": "squared cjk unified ideograph-7533"
            },
            {
                "code": "U+1F239",
                "icon": "🈹",
                "title": "squared cjk unified ideograph-5272"
            },
            {
                "code": "U+1F23A",
                "icon": "🈺",
                "title": "squared cjk unified ideograph-55b6"
            },
            {
                "code": "U+1F250",
                "icon": "🉐",
                "title": "circled ideograph advantage"
            },
            {
                "code": "U+1F251",
                "icon": "🉑",
                "title": "circled ideograph accept"
            }
        ]
    },
    {
        title : "Uncategorized",
        icon : "⌚",
        list :  [
            {
                "code": "U+00A9",
                "icon": "©",
                "title": "copyright sign"
            },
            {
                "code": "U+00AE",
                "icon": "®",
                "title": "registered sign"
            },
            {
                "code": "U+203C",
                "icon": "‼",
                "title": "double exclamation mark"
            },
            {
                "code": "U+2049",
                "icon": "⁉",
                "title": "exclamation question mark"
            },
            {
                "code": "U+0023 U+20E3",
                "icon": "#⃣",
                "title": "number sign + combining enclosing keycap"
            },
            {
                "code": "U+0030 U+20E3",
                "icon": "0⃣",
                "title": "digit zero + combining enclosing keycap"
            },
            {
                "code": "U+0031 U+20E3",
                "icon": "1⃣",
                "title": "digit one + combining enclosing keycap"
            },
            {
                "code": "U+0032 U+20E3",
                "icon": "2⃣",
                "title": "digit two + combining enclosing keycap"
            },
            {
                "code": "U+0033 U+20E3",
                "icon": "3⃣",
                "title": "digit three + combining enclosing keycap"
            },
            {
                "code": "U+0034 U+20E3",
                "icon": "4⃣",
                "title": "digit four + combining enclosing keycap"
            },
            {
                "code": "U+0035 U+20E3",
                "icon": "5⃣",
                "title": "digit five + combining enclosing keycap"
            },
            {
                "code": "U+0036 U+20E3",
                "icon": "6⃣",
                "title": "digit six + combining enclosing keycap"
            },
            {
                "code": "U+0037 U+20E3",
                "icon": "7⃣",
                "title": "digit seven + combining enclosing keycap"
            },
            {
                "code": "U+0038 U+20E3",
                "icon": "8⃣",
                "title": "digit eight + combining enclosing keycap"
            },
            {
                "code": "U+0039 U+20E3",
                "icon": "9⃣",
                "title": "digit nine + combining enclosing keycap"
            },
            {
                "code": "U+2122",
                "icon": "™",
                "title": "trade mark sign"
            },
            {
                "code": "U+2139",
                "icon": "ℹ",
                "title": "information source"
            },
            {
                "code": "U+2194",
                "icon": "↔",
                "title": "left right arrow"
            },
            {
                "code": "U+2195",
                "icon": "↕",
                "title": "up down arrow"
            },
            {
                "code": "U+2196",
                "icon": "↖",
                "title": "north west arrow"
            },
            {
                "code": "U+2197",
                "icon": "↗",
                "title": "north east arrow"
            },
            {
                "code": "U+2198",
                "icon": "↘",
                "title": "south east arrow"
            },
            {
                "code": "U+2199",
                "icon": "↙",
                "title": "south west arrow"
            },
            {
                "code": "U+21A9",
                "icon": "↩",
                "title": "leftwards arrow with hook"
            },
            {
                "code": "U+21AA",
                "icon": "↪",
                "title": "rightwards arrow with hook"
            },
            {
                "code": "U+231A",
                "icon": "⌚",
                "title": "watch"
            },
            {
                "code": "U+231B",
                "icon": "⌛",
                "title": "hourglass"
            },
            {
                "code": "U+23E9",
                "icon": "⏩",
                "title": "black right-pointing double triangle"
            },
            {
                "code": "U+23EA",
                "icon": "⏪",
                "title": "black left-pointing double triangle"
            },
            {
                "code": "U+23EB",
                "icon": "⏫",
                "title": "black up-pointing double triangle"
            },
            {
                "code": "U+23EC",
                "icon": "⏬",
                "title": "black down-pointing double triangle"
            },
            {
                "code": "U+23F0",
                "icon": "⏰",
                "title": "alarm clock"
            },
            {
                "code": "U+23F3",
                "icon": "⏳",
                "title": "hourglass with flowing sand"
            },
            {
                "code": "U+25AA",
                "icon": "▪",
                "title": "black small square"
            },
            {
                "code": "U+25AB",
                "icon": "▫",
                "title": "white small square"
            },
            {
                "code": "U+25B6",
                "icon": "▶",
                "title": "black right-pointing triangle"
            },
            {
                "code": "U+25C0",
                "icon": "◀",
                "title": "black left-pointing triangle"
            },
            {
                "code": "U+25FB",
                "icon": "◻",
                "title": "white medium square"
            },
            {
                "code": "U+25FC",
                "icon": "◼",
                "title": "black medium square"
            },
            {
                "code": "U+25FD",
                "icon": "◽",
                "title": "white medium small square"
            },
            {
                "code": "U+25FE",
                "icon": "◾",
                "title": "black medium small square"
            },
            {
                "code": "U+2600",
                "icon": "☀",
                "title": "black sun with rays"
            },
            {
                "code": "U+2601",
                "icon": "☁",
                "title": "cloud"
            },
            {
                "code": "U+260E",
                "icon": "☎",
                "title": "black telephone"
            },
            {
                "code": "U+2611",
                "icon": "☑",
                "title": "ballot box with check"
            },
            {
                "code": "U+2614",
                "icon": "☔",
                "title": "umbrella with rain drops"
            },
            {
                "code": "U+2615",
                "icon": "☕",
                "title": "hot beverage"
            },
            {
                "code": "U+261D",
                "icon": "☝",
                "title": "white up pointing index"
            },
            {
                "code": "U+263A",
                "icon": "☺",
                "title": "white smiling face"
            },
            {
                "code": "U+2648",
                "icon": "♈",
                "title": "aries"
            },
            {
                "code": "U+2649",
                "icon": "♉",
                "title": "taurus"
            },
            {
                "code": "U+264A",
                "icon": "♊",
                "title": "gemini"
            },
            {
                "code": "U+264B",
                "icon": "♋",
                "title": "cancer"
            },
            {
                "code": "U+264C",
                "icon": "♌",
                "title": "leo"
            },
            {
                "code": "U+264D",
                "icon": "♍",
                "title": "virgo"
            },
            {
                "code": "U+264E",
                "icon": "♎",
                "title": "libra"
            },
            {
                "code": "U+264F",
                "icon": "♏",
                "title": "scorpius"
            },
            {
                "code": "U+2650",
                "icon": "♐",
                "title": "sagittarius"
            },
            {
                "code": "U+2651",
                "icon": "♑",
                "title": "capricorn"
            },
            {
                "code": "U+2652",
                "icon": "♒",
                "title": "aquarius"
            },
            {
                "code": "U+2653",
                "icon": "♓",
                "title": "pisces"
            },
            {
                "code": "U+2660",
                "icon": "♠",
                "title": "black spade suit"
            },
            {
                "code": "U+2663",
                "icon": "♣",
                "title": "black club suit"
            },
            {
                "code": "U+2665",
                "icon": "♥",
                "title": "black heart suit"
            },
            {
                "code": "U+2666",
                "icon": "♦",
                "title": "black diamond suit"
            },
            {
                "code": "U+2668",
                "icon": "♨",
                "title": "hot springs"
            },
            {
                "code": "U+267B",
                "icon": "♻",
                "title": "black universal recycling symbol"
            },
            {
                "code": "U+267F",
                "icon": "♿",
                "title": "wheelchair symbol"
            },
            {
                "code": "U+2693",
                "icon": "⚓",
                "title": "anchor"
            },
            {
                "code": "U+26A0",
                "icon": "⚠",
                "title": "warning sign"
            },
            {
                "code": "U+26A1",
                "icon": "⚡",
                "title": "high voltage sign"
            },
            {
                "code": "U+26AA",
                "icon": "⚪",
                "title": "medium white circle"
            },
            {
                "code": "U+26AB",
                "icon": "⚫",
                "title": "medium black circle"
            },
            {
                "code": "U+26BD",
                "icon": "⚽",
                "title": "soccer ball"
            },
            {
                "code": "U+26BE",
                "icon": "⚾",
                "title": "baseball"
            },
            {
                "code": "U+26C4",
                "icon": "⛄",
                "title": "snowman without snow"
            },
            {
                "code": "U+26C5",
                "icon": "⛅",
                "title": "sun behind cloud"
            },
            {
                "code": "U+26CE",
                "icon": "⛎",
                "title": "ophiuchus"
            },
            {
                "code": "U+26D4",
                "icon": "⛔",
                "title": "no entry"
            },
            {
                "code": "U+26EA",
                "icon": "⛪",
                "title": "church"
            },
            {
                "code": "U+26F2",
                "icon": "⛲",
                "title": "fountain"
            },
            {
                "code": "U+26F3",
                "icon": "⛳",
                "title": "flag in hole"
            },
            {
                "code": "U+26F5",
                "icon": "⛵",
                "title": "sailboat"
            },
            {
                "code": "U+26FA",
                "icon": "⛺",
                "title": "tent"
            },
            {
                "code": "U+26FD",
                "icon": "⛽",
                "title": "fuel pump"
            },
            {
                "code": "U+2934",
                "icon": "⤴",
                "title": "arrow pointing rightwards then curving upwards"
            },
            {
                "code": "U+2935",
                "icon": "⤵",
                "title": "arrow pointing rightwards then curving downwards"
            },
            {
                "code": "U+2B05",
                "icon": "⬅",
                "title": "leftwards black arrow"
            },
            {
                "code": "U+2B06",
                "icon": "⬆",
                "title": "upwards black arrow"
            },
            {
                "code": "U+2B07",
                "icon": "⬇",
                "title": "downwards black arrow"
            },
            {
                "code": "U+2B1B",
                "icon": "⬛",
                "title": "black large square"
            },
            {
                "code": "U+2B1C",
                "icon": "⬜",
                "title": "white large square"
            },
            {
                "code": "U+2B50",
                "icon": "⭐",
                "title": "white medium star"
            },
            {
                "code": "U+2B55",
                "icon": "⭕",
                "title": "heavy large circle"
            },
            {
                "code": "U+3030",
                "icon": "〰",
                "title": "wavy dash"
            },
            {
                "code": "U+303D",
                "icon": "〽",
                "title": "part alternation mark"
            },
            {
                "code": "U+3297",
                "icon": "㊗",
                "title": "circled ideograph congratulation"
            },
            {
                "code": "U+3299",
                "icon": "㊙",
                "title": "circled ideograph secret"
            },
            {
                "code": "U+1F004",
                "icon": "🀄",
                "title": "mahjong tile red dragon"
            },
            {
                "code": "U+1F0CF",
                "icon": "🃏",
                "title": "playing card black joker"
            },
            {
                "code": "U+1F300",
                "icon": "🌀",
                "title": "cyclone"
            },
            {
                "code": "U+1F301",
                "icon": "🌁",
                "title": "foggy"
            },
            {
                "code": "U+1F302",
                "icon": "🌂",
                "title": "closed umbrella"
            },
            {
                "code": "U+1F303",
                "icon": "🌃",
                "title": "night with stars"
            },
            {
                "code": "U+1F304",
                "icon": "🌄",
                "title": "sunrise over mountains"
            },
            {
                "code": "U+1F305",
                "icon": "🌅",
                "title": "sunrise"
            },
            {
                "code": "U+1F306",
                "icon": "🌆",
                "title": "cityscape at dusk"
            },
            {
                "code": "U+1F307",
                "icon": "🌇",
                "title": "sunset over buildings"
            },
            {
                "code": "U+1F308",
                "icon": "🌈",
                "title": "rainbow"
            },
            {
                "code": "U+1F309",
                "icon": "🌉",
                "title": "bridge at night"
            },
            {
                "code": "U+1F30A",
                "icon": "🌊",
                "title": "water wave"
            },
            {
                "code": "U+1F30B",
                "icon": "🌋",
                "title": "volcano"
            },
            {
                "code": "U+1F30C",
                "icon": "🌌",
                "title": "milky way"
            },
            {
                "code": "U+1F30F",
                "icon": "🌏",
                "title": "earth globe asia-australia"
            },
            {
                "code": "U+1F311",
                "icon": "🌑",
                "title": "new moon symbol"
            },
            {
                "code": "U+1F313",
                "icon": "🌓",
                "title": "first quarter moon symbol"
            },
            {
                "code": "U+1F314",
                "icon": "🌔",
                "title": "waxing gibbous moon symbol"
            },
            {
                "code": "U+1F315",
                "icon": "🌕",
                "title": "full moon symbol"
            },
            {
                "code": "U+1F319",
                "icon": "🌙",
                "title": "crescent moon"
            },
            {
                "code": "U+1F31B",
                "icon": "🌛",
                "title": "first quarter moon with face"
            },
            {
                "code": "U+1F31F",
                "icon": "🌟",
                "title": "glowing star"
            },
            {
                "code": "U+1F320",
                "icon": "🌠",
                "title": "shooting star"
            },
            {
                "code": "U+1F330",
                "icon": "🌰",
                "title": "chestnut"
            },
            {
                "code": "U+1F331",
                "icon": "🌱",
                "title": "seedling"
            },
            {
                "code": "U+1F334",
                "icon": "🌴",
                "title": "palm tree"
            },
            {
                "code": "U+1F335",
                "icon": "🌵",
                "title": "cactus"
            },
            {
                "code": "U+1F337",
                "icon": "🌷",
                "title": "tulip"
            },
            {
                "code": "U+1F338",
                "icon": "🌸",
                "title": "cherry blossom"
            },
            {
                "code": "U+1F339",
                "icon": "🌹",
                "title": "rose"
            },
            {
                "code": "U+1F33A",
                "icon": "🌺",
                "title": "hibiscus"
            },
            {
                "code": "U+1F33B",
                "icon": "🌻",
                "title": "sunflower"
            },
            {
                "code": "U+1F33C",
                "icon": "🌼",
                "title": "blossom"
            },
            {
                "code": "U+1F33D",
                "icon": "🌽",
                "title": "ear of maize"
            },
            {
                "code": "U+1F33E",
                "icon": "🌾",
                "title": "ear of rice"
            },
            {
                "code": "U+1F33F",
                "icon": "🌿",
                "title": "herb"
            },
            {
                "code": "U+1F340",
                "icon": "🍀",
                "title": "four leaf clover"
            },
            {
                "code": "U+1F341",
                "icon": "🍁",
                "title": "maple leaf"
            },
            {
                "code": "U+1F342",
                "icon": "🍂",
                "title": "fallen leaf"
            },
            {
                "code": "U+1F343",
                "icon": "🍃",
                "title": "leaf fluttering in wind"
            },
            {
                "code": "U+1F344",
                "icon": "🍄",
                "title": "mushroom"
            },
            {
                "code": "U+1F345",
                "icon": "🍅",
                "title": "tomato"
            },
            {
                "code": "U+1F346",
                "icon": "🍆",
                "title": "aubergine"
            },
            {
                "code": "U+1F347",
                "icon": "🍇",
                "title": "grapes"
            },
            {
                "code": "U+1F348",
                "icon": "🍈",
                "title": "melon"
            },
            {
                "code": "U+1F349",
                "icon": "🍉",
                "title": "watermelon"
            },
            {
                "code": "U+1F34A",
                "icon": "🍊",
                "title": "tangerine"
            },
            {
                "code": "U+1F34C",
                "icon": "🍌",
                "title": "banana"
            },
            {
                "code": "U+1F34D",
                "icon": "🍍",
                "title": "pineapple"
            },
            {
                "code": "U+1F34E",
                "icon": "🍎",
                "title": "red apple"
            },
            {
                "code": "U+1F34F",
                "icon": "🍏",
                "title": "green apple"
            },
            {
                "code": "U+1F351",
                "icon": "🍑",
                "title": "peach"
            },
            {
                "code": "U+1F352",
                "icon": "🍒",
                "title": "cherries"
            },
            {
                "code": "U+1F353",
                "icon": "🍓",
                "title": "strawberry"
            },
            {
                "code": "U+1F354",
                "icon": "🍔",
                "title": "hamburger"
            },
            {
                "code": "U+1F355",
                "icon": "🍕",
                "title": "slice of pizza"
            },
            {
                "code": "U+1F356",
                "icon": "🍖",
                "title": "meat on bone"
            },
            {
                "code": "U+1F357",
                "icon": "🍗",
                "title": "poultry leg"
            },
            {
                "code": "U+1F358",
                "icon": "🍘",
                "title": "rice cracker"
            },
            {
                "code": "U+1F359",
                "icon": "🍙",
                "title": "rice ball"
            },
            {
                "code": "U+1F35A",
                "icon": "🍚",
                "title": "cooked rice"
            },
            {
                "code": "U+1F35B",
                "icon": "🍛",
                "title": "curry and rice"
            },
            {
                "code": "U+1F35C",
                "icon": "🍜",
                "title": "steaming bowl"
            },
            {
                "code": "U+1F35D",
                "icon": "🍝",
                "title": "spaghetti"
            },
            {
                "code": "U+1F35E",
                "icon": "🍞",
                "title": "bread"
            },
            {
                "code": "U+1F35F",
                "icon": "🍟",
                "title": "french fries"
            },
            {
                "code": "U+1F360",
                "icon": "🍠",
                "title": "roasted sweet potato"
            },
            {
                "code": "U+1F361",
                "icon": "🍡",
                "title": "dango"
            },
            {
                "code": "U+1F362",
                "icon": "🍢",
                "title": "oden"
            },
            {
                "code": "U+1F363",
                "icon": "🍣",
                "title": "sushi"
            },
            {
                "code": "U+1F364",
                "icon": "🍤",
                "title": "fried shrimp"
            },
            {
                "code": "U+1F365",
                "icon": "🍥",
                "title": "fish cake with swirl design"
            },
            {
                "code": "U+1F366",
                "icon": "🍦",
                "title": "soft ice cream"
            },
            {
                "code": "U+1F367",
                "icon": "🍧",
                "title": "shaved ice"
            },
            {
                "code": "U+1F368",
                "icon": "🍨",
                "title": "ice cream"
            },
            {
                "code": "U+1F369",
                "icon": "🍩",
                "title": "doughnut"
            },
            {
                "code": "U+1F36A",
                "icon": "🍪",
                "title": "cookie"
            },
            {
                "code": "U+1F36B",
                "icon": "🍫",
                "title": "chocolate bar"
            },
            {
                "code": "U+1F36C",
                "icon": "🍬",
                "title": "candy"
            },
            {
                "code": "U+1F36D",
                "icon": "🍭",
                "title": "lollipop"
            },
            {
                "code": "U+1F36E",
                "icon": "🍮",
                "title": "custard"
            },
            {
                "code": "U+1F36F",
                "icon": "🍯",
                "title": "honey pot"
            },
            {
                "code": "U+1F370",
                "icon": "🍰",
                "title": "shortcake"
            },
            {
                "code": "U+1F371",
                "icon": "🍱",
                "title": "bento box"
            },
            {
                "code": "U+1F372",
                "icon": "🍲",
                "title": "pot of food"
            },
            {
                "code": "U+1F373",
                "icon": "🍳",
                "title": "cooking"
            },
            {
                "code": "U+1F374",
                "icon": "🍴",
                "title": "fork and knife"
            },
            {
                "code": "U+1F375",
                "icon": "🍵",
                "title": "teacup without handle"
            },
            {
                "code": "U+1F376",
                "icon": "🍶",
                "title": "sake bottle and cup"
            },
            {
                "code": "U+1F377",
                "icon": "🍷",
                "title": "wine glass"
            },
            {
                "code": "U+1F378",
                "icon": "🍸",
                "title": "cocktail glass"
            },
            {
                "code": "U+1F379",
                "icon": "🍹",
                "title": "tropical drink"
            },
            {
                "code": "U+1F37A",
                "icon": "🍺",
                "title": "beer mug"
            },
            {
                "code": "U+1F37B",
                "icon": "🍻",
                "title": "clinking beer mugs"
            },
            {
                "code": "U+1F380",
                "icon": "🎀",
                "title": "ribbon"
            },
            {
                "code": "U+1F381",
                "icon": "🎁",
                "title": "wrapped present"
            },
            {
                "code": "U+1F382",
                "icon": "🎂",
                "title": "birthday cake"
            },
            {
                "code": "U+1F383",
                "icon": "🎃",
                "title": "jack-o-lantern"
            },
            {
                "code": "U+1F384",
                "icon": "🎄",
                "title": "christmas tree"
            },
            {
                "code": "U+1F385",
                "icon": "🎅",
                "title": "father christmas"
            },
            {
                "code": "U+1F386",
                "icon": "🎆",
                "title": "fireworks"
            },
            {
                "code": "U+1F387",
                "icon": "🎇",
                "title": "firework sparkler"
            },
            {
                "code": "U+1F388",
                "icon": "🎈",
                "title": "balloon"
            },
            {
                "code": "U+1F389",
                "icon": "🎉",
                "title": "party popper"
            },
            {
                "code": "U+1F38A",
                "icon": "🎊",
                "title": "confetti ball"
            },
            {
                "code": "U+1F38B",
                "icon": "🎋",
                "title": "tanabata tree"
            },
            {
                "code": "U+1F38C",
                "icon": "🎌",
                "title": "crossed flags"
            },
            {
                "code": "U+1F38D",
                "icon": "🎍",
                "title": "pine decoration"
            },
            {
                "code": "U+1F38E",
                "icon": "🎎",
                "title": "japanese dolls"
            },
            {
                "code": "U+1F38F",
                "icon": "🎏",
                "title": "carp streamer"
            },
            {
                "code": "U+1F390",
                "icon": "🎐",
                "title": "wind chime"
            },
            {
                "code": "U+1F391",
                "icon": "🎑",
                "title": "moon viewing ceremony"
            },
            {
                "code": "U+1F392",
                "icon": "🎒",
                "title": "school satchel"
            },
            {
                "code": "U+1F393",
                "icon": "🎓",
                "title": "graduation cap"
            },
            {
                "code": "U+1F3A0",
                "icon": "🎠",
                "title": "carousel horse"
            },
            {
                "code": "U+1F3A1",
                "icon": "🎡",
                "title": "ferris wheel"
            },
            {
                "code": "U+1F3A2",
                "icon": "🎢",
                "title": "roller coaster"
            },
            {
                "code": "U+1F3A3",
                "icon": "🎣",
                "title": "fishing pole and fish"
            },
            {
                "code": "U+1F3A4",
                "icon": "🎤",
                "title": "microphone"
            },
            {
                "code": "U+1F3A5",
                "icon": "🎥",
                "title": "movie camera"
            },
            {
                "code": "U+1F3A6",
                "icon": "🎦",
                "title": "cinema"
            },
            {
                "code": "U+1F3A7",
                "icon": "🎧",
                "title": "headphone"
            },
            {
                "code": "U+1F3A8",
                "icon": "🎨",
                "title": "artist palette"
            },
            {
                "code": "U+1F3A9",
                "icon": "🎩",
                "title": "top hat"
            },
            {
                "code": "U+1F3AA",
                "icon": "🎪",
                "title": "circus tent"
            },
            {
                "code": "U+1F3AB",
                "icon": "🎫",
                "title": "ticket"
            },
            {
                "code": "U+1F3AC",
                "icon": "🎬",
                "title": "clapper board"
            },
            {
                "code": "U+1F3AD",
                "icon": "🎭",
                "title": "performing arts"
            },
            {
                "code": "U+1F3AE",
                "icon": "🎮",
                "title": "video game"
            },
            {
                "code": "U+1F3AF",
                "icon": "🎯",
                "title": "direct hit"
            },
            {
                "code": "U+1F3B0",
                "icon": "🎰",
                "title": "slot machine"
            },
            {
                "code": "U+1F3B1",
                "icon": "🎱",
                "title": "billiards"
            },
            {
                "code": "U+1F3B2",
                "icon": "🎲",
                "title": "game die"
            },
            {
                "code": "U+1F3B3",
                "icon": "🎳",
                "title": "bowling"
            },
            {
                "code": "U+1F3B4",
                "icon": "🎴",
                "title": "flower playing cards"
            },
            {
                "code": "U+1F3B5",
                "icon": "🎵",
                "title": "musical note"
            },
            {
                "code": "U+1F3B6",
                "icon": "🎶",
                "title": "multiple musical notes"
            },
            {
                "code": "U+1F3B7",
                "icon": "🎷",
                "title": "saxophone"
            },
            {
                "code": "U+1F3B8",
                "icon": "🎸",
                "title": "guitar"
            },
            {
                "code": "U+1F3B9",
                "icon": "🎹",
                "title": "musical keyboard"
            },
            {
                "code": "U+1F3BA",
                "icon": "🎺",
                "title": "trumpet"
            },
            {
                "code": "U+1F3BB",
                "icon": "🎻",
                "title": "violin"
            },
            {
                "code": "U+1F3BC",
                "icon": "🎼",
                "title": "musical score"
            },
            {
                "code": "U+1F3BD",
                "icon": "🎽",
                "title": "running shirt with sash"
            },
            {
                "code": "U+1F3BE",
                "icon": "🎾",
                "title": "tennis racquet and ball"
            },
            {
                "code": "U+1F3BF",
                "icon": "🎿",
                "title": "ski and ski boot"
            },
            {
                "code": "U+1F3C0",
                "icon": "🏀",
                "title": "basketball and hoop"
            },
            {
                "code": "U+1F3C1",
                "icon": "🏁",
                "title": "chequered flag"
            },
            {
                "code": "U+1F3C2",
                "icon": "🏂",
                "title": "snowboarder"
            },
            {
                "code": "U+1F3C3",
                "icon": "🏃",
                "title": "runner"
            },
            {
                "code": "U+1F3C4",
                "icon": "🏄",
                "title": "surfer"
            },
            {
                "code": "U+1F3C6",
                "icon": "🏆",
                "title": "trophy"
            },
            {
                "code": "U+1F3C8",
                "icon": "🏈",
                "title": "american football"
            },
            {
                "code": "U+1F3CA",
                "icon": "🏊",
                "title": "swimmer"
            },
            {
                "code": "U+1F3E0",
                "icon": "🏠",
                "title": "house building"
            },
            {
                "code": "U+1F3E1",
                "icon": "🏡",
                "title": "house with garden"
            },
            {
                "code": "U+1F3E2",
                "icon": "🏢",
                "title": "office building"
            },
            {
                "code": "U+1F3E3",
                "icon": "🏣",
                "title": "japanese post office"
            },
            {
                "code": "U+1F3E5",
                "icon": "🏥",
                "title": "hospital"
            },
            {
                "code": "U+1F3E6",
                "icon": "🏦",
                "title": "bank"
            },
            {
                "code": "U+1F3E7",
                "icon": "🏧",
                "title": "automated teller machine"
            },
            {
                "code": "U+1F3E8",
                "icon": "🏨",
                "title": "hotel"
            },
            {
                "code": "U+1F3E9",
                "icon": "🏩",
                "title": "love hotel"
            },
            {
                "code": "U+1F3EA",
                "icon": "🏪",
                "title": "convenience store"
            },
            {
                "code": "U+1F3EB",
                "icon": "🏫",
                "title": "school"
            },
            {
                "code": "U+1F3EC",
                "icon": "🏬",
                "title": "department store"
            },
            {
                "code": "U+1F3ED",
                "icon": "🏭",
                "title": "factory"
            },
            {
                "code": "U+1F3EE",
                "icon": "🏮",
                "title": "izakaya lantern"
            },
            {
                "code": "U+1F3EF",
                "icon": "🏯",
                "title": "japanese castle"
            },
            {
                "code": "U+1F3F0",
                "icon": "🏰",
                "title": "european castle"
            },
            {
                "code": "U+1F40C",
                "icon": "🐌",
                "title": "snail"
            },
            {
                "code": "U+1F40D",
                "icon": "🐍",
                "title": "snake"
            },
            {
                "code": "U+1F40E",
                "icon": "🐎",
                "title": "horse"
            },
            {
                "code": "U+1F411",
                "icon": "🐑",
                "title": "sheep"
            },
            {
                "code": "U+1F412",
                "icon": "🐒",
                "title": "monkey"
            },
            {
                "code": "U+1F414",
                "icon": "🐔",
                "title": "chicken"
            },
            {
                "code": "U+1F417",
                "icon": "🐗",
                "title": "boar"
            },
            {
                "code": "U+1F418",
                "icon": "🐘",
                "title": "elephant"
            },
            {
                "code": "U+1F419",
                "icon": "🐙",
                "title": "octopus"
            },
            {
                "code": "U+1F41A",
                "icon": "🐚",
                "title": "spiral shell"
            },
            {
                "code": "U+1F41B",
                "icon": "🐛",
                "title": "bug"
            },
            {
                "code": "U+1F41C",
                "icon": "🐜",
                "title": "ant"
            },
            {
                "code": "U+1F41D",
                "icon": "🐝",
                "title": "honeybee"
            },
            {
                "code": "U+1F41E",
                "icon": "🐞",
                "title": "lady beetle"
            },
            {
                "code": "U+1F41F",
                "icon": "🐟",
                "title": "fish"
            },
            {
                "code": "U+1F420",
                "icon": "🐠",
                "title": "tropical fish"
            },
            {
                "code": "U+1F421",
                "icon": "🐡",
                "title": "blowfish"
            },
            {
                "code": "U+1F422",
                "icon": "🐢",
                "title": "turtle"
            },
            {
                "code": "U+1F423",
                "icon": "🐣",
                "title": "hatching chick"
            },
            {
                "code": "U+1F424",
                "icon": "🐤",
                "title": "baby chick"
            },
            {
                "code": "U+1F425",
                "icon": "🐥",
                "title": "front-facing baby chick"
            },
            {
                "code": "U+1F426",
                "icon": "🐦",
                "title": "bird"
            },
            {
                "code": "U+1F427",
                "icon": "🐧",
                "title": "penguin"
            },
            {
                "code": "U+1F428",
                "icon": "🐨",
                "title": "koala"
            },
            {
                "code": "U+1F429",
                "icon": "🐩",
                "title": "poodle"
            },
            {
                "code": "U+1F42B",
                "icon": "🐫",
                "title": "bactrian camel"
            },
            {
                "code": "U+1F42C",
                "icon": "🐬",
                "title": "dolphin"
            },
            {
                "code": "U+1F42D",
                "icon": "🐭",
                "title": "mouse face"
            },
            {
                "code": "U+1F42E",
                "icon": "🐮",
                "title": "cow face"
            },
            {
                "code": "U+1F42F",
                "icon": "🐯",
                "title": "tiger face"
            },
            {
                "code": "U+1F430",
                "icon": "🐰",
                "title": "rabbit face"
            },
            {
                "code": "U+1F431",
                "icon": "🐱",
                "title": "cat face"
            },
            {
                "code": "U+1F432",
                "icon": "🐲",
                "title": "dragon face"
            },
            {
                "code": "U+1F433",
                "icon": "🐳",
                "title": "spouting whale"
            },
            {
                "code": "U+1F434",
                "icon": "🐴",
                "title": "horse face"
            },
            {
                "code": "U+1F435",
                "icon": "🐵",
                "title": "monkey face"
            },
            {
                "code": "U+1F436",
                "icon": "🐶",
                "title": "dog face"
            },
            {
                "code": "U+1F437",
                "icon": "🐷",
                "title": "pig face"
            },
            {
                "code": "U+1F438",
                "icon": "🐸",
                "title": "frog face"
            },
            {
                "code": "U+1F439",
                "icon": "🐹",
                "title": "hamster face"
            },
            {
                "code": "U+1F43A",
                "icon": "🐺",
                "title": "wolf face"
            },
            {
                "code": "U+1F43B",
                "icon": "🐻",
                "title": "bear face"
            },
            {
                "code": "U+1F43C",
                "icon": "🐼",
                "title": "panda face"
            },
            {
                "code": "U+1F43D",
                "icon": "🐽",
                "title": "pig nose"
            },
            {
                "code": "U+1F43E",
                "icon": "🐾",
                "title": "paw prints"
            },
            {
                "code": "U+1F440",
                "icon": "👀",
                "title": "eyes"
            },
            {
                "code": "U+1F442",
                "icon": "👂",
                "title": "ear"
            },
            {
                "code": "U+1F443",
                "icon": "👃",
                "title": "nose"
            },
            {
                "code": "U+1F444",
                "icon": "👄",
                "title": "mouth"
            },
            {
                "code": "U+1F445",
                "icon": "👅",
                "title": "tongue"
            },
            {
                "code": "U+1F446",
                "icon": "👆",
                "title": "white up pointing backhand index"
            },
            {
                "code": "U+1F447",
                "icon": "👇",
                "title": "white down pointing backhand index"
            },
            {
                "code": "U+1F448",
                "icon": "👈",
                "title": "white left pointing backhand index"
            },
            {
                "code": "U+1F449",
                "icon": "👉",
                "title": "white right pointing backhand index"
            },
            {
                "code": "U+1F44A",
                "icon": "👊",
                "title": "fisted hand sign"
            },
            {
                "code": "U+1F44B",
                "icon": "👋",
                "title": "waving hand sign"
            },
            {
                "code": "U+1F44C",
                "icon": "👌",
                "title": "ok hand sign"
            },
            {
                "code": "U+1F44D",
                "icon": "👍",
                "title": "thumbs up sign"
            },
            {
                "code": "U+1F44E",
                "icon": "👎",
                "title": "thumbs down sign"
            },
            {
                "code": "U+1F44F",
                "icon": "👏",
                "title": "clapping hands sign"
            },
            {
                "code": "U+1F450",
                "icon": "👐",
                "title": "open hands sign"
            },
            {
                "code": "U+1F451",
                "icon": "👑",
                "title": "crown"
            },
            {
                "code": "U+1F452",
                "icon": "👒",
                "title": "womans hat"
            },
            {
                "code": "U+1F453",
                "icon": "👓",
                "title": "eyeglasses"
            },
            {
                "code": "U+1F454",
                "icon": "👔",
                "title": "necktie"
            },
            {
                "code": "U+1F455",
                "icon": "👕",
                "title": "t-shirt"
            },
            {
                "code": "U+1F456",
                "icon": "👖",
                "title": "jeans"
            },
            {
                "code": "U+1F457",
                "icon": "👗",
                "title": "dress"
            },
            {
                "code": "U+1F458",
                "icon": "👘",
                "title": "kimono"
            },
            {
                "code": "U+1F459",
                "icon": "👙",
                "title": "bikini"
            },
            {
                "code": "U+1F45A",
                "icon": "👚",
                "title": "womans clothes"
            },
            {
                "code": "U+1F45B",
                "icon": "👛",
                "title": "purse"
            },
            {
                "code": "U+1F45C",
                "icon": "👜",
                "title": "handbag"
            },
            {
                "code": "U+1F45D",
                "icon": "👝",
                "title": "pouch"
            },
            {
                "code": "U+1F45E",
                "icon": "👞",
                "title": "mans shoe"
            },
            {
                "code": "U+1F45F",
                "icon": "👟",
                "title": "athletic shoe"
            },
            {
                "code": "U+1F460",
                "icon": "👠",
                "title": "high-heeled shoe"
            },
            {
                "code": "U+1F461",
                "icon": "👡",
                "title": "womans sandal"
            },
            {
                "code": "U+1F462",
                "icon": "👢",
                "title": "womans boots"
            },
            {
                "code": "U+1F463",
                "icon": "👣",
                "title": "footprints"
            },
            {
                "code": "U+1F464",
                "icon": "👤",
                "title": "bust in silhouette"
            },
            {
                "code": "U+1F466",
                "icon": "👦",
                "title": "boy"
            },
            {
                "code": "U+1F467",
                "icon": "👧",
                "title": "girl"
            },
            {
                "code": "U+1F468",
                "icon": "👨",
                "title": "man"
            },
            {
                "code": "U+1F469",
                "icon": "👩",
                "title": "woman"
            },
            {
                "code": "U+1F46A",
                "icon": "👪",
                "title": "family"
            },
            {
                "code": "U+1F46B",
                "icon": "👫",
                "title": "man and woman holding hands"
            },
            {
                "code": "U+1F46E",
                "icon": "👮",
                "title": "police officer"
            },
            {
                "code": "U+1F46F",
                "icon": "👯",
                "title": "woman with bunny ears"
            },
            {
                "code": "U+1F470",
                "icon": "👰",
                "title": "bride with veil"
            },
            {
                "code": "U+1F471",
                "icon": "👱",
                "title": "person with blond hair"
            },
            {
                "code": "U+1F472",
                "icon": "👲",
                "title": "man with gua pi mao"
            },
            {
                "code": "U+1F473",
                "icon": "👳",
                "title": "man with turban"
            },
            {
                "code": "U+1F474",
                "icon": "👴",
                "title": "older man"
            },
            {
                "code": "U+1F475",
                "icon": "👵",
                "title": "older woman"
            },
            {
                "code": "U+1F476",
                "icon": "👶",
                "title": "baby"
            },
            {
                "code": "U+1F477",
                "icon": "👷",
                "title": "construction worker"
            },
            {
                "code": "U+1F478",
                "icon": "👸",
                "title": "princess"
            },
            {
                "code": "U+1F479",
                "icon": "👹",
                "title": "japanese ogre"
            },
            {
                "code": "U+1F47A",
                "icon": "👺",
                "title": "japanese goblin"
            },
            {
                "code": "U+1F47B",
                "icon": "👻",
                "title": "ghost"
            },
            {
                "code": "U+1F47C",
                "icon": "👼",
                "title": "baby angel"
            },
            {
                "code": "U+1F47D",
                "icon": "👽",
                "title": "extraterrestrial alien"
            },
            {
                "code": "U+1F47E",
                "icon": "👾",
                "title": "alien monster"
            },
            {
                "code": "U+1F47F",
                "icon": "👿",
                "title": "imp"
            },
            {
                "code": "U+1F480",
                "icon": "💀",
                "title": "skull"
            },
            {
                "code": "U+1F481",
                "icon": "💁",
                "title": "information desk person"
            },
            {
                "code": "U+1F482",
                "icon": "💂",
                "title": "guardsman"
            },
            {
                "code": "U+1F483",
                "icon": "💃",
                "title": "dancer"
            },
            {
                "code": "U+1F484",
                "icon": "💄",
                "title": "lipstick"
            },
            {
                "code": "U+1F485",
                "icon": "💅",
                "title": "nail polish"
            },
            {
                "code": "U+1F486",
                "icon": "💆",
                "title": "face massage"
            },
            {
                "code": "U+1F487",
                "icon": "💇",
                "title": "haircut"
            },
            {
                "code": "U+1F488",
                "icon": "💈",
                "title": "barber pole"
            },
            {
                "code": "U+1F489",
                "icon": "💉",
                "title": "syringe"
            },
            {
                "code": "U+1F48A",
                "icon": "💊",
                "title": "pill"
            },
            {
                "code": "U+1F48B",
                "icon": "💋",
                "title": "kiss mark"
            },
            {
                "code": "U+1F48C",
                "icon": "💌",
                "title": "love letter"
            },
            {
                "code": "U+1F48D",
                "icon": "💍",
                "title": "ring"
            },
            {
                "code": "U+1F48E",
                "icon": "💎",
                "title": "gem stone"
            },
            {
                "code": "U+1F48F",
                "icon": "💏",
                "title": "kiss"
            },
            {
                "code": "U+1F490",
                "icon": "💐",
                "title": "bouquet"
            },
            {
                "code": "U+1F491",
                "icon": "💑",
                "title": "couple with heart"
            },
            {
                "code": "U+1F492",
                "icon": "💒",
                "title": "wedding"
            },
            {
                "code": "U+1F493",
                "icon": "💓",
                "title": "beating heart"
            },
            {
                "code": "U+1F494",
                "icon": "💔",
                "title": "broken heart"
            },
            {
                "code": "U+1F495",
                "icon": "💕",
                "title": "two hearts"
            },
            {
                "code": "U+1F496",
                "icon": "💖",
                "title": "sparkling heart"
            },
            {
                "code": "U+1F497",
                "icon": "💗",
                "title": "growing heart"
            },
            {
                "code": "U+1F498",
                "icon": "💘",
                "title": "heart with arrow"
            },
            {
                "code": "U+1F499",
                "icon": "💙",
                "title": "blue heart"
            },
            {
                "code": "U+1F49A",
                "icon": "💚",
                "title": "green heart"
            },
            {
                "code": "U+1F49B",
                "icon": "💛",
                "title": "yellow heart"
            },
            {
                "code": "U+1F49C",
                "icon": "💜",
                "title": "purple heart"
            },
            {
                "code": "U+1F49D",
                "icon": "💝",
                "title": "heart with ribbon"
            },
            {
                "code": "U+1F49E",
                "icon": "💞",
                "title": "revolving hearts"
            },
            {
                "code": "U+1F49F",
                "icon": "💟",
                "title": "heart decoration"
            },
            {
                "code": "U+1F4A0",
                "icon": "💠",
                "title": "diamond shape with a dot inside"
            },
            {
                "code": "U+1F4A1",
                "icon": "💡",
                "title": "electric light bulb"
            },
            {
                "code": "U+1F4A2",
                "icon": "💢",
                "title": "anger symbol"
            },
            {
                "code": "U+1F4A3",
                "icon": "💣",
                "title": "bomb"
            },
            {
                "code": "U+1F4A4",
                "icon": "💤",
                "title": "sleeping symbol"
            },
            {
                "code": "U+1F4A5",
                "icon": "💥",
                "title": "collision symbol"
            },
            {
                "code": "U+1F4A6",
                "icon": "💦",
                "title": "splashing sweat symbol"
            },
            {
                "code": "U+1F4A7",
                "icon": "💧",
                "title": "droplet"
            },
            {
                "code": "U+1F4A8",
                "icon": "💨",
                "title": "dash symbol"
            },
            {
                "code": "U+1F4A9",
                "icon": "💩",
                "title": "pile of poo"
            },
            {
                "code": "U+1F4AA",
                "icon": "💪",
                "title": "flexed biceps"
            },
            {
                "code": "U+1F4AB",
                "icon": "💫",
                "title": "dizzy symbol"
            },
            {
                "code": "U+1F4AC",
                "icon": "💬",
                "title": "speech balloon"
            },
            {
                "code": "U+1F4AE",
                "icon": "💮",
                "title": "white flower"
            },
            {
                "code": "U+1F4AF",
                "icon": "💯",
                "title": "hundred points symbol"
            },
            {
                "code": "U+1F4B0",
                "icon": "💰",
                "title": "money bag"
            },
            {
                "code": "U+1F4B1",
                "icon": "💱",
                "title": "currency exchange"
            },
            {
                "code": "U+1F4B2",
                "icon": "💲",
                "title": "heavy dollar sign"
            },
            {
                "code": "U+1F4B3",
                "icon": "💳",
                "title": "credit card"
            },
            {
                "code": "U+1F4B4",
                "icon": "💴",
                "title": "banknote with yen sign"
            },
            {
                "code": "U+1F4B5",
                "icon": "💵",
                "title": "banknote with dollar sign"
            },
            {
                "code": "U+1F4B8",
                "icon": "💸",
                "title": "money with wings"
            },
            {
                "code": "U+1F4B9",
                "icon": "💹",
                "title": "chart with upwards trend and yen sign"
            },
            {
                "code": "U+1F4BA",
                "icon": "💺",
                "title": "seat"
            },
            {
                "code": "U+1F4BB",
                "icon": "💻",
                "title": "personal computer"
            },
            {
                "code": "U+1F4BC",
                "icon": "💼",
                "title": "briefcase"
            },
            {
                "code": "U+1F4BD",
                "icon": "💽",
                "title": "minidisc"
            },
            {
                "code": "U+1F4BE",
                "icon": "💾",
                "title": "floppy disk"
            },
            {
                "code": "U+1F4BF",
                "icon": "💿",
                "title": "optical disc"
            },
            {
                "code": "U+1F4C0",
                "icon": "📀",
                "title": "dvd"
            },
            {
                "code": "U+1F4C1",
                "icon": "📁",
                "title": "file folder"
            },
            {
                "code": "U+1F4C2",
                "icon": "📂",
                "title": "open file folder"
            },
            {
                "code": "U+1F4C3",
                "icon": "📃",
                "title": "page with curl"
            },
            {
                "code": "U+1F4C4",
                "icon": "📄",
                "title": "page facing up"
            },
            {
                "code": "U+1F4C5",
                "icon": "📅",
                "title": "calendar"
            },
            {
                "code": "U+1F4C6",
                "icon": "📆",
                "title": "tear-off calendar"
            },
            {
                "code": "U+1F4C7",
                "icon": "📇",
                "title": "card index"
            },
            {
                "code": "U+1F4C8",
                "icon": "📈",
                "title": "chart with upwards trend"
            },
            {
                "code": "U+1F4C9",
                "icon": "📉",
                "title": "chart with downwards trend"
            },
            {
                "code": "U+1F4CA",
                "icon": "📊",
                "title": "bar chart"
            },
            {
                "code": "U+1F4CB",
                "icon": "📋",
                "title": "clipboard"
            },
            {
                "code": "U+1F4CC",
                "icon": "📌",
                "title": "pushpin"
            },
            {
                "code": "U+1F4CD",
                "icon": "📍",
                "title": "round pushpin"
            },
            {
                "code": "U+1F4CE",
                "icon": "📎",
                "title": "paperclip"
            },
            {
                "code": "U+1F4CF",
                "icon": "📏",
                "title": "straight ruler"
            },
            {
                "code": "U+1F4D0",
                "icon": "📐",
                "title": "triangular ruler"
            },
            {
                "code": "U+1F4D1",
                "icon": "📑",
                "title": "bookmark tabs"
            },
            {
                "code": "U+1F4D2",
                "icon": "📒",
                "title": "ledger"
            },
            {
                "code": "U+1F4D3",
                "icon": "📓",
                "title": "notebook"
            },
            {
                "code": "U+1F4D4",
                "icon": "📔",
                "title": "notebook with decorative cover"
            },
            {
                "code": "U+1F4D5",
                "icon": "📕",
                "title": "closed book"
            },
            {
                "code": "U+1F4D6",
                "icon": "📖",
                "title": "open book"
            },
            {
                "code": "U+1F4D7",
                "icon": "📗",
                "title": "green book"
            },
            {
                "code": "U+1F4D8",
                "icon": "📘",
                "title": "blue book"
            },
            {
                "code": "U+1F4D9",
                "icon": "📙",
                "title": "orange book"
            },
            {
                "code": "U+1F4DA",
                "icon": "📚",
                "title": "books"
            },
            {
                "code": "U+1F4DB",
                "icon": "📛",
                "title": "name badge"
            },
            {
                "code": "U+1F4DC",
                "icon": "📜",
                "title": "scroll"
            },
            {
                "code": "U+1F4DD",
                "icon": "📝",
                "title": "memo"
            },
            {
                "code": "U+1F4DE",
                "icon": "📞",
                "title": "telephone receiver"
            },
            {
                "code": "U+1F4DF",
                "icon": "📟",
                "title": "pager"
            },
            {
                "code": "U+1F4E0",
                "icon": "📠",
                "title": "fax machine"
            },
            {
                "code": "U+1F4E1",
                "icon": "📡",
                "title": "satellite antenna"
            },
            {
                "code": "U+1F4E2",
                "icon": "📢",
                "title": "public address loudspeaker"
            },
            {
                "code": "U+1F4E3",
                "icon": "📣",
                "title": "cheering megaphone"
            },
            {
                "code": "U+1F4E4",
                "icon": "📤",
                "title": "outbox tray"
            },
            {
                "code": "U+1F4E5",
                "icon": "📥",
                "title": "inbox tray"
            },
            {
                "code": "U+1F4E6",
                "icon": "📦",
                "title": "package"
            },
            {
                "code": "U+1F4E7",
                "icon": "📧",
                "title": "e-mail symbol"
            },
            {
                "code": "U+1F4E8",
                "icon": "📨",
                "title": "incoming envelope"
            },
            {
                "code": "U+1F4E9",
                "icon": "📩",
                "title": "envelope with downwards arrow above"
            },
            {
                "code": "U+1F4EA",
                "icon": "📪",
                "title": "closed mailbox with lowered flag"
            },
            {
                "code": "U+1F4EB",
                "icon": "📫",
                "title": "closed mailbox with raised flag"
            },
            {
                "code": "U+1F4EE",
                "icon": "📮",
                "title": "postbox"
            },
            {
                "code": "U+1F4F0",
                "icon": "📰",
                "title": "newspaper"
            },
            {
                "code": "U+1F4F1",
                "icon": "📱",
                "title": "mobile phone"
            },
            {
                "code": "U+1F4F2",
                "icon": "📲",
                "title": "mobile phone with rightwards arrow at left"
            },
            {
                "code": "U+1F4F3",
                "icon": "📳",
                "title": "vibration mode"
            },
            {
                "code": "U+1F4F4",
                "icon": "📴",
                "title": "mobile phone off"
            },
            {
                "code": "U+1F4F6",
                "icon": "📶",
                "title": "antenna with bars"
            },
            {
                "code": "U+1F4F7",
                "icon": "📷",
                "title": "camera"
            },
            {
                "code": "U+1F4F9",
                "icon": "📹",
                "title": "video camera"
            },
            {
                "code": "U+1F4FA",
                "icon": "📺",
                "title": "television"
            },
            {
                "code": "U+1F4FB",
                "icon": "📻",
                "title": "radio"
            },
            {
                "code": "U+1F4FC",
                "icon": "📼",
                "title": "videocassette"
            },
            {
                "code": "U+1F503",
                "icon": "🔃",
                "title": "clockwise downwards and upwards open circle arrows"
            },
            {
                "code": "U+1F50A",
                "icon": "🔊",
                "title": "speaker with three sound waves"
            },
            {
                "code": "U+1F50B",
                "icon": "🔋",
                "title": "battery"
            },
            {
                "code": "U+1F50C",
                "icon": "🔌",
                "title": "electric plug"
            },
            {
                "code": "U+1F50D",
                "icon": "🔍",
                "title": "left-pointing magnifying glass"
            },
            {
                "code": "U+1F50E",
                "icon": "🔎",
                "title": "right-pointing magnifying glass"
            },
            {
                "code": "U+1F50F",
                "icon": "🔏",
                "title": "lock with ink pen"
            },
            {
                "code": "U+1F510",
                "icon": "🔐",
                "title": "closed lock with key"
            },
            {
                "code": "U+1F511",
                "icon": "🔑",
                "title": "key"
            },
            {
                "code": "U+1F512",
                "icon": "🔒",
                "title": "lock"
            },
            {
                "code": "U+1F513",
                "icon": "🔓",
                "title": "open lock"
            },
            {
                "code": "U+1F514",
                "icon": "🔔",
                "title": "bell"
            },
            {
                "code": "U+1F516",
                "icon": "🔖",
                "title": "bookmark"
            },
            {
                "code": "U+1F517",
                "icon": "🔗",
                "title": "link symbol"
            },
            {
                "code": "U+1F518",
                "icon": "🔘",
                "title": "radio button"
            },
            {
                "code": "U+1F519",
                "icon": "🔙",
                "title": "back with leftwards arrow above"
            },
            {
                "code": "U+1F51A",
                "icon": "🔚",
                "title": "end with leftwards arrow above"
            },
            {
                "code": "U+1F51B",
                "icon": "🔛",
                "title": "on with exclamation mark with left right arrow above"
            },
            {
                "code": "U+1F51C",
                "icon": "🔜",
                "title": "soon with rightwards arrow above"
            },
            {
                "code": "U+1F51D",
                "icon": "🔝",
                "title": "top with upwards arrow above"
            },
            {
                "code": "U+1F51E",
                "icon": "🔞",
                "title": "no one under eighteen symbol"
            },
            {
                "code": "U+1F51F",
                "icon": "🔟",
                "title": "keycap ten"
            },
            {
                "code": "U+1F520",
                "icon": "🔠",
                "title": "input symbol for latin capital letters"
            },
            {
                "code": "U+1F521",
                "icon": "🔡",
                "title": "input symbol for latin small letters"
            },
            {
                "code": "U+1F522",
                "icon": "🔢",
                "title": "input symbol for numbers"
            },
            {
                "code": "U+1F523",
                "icon": "🔣",
                "title": "input symbol for symbols"
            },
            {
                "code": "U+1F524",
                "icon": "🔤",
                "title": "input symbol for latin letters"
            },
            {
                "code": "U+1F525",
                "icon": "🔥",
                "title": "fire"
            },
            {
                "code": "U+1F526",
                "icon": "🔦",
                "title": "electric torch"
            },
            {
                "code": "U+1F527",
                "icon": "🔧",
                "title": "wrench"
            },
            {
                "code": "U+1F528",
                "icon": "🔨",
                "title": "hammer"
            },
            {
                "code": "U+1F529",
                "icon": "🔩",
                "title": "nut and bolt"
            },
            {
                "code": "U+1F52A",
                "icon": "🔪",
                "title": "hocho"
            },
            {
                "code": "U+1F52B",
                "icon": "🔫",
                "title": "pistol"
            },
            {
                "code": "U+1F52E",
                "icon": "🔮",
                "title": "crystal ball"
            },
            {
                "code": "U+1F52F",
                "icon": "🔯",
                "title": "six pointed star with middle dot"
            },
            {
                "code": "U+1F530",
                "icon": "🔰",
                "title": "japanese symbol for beginner"
            },
            {
                "code": "U+1F531",
                "icon": "🔱",
                "title": "trident emblem"
            },
            {
                "code": "U+1F532",
                "icon": "🔲",
                "title": "black square button"
            },
            {
                "code": "U+1F533",
                "icon": "🔳",
                "title": "white square button"
            },
            {
                "code": "U+1F534",
                "icon": "🔴",
                "title": "large red circle"
            },
            {
                "code": "U+1F535",
                "icon": "🔵",
                "title": "large blue circle"
            },
            {
                "code": "U+1F536",
                "icon": "🔶",
                "title": "large orange diamond"
            },
            {
                "code": "U+1F537",
                "icon": "🔷",
                "title": "large blue diamond"
            },
            {
                "code": "U+1F538",
                "icon": "🔸",
                "title": "small orange diamond"
            },
            {
                "code": "U+1F539",
                "icon": "🔹",
                "title": "small blue diamond"
            },
            {
                "code": "U+1F53A",
                "icon": "🔺",
                "title": "up-pointing red triangle"
            },
            {
                "code": "U+1F53B",
                "icon": "🔻",
                "title": "down-pointing red triangle"
            },
            {
                "code": "U+1F53C",
                "icon": "🔼",
                "title": "up-pointing small red triangle"
            },
            {
                "code": "U+1F53D",
                "icon": "🔽",
                "title": "down-pointing small red triangle"
            },
            {
                "code": "U+1F550",
                "icon": "🕐",
                "title": "clock face one oclock"
            },
            {
                "code": "U+1F551",
                "icon": "🕑",
                "title": "clock face two oclock"
            },
            {
                "code": "U+1F552",
                "icon": "🕒",
                "title": "clock face three oclock"
            },
            {
                "code": "U+1F553",
                "icon": "🕓",
                "title": "clock face four oclock"
            },
            {
                "code": "U+1F554",
                "icon": "🕔",
                "title": "clock face five oclock"
            },
            {
                "code": "U+1F555",
                "icon": "🕕",
                "title": "clock face six oclock"
            },
            {
                "code": "U+1F556",
                "icon": "🕖",
                "title": "clock face seven oclock"
            },
            {
                "code": "U+1F557",
                "icon": "🕗",
                "title": "clock face eight oclock"
            },
            {
                "code": "U+1F558",
                "icon": "🕘",
                "title": "clock face nine oclock"
            },
            {
                "code": "U+1F559",
                "icon": "🕙",
                "title": "clock face ten oclock"
            },
            {
                "code": "U+1F55A",
                "icon": "🕚",
                "title": "clock face eleven oclock"
            },
            {
                "code": "U+1F55B",
                "icon": "🕛",
                "title": "clock face twelve oclock"
            },
            {
                "code": "U+1F5FB",
                "icon": "🗻",
                "title": "mount fuji"
            },
            {
                "code": "U+1F5FC",
                "icon": "🗼",
                "title": "tokyo tower"
            },
            {
                "code": "U+1F5FD",
                "icon": "🗽",
                "title": "statue of liberty"
            },
            {
                "code": "U+1F5FE",
                "icon": "🗾",
                "title": "silhouette of japan"
            },
            {
                "code": "U+1F5FF",
                "icon": "🗿",
                "title": "moyai"
            }
        ]
    },
    {
        title : "Additional emoticons",
        icon : "😀",
        list :  [
            {
                "code": "U+1F600",
                "icon": "😀",
                "title": "grinning face"
            },
            {
                "code": "U+1F607",
                "icon": "😇",
                "title": "smiling face with halo"
            },
            {
                "code": "U+1F608",
                "icon": "😈",
                "title": "smiling face with horns"
            },
            {
                "code": "U+1F60E",
                "icon": "😎",
                "title": "smiling face with sunglasses"
            },
            {
                "code": "U+1F610",
                "icon": "😐",
                "title": "neutral face"
            },
            {
                "code": "U+1F611",
                "icon": "😑",
                "title": "expressionless face"
            },
            {
                "code": "U+1F615",
                "icon": "😕",
                "title": "confused face"
            },
            {
                "code": "U+1F617",
                "icon": "😗",
                "title": "kissing face"
            },
            {
                "code": "U+1F619",
                "icon": "😙",
                "title": "kissing face with smiling eyes"
            },
            {
                "code": "U+1F61B",
                "icon": "😛",
                "title": "face with stuck-out tongue"
            },
            {
                "code": "U+1F61F",
                "icon": "😟",
                "title": "worried face"
            },
            {
                "code": "U+1F626",
                "icon": "😦",
                "title": "frowning face with open mouth"
            },
            {
                "code": "U+1F627",
                "icon": "😧",
                "title": "anguished face"
            },
            {
                "code": "U+1F62C",
                "icon": "😬",
                "title": "grimacing face"
            },
            {
                "code": "U+1F62E",
                "icon": "😮",
                "title": "face with open mouth"
            },
            {
                "code": "U+1F62F",
                "icon": "😯",
                "title": "hushed face"
            },
            {
                "code": "U+1F634",
                "icon": "😴",
                "title": "sleeping face"
            },
            {
                "code": "U+1F636",
                "icon": "😶",
                "title": "face without mouth"
            }
        ]
    },
    {
        title : "Additional transport and map symbols",
        icon : "🚁",
        list :  [
            {
                "code": "U+1F681",
                "icon": "🚁",
                "title": "helicopter"
            },
            {
                "code": "U+1F682",
                "icon": "🚂",
                "title": "steam locomotive"
            },
            {
                "code": "U+1F686",
                "icon": "🚆",
                "title": "train"
            },
            {
                "code": "U+1F688",
                "icon": "🚈",
                "title": "light rail"
            },
            {
                "code": "U+1F68A",
                "icon": "🚊",
                "title": "tram"
            },
            {
                "code": "U+1F68D",
                "icon": "🚍",
                "title": "oncoming bus"
            },
            {
                "code": "U+1F68E",
                "icon": "🚎",
                "title": "trolleybus"
            },
            {
                "code": "U+1F690",
                "icon": "🚐",
                "title": "minibus"
            },
            {
                "code": "U+1F694",
                "icon": "🚔",
                "title": "oncoming police car"
            },
            {
                "code": "U+1F696",
                "icon": "🚖",
                "title": "oncoming taxi"
            },
            {
                "code": "U+1F698",
                "icon": "🚘",
                "title": "oncoming automobile"
            },
            {
                "code": "U+1F69B",
                "icon": "🚛",
                "title": "articulated lorry"
            },
            {
                "code": "U+1F69C",
                "icon": "🚜",
                "title": "tractor"
            },
            {
                "code": "U+1F69D",
                "icon": "🚝",
                "title": "monorail"
            },
            {
                "code": "U+1F69E",
                "icon": "🚞",
                "title": "mountain railway"
            },
            {
                "code": "U+1F69F",
                "icon": "🚟",
                "title": "suspension railway"
            },
            {
                "code": "U+1F6A0",
                "icon": "🚠",
                "title": "mountain cableway"
            },
            {
                "code": "U+1F6A1",
                "icon": "🚡",
                "title": "aerial tramway"
            },
            {
                "code": "U+1F6A3",
                "icon": "🚣",
                "title": "rowboat"
            },
            {
                "code": "U+1F6A6",
                "icon": "🚦",
                "title": "vertical traffic light"
            },
            {
                "code": "U+1F6AE",
                "icon": "🚮",
                "title": "put litter in its place symbol"
            },
            {
                "code": "U+1F6AF",
                "icon": "🚯",
                "title": "do not litter symbol"
            },
            {
                "code": "U+1F6B0",
                "icon": "🚰",
                "title": "potable water symbol"
            },
            {
                "code": "U+1F6B1",
                "icon": "🚱",
                "title": "non-potable water symbol"
            },
            {
                "code": "U+1F6B3",
                "icon": "🚳",
                "title": "no bicycles"
            },
            {
                "code": "U+1F6B4",
                "icon": "🚴",
                "title": "bicyclist"
            },
            {
                "code": "U+1F6B5",
                "icon": "🚵",
                "title": "mountain bicyclist"
            },
            {
                "code": "U+1F6B7",
                "icon": "🚷",
                "title": "no pedestrians"
            },
            {
                "code": "U+1F6B8",
                "icon": "🚸",
                "title": "children crossing"
            },
            {
                "code": "U+1F6BF",
                "icon": "🚿",
                "title": "shower"
            },
            {
                "code": "U+1F6C1",
                "icon": "🛁",
                "title": "bathtub"
            },
            {
                "code": "U+1F6C2",
                "icon": "🛂",
                "title": "passport control"
            },
            {
                "code": "U+1F6C3",
                "icon": "🛃",
                "title": "customs"
            },
            {
                "code": "U+1F6C4",
                "icon": "🛄",
                "title": "baggage claim"
            },
            {
                "code": "U+1F6C5",
                "icon": "🛅",
                "title": "left luggage"
            }
        ]
    },
    {
        title : "Other additional symbols",
        icon : "🌍",
        list :  [
            {
                "code": "U+1F30D",
                "icon": "🌍",
                "title": "earth globe europe-africa"
            },
            {
                "code": "U+1F30E",
                "icon": "🌎",
                "title": "earth globe americas"
            },
            {
                "code": "U+1F310",
                "icon": "🌐",
                "title": "globe with meridians"
            },
            {
                "code": "U+1F312",
                "icon": "🌒",
                "title": "waxing crescent moon symbol"
            },
            {
                "code": "U+1F316",
                "icon": "🌖",
                "title": "waning gibbous moon symbol"
            },
            {
                "code": "U+1F317",
                "icon": "🌗",
                "title": "last quarter moon symbol"
            },
            {
                "code": "U+1F318",
                "icon": "🌘",
                "title": "waning crescent moon symbol"
            },
            {
                "code": "U+1F31A",
                "icon": "🌚",
                "title": "new moon with face"
            },
            {
                "code": "U+1F31C",
                "icon": "🌜",
                "title": "last quarter moon with face"
            },
            {
                "code": "U+1F31D",
                "icon": "🌝",
                "title": "full moon with face"
            },
            {
                "code": "U+1F31E",
                "icon": "🌞",
                "title": "sun with face"
            },
            {
                "code": "U+1F332",
                "icon": "🌲",
                "title": "evergreen tree"
            },
            {
                "code": "U+1F333",
                "icon": "🌳",
                "title": "deciduous tree"
            },
            {
                "code": "U+1F34B",
                "icon": "🍋",
                "title": "lemon"
            },
            {
                "code": "U+1F350",
                "icon": "🍐",
                "title": "pear"
            },
            {
                "code": "U+1F37C",
                "icon": "🍼",
                "title": "baby bottle"
            },
            {
                "code": "U+1F3C7",
                "icon": "🏇",
                "title": "horse racing"
            },
            {
                "code": "U+1F3C9",
                "icon": "🏉",
                "title": "rugby football"
            },
            {
                "code": "U+1F3E4",
                "icon": "🏤",
                "title": "european post office"
            },
            {
                "code": "U+1F400",
                "icon": "🐀",
                "title": "rat"
            },
            {
                "code": "U+1F401",
                "icon": "🐁",
                "title": "mouse"
            },
            {
                "code": "U+1F402",
                "icon": "🐂",
                "title": "ox"
            },
            {
                "code": "U+1F403",
                "icon": "🐃",
                "title": "water buffalo"
            },
            {
                "code": "U+1F404",
                "icon": "🐄",
                "title": "cow"
            },
            {
                "code": "U+1F405",
                "icon": "🐅",
                "title": "tiger"
            },
            {
                "code": "U+1F406",
                "icon": "🐆",
                "title": "leopard"
            },
            {
                "code": "U+1F407",
                "icon": "🐇",
                "title": "rabbit"
            },
            {
                "code": "U+1F408",
                "icon": "🐈",
                "title": "cat"
            },
            {
                "code": "U+1F409",
                "icon": "🐉",
                "title": "dragon"
            },
            {
                "code": "U+1F40A",
                "icon": "🐊",
                "title": "crocodile"
            },
            {
                "code": "U+1F40B",
                "icon": "🐋",
                "title": "whale"
            },
            {
                "code": "U+1F40F",
                "icon": "🐏",
                "title": "ram"
            },
            {
                "code": "U+1F410",
                "icon": "🐐",
                "title": "goat"
            },
            {
                "code": "U+1F413",
                "icon": "🐓",
                "title": "rooster"
            },
            {
                "code": "U+1F415",
                "icon": "🐕",
                "title": "dog"
            },
            {
                "code": "U+1F416",
                "icon": "🐖",
                "title": "pig"
            },
            {
                "code": "U+1F42A",
                "icon": "🐪",
                "title": "dromedary camel"
            },
            {
                "code": "U+1F465",
                "icon": "👥",
                "title": "busts in silhouette"
            },
            {
                "code": "U+1F46C",
                "icon": "👬",
                "title": "two men holding hands"
            },
            {
                "code": "U+1F46D",
                "icon": "👭",
                "title": "two women holding hands"
            },
            {
                "code": "U+1F4AD",
                "icon": "💭",
                "title": "thought balloon"
            },
            {
                "code": "U+1F4B6",
                "icon": "💶",
                "title": "banknote with euro sign"
            },
            {
                "code": "U+1F4B7",
                "icon": "💷",
                "title": "banknote with pound sign"
            },
            {
                "code": "U+1F4EC",
                "icon": "📬",
                "title": "open mailbox with raised flag"
            },
            {
                "code": "U+1F4ED",
                "icon": "📭",
                "title": "open mailbox with lowered flag"
            },
            {
                "code": "U+1F4EF",
                "icon": "📯",
                "title": "postal horn"
            },
            {
                "code": "U+1F4F5",
                "icon": "📵",
                "title": "no mobile phones"
            },
            {
                "code": "U+1F500",
                "icon": "🔀",
                "title": "twisted rightwards arrows"
            },
            {
                "code": "U+1F501",
                "icon": "🔁",
                "title": "clockwise rightwards and leftwards open circle arrows"
            },
            {
                "code": "U+1F502",
                "icon": "🔂",
                "title": "clockwise rightwards and leftwards open circle arrows with circled one overlay"
            },
            {
                "code": "U+1F504",
                "icon": "🔄",
                "title": "anticlockwise downwards and upwards open circle arrows"
            },
            {
                "code": "U+1F505",
                "icon": "🔅",
                "title": "low brightness symbol"
            },
            {
                "code": "U+1F506",
                "icon": "🔆",
                "title": "high brightness symbol"
            },
            {
                "code": "U+1F507",
                "icon": "🔇",
                "title": "speaker with cancellation stroke"
            },
            {
                "code": "U+1F509",
                "icon": "🔉",
                "title": "speaker with one sound wave"
            },
            {
                "code": "U+1F515",
                "icon": "🔕",
                "title": "bell with cancellation stroke"
            },
            {
                "code": "U+1F52C",
                "icon": "🔬",
                "title": "microscope"
            },
            {
                "code": "U+1F52D",
                "icon": "🔭",
                "title": "telescope"
            },
            {
                "code": "U+1F55C",
                "icon": "🕜",
                "title": "clock face one-thirty"
            },
            {
                "code": "U+1F55D",
                "icon": "🕝",
                "title": "clock face two-thirty"
            },
            {
                "code": "U+1F55E",
                "icon": "🕞",
                "title": "clock face three-thirty"
            },
            {
                "code": "U+1F55F",
                "icon": "🕟",
                "title": "clock face four-thirty"
            },
            {
                "code": "U+1F560",
                "icon": "🕠",
                "title": "clock face five-thirty"
            },
            {
                "code": "U+1F561",
                "icon": "🕡",
                "title": "clock face six-thirty"
            },
            {
                "code": "U+1F562",
                "icon": "🕢",
                "title": "clock face seven-thirty"
            },
            {
                "code": "U+1F563",
                "icon": "🕣",
                "title": "clock face eight-thirty"
            },
            {
                "code": "U+1F564",
                "icon": "🕤",
                "title": "clock face nine-thirty"
            },
            {
                "code": "U+1F565",
                "icon": "🕥",
                "title": "clock face ten-thirty"
            },
            {
                "code": "U+1F566",
                "icon": "🕦",
                "title": "clock face eleven-thirty"
            },
            {
                "code": "U+1F567",
                "icon": "🕧",
                "title": "clock face twelve-thirty"
            }
        ]
    }
];
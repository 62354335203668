'use client';

import  React, {useState, useRef, useEffect} from 'react'
import {  Progress, Slider, Spin } from 'antd';
import svg from '../components/svg'
import {Helmet} from "react-helmet";
import Cookies from "js-cookie";
// import { useReactToPrint } from 'react-to-print';
import {
    TECollapse, 
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
} from "tw-elements-react";

// import UserPoints from '../components/UserPoints';
// // Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// // // Import Swiper styles
import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// // import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import VideoPlayer from '../components/VideoPlay';
import { setMyState, common, dataLoader } from '../components/Comman'
import { appStore } from '../zustand/store'
// import { List } from 'react-content-loader'
import {  Link  } from 'react-router-dom';
import config from '../config';
import { toast } from 'react-toastify';
import DiscussionTab from '../components/comments/DiscussionTab';
import VimeoVideoPlayer from '../components/vimeoVideoPlayer';
// import CommentsData from '../components/comments/CommentData';
import LessonNotes from '../components/lessonNotes';
import VdoPlayer from '../components/VdoPlayer';

import Goals from '../components/goals';

import {LoadingOutlined} from '@ant-design/icons'



const MyLearnings=(props)=> {    
    const slugName = window.location.href.split('/')[4];
    const myRefAudio = useRef(null);
    // const activeLesson = useRef(null);
    let alfaUrl = config.REACT_APP_BUCKET_URL
    let myStore = appStore(state => state);
    let userData = myStore.userData;
    const memberShipUser = userData.MembershipUser;
    // let appData = myStore.appData;
    let brandSetting = myStore.brandSetting;

    const [memberModal,setMemberModal] = useState(false);
   
    const [showAssessmentModal,setShowAssessmentModal] = useState(false);
    const [showUnlockModal,setShowUnlockModal] = useState(false);
    const [showShortModal,setShowShortModal] = useState(false);
    const [showGetPointModal,setShowGetPointModal] = useState(false);

    const[showFloatVideo,setShowFloatVideo] = useState(false);
   
    const [showCongratulationsModal, setShowCongratulationsModal] = useState(false);
    
    // const [time ,settime ] = useState("0 : 0");
    // const [completedLession ,setCompletedLession ] = useState(0);

    const [showRestrictedModal,setShowRestrictedModal] = useState(false);
    const [showQuizResultModal,setshowQuizResultModal] = useState(false);
    const [showQuizResultButton,setshowQuizResultButton] = useState(false);

    
    const [restrictedContent,setRestrictedContent] = useState('');
    const [showRestrictedVideoContent,setShowRestrictedVideoContent] = useState('');
    // const[restictButton,setRestrictButton] = useState(false);
    const[lessonIsLocked, setLessonIsLocked] = useState(false)
    const [video_type,setvideotype] =useState("")
    // const [isInnerLession , setIsInnerLession] = useState({})
    // const [open, setOpen] = useState(0);
    // const Icon =({ id, open })=> {
    //     return <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         strokeWidth={2}
    //         stroke="currentColor"
    //         className={`${id == open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    //       >
    //         <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    //     </svg>
    // }
    //   const handleOpen = (value) => setOpen(open == value ? 0 : value);

    const filteredItems = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
        {
            id: 5,
        },
        {
            id: 6,
        },
        {
            id: 7,
        },
        {
            id: 8,
        },
        {
            id: 9,
        },
    ];
      
    const [activeTab, setActiveTab] = useState("AboutLesson");
    const [activeElement, setActiveElement] = useState("");

    const handleClick = (value) => {
        if (value == activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(value);
        }
    }
    // const videoRef = useRef(null);
    // const [show, setShow] = useState(false);
    // const toggleShow = (e, postId, replyId) => setShow(!show);
    // let previousVal = 0;
    // let postUser = 0;
    // const [likePost, setLikePost] = useState(previousVal);
    // const [heart, setHeart] = useState();
    // const [usersCount, setUsersCount] = useState(postUser);
    // const [ispostData, setIsPostData] = useState();
    // const postRef = useRef(null);


    // const handlePostData = () => {
        // 👇️ access textarea value
        // setIsPostData(postRef.current.value)
        // setUsersCount((postUser) => postUser + 1)
    // };
    // const handlePostData=()=>{}
    let tokenCookie = Cookies.get(`access_token_${slugName}`)
    ? Cookies.get(`access_token_${slugName}`)
    : false;

    const urlParams = new URLSearchParams(window.location.search);
    const urlParamData = urlParams.get('course');
    
    const decoded=atob(urlParamData);
    const decodedData =urlParamData ? JSON.parse(decoded) : '';
    const myCourse =  localStorage.getItem('courseName') ?  localStorage.getItem('courseName') : '';
    const myParam = decodedData?.fId;
    
    let body = {
        funnel_id: memberShipUser.funnel_id,
        member_id: memberShipUser.id,
        funnel_page_id: myParam,
        token: tokenCookie,
    };
    const [state, setQuery] = useState({
        lession_name : '',
        rating : '',
        assessmentStep : 0,
        postId : '',
        modules : '6',
        completedModules: '0',

        readLession:false,
        funnel_id: memberShipUser.funnel_id,
        member_id: memberShipUser.member_id,
        funnel_page_id: myParam,
        token: tokenCookie,
        courseData: {},
        lessionList : {},
        lessionDetailes : {}, 
        lessionCount : '',
        lessionModule_name : '',
        lesson_id : '',
        course_id : '',
        status : '',
        firstLession : {},
        video_id : '',
        video_type : 'vimeo',
        video_url: '',
        // makeasComplete : false,
        downloadContent : [],
        download_status : false, 
        course_name : myCourse,
        lessionStatus : false,
        currentlession : null,
        currentmodule : null,
        markcomplete_status : false,
        courseCompleted : false,
        markcomplete_count : 0,
        downloadURL : '',
        loader:true,
        resourcesDetails : {
            loading : false,
            download_url : null,
            downloadable_type : null,
            resource_type : null,
            description : '',
        },
        activeClass : 0,
        lessons_type : '',
        buttonColor : null,
        totalLessonsCount : '',
        completedLessonsCount : '',
        certificate_url : null,
        moduleLocked : false,
        quizListData : [],
        quizAnswerList : [],
        showResult: false,
        resultPercentage:'',
        result_status:'',
        resultmessage : '',
        isChoseAnswer: false,
        assesment_default_text : '',
        retake_attempt_status : 0,
        quizMemberAnswerData:[],
        quizMemberResult:{},
        rewardsListData : [],
        badgesList:[]
    })
     const[videoLoader,setVideoLoader] = useState(false)
    const [lessonButtontext, setLessonButtontext] = useState('')
    const [lessonbuttonlink, setLessonButtonlink] = useState('')
    const[resultQuizLoader,setResultQuizLoader] = useState(false)

    const [showRewardModal, setShowRewardModal] = useState(false)

    const [persentageData, setPersentageData] = useState({
        totalLessonsCount : '',
        completedLessonsCount : '',
        completedModules : '0',
        certificate_url : null,
    });
    const [windowWidthDetector, setWindowWidthDetector] = useState(false);
    const[activeClass,setActiveClass] = useState(0);

    const getColor1=(type)=>{
        setMyState(setQuery, {
            // ...state,
            buttonColor : type,
        })
    }
    const getColor2=(type)=>{
        setMyState(setQuery, {
            // ...state,
            buttonColor : null,
        })
    }
    const quizListbody = {  
        funnel_id : state.funnel_id,
        member_id:memberShipUser.id,
        funnel_page_id : state.funnel_page_id,
        lesson_id : state.lesson_id,
        token : tokenCookie,
    }
    const getQuizQuestionList=()=>{
        common.getAPI({
            method: "Post",
            url: "quiz-list",
            data: quizListbody,
        },
        async (resp) => {   
            if(resp.success){
                setMyState(setQuery,{
                    // ...state,
                    question_list : resp.question_list,
                    assessmentStep : 0,
                    showResult : false,
                    assesment_default_text : resp.retake_attempt_status == 0 ? resp.attempt_msg : resp.assesment_default_text,
                    retake_attempt_status : resp.retake_attempt_status,
                });
            }
            if(resp.question_list?.length){
                setShowAssessmentModal(true)
                setMyState(setQuery, {
                    assessmentStep : 0,
                })
            }else{
                mackAsComplete(state.lesson_id, state.currentlession)
            }
        })
    }
    const getStartAssessment = ()=>{
        setMyState(setQuery,{
            // ...state,
            assessmentStep :  state.assessmentStep + 1,
        });
        // common.getAPI({
        //     method: "Post",
        //     url: "quiz-list",
        //     data: quizListbody,
        // },
        // async (resp) => {   
        //     if(resp.success){
        //         if(resp.question_list == 'undefined'){
        //             mackAsComplete(state.lesson_id, state.currentlession)
        //         }else{
        //             setMyState(setQuery,{
        //                 ...state,
        //                 assessmentStep :  state.assessmentStep + 1,
        //                 question_list : resp.question_list,
        //                 assesment_default_text : resp.retake_attempt_status == 0 ? resp.attempt_msg : resp.assesment_default_text,
        //                 retake_attempt_status : resp.retake_attempt_status,
        //             });
        //         }
        //     }else{                
        //         // toast.error(resp.message);
        //     }
        // })
    }
    const getResultOfQuiz=(a)=>{
        const quizAnswer = {
            funnel_id : state.funnel_id,
            funnel_page_id : state.funnel_page_id,
            lesson_id : state.lesson_id,
            member_id:memberShipUser.id,
            token : tokenCookie,
            answer :  JSON.stringify(selectedAnswer),
        }
        setResultQuizLoader(true)
        common.getAPI({
                method: "Post",
                url: "quiz-response",
                data: quizAnswer,
            },
            async (resp) => {   
        setResultQuizLoader(false);

                if(resp.success==true){

                    setMyState(setQuery,{
                        showResult : !state.showResult,
                        assessmentStep : null,
                        resultPercentage : resp.quiz_result?.percentage,
                        resultmessage : resp.quiz_result?.message,
                        result_status : resp.quiz_result?.result_status,
                    })
                    if(resp.quiz_result?.result_status == 'Pass'){
                        setTimeout(() => {
                            mackAsComplete(state.lesson_id, state.currentlession)
                        }, 6000);
                    }else{
                        toast.error(`Your Result Status is ${resp.quiz_result?.result_status}`)
                    }
                } else{
                    // toast.error(resp.quiz_result?.message)
                }
                setSelectedAnswer([])

            }
        );
    }
    // let answerList = [{
    //         quiz_question_id : '',
    //         choice_rand_id:'',
    //     }
    // ]
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const getSelectAnswer=(e, ans, QId,n1)=>{    
        let s=[...selectedAnswer]
        let p1={...ans}
       
        p1.quiz_question_id=QId
        s[n1]=p1
        setSelectedAnswer(s)
        // setMyState(setQuery,{isChoseAnswer: true});
    }
    const handleNext = (questionId, choicesId  ,type=null) => {    
              
        // setMyState(setQuery,{isChoseAnswer: false});
        // setSelectedAnswer(null)
        if(type!=null){
            getResultOfQuiz()
        }else{
            setMyState(setQuery,{
                // ...state,
                assessmentStep :  state.assessmentStep + 1
            });
        }
    };
    const handleBack = () => {      
        setMyState(setQuery,{
            // ...state,
            assessmentStep :  state.assessmentStep - 1,
        });
        // setMyState(setQuery,{isChoseAnswer: false});
    };

  
    // const pagination = {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //     hideOnClick: false,
    //     dynamicBullets: true,
    //     type: 'fraction',
    //     // dynamicMainBullets: 5,
    //     // renderBullet: function (index, className) {
    //     //   return '<span className="text-sm ' + className + '">' + (index + 1) + '</span>';
    //     // },
    // };


// We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    // const lessonRef = useRef(null);
 
    // // The state for our timer
    // const [timer, setTimer] = useState('00:00:00');
 
    // const getTimeRemaining = (e) => {
    //     const total = Date.parse(e) - Date.parse(new Date());
    //     const seconds = Math.floor((total / 1000) % 60);
    //     const minutes = Math.floor((total / 1000 / 60) % 60);
    //     const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    //     return {
    //         total, hours, minutes, seconds
    //     };
    // }
 
    // const startTimer = (e) => {
    //     let { total, hours, minutes, seconds } = getTimeRemaining(e);
    //     if (total >= 0) {
    //          // update the timer
    //         // check if less than 10 then we need to 
    //         // add '0' at the beginning of the variable
    //         setTimer(
    //             (hours > 9 ? hours : '0' + hours) + ':' +
    //             (minutes > 9 ? minutes : '0' + minutes) + ':'
    //             + (seconds > 9 ? seconds : '0' + seconds)
    //         )
    //     }
    // }
 
    // const clearTimer = (e) => {
    //     // If you adjust it you should also need to
    //     // adjust the Endtime formula we are about
    //     // to code next    
    //     setTimer('00:00:10');
 
    //     // If you try to remove this line the 
    //     // updating of timer Variable will be
    //     // after 1000ms or 1sec
    //     if (lessonRef.current) clearInterval(lessonRef.current);
    //     const id = setInterval(() => {
    //         startTimer(e);
    //     }, 1000)
    //     lessonRef.current = id;
    // }
 
    // const getDeadTime = () => {
    //     let deadline = new Date();
 
    //     // This is where you need to adjust if 
    //     // you entend to add more time
    //     deadline.setSeconds(deadline.getSeconds() + 10);
    //     return deadline;
    // }
 
    // // We can use useEffect so that when the component
    // // mount the timer will start as soon as possible
 
    // // We put empty array to act as componentDid
    // // mount only
    // useEffect(() => {
    //     clearTimer(getDeadTime());
    // }, []);
 
    // // Another way to call the clearTimer() to start
    // // the countdown is via action event from the
    // // button first we create function to be called
    // // by the button
    // const onClickReset = () => {
    //     clearTimer(getDeadTime());
    // }
 
    // const toggleFullScreen = () => {
        // var el = document.getElementById("full-screenVideo");
        // if (el.requestFullscreen) {
        //   el.requestFullscreen();
        // } else if (el.msRequestFullscreen) {
        //   el.msRequestFullscreen();
        // } else if (el.mozRequestFullScreen) {
        //   el.mozRequestFullScreen();
        // } else if (el.webkitRequestFullscreen) {
        //   el.webkitRequestFullscreen();
        // }
    // };


    const getCourseListData=()=>{
        if(userData.access_token){
            common.getAPI(
                {
                    method: "Post",
                    url: "courselist",
                    data: body,
                },
                async (resp) => { 
                    if(resp.courseData != ''){
                        setMyState(setQuery, {
                            // ...state,
                            courseData : resp.courseData,
                        })
                    } 
                }
            );
        }
        // audioPlay()
    }
    const [discussionMode, setDiscussionMode] = useState(false)
    const getLessonDetails=(lId ,lessions,myCourse,data,moduleName)=>{
        setvideotype("")
        setMyState(setQuery, {
            // ...state,
            lesson_id : lId,
            readLession : state.readLession == false ? !state.readLession : state.readLession,
            lessionStatus : true,
            // loader:true              
        })
        let lessonData = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            funnel_page_id: myParam,
            lesson_id : lId,
            token: tokenCookie,
        }
        setVideoLoader(true)
        common.getAPI({
                method: "Post",
                url: "lesson-details",
                data: lessonData,
            },
            async (resp) => {
                if(resp.discussion_mode == 1){
                    setDiscussionMode(true)
                }else{
                    setDiscussionMode(false)
                }
             setVideoLoader(false)
                if(localStorage.getItem('lessonSearch')){
                    localStorage.removeItem('lessonSearch');
                    myStore.updateStoreData("searchLessonData" , []);
                }
                // if(resp?.less)     
                if(resp?.restrict_text){
                    setShowRestrictedVideoContent(resp.restrict_text)
                }
                
                if(resp?.buttontext){
                    setLessonButtontext(resp?.buttontext)
                    setLessonButtonlink(resp?.buttonink)
                }
                if(resp?.restrictCourse == true){
                    setTimeout(() => {
                        var element = document.getElementById("mainPageLoader");
                        element.classList.remove("isopen");
                    }, 1500);
                    setShowRestrictedModal(true);
                    setRestrictedContent(JSON.parse(resp.release_content));
                } else{
                // const responseData = Object.values(resp?.lesson)[0];
                const responseData = resp && resp.lesson ? Object.values(resp.lesson)[0] : Object.values(resp)[0];
                let tempContainer = document.createElement('div');
                tempContainer.innerHTML = responseData.html;
                let scriptTags = tempContainer.getElementsByTagName('a');
                // let scriptSrcs = [];
                let len = scriptTags.length
                for (let i = 0; i < len; i++) {
                  if (scriptTags[0]?.style?.color) {
                    
                  } else{
                    scriptTags[0].style.color=brandSetting.theme_color2
                  }
                }
                let d1={...responseData}
                d1.html=tempContainer.innerHTML
                setvideotype(responseData.video_type); 
                    let t1= {
                        ...state,
                        lesson_id : lId,
                        video_id : responseData.video_id, 
                        video_type :  responseData.video_type,
                        lessionDetailes : d1,
                        lession_name : responseData.lesson_name,
                        lession_text : responseData.text,
                        lessons_type : responseData.lesson_type,
                        isResourceActivity : responseData.isResourceActivity,
                        loader:false,
                    }
            
                    if(lessions){
                        t1={...t1,
                            lessionList : lessions,  
                            modules : lessions.length-1,
                            course_name : myCourse, 
                            currentlession : data,
                            markcomplete_status : data?.markcomplete_status,
                            lessionModule_name:moduleName ? moduleName :state.lessionModule_name,
                            
                            // completedModules : data.markcomplete_status
                        }
                    }
                    setMyState(setQuery, {...t1})
                    if(resp?.show == 0){ 
                        // setRestrictButton(true);
                        setShowRestrictedVideoContent(resp?.restrict_text);
                    }
                    
                    
                    if(resp?.lesson){
                        // console.log(resp, 'Response Discussion');
                        
                        if(resp.lesson[0]?.lesson_type == 'text' ){
                            setActiveTab('Resources');
                            if(resp.lesson[0]?.isResourceActivity == 1){
                                setActiveTab('Resources');
                            }else if(resp.discussion_mode == 1){
                                setActiveTab('Discussions');
                            }
                            // else if(!(lessonData.funnel_id == '66976' || lessonData.funnel_id == '74737' || lessonData.funnel_id == '88476') && !resp.restrict_text){
                            //     setActiveTab('Notes')
                            // }
                        }
                        else if(resp.lesson[0]?.html != null){
                            setActiveTab('AboutLesson')
                        }else {
                            setActiveTab('')
                        }
                    }
                    // setMyState(setQuery, {lession_name: })
                   
                }
                window.videoDuration = 0;
                getQuizMemberAnswer(lId);
                if(memberShipUser.funnel_id == '66976'){
                    getRewardsListData(lId)
                    getMemberBadge(lId);
                }
            }
        );
     
        
    };

    const getLessonDetails1=(lId, lessions,data)=>{
        setvideotype("")
        setMyState(setQuery, {
            // ...state,
            lesson_id : lId,
            readLession : state.readLession == false ? !state.readLession : state.readLession,
            lessionStatus : true,
            lessionModule_name : data.course_name,
            lession_name : data.lessons[0]?.lesson_name,
            // loader:true              
        })
        let lessonData = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            funnel_page_id: myParam,
            lesson_id : lId,
            token: tokenCookie,
        }
        setVideoLoader(true)
        common.getAPI({
            method: "Post",
            url: "lesson-details",
            data: lessonData,
        },
        async (resp) => {
            if(resp.discussion_mode == 1){
                setDiscussionMode(true)
            }else{
                setDiscussionMode(false)
            }
        setVideoLoader(false)
            if(resp.restrict_text){
                setShowRestrictedVideoContent(resp.restrict_text)
            }
            if(resp?.buttontext){
                setLessonButtontext(resp?.buttontext)
                setLessonButtonlink(resp?.buttonink)
            }else{
                setLessonButtontext('')
                setLessonButtonlink('')
            }
            
            const responseData = resp && resp.lesson ? Object.values(resp.lesson)[0] : Object.values(resp)[0];          
            setvideotype(responseData.video_type); 
            
            let t1= {
                ...state,
                lesson_id : lId,
                video_id : responseData.video_id, 
                video_type :  responseData.video_type,
                lessionDetailes : responseData,
                lesson_name : responseData.lesson_name,
                lession_text : responseData.text,
                lessons_type : responseData.lesson_type,
                loader:false,
                lessionModule_name : data.course_name,
                lession_name : data.lessons[0]?.lesson_name,
                isResourceActivity : responseData.isResourceActivity, 
            }
            if(lessions){
                t1={...t1,
                    markcomplete_status : lessions.markcomplete_status,
                }
            }
            if(resp?.show == 0){ 
                // setRestrictButton(true);
                setShowRestrictedVideoContent(resp?.restrict_text);
            }
            setMyState(setQuery, {...t1})
            if(resp?.lesson){
                // console.log(resp, 'Response Discussion2');
                if(resp.lesson[0]?.lesson_type == 'text'){
                    if(resp.lesson[0]?.isResourceActivity == 1){
                        setActiveTab('Resources');
                    }else if(resp.discussion_mode == 1){
                        setActiveTab('Discussions');
                    }
                    // else if(!(lessonData.funnel_id == '66976' || lessonData.funnel_id == '74737' || lessonData.funnel_id == '88476') && !resp.restrict_text){
                    //     setActiveTab('Notes')
                    // }
                }else if(resp.lesson[0]?.html != null){
                    setActiveTab('AboutLesson')
                }else if(showRestrictedVideoContent != null){
                    setActiveTab('')
                }
                getQuizMemberAnswer(lId);
                if(memberShipUser.funnel_id == '66976'){
                    getRewardsListData(lId);
                    getMemberBadge(lId);
                } 
            }
            window.videoDuration = 0;
        }
    )};

    const findLessonIndex = (courses, lessonId) => { 
        for (const [courseIndex, course] of Object.entries(courses)) { 
          if (course.lessons) {
            for (const [lessonIndex, lesson] of Object.entries(course.lessons)) { 
              if (lesson.lesson_id == lessonId) {
                return { courseIndex: parseInt(courseIndex), lessonIndex: parseInt(lessonIndex) };
              }
            }
          }
        }
        return null; // If not found
      };
    const getLessonListData=(ind,mod)=>{
        ind=ind ? ind  :0
        if(userData.access_token){
            common.getAPI({
                method: "Post",
                url: "lessonlist",
                data: body,
            },
            async (resp) => {
                if(resp.success == true){
                    const course = resp.course;
                    
                    const lessions = Object.values(course);
                    setMyState(setQuery, {
                        // ...state,
                        lessionModule_name : resp?.course[0]?.course_name,
                    }) 
                    if(resp.course[0]?.show == 0){
                        setLessonIsLocked(true)
                        setShowRestrictedVideoContent(resp.course[0].restrict_text)
                    }else{
                        setLessonIsLocked(false)
                    }
                    let number = activeElement.replace('element', '');
                    let num = number ? number : 0;
                    let lesIndx = ( mod == 'next') ? (parseInt(num) + 1)  : parseInt(num);
                    let d1=lessions?.[lesIndx].lessons?.[ind ? ind : 0]?.lesson_id;
                    let ind_data =lessions?.[lesIndx ].lessons?.[ind];
                    setMyState(setQuery, {
                        // ...state,
                        lessionModule_name :lessions?.[lesIndx].course_name,
                    })
                    let lessonSearch = localStorage.getItem('lessonSearch'); 
                    if(lessonSearch){
                        let index = findLessonIndex(course ,lessonSearch);
                        setActiveElement(`element${index?.courseIndex}`);
                        setActiveClass(index?.lessonIndex);
                        getLessonDetails(lessonSearch,lessions, myCourse, lessions?.[index?.lessonIndex]?.lessons?.[index?.lessonIndex], lessions?.[index?.lessonIndex]?.course_name);
                    }else{
                        lessions.map((t, i)=>{
                            if(t.show == 1){
                                getLessonDetails(d1,lessions, myCourse,ind_data ? ind_data : resp.course.firstlessons, lessions?.[lesIndx].course_name);
                            }else{
                                setMyState(setQuery, {
                                    // ...state,
                                    moduleLocked : false,
                                })
                            }
                            return;
                        });
                    }

                  
                    
                } 
                getCoursePercentage()
                
            });
        }
    }
        
    const [NextLessonName, setNextLessonName] = useState('')
    // const audioPlay=()=>{
    //     myRefAudio.current.play();
    //     setTimeout(
    //         () => {
    //             myRefAudio.current.pause()
                
    //         }, 5000
    //     )
    // }
    // const mackAsComplete=(lessonId, data)=>{   
    //     let lessonData = {
    //         funnel_id: memberShipUser.funnel_id,
    //         member_id: memberShipUser.id,
    //         funnel_page_id: myParam,
    //         lesson_id : lessonId,
    //         token: tokenCookie,
    //     }
    //     common.getAPI({
    //         method: "Post",
    //         url: "markecomplete",
    //         data: lessonData,
    //     },
    //     async (resp) => {
    //         if(resp.success == true){
    //             getCoursePercentage()
    //             myRefAudio.current.play()
    //             setShowAssessmentModal(false)
    //             setShowCongratulationsModal(true)
    //             setTimeout(() => {
    //                 myRefAudio.current.pause()
    //                 setShowCongratulationsModal(false)
    //             }, 5000);
                
    //             let num = activeElement.replace('element', '');
    //             const totalLessons = state?.lessionList[num]?.lessons?.lesson_count;
    //             const hasNextLesson = activeClass < totalLessons - 1;
    //             num = num ? num : 0;
    //             if(state?.lessionList[num]?.lessons){
    //                 const lessonObject = Object?.values(state?.lessionList[num]?.lessons);
    //                 const innerLessonIndex = state?.lessionList[num]?.lessons;
    //                 if(hasNextLesson){ 
    //                     const remainingLessons = lessonObject.slice(activeClass + 1);
    //                     const nextLessonIndex = remainingLessons.findIndex((lesson) => lesson.show == 1);
    //                     if (nextLessonIndex !== -1) {
    //                         let nextac = lessonObject.findIndex((less) => less.lesson_id == remainingLessons[nextLessonIndex].lesson_id); 
    //                         setActiveClass(nextac) 
    //                         setTimeout(() => {

    //                         }, 5000);
    //                         getLessonListData(nextac);
    //                     }else{
    //                         const nextLessonIndex = remainingLessons.findIndex((lesson) => lesson.show == 0);
    //                         if(innerLessonIndex){
    //                             let nextac = lessonObject.findIndex((less) => less.lesson_id == remainingLessons[nextLessonIndex].lesson_id);
    //                             setActiveClass(nextac);
    //                             getLessonListData(nextac);
    //                             setMyState(setQuery, {
    //                                 lession_name : innerLessonIndex[nextac].lesson_name,
    //                             })
    //                             setNextLessonName(innerLessonIndex[nextac].lesson_name);
                                
    //                         }else{
    //                             getLessonListData();
    //                         }
    //                     }
    //                 }else{ 
    //                     if((parseInt(num) + 1) < state.modules){ 
    //                         let nextLessionInd1 = state?.lessionList[parseInt(num) +1].lessons
    //                         let nextLessionInd= Object.values(nextLessionInd1).findIndex((lesson) => lesson.show == 1); 
    //                         setActiveElement(`element${parseInt(num) + 1}`);
    //                         if(nextLessionInd  !== -1){
    //                             setMyState(setQuery, {
    //                                 lessionModule_name :state?.lessionList[parseInt(num) +1].course_name,
    //                                 activeClass : nextLessionInd 
    //                             })
    //                         } 
    //                         getLessonListData(0,'next');  
    //                     }else{
    //                         getLessonListData(activeClass);
    //                     }
    //                 }
    //             }
    //         }
                
    //     });
    //     // setMyState(setQuery, {
    //     //     makeasComplete : true,
    //     // })
    // }
    const mackAsComplete=(lessonId, data)=>{   
        let lessonData = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            funnel_page_id: myParam,
            lesson_id : lessonId,
            token: tokenCookie,
        }
        common.getAPI({
            method: "Post",
            url: "markecomplete",
            data: lessonData,
        },
        async (resp) => {
            if(resp.success == true){
                getCoursePercentage()
                myRefAudio.current.play()
                setShowAssessmentModal(false)
                setShowCongratulationsModal(true)
                setTimeout(() => {
                    myRefAudio.current.pause()
                    setShowCongratulationsModal(false)
                }, 5000);
                
                let num = activeElement.replace('element', '');
                const totalLessons = state?.lessionList[num]?.lessons?.lesson_count;
                const hasNextLesson = activeClass < totalLessons - 1;
                num = num ? num : 0;
                if(state?.lessionList[num]?.lessons){
                    const lessonObject = Object?.values(state?.lessionList[num]?.lessons);
                    // const innerLessonIndex = state?.lessionList[num]?.lessons;
                    if(hasNextLesson){ 
                        const remainingLessons = lessonObject.slice(activeClass + 1); 
                        // const nextLessonIndex = remainingLessons.findIndex((lesson) => lesson.show == 1);
                        // if (nextLessonIndex !== -1) {
                            let nextac = lessonObject.findIndex((less) => less.lesson_id == remainingLessons[0].lesson_id); 
                            setActiveClass(nextac) 
                            setTimeout(() => {

                            }, 5000);
                            getLessonListData(nextac);
                        // }else{
                        //     const nextLessonIndex = remainingLessons.findIndex((lesson) => lesson.show == 0);
                        //     if(innerLessonIndex){
                        //         let nextac = lessonObject.findIndex((less) => less.lesson_id == remainingLessons[0].lesson_id);
                        //         setActiveClass(nextac);
                        //         getLessonListData(nextac);
                        //         setMyState(setQuery, {
                        //             lession_name : innerLessonIndex[nextac].lesson_name,
                        //         })
                        //         setNextLessonName(innerLessonIndex[nextac].lesson_name);
                                
                        //     }else{
                        //         getLessonListData();
                        //     }
                        // }
                    }else{ 
                        if((parseInt(num) + 1) < state.modules){ 
                            // let nextLessionInd1 = state?.lessionList[parseInt(num) +1].lessons
                            // let nextLessionInd= Object.values(nextLessionInd1).findIndex((lesson) => lesson.show == 1); 
                            setActiveElement(`element${parseInt(num) + 1}`);
                            // if(nextLessionInd  !== -1){
                                setMyState(setQuery, {
                                    lessionModule_name :state?.lessionList[parseInt(num) +1].course_name,
                                    activeClass : 0 
                                })
                                setActiveClass(0);
                            // } 
                            getLessonListData(0,'next');  
                        }else{ 
                            getLessonListData(activeClass);
                        }
                    }
                }
            }
                
        });
        // setMyState(setQuery, {
        //     makeasComplete : true,
        // })
    }
    const getCoursePercentage=()=>{
        let lessonData = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            funnel_page_id: myParam,
            token: tokenCookie,
            // certificate_url : ''
        }
        common.getAPI({
            method: "Post",
            url: "completeperc",
            data: lessonData,
        },
        async (resp) => {
            setMyState(setQuery, {
                // ...state,
                completedModules : resp.percentage,
                totalLessonsCount : resp.total_lesson,
                completedLessonsCount : resp.completedcount,
                certificate_url : resp.certificate_url,
            })
            setMyState(setPersentageData,{
                ...persentageData,
                totalLessonsCount : resp.total_lesson,
                completedLessonsCount : resp.completedcount,
                completedModules : resp.percentage,
                certificate_url : resp.certificate_url,
            })
        })
    }
    
    const getDownloadContant=()=>{
        let lessonDownload = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            funnel_page_id: myParam,
            lesson_id : state.lesson_id,
            token: tokenCookie,
        } 

        common.getAPI({
            method: "Post",
            url: "downloadcontent",
            data: lessonDownload,
        }, (resp) => {
            let reData = resp?.downloadcontent?.[0] || {};
            setMyState(setQuery, {
                resourcesDetails : {
                    loading : true,
                    download_url : null,
                    downloadable_type : null,
                    resource_type : null,
                    description : '',
                    ...reData
                }
            })
        });
    }
    // function downloadMyFile(filePath) {
    //     var filename = filePath.split('/').pop();
    //     fetch(filePath).then((response) => {
    //         response.blob().then((blob) => {
    //           let url = window.URL.createObjectURL(blob);
    //           let a = document.createElement("a");
    //           a.href = url;
    //           a.download = filename.split('?')[0];
    //           a.click();
    //         });
    //     });
    // }

    const getDownloadCertificate=async(fileHref)=>{
        var iframe = document.createElement('iframe');
        iframe.id = "download_course";
        iframe.style.width = "0";
        iframe.style.height = "0";
        iframe.style.border = "none";
        iframe.src = fileHref;	
        
        iframe.onload = function() {		
            setTimeout(function(){ document.getElementById('download_course').remove(); }, 2000);
        };
        document.body.appendChild(iframe);	
    }

    let isModuleComepleted = (lessionData) => {
        // let lCount = lessionData.length;
        let status = true;
        let i = 0;
        for (let lInd in lessionData) { 
            if(!lessionData[lInd].markcomplete_status){
                i++;
                status = false;
                return;
            }
            
        }
        return status
    }
    
    useEffect(()=>{
        getCourseListData();
        getLessonListData();
        getDownloadContant();
    },[])
   

    useEffect(()=>{
        if(activeTab == 'Resources'){
            getDownloadContant();
        }
    },[activeTab])  
    
    const getInnerLessonFunction=(e, lesson, t, i)=>{
        e.preventDefault()
    }
   
    const com1=()=>{
        return<>
            <div className='col-span-1 mc_moboilCol_12'>
                {/* {state.badgesList.length == 0 && <div className='mc_whiteBox p-5 w-full mb-3'>
                    <h5 className='text-sm text-black font-bold'>I have earned 1 badges</h5>
                    <div className='mc_thumbSlider'>
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={5}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            {filteredItems.map((item, ind) =>{ 
                                return <SwiperSlide key={ind}>
                                        <div className="row-item">
                                            <div className="item-header">
                                                {svg.app.batchBlue}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                }
                            )}
                        </Swiper>
                    </div>
                    <div className='p-5 relative flex items-center justify-between mc_blueBox'>
                        <div className='mc_imgLabel'>
                            {svg.app.batchLabel}
                            <p className='text-black text-xs ml-1'>My Total Points</p>
                        </div>
                        <h5 className='text-black text-sm font-extrabold'>200</h5>
                    </div>
                </div>} */}

                {!state.loader ? 
                    <div className='mc_whiteBox p-5 w-full mb-3'>
                        {persentageData.certificate_url != null  &&
                            <a href={() => false} className='mc_btn mc_downLoad_btn' download
                                style={{fill: brandSetting.theme_button_txt_color,color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Download Certitifcate' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                onMouseEnter={(e)=> getColor1('Download Certitifcate')} onMouseLeave={(e)=> getColor2('Download Certitifcate')}
                                onClick={()=> getDownloadCertificate(persentageData.certificate_url)}
                                target='_blank'
                            > 
                                {svg.app.downloadIcon2} Download Certitifcate
                            </a>
                        }
                        <div className='flex items-center justify-between'>
                            <h5 className='text-gray-900 text-base font-bold'>Total {state.modules} Modules</h5>
                        </div>
                        <div className='mc_progressHolder mt-4'>
                            <div className="mb-0 flex items-center justify-between gap-4">
                                <p className='text-xs font-semibold text-black'>{persentageData.completedModules}%</p>
                                <p className='text-xs font-semibold text-black'>{persentageData.completedLessonsCount}/{persentageData.totalLessonsCount} Lessons</p>
                            </div>
                            <Progress percent={persentageData.completedModules == '100' ? '100' : persentageData.completedModules}
                            strokeColor={persentageData.completedModules == '100' ? '#07d001' : persentageData.completedModules > 0 ? brandSetting.theme_color2  : '#F0F0F0' }/>
                            {/* <div className="mb-0 flex items-center justify-between gap-4">
                                <p className='text-xs font-semibold text-black'>{state.completedModules==state.modules ? '100' : Math.round(100 / state.modules)*state.completedModules}%</p>
                                <p className='text-xs font-semibold text-black'>{state.completedModules}/{state.modules} Modules</p>
                            </div>
                            <Progress percent={state.completedModules==state.modules ? '100' : Math.round(100 / state.modules)*state.completedModules}/> */}
                        </div>
                        <div className='mc_listAccorfian'>
                            {/* <Collapse accordion items={items} /> */}
                            <div id="accordionExample">
                                {/* <h1>{JSON.stringify(state.lessionList)}</h1> */}
                                {state.lessionList.length ? state.lessionList.map((lesson, lessonIndex)=>{ 
                                    let count = 0;
                                    if( lesson.lessons !== undefined ){
                                        count = lesson.lessons.lesson_count;
                                    }
                                    const isCompleted = (lesson?.lessons?.lesson_count || 0) && isModuleComepleted(lesson?.lessons);
                                    // lesson.show != 1 ? setMyState(setQuery,{ moduleLocked : true }) : setMyState(setQuery,{ moduleLocked : false })
                                   
                                    if(count > 0){  
                                        return ( 
                                            <div key={lessonIndex} className="relative rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                                            <div className={`relative mb-0`} id="headingOne">
                                                <div className='mc_playPauseAccordian flex flex-1 py-3' 
                                                    onClick={() =>{
                                                        setshowQuizResultButton(false)
                                                        setMyState(setQuery, {
                                                            // ...state,
                                                            lessionModule_name : lesson.course_name,
                                                            lession_name : lesson.lessons[0]?.lesson_name,
                                                        });
                                                        setActiveClass('0');
                                                        if(lesson.show == 1){
                                                            setLessonIsLocked(false);
                                                            setShowRestrictedVideoContent(lesson?.restrict_text);
                                                             
                                                        } else{                                                           
                                                            setLessonIsLocked(true);
                                                            setShowRestrictedVideoContent(lesson?.restrict_text);
                                                        }
                                                        getLessonDetails1(lesson?.lessons[0].lesson_id, lesson?.lessons[0],lesson)
                                                        handleClick(`element${lessonIndex}`);
                                                        setActiveTab(lesson?.lessons[0].lesson_type != 'text' ? 'AboutLesson' : lesson?.lessons[0].isResourceActivity == 1 ? 'Resources' : 'Discussions');
                                                    }} 
                                                    aria-expanded="true" aria-controls="collapseOne"
                                                >
                                                    <div className='mc_playBtn flex justify-center items-start mr-3 mt2'>
                                                        <span className={`mc_circleActive_icon ${isCompleted ? 'isActiive_color' : 'isGray_color' }`} style={{fill : `${activeElement == `element${lessonIndex}` ? brandSetting.theme_color2 : isCompleted ? brandSetting.theme_color2 : '#C3C3C3' }`, border : `2px solid ${activeElement == `element${lessonIndex}` ? brandSetting.theme_color2 : isCompleted ? brandSetting.theme_color2 : '#c3c3c3'}` }}
                                                        >
                                                            {lesson.show == 1 ?
                                                                svg.app.greenTickCircle 
                                                                    : 
                                                                svg.app.lockIcon
                                                            }
                                                        </span> 
                                                    </div>
                                                    <div className='mc_accordientTxt'>
                                                        <p className='text-base text-black text-sm'>{lesson.course_name}</p>
                                                        <span className='text-gray-500 text-xs'>{count} Lesson. 
                                                        {/* 01hr 31 mins */}
                                                        </span>
                                                    </div>
                                                    <button className={`mc_accordianBtn ${activeElement == `element${lessonIndex}` && `isActive_icon text-primary dark:!text-primary-400`}`}
                                                    type="button">
                                                        <span className={`mc_accordianIcon ${activeElement == `element${lessonIndex}` ? `rotate-[-180deg] -mr-1` : `rotate-0  dark:fill-white`
                                                            } `} style={{color : `${activeElement == `element${lessonIndex}` ? brandSetting.theme_color2 : brandSetting.theme_color2}`}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                          
                                            <TECollapse show={activeElement == `element${lessonIndex}`} className={`mc_accordianItemsNew  ${activeElement == `element${lessonIndex}` ? 'openAcc' : ''} !mt-0 !rounded-b-none !shadow-none`}>
                                                <div className='mc_accordianItems'>
                                                    {lesson?.lessons && lesson.lessons.lesson_count > 0 && Object.values(lesson.lessons).filter(item => typeof item === 'object').map((t, i)=>{  

                                                        return  (
                                                            <div key={i} className='mc_innnerList'> 
                                                                <label className={`mc_checkboxList ${t.markcomplete_status == 1 ? 'mc_activeLession' : ''} ${activeClass == i ? 'isActiveLesson' : ''}`} 
                                                                    onClick={(e)=>{ 
                                                                        if(activeClass != i){
                                                                            setshowQuizResultButton(false)
                                                                        }
                                                                        // if(t.quiz_status){
                                                                        //     setshowQuizResultButton(true)
                                                                        // }
                                                                        document.getElementById('videoJsPlayer').scrollIntoView({
                                                                            behavior: 'smooth'
                                                                        });

                                                                        if(t?.buttontext){
                                                                            setLessonButtontext(t?.buttontext)
                                                                            setLessonButtonlink(t?.buttonink)
                                                                        }else{
                                                                            setLessonButtontext(t?.buttontext)
                                                                            setLessonButtonlink(t?.buttonink)
                                                                        }
                                                                        if(lessonIsLocked == true){
                                                                            setShowRestrictedVideoContent(lesson?.restrict_text)
                                                                          
                                                                        }else if(!t.show ){
                                                                            getInnerLessonFunction(e, lesson, t, i  );
                                                                            // setRestrictButton(true);
                                                                            setShowRestrictedVideoContent(t.restrict_text)
                                                                        }else{
                                                                            setShowRestrictedVideoContent(t?.restrict_text);
                                                                        }
                                                                        setMyState(setQuery, {
                                                                            lession_name : t.lesson_name,
                                                                            currentlession : lesson.t,
                                                                        })
                                                                        setActiveClass(i);
                                                                        setActiveTab(lesson?.lessons[0].lesson_type != 'text' ? 'AboutLesson' : lesson?.lessons[0]?.isResourceActivity == 1 ? 'Resources' : 'Discussions' 
                                                                        // ? (!t?.restrict_text && lesson?.lessons[0]?.isResourceActivity == 0) ? 'Notes'
                                                                        );

                                                                    }
                                                                }
                                                                    style={{color : `${t.markcomplete_status == 1 ? brandSetting.theme_color2 : '#000'} ${activeClass == i ? brandSetting.theme_color2 : '#000'}`}}
                                                                >
                                                                    {/* {showRestrictedVideoContent } */}
                                                                    <input type='radio' name='lessonList' className='mc_checkbox' onChange={()=> {
                                                                        if(lesson.show == 1){
                                                                            getLessonDetails(t.lesson_id, state.lessionList, myCourse, t )
                                                                        }
                                                                    }} checked={t.lesson_id === state.lesson_id}/>
                                                                    {(lesson.show == 1 ) ? (!t.show ? 
                                                                        <span className='fileIcon' 
                                                                            style={{fill : `${t.markcomplete_status == 1 ? brandSetting.theme_color2 : activeClass == i ? brandSetting.theme_color2 : '#c3c3c3'}`}}>
                                                                            { svg.app.lockIcon}
                                                                        </span>  
                                                                    :
                                                                    t.lesson_type == 'video' ?
                                                                        // <span className='mc_playTick' style={{fill : `${activeClass == i ? brandSetting.theme_color2 : '#c3c3c3'}`, border : `1px solid ${t.markcomplete_status == 1 ? brandSetting.theme_color2 : '#c3c3c3'} ${activeClass == i ? brandSetting.theme_color2 : '#c3c3c3'} `}}></span> 
                                                                        <span className='fileIcon' style={{fill : `${t.markcomplete_status == 1 ? brandSetting.theme_color2 : activeClass == i ? brandSetting.theme_color2 : '#c3c3c3'}`}}>
                                                                              {svg.app.lessonPlay}
                                                                        </span>
                                                                        : 
                                                                        // <span className='checkTick'></span> 
                                                                        <span className='fileIcon' style={{fill : `${t.markcomplete_status == 1 ? brandSetting.theme_color2 : activeClass == i ? brandSetting.theme_color2 : '#c3c3c3'}`}}>{svg.app.fileIcon}</span>)
                                                                    : 
                                                                        <span className='fileIcon' 
                                                                            style={{fill : `${t.markcomplete_status == 1 ? brandSetting.theme_color2 : activeClass == i ? brandSetting.theme_color2 : '#c3c3c3'}`}}>
                                                                            { svg.app.lockIcon}
                                                                        </span>
                                                                    } 
                                                                    <span className={`mc_lessonName_span ${t.markcomplete_status == 1 ? 'mc_highlight' : '' }`}
                                                                        style={{color : `${activeClass == i ? brandSetting.theme_color2 :  state.buttonColor == `LessonName${i}` ?  brandSetting.theme_color2 : '#000'}`}}  
                                                                        onMouseEnter={()=> getColor1(`LessonName${i}`)} 
                                                                        onMouseLeave={()=> getColor2(`LessonName${i}`)}
                                                                    >{t.lesson_name}
                                                                   { t.quiz_status ? <span className='mc_lesson_quiz_icon'> {svg.app.quizIcon}</span> : ''}
                                                                    </span>
                                                                
                                                                
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </TECollapse>
                                            
                                        </div>
                                    )}
                                }) : ''}
                                    
                            </div>
                            
                            {/* <a href={() => false} className='mc_btn mc_courseBtn text-sm' 
                                onClick={(e)=> getDownloadCertificate(e, state.lesson_id)}
                            >
                                Download Your Certificate
                                <span>{svg.app.arrowRight}</span>
                            </a> */}
                        </div>
                    </div> 
                    : dataLoader.videoModule } 
                
                </div>
        </>
    }

    useEffect(()=>{
        // window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1024){
                if(windowWidthDetector == false){
                    setWindowWidthDetector(true)
                }
            }else{
                if(windowWidthDetector == true){
                    setWindowWidthDetector(false)
                }
            }
        // })
    },[window.innerWidth])

// React Vimeo VideoPlayer Settings

    // const playerRef = useRef(null);
    // const [isPlaying, setIsPlaying] = useState(false);
    // const [currentTime, setCurrentTime] = useState(0);
    // const [duration, setDuration] = useState(0);
      
    // const togglePlay = () => {
    //   setIsPlaying(!isPlaying);
    // };
    // const videoCurrentTime = playerRef.getCurrentTime();

   
    // const onSeek = (e, seekItem) => {
    //   const seekTime = e.target.value;
    //   const seekTimeFloat = playerRef.current.seekTo(parseFloat(seekTime));
    //   playerRef.current.seekTo(seekTime, true);
    // //   const timeStamp = formatTime(e.timeStamp);
    // };
  
    // const onProgress = (state) => {
    //     setCurrentTime(state.playedSeconds);
    // };
  
    // const onDuration = (duration) => {
    //   setDuration(duration);
    // };
  
    // const formatTime = (seconds) => {
    //   const format = (val) => `0${Math.floor(val)}`.slice(-2);
    //   const hours = Math.floor(seconds / 3600);
    //   const minutes = Math.floor((seconds % 3600) / 60);
    //   const secs = Math.floor(seconds % 60);
  
    //   return `${hours}:${format(minutes)}:${format(secs)}`;
    // };
    
    const [postDataFilter, setPostDataFilter] = useState('');
    const propsData = {
        funnel_id: memberShipUser.funnel_id,
        member_id: memberShipUser.id,
        funnel_page_id: myParam,
        lesson_id : state.lessionDetailes.lesson_id,
        page :1,
        token: tokenCookie,
    }
    const getPostListData=()=>{
        common.getAPI({
            method: "Post",
            url: "getlesson-post-comment",
            data: propsData,
        },
        async (resp) => { 
            if(resp.success == true){
                setPostDataFilter(resp.post)
            }
        })
    }
    
    useEffect(()=>{
        getPostListData();
    //     if( video_type == 'flexi'){
    //         if(state.lessionDetailes?.embed){
    //             let s1 = document.createElement("script");
    //             s1.type = "text/javascript";
    //             s1.src = 'https://player.vdocipher.com/v2/api.js';
              
    //             document.head.appendChild(s1);
    //     }
    // }
    
    },[])

 

    const  getQuizMemberAnswer = (l_id)=>{
        let apiData = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            lesson_id : l_id,
            token: tokenCookie,
        };
      
        common.getAPI({
            method: "Post",
            url: "quiz-member-answer",
            data: apiData,
        },
        async (resp) => { 
            if(resp.success == true){             
                if(resp?.member_quiz_attend_response.length > 0){
                    setshowQuizResultButton(true);
                    setMyState(setQuery, {
                        quizMemberAnswerData : resp?.member_quiz_attend_response,
                        quizMemberResult:resp?.member_quiz_result
                    })
                   
                }else{
                    setshowQuizResultButton(false);
                }
               
            }else{ 
                setshowQuizResultButton(false);
                setMyState(setQuery, {
                    quizMemberAnswerData : [],
                    quizMemberResult:{}
                })
                // toast.error(resp.message);
            }
        });
    }
   
    const iframeRef = useRef(null);


    let videoRefer = useRef(null);
    useEffect(() => {
        const options = {
          root:null,
          rootMargin: '0px',
          threshold: window.innerWidth > 1000 ? 0.4 : 0,
        };
    
        const callback = (entries, observer) => { 
          entries.forEach(entry => { 
            if (!entry.isIntersecting) {
              setShowFloatVideo(true);
              
            } else {
              setShowFloatVideo(false);
            }
          });
        };
    
        const observer = new IntersectionObserver(callback, options);
        if (videoRefer.current) {
          observer.observe(videoRefer.current);
        }
    
        return () => {
          if (videoRefer.current) {
            observer.unobserve(videoRefer.current);
          }
        };
   
    },[videoRefer.current]);

    // // script For Dragable Element
    const lastTouch = useRef(null);
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    var width = window.innerWidth <= 430 ?  window.innerWidth - 300 : window.innerWidth - 430; 
    var height = window.innerHeight - 280;
    const [position, setPosition] = useState({ x: width, y: height });
    if(window.innerWidth < 765){
        // setPosition({x: 0, y: 0 })
    }
    const onMouseDrag = (e) => {
        // Prevent scrolling on touch devices
        if (e.type === 'touchmove') {
            e.preventDefault();
        }
    
        let movementX, movementY;
    
        if (e.type === 'mousemove') {
            movementX = e.movementX;
            movementY = e.movementY;
        } else if (e.type === 'touchmove') {
            movementX = e.touches[0].clientX - lastTouch.current.clientX;
            movementY = e.touches[0].clientY - lastTouch.current.clientY;
            lastTouch.current = e.touches[0];
        }
    
        setPosition((prevPosition) => ({
            x: prevPosition.x + movementX,
            y: prevPosition.y + movementY,
        }));
    };
  
    const onMouseDown = (e) => {
      setIsDragging(true);
      if (e.type === 'touchstart') {
        lastTouch.current = e.touches[0];
      }
    };
  
    const onMouseUp = () => {
      setIsDragging(false);
    };
  
    React.useEffect(() => {
      const container = containerRef.current;
    
      const handleMouseMove = (e) => onMouseDrag(e);
      const handleTouchMove = (e) => onMouseDrag(e);
      const handleMouseUp = () => onMouseUp();
      const handleTouchEnd = () => onMouseUp();
    
      if (isDragging) {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchend', handleTouchEnd);
      } else {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('touchend', handleTouchEnd);
      };
    //   if (isDragging) {
    //     document.addEventListener('mousemove', handleMouseMove);
    //     document.addEventListener('mouseup', onMouseUp);
    //   } else {
    //     document.removeEventListener('mousemove', handleMouseMove);
    //     document.removeEventListener('mouseup', onMouseUp);
    //   }
  
    //   return () => {
    //     document.removeEventListener('mousemove', handleMouseMove);
    //     document.removeEventListener('mouseup', onMouseUp);
    //   };
    }, [isDragging]);



    const getRewardsListData=(lId)=>{
        common.getAPI({
            method: "Post",
            url: "member-reward-list",
            data:  { funnel_id : state.funnel_id,
            member_id:memberShipUser.id,
            funnel_page_id : state.funnel_page_id,
            lesson_id : lId,
            token : tokenCookie,},
        },
        async (resp) => {   
           
            const respData = resp.rewards;
            if(resp.success){
                
                setMyState(setQuery,{rewardsListData : respData})
            }else{
                setMyState(setQuery,{rewardsListData : []})
            }
        });
    }

    const getMemberBadge = (lId)=>{
        common.getAPI({
            method: "Post",
            url: "member-badge-list",
            data:  { 
                funnel_id : state.funnel_id,
                member_id:memberShipUser.id,
                funnel_page_id : state.funnel_page_id,
                lesson_id : lId,
                token : tokenCookie,
            },
        },
        async (resp) => {   
            
            const respData = resp.badges;
            if(resp.success){
                
                setMyState(setQuery,{badgesList : respData})
            }else{
                setMyState(setQuery,{badgesList : []})
            }
        });
    }
    const customLoadingIcon = <LoadingOutlined style={{ fontSize: 40, color: '#fff' }} spin />;
    return (
    <>
        <Helmet>
            <title>My Learning</title>
        </Helmet>
      
        <div className='mc_pageScroll_dv mc_breadCum_page'>
            <div className='max-container pt-12 prelative'>
                <div className='grid grid-cols-4 gap-4 mc_mobileRow'>
                    <div className='col-span-3 mc_moboilCol_12 pb-24' 
                        ref={videoRefer} 
                    >
                        <div className='mc_AudioModal'>
                            <audio className='mt-24 pt-24'
                                ref={myRefAudio}
                                src={`${alfaUrl}/mp3/celebration_sound.mp3`}
                            />
                        </div>
                    
                   
                    {state.loader ?  dataLoader.myLearningVideo : videoLoader ?  <div id='videoJsPlayer' className='mc_videoDv flex justify-center items-center' style={{background : '#000'}}> 
                       <Spin size="large" indicator={customLoadingIcon}/>
                     </div> :
                    //   style={{background : brandSetting.theme_color1}} 
                        <div id='videoJsPlayer' className='mc_videoDv' ref={videoRefer}  style={{background :'#000'}} onContextMenu={e => e.preventDefault()} >
                            
                            {/* ref={videoRefer}  */}
                            {/* <Swiper
                                pagination={{type: 'fraction',}}
                                className="mySwiper mc_videoSlider_dv"
                                navigation={true}
                                slidesPerView={1}
                                cssMode={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                >
                                <div className='mc_swiperContent_slider'>
                                    <SwiperSlide>
                                        <video className="w-full rounded-lg" controls={false}>
                                            <source src={'/'} type="video/mp4" />
                                        </video>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <video className="w-full rounded-lg" controls={false}>
                                            <source src={'/'} type="video/mp4" />
                                        </video>
                                    </SwiperSlide>
                                </div>
                            </Swiper> */}
                            {/* {eleHide == true ? '' : 
                                <div className='mc_AudioDv'>
                                    <div className='mc_innnerPlay_dv'>
                                        <span className='center'>{svg.app.audioBgIcon}</span>
                                        <a href={() => false} className='mc_playCenter_icon'
                                            onClick={()=> handlePlayPause()}
                                        >
                                            {svg.app.VideoIcon}
                                        </a>
                                    </div>
                                </div>
                            } */}
                            {/* <h1 className='text-white'>{JSON.stringify(state.lessionDetailes)}</h1> */}
                       
                            {lessonIsLocked == true ? 
                                <div className='mc_congrats_dv'>
                                    <div className='mc_completeModal text-center'>
                                        <img src={`${alfaUrl}/images/padlock.png`} alt='batch' width={'178px'} height={'260px'}/>
                                        <p>{showRestrictedVideoContent ? showRestrictedVideoContent : ''}</p>
                                            {lessonButtontext &&
                                                <Link to={lessonbuttonlink} className='mc_btn mc_courseBtn w-50' 
                                                    style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Upgrade Now' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  
                                                    onMouseEnter={()=> getColor1('Upgrade Now')} 
                                                    onMouseLeave={()=> getColor2('Upgrade Now')}
                                                    target='_blank'
                                                >
                                                    {lessonButtontext} 
                                                    <span style={{fill : brandSetting.theme_button_txt_color}}>{svg.app.arrowRight}</span>
                                                </Link>
                                            }
                                    </div>
                                </div>
                            :   (
                                showRestrictedVideoContent ? 
                                <div className='mc_congrats_dv'>
                                    <div className='mc_completeModal text-center'>
                                        <img src={`${alfaUrl}/images/padlock.png`} alt='batch' width={'178px'} height={'260px'}/>
                                        <p>{showRestrictedVideoContent ? showRestrictedVideoContent :  ''}</p>
                                            {lessonButtontext &&
                                                <Link to={lessonbuttonlink} className='mc_btn mc_courseBtn w-50' style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Upgrade Now' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  onMouseEnter={()=> getColor1('Upgrade Now')} onMouseLeave={()=> getColor2('Upgrade Now')}
                                                target='_blank'
                                            >
                                                    {lessonButtontext} 
                                                    <span style={{fill : brandSetting.theme_button_txt_color}}>{svg.app.arrowRight}</span>
                                                </Link>
                                            }
                                    </div>
                                </div>
                                :
                                Object.keys(state.lessionDetailes).length>0 && 
                                video_type == 'generic_video' ?
                                    ( state.lessionDetailes.generic_video_type=="video_url" ?
                                        <div className={`mc_embersUrl ${ showFloatVideo == true ? 'draggable mc_vidoBox_fixed' : ''}`} id={`${ showFloatVideo == true ? 'containerBody' : ''}`}
                                            onMouseDown={onMouseDown}
                                            onTouchStart={onMouseDown}
                                            style={{ left: position.x, top: position.y }}
                                        >   
                                            { showFloatVideo == true && 
                                                <>
                                                    <a href={()=>false} className='mc_videoToggleBar'>
                                                        {svg.app.toggleMenu}
                                                    </a>    
                                                    <a href={()=> false} className='mc_floatingVideoClose' onClick={()=> setShowFloatVideo(false)}>{svg.app.crossIcon}</a>                              
                                                </>
                                            } 
                                            <VideoPlayer source={{ src: state.lessionDetailes.generic_video, type: 'video/mp4' }} showFloatVideo={showFloatVideo}/>
                                            
                                        </div>
                                    :  state.lessionDetailes.generic_video_type=="embed" && <> 
                                        <div className={`${ showFloatVideo == true ? 'draggable mc_vidoBox_fixed' : ''}`} id={`${ showFloatVideo == true ? 'containerBody' : ''}`}
                                           onMouseDown={onMouseDown}
                                           onTouchStart={onMouseDown}
                                           style={{ left: position.x, top: position.y }}
                                        >   
                                           { showFloatVideo == true && 
                                                <>
                                                    <a href={()=>false} className='mc_videoToggleBar'>
                                                        {svg.app.toggleMenu}
                                                    </a>    
                                                    <a href={()=> false} className='mc_floatingVideoClose' onClick={()=> setShowFloatVideo(false)}>{svg.app.crossIcon}</a>                              
                                                </>
                                            }   
                                           <div ref={iframeRef} className='mc_embersUrl' dangerouslySetInnerHTML={{ __html: state.lessionDetailes.generic_video.includes("allowfullscreen") ?  state.lessionDetailes.generic_video : state.lessionDetailes.generic_video.replace("<iframe", "<iframe allowfullscreen") }}></div>
                                        </div>
                                    </>
                                    ) 
                                : video_type == 'vimeo' ? <> 
                                     <div className={`${ showFloatVideo == true ? 'draggable mc_vidoBox_fixed' : ''}`} id={`${ showFloatVideo == true ? 'containerBody' : ''}`}
                                            onMouseDown={onMouseDown}
                                            onTouchStart={onMouseDown}
                                            style={{ left: position.x, top: position.y }}
                                        >   
                                            { showFloatVideo == true && 
                                                <>
                                                    <a href={()=>false} className='mc_videoToggleBar'>
                                                        {svg.app.toggleMenu}
                                                    </a>    
                                                    <a href={()=> false} className='mc_floatingVideoClose' onClick={()=> setShowFloatVideo(false)}>{svg.app.crossIcon}</a>                              
                                                </>
                                            }                                  
                                      {<VimeoVideoPlayer id={`${state.lessionDetailes.video_id}`} type='video/vimeo' showFloatVideo={showFloatVideo}/>}
                                      </div>
                                      </>
                                : video_type == 'youtube' ? <> 
                                    <div className={`${ showFloatVideo == true ? 'draggable mc_vidoBox_fixed' : ''}`} id={`${ showFloatVideo == true ? 'containerBody' : ''}`}
                                           onMouseDown={onMouseDown}
                                           onTouchStart={onMouseDown}
                                           style={{ left: position.x, top: position.y }}
                                       >   
                                           { showFloatVideo == true && 
                                                <>
                                                    <a href={()=>false} className='mc_videoToggleBar'>
                                                        {svg.app.toggleMenu}
                                                    </a>    
                                                    <a href={()=> false} className='mc_floatingVideoClose' onClick={()=> setShowFloatVideo(false)}>{svg.app.crossIcon}</a>                              
                                                </>
                                            } 
                                               
                                        {
                                            <VideoPlayer source={{ src: `https://youtube.com/embed/${state.lessionDetailes.video_id}`, type: 'video/youtube' }} />
                                        }
                                        </div>
                                        </>
                                : video_type == 'flexi' ? 
                                    <> 
                                     <div className={`${ showFloatVideo == true ? 'draggable mc_vidoBox_fixed' : ''}`} id={`${ showFloatVideo == true ? 'containerBody' : ''}`}
                                            onMouseDown={onMouseDown}
                                            onTouchStart={onMouseDown}
                                            style={{ left: position.x, top: position.y }}
                                        >   
                                            { showFloatVideo == true && 
                                                <>
                                                    <a href={()=>false} className='mc_videoToggleBar'>
                                                        {svg.app.toggleMenu}
                                                    </a>    
                                                    <a href={()=> false} className='mc_floatingVideoClose' onClick={()=> setShowFloatVideo(false)}>{svg.app.crossIcon}</a>                              
                                                </>
                                            }    
                                        {/* <div className="embedVideo" dangerouslySetInnerHTML={{__html: state.lessionDetailes.embed}}></div> */}
                                        <VdoPlayer className="embedVideo" embedCode={state.lessionDetailes.embed} vidType={video_type} showFloatVideo={showFloatVideo}/>
                                    </div>
                                    </>

                                : state.lessionDetailes.html && video_type == null ? 
                                    <div className='mc_textImage_holder'>
                                        <div className='col-span-3 mc_moboilCol_12'>
                                            <div className="post__content unset_listStyle" dangerouslySetInnerHTML={{__html: state.lessionDetailes.html}}></div>
                                        </div>
                                    </div>
                                : !state.lessionDetailes.html  &&
                                    <div className='mc_congrats_dv'>
                                        <div className='mc_completeModal text-center'>
                                            <img src={`${alfaUrl}/images/padlock.png`} alt='batch' width={'178px'} height={'260px'}/>
                                            <p>{showRestrictedVideoContent ? showRestrictedVideoContent :  ''}</p>
                                            {lessonButtontext &&
                                                <Link to={lessonbuttonlink} className='mc_btn mc_courseBtn w-50' style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Upgrade Now' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  onMouseEnter={()=> getColor1('Upgrade Now')} onMouseLeave={()=> getColor2('Upgrade Now')}
                                                    target='_blank'
                                                >
                                                    {lessonButtontext} 
                                                    <span style={{fill : brandSetting.theme_button_txt_color}}>{svg.app.arrowRight}</span>
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div> 
                    
                    } 

                        {/* <div className='mc_textImage_holder'>
                            <div className='col-span-3 mc_moboilCol_12'>
                                <h4 className='text-base text-black mt-3 font-semibold'>1. Create a Strategy</h4>
                                <p className='text-gray-900 text-sm mt-4 max-w-80 line-height-20'>Each platform needs its strategy. Every platform is different in some way and it is important to understand what works best to create targeted content and generate engagement.</p>
                                
                                <h4 className='text-sm text-black mt-6 font-semibold'>Some questions to consider are:</h4>
                                <ul className='mc_ulList'>
                                    <li className='text-black text-sm'> Why am I using this platform?   </li> 
                                    <li className='text-black text-sm'> Who is my target audience?</li> 
                                    <li className='text-black text-sm'> What brand message am I trying to push?</li>
                                    <li className='text-black text-sm'> What content works best on this platform?</li> 
                                    <li className='text-black text-sm'> How can I make my content unique?</li>
                                </ul>
                                <h4 className='text-sm text-black mt-6 font-semibold'>Some Samples</h4>
                                <div className='mc_imgHolder_dv max-w-80 '>
                                    <div className='mc_halfDv'>
                                        <img src={`${alfaUrl}/images/Course1.jpg`} className="h-auto max-w-full" alt="img" />
                                    </div>
                                    <div className='mc_halfDv pr-0'>
                                        <img src={`${alfaUrl}/images/Course2.jpg`} className="h-auto max-w-full" alt="img" />
                                    </div>
                                </div>
                                <p className='text-gray-900 text-sm mt-4 max-w-80 line-height-20'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book</p>
                                <p className='text-gray-900 text-sm mt-4 max-w-80 line-height-20'>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book</p>
                            </div>
                        </div> */}
                        

                    { !state.loader ?
                        <div className='mc_flexbox_left mt-10 mc_mobileRow'>
                            <div className='mc_heading flex sm:flex-none justify-between mc_titleRow'>
                                <h4 className='text-black text-3xl font-semibold'> {myCourse}</h4>
                                <div className='buttonsHolders flex flex-col md:flex-row gap-2'>
                                   { showQuizResultButton &&  <a href={() => false} className='mc_btn mc_courseBtn' 
                                    onClick={()=>{
                                        setshowQuizResultModal(true)
                                    }}
                                    style={{color:brandSetting.theme_button_txt_color, backgroundColor :brandSetting.theme_color1}}  
                                    onMouseEnter={()=> getColor1('Mark as Complete')} 
                                    onMouseLeave={()=> getColor2('Mark as Complete')}
                                >
                                  Quiz Result
                                </a>  
                                   }

                                    {(showRestrictedVideoContent || !state.lessionDetailes.html) ? '' :
                                        (state.markcomplete_status == 1 ?
                                            <button type='button'  
                                                className='mc_btn mc_disabled'
                                                disabled     
                                                style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Lesson is Completed' ?  brandSetting.theme_color1 : brandSetting.theme_color2}}  
                                                onMouseEnter={()=> getColor1('Lesson is Completed')} 
                                                onMouseLeave={()=> getColor2('Lesson is Completed')}
                                            >Lesson is Completed
                                            </button>
                                            :
                                            // state.markcomplete_status != 1 &&
                                            <a href={() => false} className='mc_btn mc_courseBtn' 
                                                onClick={()=>{
                                                    getQuizQuestionList()
                                                }}
                                                style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Mark as Complete' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  
                                                onMouseEnter={()=> getColor1('Mark as Complete')} 
                                                onMouseLeave={()=> getColor2('Mark as Complete')}
                                            >
                                               {state.lessionDetailes?.quiz_status ? "Complete Quiz"  : "Mark as Complete"}
                                            </a>  
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <div className='flex mt-3 mc_moboilCol_12'>
                                <p className='mr-4 text-black'>
                                    <span className='text-gray-500'>Module : </span>
                                    {state?.lessionModule_name == '' ?( (state?.lessionList.length>0) ? state.lessionList.find((d1)=>d1?.course_id==state?.currentlession?.course_id)?.course_name : dataLoader.textLoader) :
                                    state.lessionModule_name}
                                </p>
                                <p className='mr-4 text-black'>
                                    <span className='text-gray-500'>Lesson : </span>
                                    {NextLessonName != '' ? NextLessonName :( (state?.lession_name == '' || state?.lession_name == 'undefined')? state.currentlession?.lesson_name : state.lession_name)}
                                </p>
                            </div>
                        </div>
                        : dataLoader.commonLoader} 
                    </div>
                    
                    {windowWidthDetector==false && 
                        com1()
                    }
                </div>
            </div>
            { lessonIsLocked != true &&
                <div className='bg-white w-full relative'>
                    <div className="max-container">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8 mc_mobileRow">
                            <div className="col-span-3 mc_moboilCol_12">
                                <div className="mc_tabDv_banner">
                                    <div className='mc_tabHolder'>
                                        <ul className="flex sm:flex-none sm:w-full rounded-none border-none border-blue-gray-0 bg-transparent p-0"
                                        >
                                            {!showRestrictedVideoContent && state?.lessons_type != "text" && state?.lessionDetailes?.html != null &&
                                                <li className={`mc_tabList ${activeTab == 'AboutLesson' ? "color-themeColor" : ""}`} style={{borderBottom:`2px solid ${activeTab == 'AboutLesson' ? brandSetting?.theme_color2 : '#D2D2D2'}` }}>
                                                    <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('AboutLesson')}
                                                        style={{color : `${activeTab == 'AboutLesson' ? brandSetting.theme_color2 : '#000'}`}}
                                                    >About Lesson</a>
                                                </li>
                                            }
                                            {!showRestrictedVideoContent && (state?.lessionDetailes?.isResourceActivity == 1)  &&
                                                <li className={`mc_tabList ${activeTab == 'Resources' ? "color-themeColor" : ""}`} style={{borderBottom:`2px solid ${activeTab == 'Resources' ? brandSetting.theme_color2 : '#D2D2D2'}` }}>
                                                    <a href={() => false} className='mc_listItem_dv' 
                                                        onClick={() => setActiveTab('Resources')}
                                                        style={{
                                                            color : `${activeTab == 'Resources' ? brandSetting.theme_color2 : '#000'}`
                                                        }}
                                                        
                                                    >Resources</a>
                                                </li>
                                            }


                                            {!showRestrictedVideoContent && discussionMode &&
                                                <li className={`mc_tabList ${activeTab == 'Discussions' ? "color-themeColor" : ""}`} style={{borderBottom:`2px solid ${activeTab == 'Discussions' ? brandSetting.theme_color2 : '#D2D2D2'}` }}>
                                                    <a href={() => false} className='mc_listItem_dv' 
                                                        onClick={() => {
                                                            // getPostListData();
                                                            setActiveTab('Discussions')
                                                        }
                                                    }
                                                        style={{color : `${activeTab == 'Discussions' ? brandSetting.theme_color2 : '#000'}`}}
                                                    >Discussions</a>
                                                    
                                                    {/* <label class="inline-flex items-center cursor-pointer ml-2">
                                                        <input type="checkbox" value="" class="sr-only peer"/>
                                                        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span>
                                                    </label> */}

                                                </li>
                                            }
                                            {memberShipUser.funnel_id == '66976' && 
                                            <li className={`mc_tabList ${activeTab == 'Goals' ? "color-themeColor" : ""}`} 
                                            style={{borderBottom:`2px solid ${activeTab == 'Goals' ? brandSetting.theme_color2 : '#D2D2D2'}` }}>
                                                <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Goals')}
                                                style={{color : `${activeTab == 'Goals' ? brandSetting.theme_color2 : '#000'}`}}
                                                >Goals</a>
                                            </li>
                                            }
                                           
                                            {!(state.lessionDetailes?.lesson_type == 'video' && video_type == null) && !showRestrictedVideoContent  && state.lessons_type == "video" && 
                                                <li className={`mc_tabList ${activeTab == 'Notes' ? "color-themeColor" : ""}`} style={{borderBottom:`2px solid ${activeTab == 'Notes' ? brandSetting.theme_color2 : '#D2D2D2'}` }}>
                                                    <a href={() => false} className='mc_listItem_dv' 
                                                    onClick={() => {
                                                        setActiveTab('Notes');
                                                        // getNotesListData()
                                                    }}
                                                    style={{color : `${activeTab == 'Notes' ? brandSetting.theme_color2 : '#000'}`}}
                                                    >Notes</a>
                                                </li>
                                            } 
                                            {/* <li className={`mc_tabList ${activeTab == 'Transcripts' ? "color-themeColor" : ""}`}>
                                                <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Transcripts')}>Transcripts</a>
                                            </li> */}
                                            {/* <li className={`mc_tabList ${activeTab == 'Notes' ? "color-themeColor" : ""}`}>
                                                <a href={() => false} className='mc_listItem_dv' onClick={() => setActiveTab('Notes')}>Notes</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-1 mc_moboilCol_12 mobileD_none'></div>
                        </div>
                    </div>
                    <div className="max-container">
                        <div className="mx-full w-full px-0 py-8 sm:px-0 sm:py-8 lg:px-0"> 
                            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3  xl:gap-x-8 mc_mobileRow">
                            
                                {activeTab == 'AboutLesson' && state.lessons_type == "video" ? 
                                // !state.loader ?
                                    <div className='col-span-3 mc_moboilCol_12'>
                                        <div className="post__content unset_listStyle" dangerouslySetInnerHTML={{__html: state.lessionDetailes.html}}></div>
                                    </div>
                                    //  : <List width={'700'}/>
                                : activeTab == 'Discussions' ? 
                                    <div className='col-span-3 mc_moboilCol_12'>
                                        <DiscussionTab lessonId={state.lessionDetailes.lesson_id} bodyData={body} post={postDataFilter}/>
                                    </div>
                                : !showRestrictedVideoContent && activeTab == 'Notes' && state.lessons_type == "video" ? 
                                    <LessonNotes myParam={myParam} lessonId={state.lessionDetailes.lesson_id}  lessionLock={state?.lessionDetailes?.show}/>
                                :memberShipUser.funnel_id == '66976' && activeTab == 'Goals' ? 
                                    <Goals lessonId={state.lessionDetailes?.lesson_id} funnelPageId={myParam} />
                                :
                                state.lessionDetailes.isResourceActivity == 1 ?
                                    <div className='col-span-3 mc_moboilCol_12'>
                                        {/* <h4 className='text-base text-black mt-3 font-semibold'>{activeTab}</h4> */}
                                        <p className="post__content" dangerouslySetInnerHTML={{__html: state?.resourcesDetails?.description}}></p>
                                        {state?.resourcesDetails &&
                                            (state?.resourcesDetails.resource_type == 2 ?
                                                <a href={state?.resourcesDetails?.download_url} target={"_blank"} className='mc_lightBtn lightColor flex justify-between items-center mt-4' 
                                                    // onClick={()=> 
                                                    //     (state?.resourcesDetails.resource_type == 1) && downloadMyFile(state?.resourcesDetails?.download_url)
                                                    //     }
                                                    style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'DownloadMyFile' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  
                                                    onMouseEnter={()=> getColor1('DownloadMyFile')} 
                                                    onMouseLeave={()=> getColor2('DownloadMyFile')}
                                                >
                                                   Download Resource 
                                                    <span className='mc_btnIcon' style={{fill:brandSetting.theme_button_txt_color,}}>{svg.app.downloadIcon}</span>
                                                </a>
                                            :
                                                <a href={state?.resourcesDetails?.download_url} target={"_blank"} className='mc_lightBtn lightColor flex justify-between items-center mt-4' 
                                                    // onClick={()=> 
                                                    //     downloadMyFile(state?.resourcesDetails?.download_url)
                                                    // }
                                                    style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'DownloadMyFile' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  
                                                    onMouseEnter={()=> getColor1('DownloadMyFile')} 
                                                    onMouseLeave={()=> getColor2('DownloadMyFile')}
                                                >
                                                    Download Resource 
                                                    <span className='mc_btnIcon' style={{fill:brandSetting.theme_button_txt_color,}}>{svg.app.downloadIcon}</span>
                                                </a>
                                            )
                                        }
                                    </div>

                            
                                // :activeTab == 'Transcripts' ? 
                                //     <div className='col-span-3 mc_moboilCol_12'>
                                //         {/* {activeTab == 'AboutLesson' ? <h1>{'AboutLesson'}</h1> : ''} */}
                                //         <h4 className='text-base text-black mt-3 font-bold'>Transcripts For The Lessons</h4>
                                //         <div className='mc_timeLine_dv mt-12 relative'>
                                //             <ol className="border-l border-neutral-300 dark:border-neutral-500">
                                //                 {/* <!--First item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8 max-w-80">
                                //                         <div className="-ml-[27px] mr-3 h-[55px] w-[55px] rounded-full bg-themeColor text-white font-light flex items-center justify-center">00:34</div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             <b className='text-black font-extrabold'>Hello Everyone!</b> or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero’s De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //                 {/* <!--Second item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8 max-w-80">
                                //                         <div className="-ml-[27px] mr-3 h-[55px] w-[55px] rounded-full bg-themeColor text-white font-light flex items-center justify-center">00:34</div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn’t distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be <b className='text-black font-extrabold'>very useful when the focus is meant to be on design, not content. The purpose of lorem ipsum is to</b> create a natural looking block of text (sentence, paragraph, page, etc.that doesn’t distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //                 {/* <!--Third item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8 max-w-80">
                                //                         <div className="-ml-[27px] mr-3 h-[55px] w-[55px] rounded-full bg-themeColor text-white font-light flex items-center justify-center">00:34</div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             <b className='text-black font-extrabold'>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets,</b> and again during the 90s as desktop publishers bundled the text with their software. Today it’s seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //                 {/* <!--Four item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8 max-w-80">
                                //                         <div className="-ml-[27px] mr-3 h-[55px] w-[55px] rounded-full bg-themeColor text-white font-light flex items-center justify-center">00:34</div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, <b className='text-black font-extrabold'>and again during the 90s as desktop publishers bundled the text with their software.</b> Today it’s seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //                 {/* <!--Five item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8 max-w-80">
                                //                         <div className="-ml-[27px] mr-3 h-[55px] w-[55px] rounded-full bg-themeColor text-white font-light flex items-center justify-center">00:34</div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             <b className='text-black font-extrabold'>The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.)</b> that doesn’t distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content. The purpose of lorem ipsum is to create a natural looking block.
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //             </ol>
                                //         </div>
                                //     </div>
                                
                                // :activeTab == 'Notes' ? 
                                //     <div className='col-span-3 mc_moboilCol_12'>
                                //         <h4 className='text-base text-black mt-3 font-bold mb-4'>My Notes For The Lessons</h4>
                                //         <div className='flex w-full justify-end flex-wrap'>
                                //             <textarea rows={'4'} defaultValue={''} className="mc_textarea w-full px-4 py-4" placeholder='Type your notes and save..'></textarea>
                                //             <div className='flex w-full justify-end flex-wrap mt-4'>
                                //                 <a href={() => false} className='mc_btn btn-base'>Save Note</a>
                                //             </div>
                                //         </div>
                                //         <div className='mt-4 mc_noteHead'>
                                //             <h5 className='text-black text-bold text-sm-13 pb-3 font-bold'>My Notes</h5>
                                //         </div>

                                //         <div className='mc_fileTimeline_dv'>
                                //             <ol className="border-l border-neutral-300 dark:border-neutral-500">
                                //                 {/* <!--First item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8">
                                //                         <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                //                             <span>{svg.app.fileBoard}</span> 00:34
                                //                         </div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             <a href={() => false} className="mc_crossIcon">{svg.app.crossIcon}</a>
                                //                             Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //                 {/* <!--Two item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8">
                                //                         <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                //                             <span>{svg.app.fileBoard}</span> 00:34
                                //                         </div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             <a href={() => false} className="mc_crossIcon">{svg.app.crossIcon}</a>
                                //                             Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //                 {/* <!--Three item--> */}
                                //                 <li>
                                //                     <div className="flex-start flex items-center pt-3 pb-8">
                                //                         <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                //                             <span>{svg.app.fileBoard}</span> 00:34
                                //                         </div>
                                //                         <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                //                             <a href={() => false} className="mc_crossIcon">{svg.app.crossIcon}</a>
                                //                             Richard McClintock, a Latin scholar from Hampden-Sydney College, is credited with discovering the source behind the ubiquitous filler text. In seeing a sample of lorem ipsum, his interest was piqued by consectetur—a genuine, albeit rare, Latin word. Consulting a Latin dictionary led McClintock to a passage from De Finibus Bonorum et Malorum (“On the Extremes of Good and Evil”), a first-century B.C. text from the Roman philosopher Cicero.
                                //                         </p>
                                //                     </div>
                                //                 </li>
                                //             </ol>
                                //         </div>
                                //     </div>
                                :''
                                }
                                <div className='col-span-1 mc_moboilCol_12'>
                                    {memberShipUser.funnel_id == '66976' && state.rewardsListData?.length > 0 &&<div className='mc_rewardBox'>
                                        <div className='mc_innerBlueBox'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="142.624" height="169.249" viewBox="0 0 142.624 169.249">
                                                <g id="Group_2001" data-name="Group 2001" transform="matrix(0.999, -0.035, 0.035, 0.999, -1.466, 4.304)">
                                                    <path id="Path_1363" data-name="Path 1363" d="M297.162,453.008c0,1.859-23.189,3.366-51.795,3.366s-51.795-1.507-51.795-3.366,23.189-3.367,51.795-3.367S297.162,451.149,297.162,453.008Z" transform="translate(-159.453 -290.138)" fill="#5ca7bf" opacity="0.55"/>
                                                    <g id="Group_1943" data-name="Group 1943" transform="translate(12.185 69.766)">
                                                        <path id="Path_1364" data-name="Path 1364" d="M189.128,358.888c-8.318-6.2-20.474-13.269-21.471-24.748-.145-1.666-2.763-1.68-2.617,0,1.1,12.682,13.527,20.12,22.767,27.008,1.35,1.008,2.657-1.264,1.32-2.26Z" transform="translate(-165.034 -332.886)" fill="#012329"/>
                                                    </g>
                                                    <g id="Group_1944" data-name="Group 1944" transform="translate(122.784 51.448)">
                                                        <path id="Path_1365" data-name="Path 1365" d="M311.22,342.19c4.183-3.529,6.469-9.292,8.793-14.11a71.406,71.406,0,0,0,6.116-17.389c.318-1.646-2.2-2.35-2.524-.7a68.681,68.681,0,0,1-5.852,16.764c-2.216,4.593-4.4,10.222-8.384,13.58-1.288,1.087.571,2.931,1.852,1.85Z" transform="translate(-308.933 -309.052)" fill="#012329"/>
                                                    </g>
                                                    <g id="Group_1945" data-name="Group 1945" transform="translate(59.925 130.154)">
                                                        <path id="Path_1366" data-name="Path 1366" d="M234.644,420.021a1.309,1.309,0,0,0-2.617,0,141.331,141.331,0,0,0,2.141,23.658,5.019,5.019,0,0,1-5.07-.089,1.31,1.31,0,0,0-1.321,2.26,7.7,7.7,0,0,0,7.528.2,1.408,1.408,0,0,0,1.639-1.616A137.487,137.487,0,0,1,234.644,420.021Z" transform="translate(-227.147 -413.145)" fill="#012329"/>
                                                        <path id="Path_1367" data-name="Path 1367" d="M302.539,432.417a5.019,5.019,0,0,1-4.574,2.188,141.325,141.325,0,0,0-7.885-22.408,1.309,1.309,0,0,0-2.38,1.088,137.477,137.477,0,0,1,8.054,23.161,1.409,1.409,0,0,0,2.164.789,7.693,7.693,0,0,0,6.763-3.312A1.31,1.31,0,0,0,302.539,432.417Z" transform="translate(-241.131 -411.455)" fill="#012329"/>
                                                    </g>
                                                    <g id="Group_1964" data-name="Group 1964" transform="translate(128.89 21.533)">
                                                        <g id="Group_1961" data-name="Group 1961">
                                                            <path id="Path_1400" data-name="Path 1400" d="M317.895,282.882a13.081,13.081,0,0,0,7.186-12.1c-.025-.862-1.368-.865-1.343,0a11.768,11.768,0,0,1-6.521,10.945c-.771.39-.092,1.549.678,1.159Z" transform="translate(-316.877 -270.13)" fill="#012329"/>
                                                        </g>
                                                        <g id="Group_1962" data-name="Group 1962" transform="translate(1.33 12.089)">
                                                            <path id="Path_1401" data-name="Path 1401" d="M319.6,289.57a15.979,15.979,0,0,1,8.976-2.36c.865.027.863-1.316,0-1.343a17.333,17.333,0,0,0-9.653,2.543.672.672,0,0,0,.677,1.159Z" transform="translate(-318.608 -285.858)" fill="#012329"/>
                                                        </g>
                                                        <g id="Group_1963" data-name="Group 1963" transform="translate(4.121 17.644)">
                                                            <path id="Path_1402" data-name="Path 1402" d="M322.876,294.6a18.228,18.228,0,0,1,8.259.785c.822.274,1.174-1.022.357-1.3a19.062,19.062,0,0,0-8.615-.833c-.845.111-.856,1.455,0,1.343Z" transform="translate(-322.239 -293.087)" fill="#012329"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_1968" data-name="Group 1968" transform="translate(0 42.006)">
                                                        <g id="Group_1965" data-name="Group 1965">
                                                            <path id="Path_1403" data-name="Path 1403" d="M149.826,298.173A24.777,24.777,0,0,1,166.15,302.8c.7.5,1.376-.661.678-1.159a25.927,25.927,0,0,0-17-4.81c-.857.059-.864,1.4,0,1.343Z" transform="translate(-149.18 -296.767)" fill="#012329"/>
                                                        </g>
                                                        <g id="Group_1966" data-name="Group 1966" transform="translate(1.774 8.904)">
                                                            <path id="Path_1404" data-name="Path 1404" d="M152.129,310.138a70.272,70.272,0,0,1,11.528-.35c.864.045.861-1.3,0-1.343a70.269,70.269,0,0,0-11.528.35c-.849.1-.859,1.44,0,1.343Z" transform="translate(-151.488 -308.351)" fill="#012329"/>
                                                        </g>
                                                        <g id="Group_1967" data-name="Group 1967" transform="translate(1.774 12.847)">
                                                            <path id="Path_1405" data-name="Path 1405" d="M152.76,326.5a33.6,33.6,0,0,1,13.4-11.786c.776-.38.1-1.539-.678-1.159A34.93,34.93,0,0,0,151.6,325.825c-.476.723.686,1.4,1.16.677Z" transform="translate(-151.488 -313.482)" fill="#012329"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group_1960" data-name="Group 1960" transform="translate(11.281 0)">
                                                        <g id="Group_1948" data-name="Group 1948">
                                                            <path id="Path_1368" data-name="Path 1368" d="M219.925,291.528A45.107,45.107,0,0,0,197.194,279.5c-13.935-2.589-27.968-17.415-27.968-17.415a16.882,16.882,0,0,0,.193,6.07s-4.012-.275-5.562-1.971c0,0,5.112,12.6,32.286,18.09C211.816,287.519,219.925,291.528,219.925,291.528Z" transform="translate(-163.858 -246.737)" fill={brandSetting.theme_color2}/>
                                                            <path id="Path_1369" data-name="Path 1369" d="M249.269,285.431a45.106,45.106,0,0,1,18.066-18.3c12.513-6.658,21.439-25.017,21.439-25.017a16.908,16.908,0,0,1,1.641,5.847s3.744-1.469,4.712-3.551c0,0-1.088,13.555-25.355,26.958C255.8,279.17,249.269,285.431,249.269,285.431Z" transform="translate(-183.096 -242.114)" fill={brandSetting.theme_color2}/>
                                                            <path id="Path_1370" data-name="Path 1370" d="M193.622,275.15s-1.536,16.109,33.4,16.911C227.026,292.061,206.636,266.634,193.622,275.15Z" transform="translate(-170.743 -249.355)" fill="#012329"/>
                                                            <path id="Path_1371" data-name="Path 1371" d="M274.953,261.725s6.307,14.9-26.775,26.171C248.178,287.895,260.137,256.163,274.953,261.725Z" transform="translate(-181.479 -247.976)" fill="#012329"/>
                                                            <path id="Path_1372" data-name="Path 1372" d="M196.324,273.914s4.229,11.71,31.329,17.964C227.653,291.877,210.18,266.69,196.324,273.914Z" transform="translate(-171.371 -249.171)" fill="#012329"/>
                                                            <path id="Path_1373" data-name="Path 1373" d="M273.3,261.444s-.564,12.437-24.59,26.449C248.713,287.894,257.927,258.655,273.3,261.444Z" transform="translate(-181.027 -247.336)" fill="#012329"/>
                                                            <path id="Path_1374" data-name="Path 1374" d="M231.28,288.32s-6.523-18.518-17.267-25.6-21.159,1.417-20.4,9.345c0,0,10.756-9.273,33.4,16.911Z" transform="translate(-170.735 -246.269)" fill={brandSetting.theme_color2}/>
                                                            <path id="Path_1375" data-name="Path 1375" d="M234.031,292.9s1.446-4.111,3.972-4.115l.616,4.06-4.137.628Z" transform="translate(-180.097 -252.914)" fill="#488b9b"/>
                                                            <path id="Path_1376" data-name="Path 1376" d="M242.632,286.232s.655-19.623,8.773-29.607,20.607-5.01,22.265,2.781c0,0-13.046-5.611-26.775,26.171Z" transform="translate(-179.715 -244.59)" fill={brandSetting.theme_color2}/>
                                                            <path id="Path_1377" data-name="Path 1377" d="M248.444,291.075s-2.6-3.5-5.014-2.752l.616,4.06,4.137-.628Z" transform="translate(-182.272 -252.783)" fill="#488b9b"/>
                                                            <path id="Path_1378" data-name="Path 1378" d="M237.908,295.335a4.338,4.338,0,0,1-.761-4.943,4.089,4.089,0,0,1,5.443-1.818,5.013,5.013,0,0,1,2.4,5.671Z" transform="translate(-180.702 -252.771)" fill="#5ca7bf"/>
                                                            <path id="Path_1379" data-name="Path 1379" d="M208.179,271.888a20.759,20.759,0,0,1,7.093-10.011,16.7,16.7,0,0,1,3.423,2.154,33.633,33.633,0,0,0-7.377,9.164S209.628,272.456,208.179,271.888Z" transform="translate(-174.114 -246.687)" fill="#77cad8"/>
                                                            <path id="Path_1380" data-name="Path 1380" d="M213.389,274.5s3.376-6.289,7.357-8.892c0,0,.475.4.766.673a28.7,28.7,0,0,0-7.066,8.8S213.8,274.718,213.389,274.5Z" transform="translate(-175.32 -247.551)" fill="#77cad8"/>
                                                            <path id="Path_1381" data-name="Path 1381" d="M259.367,253.233s6.012.523,11.058,7.066a8.263,8.263,0,0,1,2.089-.7s-3.985-6.148-10.925-7.465C261.589,252.135,259.888,252.9,259.367,253.233Z" transform="translate(-185.96 -244.433)" fill="#77cad8"/>
                                                            <path id="Path_1382" data-name="Path 1382" d="M257.982,254.279s5.472,1.1,11.071,6.889a7.62,7.62,0,0,0-1.056.565s-5.34-5.435-11.02-6.671A2.759,2.759,0,0,1,257.982,254.279Z" transform="translate(-185.407 -244.929)" fill="#77cad8"/>
                                                            <g id="Group_1946" data-name="Group 1946" transform="translate(59.409 35.922)">
                                                                <path id="Path_1383" data-name="Path 1383" d="M241.788,290.28a2.474,2.474,0,0,1,1.633.673.724.724,0,0,0,1.005-.152.736.736,0,0,0-.153-1.005,3.682,3.682,0,0,0-2.316-.943.738.738,0,0,0-.8.629.726.726,0,0,0,.629.8Z" transform="translate(-241.155 -288.851)" fill="#77cad8"/>
                                                            </g>
                                                            <g id="Group_1947" data-name="Group 1947" transform="translate(102.137 8.38)">
                                                                <path id="Path_1384" data-name="Path 1384" d="M301.035,253.331a47.569,47.569,0,0,1-4.118,5.414c-.611.694.577,1.506,1.182.818a47.636,47.636,0,0,0,4.118-5.414c.507-.775-.675-1.595-1.182-.818Z" transform="translate(-296.747 -253.017)" fill="#77cad8"/>
                                                            </g>
                                                        </g>
                                                        <path id="Path_1385" data-name="Path 1385" d="M285.8,384.973,201.58,397.753a2.553,2.553,0,0,1-2.908-2.141l-13.164-86.744,89.268-13.547,13.164,86.744A2.553,2.553,0,0,1,285.8,384.973Z" transform="translate(-168.868 -254.427)" fill="#fefefe"/>
                                                        <g id="Group_1949" data-name="Group 1949" transform="translate(22.403 50.314)" opacity="0.35">
                                                            <rect id="Rectangle_326" data-name="Rectangle 326" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 0, 12.092)" fill="#caedf6"/>
                                                            <rect id="Rectangle_327" data-name="Rectangle 327" width="4.791" height="81.563" transform="translate(9.285 10.674) rotate(-8.632)" fill="#caedf6"/>
                                                            <rect id="Rectangle_328" data-name="Rectangle 328" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 18.384, 9.303)" fill="#caedf6"/>
                                                            <rect id="Rectangle_329" data-name="Rectangle 329" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 28.201, 7.814)" fill="#caedf6"/>
                                                            <rect id="Rectangle_330" data-name="Rectangle 330" width="4.791" height="81.563" transform="translate(37.485 6.392) rotate(-8.632)" fill="#caedf6"/>
                                                            <rect id="Rectangle_331" data-name="Rectangle 331" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 46.585, 5.024)" fill="#caedf6"/>
                                                            <rect id="Rectangle_332" data-name="Rectangle 332" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 56.584, 3.503)" fill="#caedf6"/>
                                                            <rect id="Rectangle_333" data-name="Rectangle 333" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 65.844, 2.102)" fill="#caedf6"/>
                                                            <rect id="Rectangle_334" data-name="Rectangle 334" width="4.791" height="81.563" transform="matrix(0.989, -0.15, 0.15, 0.989, 74.963, 0.719)" fill="#caedf6"/>
                                                        </g>
                                                        <path id="Path_1387" data-name="Path 1387" d="M187.248,320.278s49.171-9.459,91.422.64l-2.181-14.37Z" transform="translate(-169.271 -257.025)" fill="#878787" opacity="0.26"/>
                                                        <g id="Group_1950" data-name="Group 1950" transform="translate(57.572 82.273)">
                                                            <path id="Path_1388" data-name="Path 1388" d="M254.466,350.23s-5.5,3.313-10.754,1.632-5.31,2.078-4.666,3.855,4.028,5.014,9.575,4.548,9.619-2.869,11.009-6.047C261.3,350.4,257.912,347.5,254.466,350.23Z" transform="translate(-238.764 -349.158)" fill="#012329"/>
                                                            <path id="Path_1389" data-name="Path 1389" d="M245.028,361.712s2.522-4.406,6.063-2.29c0,0,1.832-1.547,3.391-1.029a6.619,6.619,0,0,1,2.691,1.956,16.608,16.608,0,0,1-6.016,1.91A11.635,11.635,0,0,1,245.028,361.712Z" transform="translate(-240.214 -351.27)" fill={brandSetting.theme_color2}/>
                                                        </g>
                                                        <g id="Group_1951" data-name="Group 1951" transform="translate(51.348 75.205)">
                                                            <path id="Path_1390" data-name="Path 1390" d="M235.03,347.987c.26,1.712-.495,3.247-1.686,3.428s-2.367-1.061-2.627-2.773.495-3.247,1.686-3.428S234.77,346.274,235.03,347.987Z" transform="translate(-230.665 -341.173)" fill="#012329"/>
                                                            <path id="Path_1391" data-name="Path 1391" d="M269.514,342.753c.26,1.712-.495,3.247-1.686,3.428s-2.367-1.061-2.627-2.773.495-3.247,1.686-3.428S269.254,341.041,269.514,342.753Z" transform="translate(-238.645 -339.962)" fill="#012329"/>
                                                            <path id="Path_1392" data-name="Path 1392" d="M234.8,347.038a.43.43,0,1,1-.49-.361A.43.43,0,0,1,234.8,347.038Z" transform="translate(-231.423 -341.515)" fill="#fefefe"/>
                                                            <path id="Path_1393" data-name="Path 1393" d="M269.514,341.769a.43.43,0,1,1-.49-.36A.43.43,0,0,1,269.514,341.769Z" transform="translate(-239.457 -340.296)" fill="#fefefe"/>
                                                        </g>
                                                        <g id="Group_1955" data-name="Group 1955" transform="translate(49.235 71.893)">
                                                            <g id="Group_1952" data-name="Group 1952" transform="translate(0 4.006)">
                                                                <path id="Path_1394" data-name="Path 1394" d="M231.466,340.886c-1.574-.153-3.237.506-3.543,2.209-.082.457.633.539.714.085.234-1.3,1.589-1.692,2.744-1.579a.36.36,0,0,0,.085-.715Z" transform="translate(-227.916 -340.864)" fill="#002229"/>
                                                            </g>
                                                            <g id="Group_1953" data-name="Group 1953" transform="translate(29.805)">
                                                                <path id="Path_1395" data-name="Path 1395" d="M267.2,336.483a2.234,2.234,0,0,1,2.681,1.051c.214.407.874.118.657-.293a2.971,2.971,0,0,0-3.631-1.415c-.436.155-.141.811.293.657Z" transform="translate(-266.696 -335.652)" fill="#012329"/>
                                                            </g>
                                                            <g id="Group_1954" data-name="Group 1954" transform="translate(16.847 24.229)">
                                                                <path id="Path_1396" data-name="Path 1396" d="M250.061,368.617a8.9,8.9,0,0,0,6.631-.748.36.36,0,0,0-.293-.657,8.17,8.17,0,0,1-6.072.736c-.439-.144-.706.523-.266.669Z" transform="translate(-249.836 -367.176)" fill="#012329"/>
                                                            </g>
                                                        </g>
                                                        <g id="Group_1956" data-name="Group 1956" transform="translate(6.535 18.404)">
                                                            <path id="Path_1397" data-name="Path 1397" d="M172.657,267.353a20.145,20.145,0,0,1,2.556,2.106.743.743,0,0,0,1.011.108.724.724,0,0,0,.108-1.011,22.383,22.383,0,0,0-2.822-2.36c-.759-.53-1.613.626-.853,1.158Z" transform="translate(-172.36 -266.059)" fill="#77cad8"/>
                                                        </g>
                                                        <g id="Group_1959" data-name="Group 1959" transform="translate(101.674 35.654)">
                                                            <g id="Group_1957" data-name="Group 1957" transform="translate(3.903 0.287)">
                                                                <path id="Path_1398" data-name="Path 1398" d="M304.578,297.718l-1.208-7.961a1.079,1.079,0,0,0-2.133.324l1.208,7.96a1.079,1.079,0,0,0,2.133-.323Z" transform="translate(-301.222 -288.875)" fill="#d9faff"/>
                                                            </g>
                                                            <g id="Group_1958" data-name="Group 1958">
                                                                <path id="Path_1399" data-name="Path 1399" d="M297.35,290.65a1.079,1.079,0,0,0-.324-2.133,1.079,1.079,0,0,0,.324,2.133Z" transform="translate(-296.144 -288.502)" fill="#d9faff"/>
                                                            </g>
                                                        </g>
                                                        <path id="Path_1386" data-name="Path 1386" d="M276.057,301.17l-93.337,14.36a2.553,2.553,0,0,1-2.912-2.136l-1.792-11.643a2.553,2.553,0,0,1,2.136-2.912l93.337-14.36a2.553,2.553,0,0,1,2.912,2.136l1.792,11.643A2.554,2.554,0,0,1,276.057,301.17Z" transform="translate(-167.127 -251.911)" fill={brandSetting.theme_color2}/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <a href={()=> false} className='mc_rewardBtn'
                                            style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'myRewardsBtn' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  
                                            onMouseEnter={()=> getColor1('myRewardsBtn')} 
                                            onMouseLeave={()=> getColor2('myRewardsBtn')}
                                            onClick={()=> setShowRewardModal(true)}
                                        >My Rewards <span style={{
                                            fill : brandSetting.theme_button_txt_color
                                        }}>{svg.app.arrowRight}</span></a>
                                    </div>
                                    }
                                    {/* <div  className='px-0 mc_diamandBatch'>
                                        <div className='mc_blueBanner pt-24 bg-gray mt-5'>
                                            <div className='mc_bannerborder'>
                                                <div className='mc_batchHolder_dv flex flex-wrap items-center justify-center'>
                                                    {svg.app.dimandBatch}
                                                    <div className='mc_diamondImg'>
                                                        <img src={`${alfaUrl}/images/diamond.png`} alt='diamond'/>
                                                        <img src={`${alfaUrl}/images/redBatch.png`} alt='diamond'/>
                                                        <span className='mc_batchText mc_redBatch'>Level 1</span>
                                                    </div>
                                                    <span className='mc_batchText'>Diamond</span>

                                                    <div className='mc_grayBox flex justify-around flex-wrap items-center w-full py-5 bg-gray-100'>
                                                        <div className='mc_svgStar'>
                                                            {svg.app.bannerStarIcon}
                                                        </div>
                                                        <div className='mc_midContent text-center'>
                                                            <h3 className='text-bold redColor'>150</h3>
                                                            <p>Points Away <br/> From The Diamond</p>
                                                        </div>
                                                        <div className='mc_svgStar'>
                                                            {svg.app.bannerStarIcon}
                                                        </div>
                                                    </div>
                                                    <div className='mc_grayDark flex justify-center flex-wrap items-center w-full py-3 bg-gray-200'>
                                                        <a href={() => false} className='mc_textTooltip text-sm' onClick={()=> setShowGetPointModal(true)}>
                                                        How to get this points?
                                                        <span className='mc_tooltipIcon'>{'i'}
                                                            <span className='mc_tooltipContent'>
                                                                How to get this points?
                                                            </span>
                                                        </span>
                                                        </a>
                                                    </div>
                                                    <div className='mc_whiteBox_bg p-3 w-full flex items-center justify-center flex-wrap '>
                                                        <p className='w-full text-center text-gray-500 text-sm'><span className="text-black">20</span> Members Received this Badge.</p>
                                                        <div className='mc_profileList'>
                                                            <ul className='flex mt-3'>
                                                                <li className='mx-1'>
                                                                    <a href={() => false}>
                                                                        <img src={`/assets/images/profile.jpeg`} alt="profile image" className="rounded-full" width={'40px'} height={'40px'}/>
                                                                    
                                                                    </a>
                                                                </li>
                                                                <li className='mx-1'>
                                                                    <a href={() => false}>
                                                                        <img src={`${alfaUrl}/images/profile.jpeg`} alt="profile image" className="rounded-full" width={'40px'} height={'40px'}/>
                                                                        
                                                                    </a>
                                                                </li>
                                                                <li className='mx-1'>
                                                                    <a href={() => false}>
                                                                        <img src={`${alfaUrl}/images/profile.jpeg`} alt="profile image" className="rounded-full" width={'40px'} height={'40px'}/>
                                                                        
                                                                    </a>
                                                                </li>
                                                                <li className='mx-1'>
                                                                    <a href={() => false}>
                                                                        <img src={`${alfaUrl}/images/profile.jpeg`} alt="profile image" className="rounded-full" width={'40px'} height={'40px'}/>
                                                                        
                                                                    </a>
                                                                </li>
                                                                <li className='mx-1'>
                                                                    <a href={() => false} className='mc_circleDark rounded-full flex items-center justify-center' onClick={()=>setMemberModal(true)}>
                                                                        15+
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                windowWidthDetector && 
                com1()
            }
       
        </div>
        {/* <UserPoints/>    */}


        <TEModal show={showRewardModal} setShow={setShowRewardModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowRewardModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <div className='mc_modalUpperDv'>
                        <img src={`${alfaUrl}/images/blueBatchTick.png`} alt='Batch Icon' /> 
                        <div className='mc_modalHead_dv'>
                            <h3>My Rewards</h3>
                            {/* <p>Rewards receive {state.rewardsListData.length} / Owned by 2</p> */}
                        </div>
                    </div>
                    <TEModalBody >
                        <div className='mc_modalBody mc_rewardModalBody'>
                            {
                                state.rewardsListData?.length && state.rewardsListData.map((rewad, indx)=>{

                                return <div key={indx} className='mc_modalRewardContent  flex flex-wrap flex-col sm:flex-row items-center'>
                                        {rewad.show == 0 && 
                                            <div className='mc_rewardOverlay_dv'>
                                                <div className='mc_lockInnner_dv'>
                                                    <img src={`${alfaUrl}/images/rewardLock.png`} alt='Lock Icon'/>
                                                    <p>{rewad.need_points}</p>
                                                </div>
                                            </div>
                                        
                                        }
                                        <div className='mc_moadlImage'>
                                            {(rewad.reward_image == null || rewad.reward_image == "null" )? 
                                             <span>{svg.app.rewardBatch}</span>:
                                                <img src={rewad.reward_image} alt='unlock' width={'230px'} height={'210px'} style={{objectFit:'contain'}}/>           
                                            }
                                        </div>
                                        <div className='mc_textContentModal'>
                                            <h3 className='text-xl text-black font-bold mb-6'>{rewad.reward_name}</h3>
                                            <p>{rewad.reward_type}</p>
                                            <div className='mc_flexReward'>
                                                <div className='mc_rewardBatch_box'>
                                                    <span className='mc_batchIcon_img'>
                                                       {svg.app.pointbadge}
                                                    </span>
                                                    <p>Total points <b>{rewad.reward_points}</b></p>
                                                </div>
                                               {rewad?.button_text && 
                                                <div className='mc_rewardBatch_box mc_nextBOx'>
                                                    <p>External Link 
                                                        <a href='#'  className='mc_external_link'
                                                            style={{
                                                                backgroundColor : `${state.buttonColor == `externalLink${indx}` ? brandSetting.theme_color2 :  brandSetting.theme_color1}`, 
                                                                color : brandSetting.theme_button_txt_color,
                                                                fill : brandSetting.theme_button_txt_color,
                                                            }}  
                                                            onMouseEnter={()=> getColor1(`externalLink${indx}`)} 
                                                            onMouseLeave={()=> getColor2(`externalLink${indx}`)}
                                                        >
                                                            <span className='mc_linkIcon_img'>
                                                                {svg.app.GotoLinkIcon}
                                                            </span> 
                                                            {rewad.button_text}
                                                            {svg.app.arrowRight}
                                                        </a>
                                                    </p>
                                                </div>
                                                }  
                                            </div>
                                        </div>
                                    </div>
                                }) 
                            }
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
        

        <TEModal show={showUnlockModal} setShow={setShowUnlockModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowUnlockModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImage'>
                                    {/* <img src={`${alfaUrl}/images/unlock.png`} alt='unlock' width={'310px'} height={'280px'}/> */}
                                </div>
                                <div className='mc_textContentModal'>
                                    <h3 className='text-xl text-black font-bold mb-6'>Complete your goals & unlock the lessons</h3>
                                    <ul>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.lockIcon}</span> Unlock when complete a lesson.</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.lockIcon}</span> Unlock when reach certain points.</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.lockIcon}</span> Unlock when get a badge.</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.lockIcon}</span> Unlock when complete a Quiz.</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.lockIcon}</span> Unlock Lesson after certain days </li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.lockIcon}</span> Unlock lesson at a specific date</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
        
        <TEModal show={showGetPointModal} setShow={setShowGetPointModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowGetPointModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImage'>
                                    {/* <img src={`${alfaUrl}/images/getPoints.png`} alt='getpoint' width={'310px'} height={'280px'}/> */}
                                </div>
                                <div className='mc_textContentModal'>
                                    <h3 className='text-xl text-black font-bold mb-6'>How to get this points</h3>
                                    <ul>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Automated Points (Points and Badges section)</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Points for watching full video</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Points for Likes</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Points for comments</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Points for resources download </li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Points for Quiz Comletion</li>
                                        <li className='flex mb-3 text-sm font-medium'><span className='mr-3'>{svg.app.modalBatch}</span> Points for lesson completion - Mark Complete</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        <TEModal show={showShortModal} setShow={setShowShortModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog mc_modalShort'>
                <TEModalContent className='mc_modalContent'>
                    <TEModalHeader className='mc_modalHeader'>
                        {/* <!--Modal title--> */}
                        <div className='mc_moadlHeaderImage'>
                            {/* <img src={`${alfaUrl}/images/batchIcon.png`} alt='unlock' width={'70px'} height={'70px'}/> */}
                            <h5 className="text-base font-semibold leading-normal text-black ml-2">
                                20 Members<br/> Received this Badge
                            </h5>
                        </div>
                    </TEModalHeader>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowShortModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                    <div className='mc_modalBody'>
                        <div className='mc_listAccorfian'>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                        {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className='mb-2'>
                                                <p className='text-base text-black font-semibold'>John Doe</p>
                                                <span className='text-sm text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <span className='font-light text-xs'>03:00 Mn Ago</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className='mb-2'>
                                                <p className='text-base text-black font-semibold'>John Doe</p>
                                                <span className='text-sm text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <span className='font-light text-xs'>03:00 Min Ago</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className='mb-2'>
                                                <p className='text-base text-black font-semibold'>John Doe</p>
                                                <span className='text-sm text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <span className='font-light text-xs'>03:00 Mn Ago</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className='mb-2'>
                                                <p className='text-base text-black font-semibold'>John Doe</p>
                                                <span className='text-sm text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <span className='font-light text-xs'>03:00 Mn Ago</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'60px'} height={'60px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className='mb-2'>
                                                <p className='text-base text-black font-semibold'>John Doe</p>
                                                <span className='text-sm text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <span className='font-light text-xs'>03:00 Mn Ago</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

      

       

       

        {/* <!-- Modal --> */}
        <TEModal show={showCongratulationsModal} setShow={setShowCongratulationsModal} scrollable className='mc_fullScreen_modal mc_restrictionModal bg_imageCongratulation' style={{backgroundImage: `url("${alfaUrl}/images/complete_coursefull.png")`}}>
            <TEModalDialog size="fullscreen" className='mc_fullScreen_dialogBox'>
                <TEModalContent className='mc_fullScreen_modalContent'>
                    {/* <a href={() => false} className='mt-24 pt-24' onClick={()=> audioPlay()}>AUdio Play</a> */}
                   
                    
                    {/* <!--Close button--> */}
                    {/* <button type="button" className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                        onClick={() => setShowCongratulationsModal(false)} aria-label="Close" >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button> */}
                    {/* <!--Modal body--> */}
                    <TEModalBody className='mc_modalBody'>
                        {/* <div className='mc_congrats_dv' style={{backgroundImage:`url(${alfaUrl}/assets/images/partical.png)`}}>
                            <span>{svg.app.tickGreenIcon}</span>
                            <h2 className='mc_modalTitle'>Congratulations!</h2>
                            <p>You have achieved</p>
                            <span className='mc_getPointsNo'>200</span>
                            <p>points for the next level badge</p>
                            <img src={`${alfaUrl}/images/batch1.png`} alt='batch' width={'300px'} height={'270px'}/>
                            <div className='mb-6'>
                                <a href={() => false} className='mc_whiteBtn'>Collect My Badge</a>
                            </div>
                        </div> */}
                        {/* <div className='mc_congrats_dv'>
                            <div className='mc_completeModal'>
                                <span className='flex items-center justify-center mc_tickIcon_modal'>{svg.app.tickGreenIcon}</span>
                                <img src={`${alfaUrl}/images/completeCourseFull.png`} alt='batch' width={'510px'} height={'460px'}/>
                            </div>
                        </div> */}
                    </TEModalBody>
                        {/* <div className='mc_cloudimg'>
                            <img src={`${alfaUrl}/images/cloudImg.png`} alt='cloud image'/>
                        </div> */}
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        {/* <!-- Modal  --> */}
        <TEModal show={memberModal} setShow={setMemberModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog mc_modalShort'>
                <TEModalContent className='mc_modalContent'>
                    <TEModalHeader className='mc_modalHeader bg-white'>
                        {/* <!--Modal title--> */}
                        <div className='mc_moadlHeaderImage'>
                            {/* <img src={`${alfaUrl}/images/batchIcon.png`} alt='unlock' width={'70px'} height={'70px'}/> */}
                            {svg.app.greenTickCircle}
                            <h5 className="text-base font-semibold leading-normal text-black ml-2">
                                22 Members <br/> Completed their course
                            </h5>
                        </div>
                    </TEModalHeader>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setMemberModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                    <div className='mc_modalBody'>
                        <div className='mc_listAccorfian'>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                        {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'40px'} height={'40px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className=''>
                                                <p className='text-sm text-black font-semibold'>John Doe</p>
                                                <span className='text-xs text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <div className='mc_batchText_dv flex justify-between items-center '>
                                                <div className='mr-3 relative'>
                                                    {/* <img src={`${alfaUrl}/images/batch11.png`} alt='batch'/> */}
                                                    <span className='mc_whiteCircle'>2</span>
                                                </div>
                                                <h3>Congrats</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'40px'} height={'40px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className=''>
                                                <p className='text-sm text-black font-semibold'>John Doe</p>
                                                <span className='text-xs text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <div className='mc_batchText_dv flex justify-between items-center '>
                                                <div className='mr-3 relative'>
                                                    {/* <img src={`${alfaUrl}/images/batch11.png`} alt='batch'/> */}
                                                    <span className='mc_whiteCircle'>2</span>
                                                </div>
                                                <h3>Congrats</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'40px'} height={'40px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className=''>
                                                <p className='text-sm text-black font-semibold'>John Doe</p>
                                                <span className='text-xs text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <div className='mc_batchText_dv flex justify-between items-center '>
                                                <div className='mr-3 relative'>
                                                    {/* <img src={`${alfaUrl}/images/batch11.png`} alt='batch'/> */}
                                                    <span className='mc_whiteCircle'>2</span>
                                                </div>
                                                <h3>Congrats</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'40px'} height={'40px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className=''>
                                                <p className='text-sm text-black font-semibold'>John Doe</p>
                                                <span className='text-xs text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <div className='mc_batchText_dv flex justify-between items-center '>
                                                <div className='mr-3 relative'>
                                                    {/* <img src={`${alfaUrl}/images/batch11.png`} alt='batch'/> */}
                                                    <span className='mc_whiteCircle'>2</span>
                                                </div>
                                                <h3>Congrats</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mc_borseredBoxModal flex flex-wrap py-3'>
                                <div className='flex justify-center items-center w-full relative'>
                                    <span className='mr-4'>
                                    {/* <img src={`${alfaUrl}/images/customer1.jpeg`} className='rounded-full' alt='studio' width={'40px'} height={'40px'}/> */}
                                    </span>
                                    <div className='mc_ueserLists_dv w-full'>
                                        <div className='flex justify-between items-center text-gray-500 w-full'>
                                            <div className=''>
                                                <p className='text-sm text-black font-semibold'>John Doe</p>
                                                <span className='text-xs text-black mt-1'><b className='font-semibold'>Points: 350, </b> 4 Badges</span>
                                            </div>
                                            <div className='mc_batchText_dv flex justify-between items-center '>
                                                <div className='mr-3 relative'>
                                                    {/* <img src={`${alfaUrl}/images/batch11.png`} alt='batch'/> */}
                                                    <span className='mc_whiteCircle'>2</span>
                                                </div>
                                                <h3>Congrats</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        {/* <UserPoints/>    */}
        <TEModal show={showAssessmentModal} setShow={setShowAssessmentModal} scrollable className='mc_modalHolder mc_assestModal'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowAssessmentModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody className='mc_paddZero'>
                        <div className='mc_modalBody'>                   
                            {/* <QuizApp steps={state.assessmentStep} slugName={slugName} questionList={state.question_list}/> */}
                            {/* <h1>{JSON.stringify(state?.question_list)}</h1>                        */}
                            <div className='mc_box_assessment'>
                                {state.assessmentStep == 0 ? '' : <>
                                    <div className='mc_modalHeader flex items-center justify-between'>
                                        {/* {(state?.question_list && state?.question_list?.length == 0) &&  */}
                                        <div className='mc_leftText_dv'>
                                            <h3 className='text-black text-lg font-medium'>{state.course_name}</h3>
                                            <p className='text-sm text-gray-400 font-medium mt-2'>
                                                {/* {state.lessionList?.length}th  */}
                                                Lesson : <span className='text-black'>{(state.lession_name == '' || state.lession_name == 'undefined')? state.currentlession?.lesson_name : state.lession_name}</span></p>
                                        </div>
                                        {/* } */}
                                        {/* <div className="w-[80px] mc_timerCount">
                                            <svg viewBox="0 0 200 210" width="80">
                                                <defs>
                                                    <mask id="m1"><circle cx="100" cy="105" r="55" fill="white"></circle></mask>
                                                    <linearGradient id="lg1" gradientTransform="rotate(0) skewX(-20) skewY(-40)">
                                                        <stop offset={`100%`} stopColor="#2DBA0C"></stop>
                                                        <stop offset="0" stopColor="#2DBA0C"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path pathLength="360" d="M100 175 a 75 75 0 1 1 1 0" stroke="LightSlateGray" fill="none" strokeWidth="10" strokeLinecap="round" strokeDasharray="360 360" strokeDashoffset="0"></path>
                                                <path pathLength="360" d="M100 175 a 75 75 0 1 1 1 0" stroke="url(#lg1)" fill="none" strokeWidth="10" strokeLinecap="round" strokeDasharray={`${360*(state.assessmentStep+1)/10} 360`} strokeDashoffset="0"></path>
                                                <g transform="translate(100 180)" font-size="60" fontFamily="sans-serif" fontWeight="bold" text-anchor="middle">
                                                    <text className='mc_svgText'>{timer}</text>
                                                    <text className='mc_svgText2'>left</text>
                                                </g>
                                            </svg>
                                        </div> */}
                                    </div>
                                    
                                    {state.showResult == true && 
                                        <div className='mc_innerContentHead'>
                                            <div className="mc_questionHead">
                                                <h2 className="text-xl text-center font-bold mb-4">Your Result Status <br/><span className={state?.result_status === 'Fail' ? 'text-[#ff4141]' : 'text-[#2faa02]'}>{state.result_status} </span> </h2>
                                                <p className="text-sm text-center font-bold mb-4">Total Percentage - {state.resultPercentage}%</p>
                                                <p className="text-sm text-center font-normal mb-4 center text-center">{state.resultmessage}</p>
                                            </div>
                                        </div>
                                        // <h2>Quiz list is not Found!!</h2>   
                                    }
                                    </>              
                                }
                                {state.assessmentStep == 0 ? <>
                                    <div className='mc_assessmentContent'>
                                        <div className='p-4'>
                                            <img src={`${alfaUrl}/images/quiz.png`} alt='' width={'356px'} height={'165px'} className='mx-auto block'/>
                                        </div>
                                        <p className='text-lg text-black font-medium text-center'>{state.course_name} </p>
                                        <p className='text-sm text-gray-400 font-medium text-center mt-2'>
                                            {/* {state.lessionList?.length}th  */}
                                        Lesson : {(state.lession_name == '' || state.lession_name == 'undefined')? state.currentlession?.lesson_name : state.lession_name}</p>
                                        <ul className="w-full flex flex border-neutral-100 border-opacity-100 text-center mc_borderedList">
                                            <li className="w-full px-2">
                                                <span className='text-4xl color-themeColor font-medium'>{state.question_list?.length ? state.question_list?.length : 0}</span>
                                                {/* <p className='text-xs text-gray-400'>Following</p> */}
                                                <p className='text-sm text-gray-600 mt-2' >Single choice questions</p>
                                            </li>
                                            {/* <li className="w-full px-2">
                                                <span className='text-4xl color-themeColor font-medium'>1.5</span>
                                                <p className='text-xs text-gray-400'>Followers</p>
                                                <p className='text-sm text-gray-600 mt-2' >Minutes Per Questions</p>
                                            </li>
                                            <li className="w-full px-2">
                                                <span className='text-4xl color-themeColor font-medium'>05</span>
                                                <p className='text-xs text-gray-400'>Posts</p>
                                                <p className='text-sm text-gray-600 mt-2' >points for the Assessment</p>
                                            </li> */}

                                        </ul>
                                        <div className='mc_TextContentList'>
                                            <div className='text-sm text-gray-700 mt-3 text-center' dangerouslySetInnerHTML={{ __html: state.assesment_default_text }} />
                                            {state.retake_attempt_status != 0 && 
                                                <a href={() => false} className='mc_btn mc_courseBtn text-base mt-6 max-w-' 
                                                    style={{fill: brandSetting.theme_button_txt_color,color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Start Assessment' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                    onMouseEnter={(e)=> getColor1('Start Assessment')} onMouseLeave={(e)=> getColor2('Start Assessment')} 
                                                    onClick={(e)=>{
                                                        getStartAssessment();
                                                    }}
                                                    >
                                                    Start Assessment
                                                    <span>{svg.app.arrowRight}</span>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                    </>

                                    :   state.showResult != true && state.question_list?.length > 0 && state.assessmentStep <= state.question_list?.length ? <div className='mc_innerContentHead'>
                                            <div className="flex items-center justify-between mc_questionHead">
                                                <div className='flex items-center'>
                                                    <span className='mc_countCircle'
                                                        style={{ color: brandSetting.theme_button_txt_color, backgroundColor : brandSetting.theme_color2}}
                                                    >{state.assessmentStep}</span>
                                                    <p>{state.question_list[state.assessmentStep-1]?.question}</p>
                                                </div>
                                            </div>
                                            <div className='mc_answerList px-7 py-5'>
                                                <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                                <div className='mc_formGroup' >
                                                {/* flex justify-start flex-wrap */}                                             
                                                    {state.question_list[state.assessmentStep-1]?.choices.map((ans, opt)=>{
                                                        // let qus=state.question_list[state.assessmentStep-1].question_id
                                                        return <div key={opt}>
                                                            <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                                <label className="w-full break-words  hover:cursor-pointer">
                                                               
                                                                    <input className="mc_checkBox_input mc_checkbox" type="radio" name={`answer_rand_${state.question_list[state.assessmentStep-1]?.question_id}`}       
                                                                        value={ans}
                                                                        checked={selectedAnswer?.filter(t1=>((t1.choice_rand_id=== ans.choice_rand_id) && (t1.choice_name=== ans.choice_name)) ).length==0 ? false : true   } 
                                                                        onChange={(e)=> getSelectAnswer(e, ans,state.question_list[state.assessmentStep-1]?.question_id ,state.assessmentStep-1)}
                                                                    />
                                                                    <span className='checkTick text-base mc_radio -mt-0.5'
                                                                        style={{borderColor: brandSetting.theme_color2}}
                                                                    >
                                                                        <i style={{backgroundColor: brandSetting.theme_color2}}></i>    
                                                                    </span> {ans.choice_name}
                                                                </label>
                                                            </div>
                                                            <div className='mc_innerContentHead mt-5 pt-5'>
                                                                {state.assessmentStep <= state.question_list?.length &&
                                                                    <div className="h-2 w-full bg-neutral-200 dark:bg-neutral-600">
                                                                        <div className={`h-2 mc_borderProgress ${state.assessmentStep == state.question_list?.length && 'mc_afterNone'}`} style={{ width: `${(100/state.question_list?.length)*state.assessmentStep}%`, backgroundColor: brandSetting.theme_color2 }}>
                                                                            <span style={{backgroundColor: brandSetting.theme_color2, borderColor: brandSetting.theme_color2}}> 
                                                                                {state.assessmentStep}/{state.question_list?.length}
                                                                                <i style={{backgroundColor: brandSetting.theme_color2, borderColor: brandSetting.theme_color2}}></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                }    
                                                                <div className="flex justify-between p-6 mc_buttonHolde_dv">
                                                                    <div></div>
                                                                    {(state.assessmentStep <= state.question_list?.length) &&  (
                                                                        <div className='flex'>
                                                                            {state.assessmentStep != 1 &&
                                                                                <button className="px-6 py-1.5 rounded-lg text-gray-700 mr-2 mc_btn"
                                                                                onClick={(e)=>{
                                                                                    handleBack();
                                                                                }}
                                                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'PreviousBtn' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                                                onMouseEnter={(e)=> getColor1('PreviousBtn')} onMouseLeave={(e)=> getColor2('PreviousBtn')} 
                                                                            >
                                                                                <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}>{svg.app.arrowGrayLeft}</span> Previous
                                                                            </button>
                                                                        }                                                           
                                                                            {state.assessmentStep == state.question_list?.length ? <button className="px-6 py-1.5 rounded-lg text-gray-700 mc_btn"
                                                                            disabled={resultQuizLoader}
                                                                                onClick={()=> {
                                                                                    if(selectedAnswer[state.assessmentStep-1]){
                                                                                        handleNext(state.question_list[state.assessmentStep-1]?.question_id,selectedAnswer?.choice_rand_id ,"submit")
                                                                                        // getResultOfQuiz(state.question_list[state.assessmentStep-1]?.question_id, ans.choice_rand_id);
                                                                                    }else{
                                                                                        toast.error('Please select answer to continue.')
                                                                                    }                       
                                                                                }}
                                                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Result' ? brandSetting.theme_color1 : brandSetting.theme_color2}`}}
                                                                                onMouseEnter={(e)=> getColor1('Result')} onMouseLeave={(e)=> getColor2('Result')} 
                                                                            >
                                                                              { resultQuizLoader ? 'Loading Result...' : 'Result' }
                                                                                <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}>
                                                                                    {svg.app.arrowGrayRight}
                                                                                </span> 
                                                                            </button> :
                                                                            
                                                                            <button className="disabled px-6 py-1.5 rounded-lg text-gray-700 mc_btn"
                                                                                onClick={()=>{
                                                                                    if(selectedAnswer[state.assessmentStep-1]){
                                                                                        handleNext(state.question_list[state.assessmentStep-1]?.question_id,selectedAnswer?.choice_rand_id)
                                                                                        // handleNext(selectedAnswer)
                                                                                    }else{
                                                                                        
                                                                                        toast.error('Please select answer to continue.')
                                                                                    }
                                                                                    
                                                                                }}
                                                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'NextBtn' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                                                onMouseEnter={(e)=> getColor1('NextBtn')} onMouseLeave={(e)=> getColor2('NextBtn')} 

                                                                                // disabled=
                                                                            >
                                                                                Next <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}>{svg.app.arrowGrayRight}</span> 
                                                                            </button>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                            
                                        : ""
                                        // <div className='mc_innerContentHead'>
                                        //     <div className="mc_questionHead">
                                        //         <h2>Quiz Questions not available </h2>
                                        //         {/* <p>Total Points - 65%</p> */}
                                        //         <div className="flex text-center">
                                        //             <a href={() => false} className="mc_btn mt-5 mx-auto"
                                        //                 style={{fill: brandSetting.theme_button_txt_color,color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Start Assessment' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                        //                 onMouseEnter={(e)=> getColor1('Start Assessment')} 
                                        //                 onMouseLeave={(e)=> getColor2('Start Assessment')} 
                                        //                 onClick={()=>{
                                        //                     // navigator('/')
                                        //                     setShowAssessmentModal(false)
                                        //                 }}
                                        //             >
                                        //                 <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}></span>    
                                        //                 Close
                                        //             </a>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        }
                                    
                                        
                                    
                                    
                                            {/* <div className='mc_innerContentHead'>
                                                <div className="flex items-center justify-between mc_questionHead">
                                                    <div className='flex items-center'>
                                                        <span className='mc_countCircle'>1</span>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                                    </div>
                                                </div>
                                                <div className='mc_answerList px-7 py-5'>
                                                    <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                                    <div className='mc_formGroup flex justify-start'>
                                                        <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                            <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                                <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                                <span className='checkTick text-base mc_radio'></span> Option 1
                                                            </label>
                                                        </div>
                                                        <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                            <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                                <input
                                                                className="mc_checkBox_input mc_checkbox"
                                                                type="radio"
                                                                name="answer_rand_id"
                                                                value="option1" />
                                                                <span className='checkTick text-base mc_radio'></span> Option 2
                                                            </label>
                                                        </div>
                                                        <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                            <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                                <input
                                                                className="mc_checkBox_input mc_checkbox"
                                                                type="radio"
                                                                name="answer_rand_id"
                                                                value="option1" />
                                                                <span className='checkTick text-base mc_radio'></span> Option 3
                                                            </label>
                                                        </div>
                                                        <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                            <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                                <input
                                                                className="mc_checkBox_input mc_checkbox"
                                                                type="radio"
                                                                name="answer_rand_id"
                                                                value="option1" />
                                                                <span className='checkTick text-base mc_radio'></span> Option 4
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        
                                    {/* :  state.assessmentStep == 2 ?  <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center justify-start mc_questionHead">
                                            <span className='mc_countCircle'>2</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :  state.assessmentStep == 3 ?  <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center  justify-start mc_questionHead">
                                            <span className='mc_countCircle'>3</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :  state.assessmentStep == 4 ?  <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center  justify-start mc_questionHead">
                                            <span className='mc_countCircle'>4</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :  state.assessmentStep == 5 ?  <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center justify-start mc_questionHead">
                                            <span className='mc_countCircle'>5</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :  state.assessmentStep == 6 ?  <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center justify-start mc_questionHead">
                                            <span className='mc_countCircle'>6</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :  state.assessmentStep == 7 ? <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center justify-start mc_questionHead">
                                            <span className='mc_countCircle'>7</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </> 
                                    :  state.assessmentStep == 8 ? <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center justify-start mc_questionHead">
                                            <span className='mc_countCircle'>8</span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt?</p>
                                        </div>
                                        <div className='mc_answerList px-7 py-5'>
                                            <p className='text-sm text-gray-500 font-semibold mb-3'>Select a correct answer below</p>
                                            <div className='mc_formGroup flex justify-start'>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input className="mc_checkBox_input mc_checkbox" type="radio" name="answer_rand_id" value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 1
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 2
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 3
                                                    </label>
                                                </div>
                                                <div className="mr-8 inline-block min-h-[1.5rem] pl-[1.5rem] mc_checkboxList">
                                                    <label className="inline-block pl-[0.15rem] hover:cursor-pointer">
                                                        <input
                                                        className="mc_checkBox_input mc_checkbox"
                                                        type="radio"
                                                        name="answer_rand_id"
                                                        value="option1" />
                                                        <span className='checkTick text-base mc_radio'></span> Option 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :  state.assessmentStep == 9 && <>
                                    <div className='mc_innerContentHead'>
                                        <div className="flex items-center justify-start mc_questionHead">
                                            <h2>Your Result</h2>
                                            <p>Total Points - 65%</p>
                                        </div>
                                    </div>
                                    </>
                                } */}
                                {/* {state.assessmentStep != 0 && 
                                <> 
                                    <div className='mc_innerContentHead'>
                                        {state.assessmentStep != 9 &&
                                            <div className="h-2 w-full bg-neutral-200 dark:bg-neutral-600">
                                                <div className={`h-2 mc_borderProgress ${state.assessmentStep == 8 && 'mc_afterNone'}`} style={{ width: `${(100/8)*state.assessmentStep}%` }}>
                                                    <span> {state.assessmentStep}/8</span>
                                                </div>
                                            </div>
                                        }    
                                        <div className="flex justify-between p-6 mc_buttonHolde_dv">
                                            
                                            <div></div>
                                            {(state.assessmentStep <= 8) &&  (
                                                <div className='flex'>
                                                    <button className="px-6 py-1.5 rounded-lg text-gray-700 mr-2 mc_btn"
                                                        onClick={handleBack}
                                                        style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'PreviousBtn' ? brandSetting.theme_color2 : '#C4C4C4'}`}}
                                                        onMouseEnter={(e)=> getColor1('PreviousBtn')} onMouseLeave={(e)=> getColor2('PreviousBtn')} 
                                                    >
                                                        <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}>{svg.app.arrowGrayLeft}</span> Previous
                                                    </button>
                                                    {state.assessmentStep == 8 ? <button className="px-6 py-1.5 rounded-lg text-gray-700 mc_btn"
                                                            onClick={()=> {
                                                                handleNext();
                                                                getResultOfQuiz();
                                                            }}
                                                            style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Result' ? brandSetting.theme_color1 : brandSetting.theme_color2}`}}
                                                            onMouseEnter={(e)=> getColor1('Result')} onMouseLeave={(e)=> getColor2('Result')} 
                                                        >
                                                            Result <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}>{svg.app.arrowGrayRight}</span> 
                                                        </button> :
                                                        <button className="px-6 py-1.5 rounded-lg text-gray-700 mc_btn"
                                                            onClick={()=>handleNext()}
                                                            style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'NextBtn' ? brandSetting.theme_color1 : '#C4C4C4'}`}}
                                                            onMouseEnter={(e)=> getColor1('NextBtn')} onMouseLeave={(e)=> getColor2('NextBtn')} 
                                                        >
                                                            Next <span className='mc_iconHolder' style={{fill: brandSetting.theme_button_txt_color,}}>{svg.app.arrowGrayRight}</span> 
                                                        </button>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                                } */
                                }
                            </div>
                                
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        <TEModal show={showRestrictedModal} setShow={setShowRestrictedModal} scrollable className='mc_fullScreen_modal mc_restrictionModal'>
            <TEModalDialog size="fullscreen" className='mc_fullScreen_dialogBox'>
                <TEModalContent className='mc_fullScreen_modalContent'>
                    {/* <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => {setShowRestrictedModal(false)
                        window.location.replace('/dashboard')
                    }} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a> */}
                    {/* <!--Modal body--> */}
                    <TEModalBody className='mc_modalBody'>
                        <div className='mc_congrats_dv'>
                            <div className='mc_completeModal text-center'>
                                {/* <span className='flex items-center justify-center mc_tickIcon_modal'>{svg.app.tickGreenIcon}</span> */}
                                <img src={`${alfaUrl}/images/padlock.png`} alt='batch' width={'178px'} height={'260px'}/>
                                {/* <h4 className='text-xl font-bold my-3'>We are sorry!</h4> */}
                                <p>{restrictedContent?.upgradetext ? restrictedContent.upgradetext :  'Currently the course access has been restricted'}</p>
                                <div className='mc_btnModal_holder mt-5'>
                                {restrictedContent?.buttontext &&
                                    <Link to={restrictedContent?.buttonlink ? restrictedContent?.buttonlink  : '/dashboard'} className='mc_btn mc_courseBtn w-50' style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Upgrade Now' ?  brandSetting.theme_color2 : brandSetting.theme_color1}}  onMouseEnter={()=> getColor1('Upgrade Now')} onMouseLeave={()=> getColor2('Upgrade Now')}
                                        target='_blank'
                                    >
                                        {restrictedContent.buttontext} 
                                        <span style={{fill : brandSetting.theme_button_txt_color}}>{svg.app.arrowRight}</span>
                                    </Link>
                                }
                                </div>
                            </div>
                        </div>
                        {/* <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImage'>
                                    <img src={`${alfaUrl}/images/unlock.png`} alt='unlock' width={'310px'} height={'280px'}/>
                                    <h4>We are sorry!</h4>
                                    <p>Currently the course access has been restricted</p>
                                    <div className='mc_btnModal_holder'>
                                        <a href={() => false} className='mc_btn mc_courseBtn' style={{color:brandSetting.theme_button_txt_color }}>Upgrade Now 
                                            <span>{svg.app.arrowRight}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

            <TEModal show={showQuizResultModal} setShow={setshowQuizResultModal} scrollable className='mc_modalHolder mc_assestModal'>
                <TEModalDialog centered className='mc_modalDialog'>
                    <TEModalContent className='mc_modalContent'>
                        <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setshowQuizResultModal(false)} aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </a>
                        {/* <!--Modal body--> */}
                        <TEModalBody className='mc_paddZero'>
                            <div className='mc_modalBody'>

                                <>
                                    <div className='mc_modalHeader flex items-center justify-between'>
                                        <h3 className='text-black text-lg font-medium'>Quiz Response</h3>
                                    </div>
                                    <div className='mc_assessmentContent'>
                                        <div className='pl-5 pr-5'>
                                            <h2 className="text-xl  font-bold mb-2">Your Result Status - <span className={state.quizMemberResult?.result_status === 'Fail' ? 'text-[#ff4141]' : 'text-[#2faa02]'}> {state.quizMemberResult?.result_status}</span> </h2>
                                            <p className="text-sm  mb-8">Total Percentage -<span className='text-base font-bold'> {state.quizMemberResult?.percentage}% </span></p>

                                            <div>
                                                {
                                               state.quizMemberAnswerData &&  state.quizMemberAnswerData.length > 0 &&  state.quizMemberAnswerData.map((quiz, i) => <div className='mb-5' key={i}>
                                                        <h4 className='font-bold text-base pb-1 w-full break-words'>{quiz.question} </h4>
                                                        <p className='flex items-center justify-start gap-1 font-medium text-sm '> <span className='break-words'>{quiz.choice_name}</span> <span> {quiz.answer_mode ? svg.app.greenTick : svg.app.redCross}</span></p>
                                                    </div>)
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
    </>
  )
}
export default MyLearnings;

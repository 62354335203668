'use client';
import React, { useEffect, useState, useRef } from 'react'
import svg from '../components/svg';
import { postDataFilter, followersData } from './index';
import {
    TECollapse, 
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEDropdown,
    TEDropdownToggle,
    TEDropdownMenu,
    TEDropdownItem,
  } from "tw-elements-react";
    // Initialization for ES Users
import {
  InfiniteScroll,
  Ripple,
  LazyLoad,
  initTE,
} from "tw-elements";
    
import {useForm} from 'react-hook-form';
import {  Skeleton, Space } from 'antd';
// import Comment from '../components/comments/Comment';
import CommentsData from '../components/comments/CommentData';
// import useInfiniteScroll from "../components/infinityScroll";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
import { Navigation } from 'swiper/modules';

import { Link } from 'react-router-dom';
import Createpost from '../components/postData/Createpost';
import Uploadpost from '../components/postData/uploadpost';
import ActiveUsers from '../components/birthday/activeUsers';
import LatestFollowers from '../components/postData/Followers';
import {NoDataWrapper, common , setMyState} from '../components/Comman'; 


export default function Community(props) {
  // console.log(props, 'CommunityProps');
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const showSkeleton = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };
  useEffect(()=>{
    showSkeleton()
  },[])

  const filteredItems = [
      {id: 1},{id: 2},{id: 3},{id: 4},{id: 5},{id: 6},{id: 7},{id: 8},{id: 9},
  ];
  const[state, setQuery]=useState({
    togglePost : false,
    upLoadFile : '',
    postUploadFile : '',
    postDataFilter : postDataFilter,
    rating : '',
    createPost : false,
    postId : '',
    isPlay : false,
    page : 1,
    loader : false,
    show : false,
    profileImage : '',
    followersData : followersData,
    postType: '',
    postUrl: '',
  });
  const [birthDayModal, setBirthDayModal] = useState(false)
  const [congratulationsModal, setCongratulationsModal] = useState(false);
  const [followersModal, setFollowersModal] = useState(false);

  const getPostHandeled=()=>{
    // console.log('Post');
    setMyState(setQuery,{
      ...state,
      togglePost : !state.togglePost,
      // postDataFilter :  
    })

  }
/************************* Audio File **************************/
  const [buttonName, setButtonName] = useState("Play");
  const [audio, setAudio] = useState();
  const [isVideo, setVideo] = useState();
  var a;
  useEffect(() => {
    if (a) {
      a.pause();
      a = null;
      setButtonName("Play");
    }
    if (audio) {
      a = new Audio(audio);
      a.onended = () => {
        setButtonName("Play");
      };
    }
  }, [audio]);

  const handleClick = () => {
    if (buttonName == "Play") {
      a.play();
      setButtonName("Pause");
    } else {
      a.pause();
      setButtonName("Play");
    }
  };
  const addFile = (e) => {
    // console.log(e, 'Audio File');
    if (e.target.files[0]) {
      setAudio(URL.createObjectURL(e.target.files[0]));
    }
  };
  const profileImgUpload=(e)=>{
    // console.log(e.target.files[0], 'FIle');
    const profileImgURL = window.URL.createObjectURL(e.target.files[0]);
    // console.log("e.target.files",objectURL)
    setMyState(setQuery,{
      ...state,
      profileImage : profileImgURL,
    })
  }
/*****************************/
  const createPostData=(event, postId)=>{
    setMyState(setQuery,{
      ...state,
      createPost : !state.createPost,
      postId : postId,
      postDataFilter  : [{
          ...postDataFilter,
          postId : postId,
          postUrl:event.target.value,
          // postContent: 
        }
      ]

    })
  }
  const toggleShow = (event, postIndex) => {
    // setShow(!show);
    setMyState(setQuery,{
      ...state,
      show : !state.show,
      postId : postIndex,
    })
  }
  const ref = useRef(null);
  let fetchPostData=()=>{
    let elem=document.getElementById("scrollerPosts")
    let targetScrollHeight = elem.scrollHeight - elem.clientHeight;
    if (targetScrollHeight == elem.scrollTop){
      let p = state.page + 1;
      setMyState(setQuery,{
        ...state,
        page : p,
      })
      fetchDataFromPost(true,p)
    }
  }
  const fetchDataFromPost=(load=false, page)=>{
    // console.log('dsdsd');
    // setLoader(true)
    setMyState(setQuery,{
      ...state, 
      loader : true,
      pageLoader : true,
      postDataFilter : postDataFilter,
    })
  }
  useEffect(()=>{
    fetchPostData()
  },[postDataFilter])


    const [Items, setItems] = useState(
      Array.from(Array(5).keys(), (n) => n + 1)
    );
    const [isFetching, setIsFetching] = useState(false);
    function loadMoreItems() {
      setIsFetching(true);
      //mocking an API call
      setTimeout(() => {
        setItems((prevState) => [
          ...prevState,
          ...Array.from(Array(5).keys(), (n) => n + prevState.length + 1),
        ]);
        setIsFetching(false);
      }, 2000);
    }
    //we change here
    // const [Items, setItems] = useState([]);
    // const [isFetching, setIsFetching] = useState(false);

    //setting tha initial page
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(4);
    //we need to know if there is more data

    // const [HasMore, setHasMore] = useState(true);
    // const [lastElementRef] = useInfiniteScroll(
    //   HasMore ? loadMoreItems : () => {},
    //   // isFetching
    // );

    // on initial mount

    // useEffect(() => {
    //   loadMoreItems();
    // }, []);

    // function loadMoreItems() {
    //   setIsFetching(true);

    //using Post to access the third party API

    //   // axios({
    //   //   method: "GET",
    //   //   url: "https://jsonplaceholder.typicode.com/albums",
    //   //   params: { _page: page, _limit: 4},
    //   // }).then((res) => {

    //     let res = state.postDataFilter;
    //     setItems((prevTitles) => {
    //       return [...new Set([...prevTitles, ...res.map((b) => b.postTitle)])];
    //     });
    //     setPage((prevPageNumber) => prevPageNumber + 1);
    //     setHasMore(res.length > 0);
    //     setIsFetching(false);

    //   // })
    //   // .catch((e) => {
    //   //   console.log(e);
    //   // });
    // }
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    






// init infinite scroll

// initTE({ Ripple, LazyLoad });
// const postsContainer = document.getElementById("scrollerPosts");
// const spinner = document.getElementById("spinner");

// const itemsImg = [
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/31.jpg",
//     title: "This is an title of the arzticle",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/23.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/29.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/27.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/25.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/24.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/31.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
//   {
//     img: "https://tecdn.b-cdn.net/img/Photos/Others/images/32.jpg",
//     title: "This is an title of the article",
//     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam cupiditate veniam voluptatibus laudantium cum dolorem illo. Quos architecto deserunt saepe.",
//   },
// ];

// const getPostTemplate = (post) => {
//   // returns the HTML template with post's title, image & text
//   return `
//   <div className="flex flex-col items-center">
//     <div
//       className="block rounded-lg bg-white shadow-[0_2px_5px_0_rgba(0,0,0,0.25),0_3px_10px_0px_rgba(0,0,0,0.2)] dark:bg-neutral-700"
//     >
//       <div
//         className="relative overflow-hidden bg-cover bg-no-repeat"
//         data-te-ripple-init
//         data-te-ripple-color="light"
//       >
//         <img
//           className="rounded-lg"
//           src="${post.img}"
//           alt=""
//         />
//         <a href={()=>false}>
//           <div
//             className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"
//           ></div>
//         </a>
//       </div>
//     </div>
//     <h5 className="mt-5 mb-3 text-xl font-normal text-[#4f4f4f] dark:text-white">
//       ${post.title}
//     </h5>
//     <p className="text-center mb-3 text-[#4f4f4f] dark:text-neutral-200">
//       ${post.text}
//     </p>
//     <button
//       type="button"
//       data-te-ripple-init
//       data-te-ripple-color="light"
//       className="inline-block mb-3 rounded-full bg-info px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-info-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
//     >
//       Read more
//     </button>
//   </div>
//   `;
// };

// // posts - array of templates
// const posts = itemsImg.map((item) => getPostTemplate(item));

// const generateRow = (firstPost, secondPost) => {
//   // Returns a div.row element with two columns generated based on arguments
//   let el = document.createElement("div");
//   let classes = ["w-full"];

//   classes.forEach((className) => el.classList.add(className));

//   el.innerHTML = `
//     ${firstPost}
//     ${secondPost}
//   `;
//   return el;
// };

// // rows - array of rows with two posts each
// const rows = [];

// // iterates over posts and creates a row for every two of them
// for (let i = 0; i < posts.length - 1; i += 2) {
//   rows.push(generateRow(posts[i], posts[i + 1]));
// }

// // renderedItems - number of items already rendered
// let renderedItems = 0;

// const renderItems = (index) => {
//   // timeout simulates delay in loading items (f.e. API call)
//   setTimeout(() => {
//     // hide spinner
//     spinner.classList.replace("inline-block", "hidden");

//     postsContainer.appendChild(rows[index]);
//   }, 1500);
// };

// const loadItems = () => {
//   if (renderedItems < rows.length) {
//     // show spinner
//     postsContainer.appendChild(spinner);
//     spinner.classList.replace("hidden", "inline-block");

//     renderItems(renderedItems);

//     renderedItems++;

//     // Removes event listener after all items have been loaded
//     if (renderedItems === rows.length) {
//       window.removeEventListener("complete.te.infiniteScroll", loadItems);
//     }
//   }
// };

// // load items when window is scrolled to the end
// window.addEventListener("complete.te.infiniteScroll", loadItems);

// // init infinite scroll
// new InfiniteScroll(window);



  return (
    <>
      {/* <h1>Community & Create Post Flow</h1> */}
      <div className='py-5'>
        <div className='max-container'>
          <div className="grid grid-cols-4 gap-4 mc_grigBox_holder mc_mobileRow">
            {/* Left Profile */}
              <form onSubmit={()=>handleSubmit((data) => console.log(data, 'FormData'))} className="mc_strikyBox_wrap mc_grigBox mc_moboilCol_12 lg:col-span-1 xl:col-span-1">
                <div className="block rounded-lg bg-white mc_card_dv mc_grigBox">
                  {state?.profileImage ? 
                  <div className="relative overflow-hidden bg-cover bg-no-repeat mc_profileImg_bg" style={{backgroundImage:`url(${String(state?.profileImage)})`}}>
                    {/* <img src={String(state?.profileImage)} alt='upload' className='rounded-t-lg mx-auto'/> */}
                  </div>
                : 
                  <div className='mc_upload_wrapper mc_uploadProfile_dv'>
                    <label htmlFor='profile_upload' className='relative w-full mc_uploadLabel'>
                      <input type='file' className='w-full mc_withoutBordered' id='profile_upload' accept="image/*" onChange={(e)=> profileImgUpload(e)}/>
                      <span className='text-center w-full mb-2 flex items-center justify-center'>{svg.app.profileIcon}</span>
                      <b className='text-sm text-black font-bold'>Upload your profile picture <span className='block w-full font-light text-gray-700'>and earn</span></b>
                      <span className='flex items-center justify-center w-full text-3xl text-black font-bold'>
                        <img src='/assets/images/batch11.png' alt='batch11' width={'30px'} height={'30px'}/> 05
                      </span>
                      <span className='text-sm text-black font-light'>points instantly</span>
                    </label>
                  </div>
                  }
                  
                  <div className="p-6 text-center">
                    <h5
                      className="text-base font-light text-black-800">
                      Vijaragavan Ramachari
                    </h5>
                  </div>
                  <div className='border-b-2 border-neutral-100 border-opacity-100'></div>
                  <ul className="w-full flex flex border-neutral-100 border-opacity-100 text-center mc_borderedList">
                    <li className="w-full px-2">
                      <span>230</span>
                      <p className='text-xs text-gray-400'>Following</p>
                    </li>
                    <li className="w-full px-2">
                      <span>300</span>
                      <p className='text-xs text-gray-400'>Followers</p>
                    </li>
                    <li className="w-full px-2">
                      <span>1000</span>
                      <p className='text-xs text-gray-400'>Posts</p>
                    </li>
                  </ul>
                  <div className="p-6">
                    <div className='mc_batchImg'>
                      <img src='/assets/images/batch11.png' alt='batch' width={'42px'}/>
                      <p className='mt-2 text-sm text-gray-400'>My Total Points</p>
                      <h5 className='text-2xl text-gray-800 font-bold mb-0'>200</h5>
                      <p className='mt-2 text-sm text-black text-medium'>I have earned 2 badges</p>
                    </div>
                    {/* <h5 className='text-sm text-black font-bold'>I have earned 1 badges</h5> */}
                    <div className='mc_thumbSlider mc_slideThumb'>
                        <Swiper
                            slidesPerView={6}
                            spaceBetween={5}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                        >
                            {filteredItems.map((item, ind) =>{ 
                                return <SwiperSlide key={ind}>
                                    <div className="row-item">
                                        <div className="item-header">
                                            {svg.app.batchBlue}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </div>
                  </div>
                </div>
                <div className='mc_whiteBox mt-4 p-5'>
                  <div className='flex items-center'>
                    <span className='mr-2'>
                      {/* {svg.app.usersIcon} */}
                      <img src='/assets/images/userFollow.png' alt='img'/>
                    </span>
                    <p className='text-sm text-black text-medium font-bold'>My Latest Followers</p>
                  </div>
                  <div className='mc_profileList'>
                    <ul className='flex mt-3'>
                        <li className='mx-1'>
                            <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                        </li>
                        <li className='mx-1'>
                            <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                        </li>
                        <li className='mx-1'>
                            <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                        </li>
                        <li className='mx-1'>
                            <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                        </li>
                        <li className='mx-1'>
                            <a href={() => false} className='mc_circleDark rounded-full flex items-center justify-center' onClick={()=> setFollowersModal(true)}>
                                15+
                            </a>
                        </li>
                    </ul>
                  </div>
                </div>
              </form>
            {/* Center Post Data */}
              <div className="xl:col-span-2 lg:col-span-2 mc_moboilCol_12">
                <div className='mc_formWrapper'>
                  <Uploadpost profilePic={'/assets/images/customer1.jpeg'} onSubmitData={''} postContent={'fdgdgdf'} fileType={state.upLoadFile} postData={state.postDataFilter} />
                </div>
                {state.createPost == true && 
                  <Createpost onCreate={(e)=>createPostData(e, state.postId+1)} postData={state.postDataFilter} />
                }
                <div id="scrollerPosts" 
                  // onScroll={(e)=>fetchPostData(e)} 
                  // className="overflow-y-scroll" 
                >
                
                    {state.postDataFilter?.map((postData, postIndex)=>{
                        if (state.postDataFilter.length == postIndex + 1) {
                          return (
                            //referencing the last item to be watched by the observer
                            <Skeleton.Node  loading={loading} active={active} shape={'circle'} block={''} size={'large'}>
                              <div key={postIndex} className='w-full'>
                                <div className='mc_postData_dv'>
                                  <div className='flex flex-wrap p-5 relative'>
                                    <TEDropdown className='mc_dropdown_wrapper'>
                                      <TEDropdownToggle className="mc_dotIcon_btn">
                                        <span>{svg.app.dotsIcon}</span>
                                      </TEDropdownToggle>

                                      <TEDropdownMenu className='mc_dropdownMenu'>
                                        <TEDropdownItem>
                                          <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                            <span className='mr-2'>{svg.app.editIcon}</span> Edit Post
                                          </Link>
                                        </TEDropdownItem>
                                        <TEDropdownItem>
                                          <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                            <span className='mr-2'>{svg.app.deleteIcon}</span>  Delete Post
                                          </Link>
                                        </TEDropdownItem>
                                        <TEDropdownItem>
                                          <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                            <span className='mr-2'>{svg.app.pinIcon}</span> Pin Post
                                          </Link>
                                        </TEDropdownItem>
                                      </TEDropdownMenu>
                                    </TEDropdown>
                                    <div className='mc_formImg_dv'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='customer' className='rounded-full mr-4' width={'42px'} height={'42px'}/>
                                    </div>
                                    <div className='mc_userDetail'>
                                      <h6>{postData.userName}</h6>
                                      <p className='flex text-xs'>230 Followers | 300 Posts <span className='ml-3 mc_clockGray mr-1'>{svg.app.clockIcon}</span><span className='text-gray-400'> 0 mnts ago</span></p>
                                    </div>
                                  </div>
                                  <div className='flex flex-wrap px-5 py-2'>
                                    <h5 className='text-sm text-gray-900 font-bold mb-4'>{postData.postTitle}</h5>
                                    <p className='text-sm w-full mb-5'>{postData.postContent}</p>
                                    <div className='w-full'>
                                      <div className='mc_audioBox'>
                                        <div className='mc_AudioDv'>
                                          <div className='mc_innnerPlay_dv'>
                                              <span className='center'>{svg.app.audioBgIcon}</span>
                                              <a href={() => false} className='mc_playCenter_icon' onClick={()=> setMyState(setQuery,{
                                                ...state,
                                                isPlay :!state.isPlay
                                              })}>

                                                  {svg.app.VideoIcon}
                                              </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_postData_dv_list p-5'>
                                  <div className='flex flex-wrap items-center justify-between w-full'>
                                    <div className='icons_holder'>
                                      <a href={() => false} className='mc_iconList mr-8 flex items-center text-gray-500'
                                        onClick={()=>{
                                          let data = [...state.postDataFilter];
                                          data[postIndex].postLike = (data[postIndex].postLike == false ? true : false);
                                          console.log(data[postIndex], 'data[i].postDataFilter');
                                          setMyState(setQuery,{
                                              ...state,
                                              postDataFilter : data,
                                          })
                                          // setLikePost((previousVal) => previousVal + 1)
                                        }}
                                      >
                                        {postData.postLike == true ? 
                                          <span className={`mr-2 mc_true`}>{svg.app.darkLikeIcon}</span> 
                                        :
                                          <span className='mr-2'>{svg.app.likeIcon}</span> 
                                        }
                                          Like
                                      </a>
                                      <a href={() => false} className={`mc_iconList flex items-center mr-8 text-gray-500 ${state.postId == postIndex && state.show ? 'isActive_list' : ''}`} onClick={(e)=>toggleShow(e, postIndex)}>
                                          <span className='mr-2'>{svg.app.replyIcon}</span> 
                                          Comment
                                      </a>
                                    </div>
                                    <p className='text-gray-500 text-sm font-medium'>100 Likes | 0 Comments</p>
                                  </div>
                                  <div className='flex w-full items-center mc_commentBox_dv'>
                                      <TECollapse show={(state.postId == postIndex) ? state.show : ''} className=' w-full' >
                                          <CommentsData post={postData} />
                                      </TECollapse>
                                  </div>
                                </div>
                                
                              </div> 
                            </Skeleton.Node>
                          );
                        } else {
                          return (
                            <Skeleton.Node  loading={loading} active={active} shape={'circle'} block={''} size={'large'}>
                              <div key={postIndex} className='w-full'>
                                <div className='mc_postData_dv mc_birthDay_modal'>
                                  <div className='flex flex-wrap p-5 relative'>
                                    <TEDropdown className='mc_dropdown_wrapper'>
                                      <TEDropdownToggle className="mc_dotIcon_btn">
                                        <span>{svg.app.dotsIcon}</span>
                                      </TEDropdownToggle>

                                      <TEDropdownMenu className='mc_dropdownMenu'>
                                        <TEDropdownItem>
                                          <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                            <span className='mr-2'>{svg.app.editIcon}</span> Edit Post
                                          </Link>
                                        </TEDropdownItem>
                                        <TEDropdownItem>
                                          <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                            <span className='mr-2'>{svg.app.deleteIcon}</span> Delete Post
                                          </Link>
                                        </TEDropdownItem>
                                        <TEDropdownItem>
                                          <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                            <span className='mr-2'>{svg.app.pinIcon}</span> Pin Post
                                          </Link>
                                        </TEDropdownItem>
                                      </TEDropdownMenu>
                                    </TEDropdown>
                                    <div className='mc_formImg_dv'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='customer' className='rounded-full mr-4' width={'42px'} height={'42px'}/>
                                    </div>
                                    <div className='mc_userDetail'>
                                      <h6>{postData.userName}</h6>
                                      <p className='flex text-xs'>230 Followers | 300 Posts <span className='ml-3 mc_clockGray mr-1'>{svg.app.clockIcon}</span><span className='text-gray-400'> 0 mnts ago</span></p>
                                    </div>
                                  </div>
                                  <p className='text-sm px-5'>Congrats for your course completion <span className='color-themeColor font-medium'>Vijayaragavan Ramachari</span></p>
                                  
                                  {/* Congratulations Post Data Here */}
                                  <div className='userCongratPost flex items-center justify-center flex-wrap'>
                                    <div className='userImg_holder w-full text-center'>
                                      <img src={'/assets/images/customer1.jpeg'} alt="img" width={'103px'} height={'103px'} className='mb-12 rounded-full'/>
                                    </div>
                                    <div className='mc_br_img  '>
                                      <div className='mc_textHolde_mod'>
                                        <h3>Congratulations</h3>
                                        <p>For your course completion</p>
                                      </div>
                                      <div className='mc_partyImg_holder'>
                                        <img src={'/assets/images/party.png'} alt='birthdaye'/>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Birthday Post Data Here */}
                                  {/* <div className='userCongratPost flex items-center justify-center flex-wrap'>
                                    <div className='userImg_holder w-full text-center'>
                                      <img src={'/assets/images/customer1.jpeg'} alt='customer' width={'103px'} height={'103px'} className='mb-12 rounded-full'/>
                                    </div>
                                    <div className='mc_br_img  '>
                                      <div className='mc_textHolde_mod'>
                                        <img src={'/assets/images/birthday.png'} alt='birthdaye'/>
                                      </div>
                                    </div>
                                  </div> */}

                                  {/* Audio Post Data Here */}
                                  {/* <div className='flex flex-wrap px-5 py-2'>
                                    <h5 className='text-sm text-gray-900 font-bold mb-4'>{postData.postTitle}</h5>
                                    <p className='text-sm w-full mb-5'>{postData.postContent}</p>
                                    <div className='w-full'>
                                      <div className='mc_audioBox'>
                                        <div className='mc_AudioDv'>
                                          <div className='mc_innnerPlay_dv'>
                                              <span className='center'>{svg.app.audioBgIcon}</span>
                                              <a href={() => false} className='mc_playCenter_icon' onClick={()=> setMyState(setQuery,{
                                                ...state,
                                                isPlay :!state.isPlay
                                              })}>
        
                                                  {svg.app.VideoIcon}
                                              </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}

                                  {/* Video Post Data Here */}
                                  {/* <div className='flex flex-wrap px-5 py-2'>
                                    <h5 className='text-sm text-gray-900 font-bold mb-4'>{postData.postTitle}</h5>
                                    <p className='text-sm w-full mb-5'>{postData.postContent}</p>
                                    <div className='w-full'>
                                      <div className='mc_videoPostdv'>
                                        <video className={'mc_videoComponent'} src="/assets/video/demo.mp4" type="video/mp4"></video>
                                        <span className='mc_whiteCircle'>{svg.app.videoPostPlay}</span>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                <div className='mc_postData_dv_list p-5'>
                                  <div className='flex flex-wrap items-center justify-between w-full'>
                                    <div className='icons_holder'>
                                      <a href={() => false} className='mc_iconList mr-8 flex items-center text-gray-500'
                                        onClick={()=>{
                                          let data = [...state.postDataFilter];
                                          data[postIndex].postLike = (data[postIndex].postLike == false ? true : false);
                                          console.log(data[postIndex], 'data[i].postDataFilter');
                                          setMyState(setQuery,{
                                              ...state,
                                              postDataFilter : data,
                                          })
                                          // setLikePost((previousVal) => previousVal + 1)
                                        }}
                                      >
                                        {postData.postLike == true ? 
                                          <span className={`mr-2 mc_true`}>{svg.app.darkLikeIcon}</span> 
                                        :
                                          <span className='mr-2'>{svg.app.likeIcon}</span> 
                                        }
                                          Like
                                      </a>
                                      <a href={() => false} className={`mc_iconList flex items-center mr-8 text-gray-500 ${state.postId == postIndex && state.show ? 'isActive_list' : ''}`} onClick={(e)=>toggleShow(e, postIndex)}>
                                          <span className='mr-2'>{svg.app.commentIcon}</span> 
                                          Comment
                                      </a>
                                    </div>
                                    <a href={() => false} className='mc_iconList flex items-center mr-8 text-gray-500'>
                                        <span className='mr-2'>{svg.app.sendPostIcon}</span> 
                                        Send
                                    </a>
                                    <p className='text-gray-500 text-sm font-medium'>100 Likes | 0 Comments</p>
                                  </div>
                                  <div className='flex w-full items-center mc_commentBox_dv'>
                                      <TECollapse show={(state.postId == postIndex) ? state.show : ''} className=' w-full' >
                                          <CommentsData post={postData}/>
                                      </TECollapse>
                                  </div>
                                </div>
                              </div> 
                            </Skeleton.Node>
                          );
                        }
                    })}
                      {/* {isFetching && <p>Fetching items...</p>} 
                      {state.postDataFilter?.map((postData, postIndex)=>{
                        
                          return (
                            <Skeleton.Node loading={loading} active={active} shape={'circle'} block={''} size={'large'}>
                              <div key={postIndex} className='w-full'>
                                <div className='mc_postData_dv'>
                                  <div className='flex flex-wrap p-5'>
                                    <div className='mc_formImg_dv'>
                                      <img src={'/assets/images/customer1.jpeg'} className='rounded-full mr-4' width={'42px'} height={'42px'}/>
                                    </div>
                                    <div className='mc_userDetail'>
                                      <h6>{postData.userName}</h6>
                                      <p className='flex text-xs'>230 Followers | 300 Posts <span className='ml-3 mc_clockGray mr-1'>{svg.app.clockIcon}</span><span className='text-gray-400'> 0 mnts ago</span></p>
                                    </div>
                                  </div>
                                  <div className='flex flex-wrap px-5 py-2'>
                                    <h5 className='text-sm text-gray-900 font-bold mb-4'>{postData.postTitle}</h5>
                                    <p className='text-sm w-full mb-5'>{postData.postContent}</p>
                                    <div className='w-full'>
                                      <div className='mc_audioBox'>
                                        <div className='mc_AudioDv'>
                                          <div className='mc_innnerPlay_dv'>
                                              <span className='center'>{svg.app.audioBgIcon}</span>
                                              <a href={() => false} className='mc_playCenter_icon' onClick={()=> setMyState(setQuery,{
                                                ...state,
                                                isPlay :!state.isPlay
                                              })}>

                                                  {svg.app.VideoIcon}
                                              </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mc_postData_dv_list p-5'>
                                  <div className='flex flex-wrap items-center justify-between w-full'>
                                    <div className='icons_holder'>
                                      <a href={() => false} className='mc_iconList mr-8 flex items-center text-gray-500'
                                        onClick={()=>{
                                          let data = [...state.postDataFilter];
                                          data[postIndex].postLike = (data[postIndex].postLike == false ? true : false);
                                          // console.log(data[postIndex], 'data[i].postDataFilter');
                                          setMyState(setQuery,{
                                              ...state,
                                              postDataFilter : data,
                                          })
                                          // setLikePost((previousVal) => previousVal + 1)
                                        }}
                                      >
                                        {postData.postLike == true ? 
                                          <span className={`mr-2 mc_true`}>{svg.app.darkLikeIcon}</span> 
                                        :
                                          <span className='mr-2'>{svg.app.likeIcon}</span> 
                                        }
                                          Like
                                      </a>
                                      <a href={() => false} className='mc_iconList flex items-center mr-8 text-gray-500' onClick={(e)=>toggleShow(e, postIndex)}>
                                          <span className='mr-2'>{svg.app.replyIcon}</span> 
                                          Comment
                                      </a>
                                    </div>
                                    <p className='text-gray-500 text-sm font-medium'>100 Likes | 0 Comments</p>
                                  </div>
                                  <div className='flex w-full items-center mc_commentBox_dv'>
                                      <TECollapse show={(state.postId == postIndex) ? state.show : ''} className=' w-full' >
                                          <CommentsData post={postData}/>
                                      </TECollapse>
                                  </div>
                                </div>
                                
                              </div>   
                            </Skeleton.Node>                   
                          )
                        })
                      } */}

                  
                </div>
                <div className="spinner-border text-info mt-3" role="status" id='spinner'>
                  {/* Loading  */}
                  <span className="sr-only"></span>
                  <div className="dot-pulse mr-8"></div>
                  <div className="dot-pulse"></div>
                </div>
              </div>
            {/* Right Box Data */}
              <div className="mc_strikyBox_wrap mc_grigBox lg:col-span-1 xl:col-span-1 mc_moboilCol_12">
                
                <div className='mc_whiteBox'>
                  <div className='mc_holder p-5'>
                    <div className='flex items-center'>
                      <span className='mr-2'>
                        {/* {svg.app.usersIcon} */}
                        <img src='/assets/images/userFollow.png' alt='img'/>
                      </span>
                      <p className='text-sm text-black text-medium font-bold '>24 Course Completion</p>
                    </div>
                    <div className='mc_profileList'>
                      <ul className='flex mt-3'>
                          <li className='mx-1'>
                              <a href={()=> false}>
                                  <img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/>                                
                              </a>
                          </li>
                          <li className='mx-1'>
                              <a href={()=> false}>
                                  <img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/>                                
                              </a>
                          </li>
                          <li className='mx-1'>
                              <a href={()=> false}>
                                  <img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/>                                
                              </a>
                          </li>
                          <li className='mx-1'>
                              <a href={()=> false}>
                                  <img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/>
                              </a>
                          </li>
                          <li className='mx-1'>
                              <a href={() => false} className='mc_circleDark rounded-full flex items-center justify-center'>
                                  15+
                              </a>
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div className='py-5 text-center mc_dotedBorder_top'>
                    <p className='text-black text-sm px-12'>Say, <span className='color-themeColor font-semibold'>Congrats</span> for course completed members & <b className='font-bold'>Earn 2 Points</b></p>
                    <div className='mc_btnHolder mt-5 px-5'>
                      <a href={() => false} className='mc_transparent_btn text-center px-5 w-full font-semibold text-sm text-black' onClick={()=>setBirthDayModal(true)}>
                        Wish & Earn Points
                      </a>
                    </div>
                  </div>
                </div>
                <ActiveUsers  activeUser={'12 Birthday'} userName={'Meera Doen' } followers={'100 Members'}/>
            </div>
          </div>
        </div>
      </div>
      {/* <BirthDay Modal/>    */}
      <TEModal show={birthDayModal} setShow={setBirthDayModal} className='mc_birthDay_modal'>
        <TEModalDialog centered className='mc_modalDialog'>
            <TEModalContent className='mc_modalContent'>
                <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setBirthDayModal(false)} aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </a>
                {/* <!--Modal body--> */}
                <TEModalBody className='mc_paddZero'>
                    <div className='mc_modalBody'>    
                        <div className='mc_br_img'>
                          <img src={'/assets/images/birthday.png'} alt='birthday'/>
                        </div>
                        <div className='flex items-center justify-center mc_formList'>
                          <span>
                            <img src={'/assets/images/customer1.jpeg'} alt='customer' width={'70px'} height={'70px'} className='rounded-full'/>
                          </span>
                          <div className='ml-4'>
                            <label className='w-full text-gray-800 font-semibold text-sm'>Meera Jasmine</label>
                            <input type='text' placeholder='Wish him on timeline (Optional)' className='mc_input' />
                          </div>
                        </div>
                        <div className='mt-5'>
                          <a href={() => false} className='mc_btn mc_courseBtn text-sm font-medium text-center'>Post Your Wish & Earn 2 Points</a>
                        </div>
                    </div>
                </TEModalBody>
            </TEModalContent>
        </TEModalDialog>
      </TEModal>

      {/* <BirthDay Modal/>    */}
      {/* <TEModal show={congratulationsModal} setShow={setCongratulationsModal} className='mc_birthDay_modal mc_modalCongratulations'>
        <TEModalDialog centered className='mc_modalDialog'>
            <TEModalContent className='mc_modalContent'>
                <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setCongratulationsModal(false)} aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </a>
                <TEModalBody className='mc_paddZero'>
                    <div className='mc_modalBody'>    
                        <div className='mc_br_img p-0'>
                          <div className='formImg_congrat w-full v1'>
                            <img src={'/assets/images/congratulatiionsV2.png'} alt='img' className='mx-auto'/>
                            // <p>For your course completion</p> 
                          </div>
                          <div className='mc_textHolde_mod'>
                             <h3>Congratulations</h3> 
                          </div>

                          <img src={'/assets/images/party.png'} alt='birthday'/> 
                        </div>
                        <div className='flex items-center justify-center mc_formList'>
                          <span>
                            <img src={'/assets/images/customer1.jpeg'} alt='customer' width={'70px'} height={'70px'} className='rounded-full'/>
                          </span>
                          <div className='ml-4'>
                            <label className='w-full text-gray-800 font-semibold text-sm'>Meera Jasmine</label>
                            <input type='text' placeholder='Wish him on timeline (Optional)' className='mc_input' />
                          </div>
                        </div>
                        
                        <div className='mt-5'>
                            <a href={() => false} className='mc_btn mc_courseBtn text-sm font-medium text-center'>Do Congrats & Earn 2 Points</a>
                          </div>
                    </div>
                </TEModalBody>
            </TEModalContent>
        </TEModalDialog>
      </TEModal> */}


      {/* <!-- Modal  --> */}
      <TEModal show={followersModal} setShow={setFollowersModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog mc_modalShort'>
                <TEModalContent className='mc_modalContent'>
                    <TEModalHeader className='mc_modalHeader bg-white'>
                        {/* <!--Modal title--> */}
                        <div className='mc_moadlHeaderImage'>
                            {/* <img src={'/assets/images/batchIcon.png'} alt='unlock' width={'70px'} height={'70px'}/> */}
                            {/* {svg.app.greenTickCircle} */}
                            <h5 className="text-base font-semibold leading-normal text-black ml-2">
                                My latest followers [22]
                            </h5>
                        </div>
                    </TEModalHeader>
                      <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setFollowersModal(false)} aria-label="Close">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                          </svg>
                      </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                    <div className='mc_modalBody'>
                        <LatestFollowers className={'mc_followers_Comp'} followData={state.followersData} />
                    </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    </>
  )
}

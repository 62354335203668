import React, {useEffect, useState} from 'react'
import svg from '../components/svg'
import { common, setMyState, validatePassword, dataLoader } from '../components/Comman';
import { appStore } from '../zustand/store'
import Cookies from "js-cookie";
import { toast } from "react-toastify";

function ChangePassword() {
    const slugName = window.location.href.split('/')[4];

    let myStore = appStore(state => state);
    let userData = myStore.userData.MembershipUser;
  // const userNamedata = Object.keys(userData.MembershipUser.name)
  let appData = myStore.appData;
  let brandSetting = myStore.brandSetting;

    const urlParams = new URLSearchParams(window.location.search);
    const urlParamData = urlParams.get('memberId');
    const decoded=atob(urlParamData);
    const decodedData = JSON.parse(decoded)
    const myParam = decodedData.fId;
    let tokenCookie = Cookies.get(`access_token_${slugName}`)
    ? Cookies.get(`access_token_${slugName}`)
    : false;
  

    const [state, setQuery] = useState({
        password : '',
        newPassword : '',
        confirmPassword : '',
        buttonColor : null,
      })
    let getColor1 =(type)=>{
        setMyState(setQuery,{buttonColor : type})
    }
    let getColor2 =(type)=>{
        setMyState(setQuery,{buttonColor : null})
    }
    // let getColorUpdate1=(e)=>{
    //   setMyState(setQuery,{themeColor1 : brandSetting.theme_color2})
    // }
    // let getColorUpdate2=(e)=>{
    //   setMyState(setQuery,{themeColor1 : brandSetting.theme_color1})
    // }
    let [error , setErrors ] = useState({}); 
    const[showPassword,setShowPassword] = useState({  
        password : false,
        newPassword : false,
        confirmPassword : false
    })

    const handleSubmit = async(e) =>{
        let passwordRegex = /^(?=.*[a-z])(?=.*[0-9\W]).{8,}$/;
        e.preventDefault();
        const errorsMess = {};
        // if(!state.password.length){ 
        //         errorsMess.password = 'Current password field is required.'
        // }
        //  if(!state.newPassword.length){
        //     errorsMess.newPassword = 'New password field is required.'
        // }else{
        //     if(!validatePassword(state.newPassword)){
        //         errorsMess.newPassword = 'Invalid password.'
        //     }
        // }
        //  if(!state.confirmPassword.length){
        //     errorsMess.confirmPassword = 'Confirm password field is required.'
        // }
        // const containAllValue = Object.values(state).every(value => value)
       
        // if(containAllValue){ 
        //     if(state.confirmPassword !== state.newPassword){
        //         errorsMess.confirmPassword = 'Both new password and confirm pasword should be same.'
        //     }
        // }
        
        if (Object.keys(errorsMess).length > 0) {   
            setErrors(errorsMess);
            return false
          } else {
           
            setErrors({}); 
             common.getAPI({
                isLoader : true,
                url : 'changepassword',
                method : 'POST',
                data : {member_id  :userData.id,
                        currentpassword : state.password,
                        newpassword: state.newPassword,
                        confirmnewpassword:state.confirmPassword ,
                        funnel_id :userData.funnel_id,
                        token: tokenCookie
                }
            }, (resp) => { 
                // console.log(resp, 'Responce');
                if(resp.success){
                    setMyState(setQuery, {password : '', newPassword : '',confirmPassword : '',});
                    toast.success("Password Updated Successfully.");
                }else if(resp?.currentpassword?.length){
                    toast.error(resp.currentpassword[0]);
                }else if(resp?.newpassword?.length){
                    toast.error(resp.newpassword[0]);
                }else if(resp?.confirmnewpassword?.length){
                    toast.error(resp.confirmnewpassword[0]);
                }else {
                    toast.error(resp.message);
                }
            });
	    }
    }
 
  return (
    <>

        <div className='mc_pageScroll_dv mc_breadCum_page'>
            <div className="max-container mb-5 w-height-vh">
                {/* <div className="mx-full w-full px-0 py-5 sm:px-0 sm:py-8 lg:px-0">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-900 mb-0">Profile Page</h2>
                </div> */}
                <div className="grid mc_grigBox_holder mc_mobileRow mb-5 mt-8">
                    <div className="mc_changePassword_box">
                        <div className='mc_changePassword_InnerDv'> 
                            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-5 mt-5">
                                <span className="mc_keyPageIcon" style={{fill: brandSetting.theme_color2}}>{svg.app.passwordIcon}</span>
                                <span className="tracking-wide">Change Password</span>
                            </div>
                            {/* <h3 className='text-base font-medium'>Change Password</h3> */}
                            <div className="grid md:grid-cols-1 text-sm">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-2">
                                        <div className="mt-5 pr-3">
                                            <label className='mc_labelField'>Current Password</label>
                                            <div className='mc_inputHolder relative'>
                                                <input type={showPassword.password ? 'text' : 'password'} value={state.password} className='mc_input' onChange={(e)=> setMyState(setQuery, {password : e.target.value})} />
                                                <span className='mc_fieldIcon' 
                                                    onClick={()=>setShowPassword(prev => ({...prev,password: !prev.password,}))}
                                                >
                                                    {showPassword.password ? svg.app.eyeOpenIcon :svg.app.eyeCloseIcon}
                                                </span>
                                            {error.password && <p className='mc_errortext'>{error.password}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 mt-5">
                                        <div className="mt-5 pr-3">
                                            <label className='mc_labelField'>New Password</label>
                                            <div className='mc_inputHolder relative'>
                                                <input type={showPassword.newPassword ? 'text':'password'} value={state.newPassword} className='mc_input' onChange={(e)=> setMyState(setQuery, {newPassword : e.target.value})}/>
                                                <span className='mc_fieldIcon' onClick={()=>setShowPassword(prev => ({...prev,newPassword: !prev.newPassword,}))}>{showPassword.newPassword ? svg.app.eyeOpenIcon :svg.app.eyeCloseIcon}</span>
                                            {error.newPassword && <p className='mc_errortext'>{error.newPassword}</p>}
                                            </div>
                                        </div>
                                        <div className="mt-5 px-3">
                                            <label className='mc_labelField'>Confirm Password</label>
                                            <div className='mc_inputHolder relative'>
                                                <input type={showPassword.confirmPassword ? 'text':'password'} value={state.confirmPassword} className='mc_input' onChange={(e)=> setMyState(setQuery, {confirmPassword : e.target.value})}/>
                                                <span className='mc_fieldIcon' onClick={()=>setShowPassword(prev => ({...prev,confirmPassword: !prev.confirmPassword,}))}>{showPassword.confirmPassword ? svg.app.eyeOpenIcon :svg.app.eyeCloseIcon}</span>
                                            {error.confirmPassword && <p className='mc_errortext'>{error.confirmPassword}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mc_btnHolder_Dvend grid grid-cols-2'>
                                        <div className="">
                                            <div className='mcRequirements'>
                                                <h3 className='text-base font-medium mt-12 mb-3'>Password Recommendations</h3>
                                                <ul>
                                                    <li>Minimum 8 characters long-the more, the better</li>
                                                    <li>Atleast one lowercase character</li>
                                                    <li>Atleast one number and symbol</li>
                                                </ul>
                                            </div>
                                            <button type="submit" className='mc_btn mc_courseBtn' style={{color:brandSetting.theme_button_txt_color, backgroundColor : state.buttonColor == 'Save Changes' ? brandSetting.theme_color2 : brandSetting.theme_color1}} onMouseEnter={()=> getColor1('Save Changes')} onMouseLeave={()=> getColor2('Save Changes')}>Save Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ChangePassword
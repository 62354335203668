'use client';

import React, { useEffect, useState } from 'react'
// import { footerLinks, socialMedia } from "../constants/index";
// import { Link } from 'react-router-dom';
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,

} from "tw-elements-react";
import { setMyState, common } from '../components/Comman';
import { appStore } from '../zustand/store';
import Cookies from "js-cookie";
// import config from '../config';
import svg from '../components/svg';
const Footer = () => {
  // let alfaUrl = config.REACT_APP_BUCKET_URL
  const slugName = window.location.href.split('/')[4];

  let myStore = appStore(state => state);
  let userData = myStore.userData;
  let footData = myStore.footerData;
  // let appData = myStore.appData;
  let brandSetting = myStore.brandSetting;
  const funnelId = userData.MembershipUser.funnel_id;

  // const footSocial = footData.social_media;
  // const footerMedia = JSON.parse(footSocial);

  let tokenCookie = Cookies.get(`access_token_${slugName}`)
    ? Cookies.get(`access_token_${slugName}`)
    : false;

  const [state, setQuery] = useState({
    socialMedia: {},
    privacytitle: '',

    themeColor: '#fff',
    themeColor1: '#fff',
    socialBgColor: null,
    textColor: null,
  })
  // let getColor1 = (e) => {
  //   setMyState(setQuery, { themeColor: brandSetting.theme_coorl1 })
  // }
  // let getColor2 = (e) => {
  //   setMyState(setQuery, { themeColor: '#fff' })
  // }
  // let getColorUpdate1 = (e) => {
  //   setMyState(setQuery, { themeColor1: brandSetting.theme_color1 })
  // }
  // let getColorUpdate2 = (e) => {
  //   setMyState(setQuery, { themeColor1: '#fff' })
  // }
  let getSocialBgColor = (type) => {
    setMyState(setQuery, {
      ...state,
      socialBgColor: type,
    })
  }
  let getSocialBgColor2 = (type) => {
    setMyState(setQuery, {
      ...state,
      socialBgColor: null,
    })
  }
  
  const getFooterData = () => {
    let footerData = {
      funnel_id: funnelId,
      token: tokenCookie,
    }
    common.getAPI({
      method: "Post",
      url: "get-footer",
      data: footerData,
    }, (resp) => {
      if (resp.success == true) {
        let footerSetting = resp.footer_settings;
        // let footerSocial = resp.social_media;
        let footerSettingData = JSON.parse(footerSetting)
        myStore.updateStoreData('footerData', footerSettingData);
        // setMyState(setQuery, {
        //   socialMedia : footerMedia,
        // })
        // let tempContainer = (showModal == true) ? footerSettingData?.privacytxt : (showModalTerms == true ? footerSettingData?.tandctxt : '');
        
        let tempContainer = document.getElementById("footerHtml");
        tempContainer.innerHTML = footerSettingData?.tandctxt.html;
        let scriptTags = tempContainer.find('a');
        
        if (scriptTags.style?.color) {
          
        } else{
          scriptTags.style.color=brandSetting.theme_color2
        }
      }
    });
  }
  useEffect(() => {
    getFooterData()
  }, [])

  const [showModal, setShowModal] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);

  

  useEffect(()=>{
    let style=document.createElement("style")
    style.innerHTML=`.footerHtml a{
      color : ${brandSetting.theme_color2};
    }`
    document.head.append(style)
  },[])
  return (
    <>
      {/* <footer className='max-container pb-24'>
          <div className='grid grid-cols-1 justify-between gap-4 md:grid-cols-2'>
            <div className='grid grid-cols-1 justify-between gap-4 md:grid-cols-1'>
              <h5 className='text-md font-semibold mb-3 text-white'>About</h5>
              <p className='text-white text-sm leading-7 font-montserrat text-white-400 sm:max-w-xl font-medium'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              </p>
              <a href={()=> false} className='mc_footbtn'>Read More</a>
            </div>

            <div className='grid grid-cols-3 justify-between gap-4'>
              {footerLinks.map((section, i) => {
                return <div key={i}>
                  <h4 className='text-white font-montserrat text-lg leading-normal font-medium mb-6 text-white'>
                    {section.title}
                  </h4>
                  <ul>
                    {section.links.map((link , j) => {
                      return <li
                          className='text-white mt-3 font-montserrat text-base leading-normal text-white-400 hover:text-slate-gray'
                          key={j}
                        >
                          <Link to={link.link} className='text-white-400 text-sm '>{link.name}</Link>
                        </li>
                      }
                    )}
                  </ul>
                </div>
                }
              )}
            </div>
          </div>

        </footer> */}


      <div className='footerDv_fix'>
        <div className='text-white-400 max-sm:flex-col max-sm:items-center mc_lightDark' style={{ backgroundColor: brandSetting.theme_color2 }}>
          <div className='flex justify-between flex-wrap w-full max-container py-3 bg-black-800'>
            <div className={`flex flex-1 flex-wrap justify-${(footData.insta_url || footData.fb_url || footData.x_url) ? 'start' : 'center'} items-center align-center gap-2 font-montserrat`}>
              <div className='text-white font-montserra w-full md:w-auto md:flex sm:w-auto lg:w-auto lg:items-center lg:flex'>

                <ul className='flex gap-2 mb-3 md:mb-0 lg:mb-0 sm:mb-0 xl:mb-0'>
                  <li className='pr-4'>{footData.copyright_text}</li>
                  
                  {/* <li className='px-3 h-4 hover:text-themeColor'>
                          <Link to="/help">Help</Link>
                        </li>
                        <li>|</li> */}
                  {footData.privacy_status == true && <>
                    <li className='pl-4 '>|</li>
                    <li className='px-3 h-4 hover:text-themeColor'>
                      <a href={() => false} onClick={() => setShowModal(true)} style={{ color: `${state.socialBgColor == footData.privacytitle ? brandSetting.theme_color1 : '#fff'}` }} onMouseEnter={(e) => getSocialBgColor(footData.privacytitle)} onMouseLeave={(e) => getSocialBgColor2(footData.privacytitle)}>{footData.privacytitle}</a>
                    </li>
                    </>
                  }
                  {footData.terms_status == true && <>
                    <li>|</li>
                    <li className='px-3 h-4 hover:text-themeColor'>
                      <a href={() => false} onClick={() => setShowModalTerms(true)} style={{ color: `${state.socialBgColor == footData.tandctitle ? brandSetting.theme_color1 : '#fff'}` }} onMouseEnter={(e) => getSocialBgColor(footData.tandctitle)} onMouseLeave={(e) => getSocialBgColor2(footData.tandctitle)}>{footData.tandctitle}</a>
                    </li>
                    </>
                  }
                </ul>
              </div>
            </div>
            {(footData.insta_url || footData.fb_url || footData.x_url) && 
              <div className='text-white font-montserrat sm:w-auto lg:w-auto lg:items-center lg:flex '>
                <div className='flex items-center gap-2 mc_socialIcon'>
                  {footData.fb_url &&
                    <a href={footData.fb_url} target="_blank" className='flex justify-center items-center w-8 h-8 bg-white rounded-full' style={{ fill: `${state.socialBgColor == 'Facebook' ? '#fff' : brandSetting.theme_color2}`, backgroundColor: `${state.socialBgColor == 'Facebook' ? brandSetting.theme_color1 : '#fff'}` }} onMouseEnter={(e) => getSocialBgColor('Facebook')} onMouseLeave={(e) => getSocialBgColor2('Facebook')}>
                      {/* <img src={`${alfaUrl}/images/facebook.svg`} alt={'Facebook'} width={16} height={16} /> */}
                      {svg.app.facebookIcon}
                    </a>
                  }
                  {footData.x_url &&
                    <a href={footData.x_url} target="_blank" className='flex justify-center items-center w-8 h-8 bg-white rounded-full' style={{ fill: `${state.socialBgColor == 'Twitter' ? '#fff' : brandSetting.theme_color2}`, backgroundColor: `${state.socialBgColor == 'Twitter' ? brandSetting.theme_color1 : '#fff'}` }} onMouseEnter={(e) => getSocialBgColor('Twitter')} onMouseLeave={(e) => getSocialBgColor2('Twitter')}>
                      {/* <img src={`${alfaUrl}/images/twitter.svg`} alt={'Twitter'} width={16} height={16} /> */}
                      {svg.app.twitterIcon}
                    </a>
                  }
                  {footData.insta_url &&
                    <a href={footData.insta_url} target="_blank" className='flex justify-center items-center w-8 h-8 bg-white rounded-full' style={{ fill: `${state.socialBgColor == 'Instagram' ? '#fff' : brandSetting.theme_color2}`, backgroundColor: `${state.socialBgColor == 'Instagram' ? brandSetting.theme_color1 : '#fff'}` }} onMouseEnter={(e) => getSocialBgColor('Instagram')} onMouseLeave={(e) => getSocialBgColor2('Instagram')}>
                      {/* <img src={`${alfaUrl}/images/instagram.svg`} alt={'Instagram'} width={16} height={16} /> */}
                      {svg.app.instagramIcon}
                    </a>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {/* <!--TopRight Sidebar Modal--> */}
      <TEModal show={showModal} setShow={setShowModal} className='mc_modal_wrapper'>
        <TEModalDialog size="xl"
          position="center"
          centered
          theme={{
            show: "translate-x-0 opacity-100",
            hidden: "translate-x-[100%] opacity-0",
          }}
        >
          <TEModalContent>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200 footer_head_text">
                {footData.privacytitle}
              </h5>
            </TEModalHeader>
            <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowModal(false)} aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
            {/* <!--Modal body--> */}
            <TEModalBody className='footerHtml'>
              {/* <h5 className='text-base font-bold mb-4'>Privacy Policy</h5> */}
              <div className='text-sm text-gray-700 mb-4' dangerouslySetInnerHTML={{ __html: footData.privacytxt }}></div>
              {/* <p className='text-sm text-gray-700 mb-4'>Pellentesque lobortis, est vitae imperdiet sagittis, magna odio facilisis elit, et egestas lectus mauris at dui. Donec sodales mollis odio, non sagittis purus iaculis at. Sed a mi imperdiet, convallis arcu in, hendrerit tellus. Nulla commodo eros ut purus aliquam suscipit. Donec blandit eleifend urna quis hendrerit. Proin vehicula sit amet turpis pharetra cursus. Duis bibendum consectetur neque ut dictum. Vivamus sed malesuada velit. Integer id risus elit. Donec varius, urna nec euismod tincidunt, eros mauris efficitur nibh, vitae consequat turpis velit tincidunt lorem. Vestibulum a odio a mi tempus egestas.
                        </p>
                        <p className='text-sm text-gray-700 mb-4'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer non interdum neque. Nulla imperdiet consectetur elit, sit amet pellentesque risus efficitur id. Donec tempor mollis ante, vitae ultrices odio. Nulla iaculis non felis id porta. Aenean id ultrices metus. Pellentesque lobortis, est vitae imperdiet sagittis, magna odio facilisis elit, et egestas lectus mauris at dui. Donec sodales mollis odio, non sagittis purus iaculis at. Sed a mi imperdiet, convallis arcu in, hendrerit tellus. Nulla commodo eros ut purus aliquam suscipit. Donec blandit eleifend urna quis hendrerit. Proin vehicula sit amet turpis pharetra cursus. Duis bibendum consectetur neque ut dictum. Vivamus sed malesuada velit. Integer id risus elit. Donec varius, urna nec euismod tincidunt, eros mauris efficitur nibh, vitae consequat turpis velit tincidunt lorem. Vestibulum a odio a mi tempus egestas.
                        </p> */}
            </TEModalBody>

          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      <TEModal show={showModalTerms} setShow={setShowModalTerms} className='mc_modal_wrapper'>
        <TEModalDialog size="xl"
          position="center"
          centered
          theme={{
            show: "translate-x-0 opacity-100",
            hidden: "translate-x-[100%] opacity-0",
          }}
        >
          <TEModalContent>
            <TEModalHeader >
              {/* <!--Modal title--> */}
              <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200 footer_head_text">
                {footData.tandctitle}
              </h5>
              <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowModalTerms(false)} aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </a>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody  className='footerHtml'>
              {/* <h5 className='text-base font-bold mb-4'>Terms</h5> */}
              <p className='text-sm text-gray-700 mb-4' dangerouslySetInnerHTML={{ __html: footData.tandctxt }}></p>
              {/* <p className='text-sm text-gray-700 mb-4'>Pellentesque lobortis, est vitae imperdiet sagittis, magna odio facilisis elit, et egestas lectus mauris at dui. Donec sodales mollis odio, non sagittis purus iaculis at. Sed a mi imperdiet, convallis arcu in, hendrerit tellus. Nulla commodo eros ut purus aliquam suscipit. Donec blandit eleifend urna quis hendrerit. Proin vehicula sit amet turpis pharetra cursus. Duis bibendum consectetur neque ut dictum. Vivamus sed malesuada velit. Integer id risus elit. Donec varius, urna nec euismod tincidunt, eros mauris efficitur nibh, vitae consequat turpis velit tincidunt lorem. Vestibulum a odio a mi tempus egestas.
                        </p>
                        <p className='text-sm text-gray-700 mb-4'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer non interdum neque. Nulla imperdiet consectetur elit, sit amet pellentesque risus efficitur id. Donec tempor mollis ante, vitae ultrices odio. Nulla iaculis non felis id porta. Aenean id ultrices metus. Pellentesque lobortis, est vitae imperdiet sagittis, magna odio facilisis elit, et egestas lectus mauris at dui. Donec sodales mollis odio, non sagittis purus iaculis at. Sed a mi imperdiet, convallis arcu in, hendrerit tellus. Nulla commodo eros ut purus aliquam suscipit. Donec blandit eleifend urna quis hendrerit. Proin vehicula sit amet turpis pharetra cursus. Duis bibendum consectetur neque ut dictum. Vivamus sed malesuada velit. Integer id risus elit. Donec varius, urna nec euismod tincidunt, eros mauris efficitur nibh, vitae consequat turpis velit tincidunt lorem. Vestibulum a odio a mi tempus egestas.
                        </p> */}
            </TEModalBody>

          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  )
}

export default Footer

import React, { useEffect, useCallback, useState, useRef } from 'react'
import svg from '../components/svg';

import { setMyState, common, dataLoader } from '../components/Comman';
import { appStore } from '../zustand/store';
import Cookies from "js-cookie";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import config from '../config';
// import PDFGenerator from '../components/generatePDF';

function MyPlan() {
  let alfaUrl = config.REACT_APP_BUCKET_URL
  const slugName = window.location.href.split('/')[4];

  const navigate = useNavigate();
  let myStore = appStore(state => state);
  let userData = myStore.userData;
  // const userNamedata = Object.keys(userData.MembershipUser.name)
  let appData = myStore.appData;
  let brandSetting = myStore.brandSetting;

  const userName = userData.MembershipUser.name;
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamData = urlParams.get('memberId');
  const decoded = atob(urlParamData);
  const decodedData = JSON.parse(decoded)
  const myParam = decodedData.fId;

  let tokenCookie = Cookies.get(`access_token_${slugName}`)
    ? Cookies.get(`access_token_${slugName}`)
    : false;

  const [state, setQuery] = useState({
    loader: false,
    funnel_id: myParam,
    myPlansData: [],
    memberId: userData.MembershipUser.id,
    themeColor: brandSetting.theme_color1,
    themeColor1: brandSetting.theme_color2,
    buttonColor: null,
    invoiceData: {},
    codedInvoiceData:{}
  })
  let getColor1 = (e) => {
    setMyState(setQuery, { themeColor: brandSetting.theme_color2 })
  }
  let getColor2 = (e) => {
    setMyState(setQuery, { themeColor: brandSetting.theme_color1 })
  }
  let getColorUpdate1 = (e) => {
    setMyState(setQuery, { themeColor1: brandSetting.theme_color1 })
  }
  let getColorUpdate2 = (e) => {
    setMyState(setQuery, { themeColor1: brandSetting.theme_color2 })
  }
  // const notify = () => toast("Wow so easy!");
  let getButtonColor1 = (type) => {
    setMyState(setQuery, { buttonColor: type })
  }
  let getButtonColor2 = (type) => {
    setMyState(setQuery, { buttonColor: null })
  }

  let [isProcess, setIsProcess] = useState(false);
  let [planLoading, setPlanLoading] = useState(false);
  const getMyPlans = (e) => {
    let myplans = {
      funnel_id: myParam,
      token: tokenCookie,
      member_id : state.memberId
    }
    setPlanLoading(true);
    common.getAPI(
      {
        method: "Post",
        url: "get-myplans",
        data: myplans,
      },
      async (resp) => {
        setPlanLoading(false);
        const orderData = Object.values(resp.orderData);
        // console.log(resp.orderData.data, orderData.data, 'Mypaln Data');

        if (resp.success == true) {
          setMyState(setQuery, {
            ...state,
            // profileData : resp,
            myPlansData: resp.orderData,
          })

        }
      }
    );
    // state.myPlansData
  }
  useEffect(() => {
    getMyPlans();
  }, [])
  const invoiceRef = useRef()

  const getDownloadInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: 'invoice',
    pageStyle: `
      @media print {
        body {
          margin: 0;
        
        }
      }
    `,
  });

 

  return (
    <>
      <div className='mc_pageScroll_dv mc_breadCum_page'>
        <div className="max-container mb-5 w-height-vh">
          {/* <div className="mx-full w-full px-0 py-5 sm:px-0 sm:py-8 lg:px-0">
                <h2 className="text-3xl font-semibold tracking-tight text-gray-900 mb-0">My Plans</h2>
            </div> */}
          <div className="grid mc_grigBox_holder mc_mobileRow mb-5 mt-8">
            <div className="">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-0">
                <span className="mc_keyPageIcon" style={{ fill: `${brandSetting.theme_color2}` }}>
                  {svg.app.myPlansIcon}
                </span>
                <span className="tracking-wide">My Plans</span>
              </div>
              <div className='mc_tables'>
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div className="mc_tableResponsive">
                        <table className="min-w-full text-left text-sm font-light">
                          {/* <thead
                                className="border-b bg-white font-medium dark:border-neutral-500 dark:bg-neutral-600">
                                <tr>
                                  <th scope="col" className="px-6 py-4">#</th>
                                  <th scope="col" className="px-6 py-4">PRODUCT NAME</th>
                                  <th scope="col" className="px-6 py-4">PRICE</th>
                                  <th scope="col" className="px-6 py-4">PURCHASE DATE</th>
                                  <th scope="col" className="px-6 py-4">MEMBEBERSHIP PRODUCT ID</th>
                                  <th scope="col" className="px-6 py-4">INVOICE</th>
                                </tr>
                              </thead> */}
                          <tbody>
                            {!planLoading && state.myPlansData.length > 0 && state.myPlansData.map((items, index) => {
                              // const MyPlanData = JSON.parse(items.data);
                              // console.log(items, MyPlanData, 'MyPlanDataMyPlanData');
                              return (
                                <tr key={index} className="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700">

                                  <td className="whitespace-nowrap px-6 py-4 font-medium">
                                    <span className='mc_tableCount' style={{
                                      color: brandSetting.theme_button_txt_color,
                                      backgroundColor: brandSetting.theme_color2
                                    }} >{index + 1}</span>
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4">
                                    <span className='mc_blockSpan'>PRODUCT NAME</span>
                                    {items.product_title}
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4">
                                    <span className='mc_blockSpan'>PURCHASE DATE</span>
                                    {items.created_at}
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4">
                                    <span className='mc_blockSpan'>MEMBERSHIP PRODUCT ID</span>
                                    {items.membership_product_id}
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4">
                                    <span className='mc_blockSpan'>INVOICE</span>
                                    {items.invoice_id}
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4">
                                    <span className='mc_blockSpan'>PRICE</span>
                                    {items.product_price} {items.currency}
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4">
                                    {/* {items.invoice_id} */}
                                    <a href={void (0)} className='mc_invoiceBtn'
                                      style={{
                                        color: brandSetting.theme_button_txt_color,
                                        fill: brandSetting.theme_color2
                                      }}
                                      onClick={() =>{ 
                                        setMyState(setQuery, { invoiceData: items,codedInvoiceData: JSON.parse(items.data)})
                                        setTimeout(() => getDownloadInvoice(), 2000)
                                        
                                        }}
                                    >
                                      {svg.app.invoiceIcon}
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}

                          </tbody>
                        </table>
                        {planLoading && dataLoader.tableLoader}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display:'none'}}>      
              <div ref={invoiceRef} className='mc_myPlanInvoice max-container'>
                <h3 style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#022031', marginTop: '0px', marginbottom: '0px', width: '100%', lineHeight: 'normal' }}>{state.codedInvoiceData.product_title}</h3>
                  <div className="invoice-container" style={{padding: '30px', margin: '15px auto', maxWidth: '850px', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '6px'}}>
                    <table className="invoiceTable" style={{width: '100%', border: 'none', background: 'none', borderCollapse: 'collapse'}}>
                      <thead>
                        <tr>
                          <th align="left">
                            <img style={{marginBottom: '20px'}} src={brandSetting.logo} width={200} />
                          </th>
                          <th align="right">
                            <h3 style={{color: '#010101', fontSize: '1.75rem', marginTop: '0 !important', marginBottom: '20px !important', fontWeight: 600}}>Invoice</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td align="left" style={{borderTop: '1px solid #e1e1e1', padding: '16px 0px'}}><strong>Date:</strong> {common.dateFormatter(state.invoiceData.created_at, 'DD/MM/YYYY')}</td>
                          <td align="right" style={{borderTop: '1px solid #e1e1e1', padding: '16px 0px'}}><strong>Invoice No :</strong> {state.invoiceData.invoice_id}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} align="left" valign="top" style={{borderTop: '1px solid #e1e1e1', padding: '16px 0px 8px'}}>
                            <p style={{marginBottom: '4px', color: '#010101'}}><strong style={{color: '#010101', fontSize: '1.75rem'}}>Billing info</strong> </p>
                            <p>{state.codedInvoiceData?.data?.first_name} <br />{state.codedInvoiceData?.data?.email}<br />
                            </p>
                            <p style={{marginBottom: '4px', color: '#010101'}}><strong>Membership Product Id :</strong> {state.invoiceData.membership_product_id}</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div className="responsive-table" style={{overflow: 'auto', border: '1px solid #DFDFDF', borderRadius: '4px', marginTop: '10px'}}>
                              <table className="table" width="100%" style={{textAlign: 'left', borderCollapse: 'collapse'}}>
                                <thead>
                                  <tr>
                                    <th style={{color: '#010101', padding: '0.75rem', background: '#F7F7F7', borderBottom: '1px solid #DFDFDF'}}>Description</th>
                                    <th style={{color: '#010101', padding: '0.75rem', background: '#F7F7F7', borderBottom: '1px solid #DFDFDF'}} align="right" />
                                    <th style={{color: '#010101', padding: '0.75rem', background: '#F7F7F7', borderBottom: '1px solid #DFDFDF'}} align="right">Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{background: '#fff', borderBottom: '1px solid #DFDFDF',padding:' 0.75rem',fontSize: '14px'}}>{state.invoiceData?.product_title}</td>
                                    <td align="right" style={{background: '#fff', borderBottom: '1px solid #DFDFDF',padding:' 0.75rem',fontSize: '14px'}}>1 * {state.invoiceData.product_price}</td>
                                    <td align="right" style={{background: '#fff', borderBottom: '1px solid #DFDFDF',padding:' 0.75rem',fontSize: '14px'}}>{state.invoiceData.currency} {state.invoiceData.product_price}</td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={2} align="right" style={{background: '#F7F7F7', borderBottom: '1px solid #DFDFDF',padding:' 0.75rem',fontSize: '14px'}}><strong>Total Amount Payable</strong></td>
                                    <td align="right" style={{background: '#F7F7F7', borderBottom: '1px solid #DFDFDF',padding:' 0.75rem',fontSize: '14px'}}>{state.invoiceData.currency} {state.invoiceData.product_price}</td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>    
          </div>
        </div>

      </div>

    </>
  )
}

export default MyPlan
'use client'
import React, { Fragment, useState,useEffect, useRef} from 'react'
import { Routes, Route, NavLink, useLocation, Link, useNavigate     } from 'react-router-dom';
import { Menu, Transition, Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { common, setMyState } from './Comman'; 
import { navLinks } from "../constants/index";
import svg from "../components/svg";
import { Breadcrumb } from 'antd';
import { appStore } from '../zustand/store';
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalBody,
  TETooltip,
} from "tw-elements-react";

import Home from '../pages/home'
import MyCourses from '../pages/my-courses';
import MyLearnings from '../pages/my-learning';
import AuthPage from '../pages/auth/[auth]';
import AutoLogin from '../pages/auth/autoLogin';

import CourseDetails from '../pages/course-details';
import Community from '../pages/community';
import Footer from '../Sections/footer';
import Webinars from '../pages/webinars';
// import AboutUs from "../pages/about-us";
import Carear from "../pages/carear";
import Blogs from "../pages/blogs";
import Press from "../pages/press";
import Affiliate from "../pages/affiliate";
import Partnership from "../pages/partnership";
import Help from '../pages/help';
import Privacy from '../pages/privacy';
import Terms from '../pages/terms';
// import FreeClasses from "../pages/free-classes";
import Memberships from "../pages/memberships";
import BecomeTeacher from "../pages/become-teacher";
import ReferFreiend from "../pages/refer-friend";
import HelpSupport from "../pages/help-support"
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import ChangePassword from '../pages/change-password';
import MyPlan from '../pages/my-plan';
import PageNotFound from '../pages/404';
import ResetPassword from '../pages/auth/resetpassword';
import config from '../config';
import { toast } from 'react-toastify';
// import ChatEvents from '../pages/chat-events';
import EventLogin from '../pages/events/eventAuth';
import EventProfile from '../pages/events/profile';
import Select from 'react-select'



const Nav = (props, { routes }) => {
  const slugName = window.location.href.split('/')[4];
  // const {slug} = routes.params;
  let alfaUrl = config.REACT_APP_BUCKET_URL
  let myStore = appStore(state => state);
  let userData = myStore.userData;
  const memberShipUser = userData.MembershipUser;
  let appData = myStore.appData;
  let brandSetting = myStore.brandSetting;
  let searchLessonData = myStore.searchLessonData;
  // const urlParams = new URLSearchParams(window.location.search);
  //   const urlParamData = urlParams.get('id');
  //   const urlParamDataToken = urlParams.get('token');
  //   const decoded=atob(urlParamData);
  //   const decodedToken=atob(urlParamDataToken);
  //   console.log(urlParamDataToken, '<= urlParamDataToken =>', urlParamData);


  // console.log(memberShipUser.email, 'userData');
  // const urlParams = new URLSearchParams(window.location.search);
  // const urlParamData = urlParams.get('course');
  // const decoded= atob(urlParamData);
  // const decodedData = urlParamData ? JSON.parse(decoded) : '';

  const decodedData = localStorage.getItem('courseName');
  const myCourse = decodedData ? decodedData : '';

  // const urlParams = new URLSearchParams(window.location.search);
    // const urlParamDataId = urlParams.get('id');
    // const urlParamDataToken = urlParams.get('token');
    // const decodedID=atob(urlParamDataId);
    // const decodedToken=atob(urlParamDataToken);
    // console.log(urlParamDataToken, '<= urlParamDataToken =>', urlParamData);

  // console.log(myCourse, urlParamData, 'myStoremyStoremyStore');
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  // const [isActiveMenu, setIsActiveMenu] = useState(true)

  const location = useLocation();
  const [pathname,setpath]=useState(location.pathname)
  const [showGetInviteModal,setShowGetInviteModal] = useState(false);
  // const [switchMember,setswitchmember]=useState({})
  // const [switchButton,setSwitchButton]=useState(false)
  const[selectedSearchType,setSelectedSearchType] = useState({value:'course' , label:'Course Name'});
  const[searchFocus,setSearchFocus]= useState(false);

  const [state, setQuery] = useState({
    funnel_id: '',
    funnel_page_id: '',
    lesson_id: '',
    member_id: '',
    token : '',
    profileName : '',
    profileEmail : '',
    profile_img: `${alfaUrl}/images/user.png`,
    funnelDetails : {},
    keyword : '',
    pages : '',
    courseData :{},
    search_keyword : '',
    searchApiName : '',
    searchStatus : false,
    menuColor : '#374151',
    hoverMenueColor : brandSetting.theme_color2,
    buttonColor : null,
    inviteCode : '',
    // mobileDevice : false,
  })
  useEffect(()=>{
    setSelectedSearchType({value:'course' , label:'Course Name'});
    setSearchFocus(false);
  },[location?.pathname])
  
  // useEffect(()=>{
  //   let data = localStorage
  //   let d2={}
  //   let currentslug =window.location.href?.split("/")[4]
  //   if(data){
  //     Object.keys(data).forEach((dat,i)=>{
  //       if(dat.includes("course-storage_") && !dat.includes(currentslug)){
  //         if(data[dat]){
  //           let dm = JSON.parse(data[dat])
  //           if(dm?.state?.appData?.name){
  //             let host =window.location.href
  //             let slugNamurl = host.split('/')
  //             slugNamurl[4]=dat.replace("course-storage_",'')
  //             slugNamurl=slugNamurl.join("/")
  //             d2[dm?.state?.appData?.name]=slugNamurl
  //           }
  //         }
  //       }
  //     }
  
  //     )
  //     setswitchmember(d2)
  //   }
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   // console.log(isMobile, 'isMobile');
  //   setMyState(setQuery, {
  //     mobileDevice : isMobile,
  //   })
  // },[location])
  
  // let getColor1 =(e)=>{
  //     setMyState(setQuery,{menuColor : brandSetting.theme_color2})
  // }
  // let getColor2 =(e)=>{
  //     setMyState(setQuery,{menuColor : '#374151'})
  // }
  // let getButtonColor1=(type)=>{
  //   setMyState(setQuery,{buttonColor : type})
  // }
  // let getButtonColor2=(type)=>{
  //   setMyState(setQuery,{buttonColor : null})
  // }

  let tokenCookie = Cookies.get(`access_token_${slugName}`)
  ? Cookies.get(`access_token_${slugName}`)
  : false; 
  {((pathname.includes("/resetpassword") ? false : true) && tokenCookie != '' && pathname == '/' || pathname == '/login') &&
    window.location.replace('dashboard')
  }

  const classNames =(...classes)=> { 
    return classes.filter(Boolean).join(' ')
  }
  const [activeMenu, setActiveMenu] = useState('')
  let body = {
    funnel_id: memberShipUser?.funnel_id,
    member_id: memberShipUser?.id,
    funnel_page_id: memberShipUser?.funnel_page_id,
    token: tokenCookie, 
  };
// const getCourseListData=()=>{
//   setMyState(setQuery,{  pageLoading : true});
//   if(userData.access_token){
//     common.getAPI(
//       {
//         method: "Post",
//         url: "courselist",
//         data: body,
//       },
//       async (resp) => {
//         if(resp.courseData != ''){
//           setMyState(setQuery, {
//             ...state,
//             courseData : resp.courseData,
//             banner_img : resp.banner_img,
//             // updated_date : resp.updated_at,
//             pageLoading : false,
//           })
//         } 
//       }
//       );
//     }
//   }
  // const searchData = {
  //   funnel_id : appData.funnel_id,
  //   member_id : userData?.MembershipUser?.id,
  //   search_name : state.keyword,
  //   token : tokenCookie
  // }
  const getSearchData=(type , searchData ,searchType=null)=>{ 
    let data ={
      search_keyword: searchData,
      apiName: type,
      success: true,
      searchType: searchType ? searchType : selectedSearchType.value
    };
  myStore.updateStoreData("searchList" , data)
    
  }
  useEffect(()=>{
    // getCourseListData();
  },[state.keyword])



  const getProfile=()=>{
    let profiledetails = {
      token: tokenCookie,
    }
    common.getAPI({
      method: "Post",
      url: "profile",
      data: profiledetails,
    },
    (resp) => {
      setMyState(setQuery, {
        ...state,
        // profileData : resp,
        profileName : resp.name,
        profileEmail : resp.email,
        password : resp.password,
        funnel_id : resp.funnel_id,
        profile_img : resp.profile_img,
      })
      // console.log(state.profile_img, 'profile_img');
    });
  }
  const inviteCode = {
    funnel_id : appData.funnel_id,
    token : tokenCookie
  }
  const getInviteCodeData=()=>{
    common.getAPI({
      method: "Post",
      url: "getinvitecode",
      data: inviteCode,
    },
    (resp) => {
      // console.log(resp, 'inviteCode');
      if(resp.success){
        const funnelData = resp.funnel_data[0];
        // console.log(funnelData, 'funnelData');
        setShowGetInviteModal(true)
        setMyState(setQuery, {
          ...state,
          inviteCode : funnelData.funnel_code,
        })
      }
    });
  }

  useEffect(()=>{
    setpath(location.pathname)
    getProfile()
  },[state.profile_img]);
  useEffect(()=>{
    setMyState(setQuery, { 
      profile_img : memberShipUser.profile_img,
    })
  },[myStore]);
  
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [ ])

  const getFunnelUrl=(type, funnelId )=>{
      const myParam = JSON.stringify({fId :funnelId})
      const encoded=btoa(myParam);
      // const decoded=atob(encoded);
      if(type == 'profile'){
        navigate(`/profile?memberId=${encoded}`);
      }else if(type == 'change-password'){
        navigate(`/change-password?memberId=${encoded}`);
      }else if(type == 'my-plan'){
        navigate(`/my-plan?memberId=${encoded}`);
      }
      
  } 


  const Logout=(e)=>{
      let data ={
        membership_device_id : userData?.membership_device_id,
        token: tokenCookie,
      }
      common.getAPI({
        method: "Post",
        url: "logout",
        data: data,
      },
      (resp) => {
        // console.log(resp, 'Response');
        // return
        // Cookies.remove(`access_token_${slugName}`);
        // Cookies.remove(`access_token_${slugName}`,"")
        document.cookie = `access_token_${slugName}= "";`;
        // navigate("/login");
        myStore.updateStoreData('userData' , {});
        myStore.updateStoreData('script' , null);
      });
    
  }
// const match = useMatch(pathname); 
const optionsSearch = [
  {value:'course' , label: 'Course Name' },
  {value:'lesson' , label: 'Lesson Name'}
]
const[menuIsOpen,setMenuIsOpen] = useState(false)
  const newPath = pathname.substring(0, pathname.lastIndexOf('/'));
  const refSelect = useRef();
 
  useOutsideClick(refSelect, () =>{ setMenuIsOpen(false);});

function useOutsideClick(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {

                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },

        [ref, handler]
    );
} 

const handleSingleLessonClick = (val)=>{
  const myParam = JSON.stringify({fId :val.funnel_page_id});
   const encoded=btoa(myParam);
   localStorage.setItem('lessonSearch',val?.lesson_id);
   setMyState(setQuery,{ keyword: ''});
   setSelectedSearchType({value:'course' , label:'Course Name'});
   myStore.updateStoreData("searchLessonData" , []);
   let data ={
     search_keyword: '',
     apiName: '',
     success: true,
     searchType: 'course'
   };
   myStore.updateStoreData("searchList" , data);
   setSearchFocus(false);
   navigate(`/my-learning?course=${encoded}`);
}
  return (<>
  
    <Helmet>
        <link rel="shortcut icon" href={appData.favicon_url} type="image/png/ico" />
    </Helmet>
  {
        
      (tokenCookie != '') && pathname == '/dashboard' ||  pathname == '/my-learning' || pathname == '/profile' || pathname == '/change-password' || pathname == '/my-plan' || newPath == '/dashboard' || newPath == '/membership' || pathname == '/events' || pathname == '/events/profile'
       ?
      <>
      <div className="mc_headerWrapper">
        <header className={`padding-x flex justify-center align-center py-8 z-10 w-full mc_mainHeader ${ location.pathname == '/dashboard' ? 'course_header' : ''}`}>
          <nav className='w-full flex justify-between items-center max-container'>
            <Link to={'/dashboard'} className=" sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 mc_logo">
              <img
                src={brandSetting.logo}
                // src={mainLogo.src}
                alt='logo'
                width={'170px'}
                height={'55px'}
                className='m-0'
              />
            </Link>
            <ul className='sm:hidden lg:flex lg:gap-x-8 mc_mainNav' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              {navLinks.map((item, index) => (
                <li key={index} className={activeMenu == `${item.href}` ? ' mc_activeMenu' : '' } onClick={()=> setActiveMenu(item.href)}>
                  <NavLink to={`${item.href == '/dashboard ' ? '/dashboard' : item.href}`}
                    className={`font-montserrat leading-normal text-sm text-slate-gray font-semibold`}
                    style={{color: `${activeMenu == `${item.href}` || item.href == '/dashboard' ? brandSetting.theme_color2 : brandSetting.theme_color1}`, borderBottom : `2px solid ${activeMenu == item.href || item.href == '/dashboard' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} 
                  >
                    <span>{item.label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className='flex gap-2 text-md leading-normal font-medium font-montserrat'>
              {/* <Link to='/'>Sign in</Link>
              <span>/</span>
              <Link to='/'>Explore now</Link> */}
           
             {location?.pathname == '/dashboard' && <div className="mc_searchBar wide:mr-2">
                <div className='lg:max-w-[100%] w-full flex items-center gap-1 sm:border sm:border-slate-gray rounded-full search_div'>
                {/* max-sm:flex-col  */}
                   <div  ref={refSelect}>
                    <Select
                            options={optionsSearch}
                            className="search-dropdown"
                            classNamePrefix="select"
                            value={selectedSearchType}
                            onChange={(e) =>{
                              setSelectedSearchType(e);
                              getSearchData('searchcourselist',state.keyword,e.value); 
                            }}
                            styles = {{
                              control: base => ({
                                ...base,
                                border: 0,
                                boxShadow: 'none'
                              })
                            }}
                             menuIsOpen={menuIsOpen}
                              onMenuClose={() => setMenuIsOpen(false)}
                              onMenuOpen={() => setMenuIsOpen(true)}
                          />
                   </div>
                  
                    <input type='search' placeholder={selectedSearchType.value == 'course' ? window.innerWidth > 740 ? "Search by course name" : "Search by course" :  window.innerWidth > 740 ? 'Search by lesson name' :'Search by lesson'} className=' w-full w-100 py-2.5 sm:flex-1 max-sm:w-full text-base leading-normal text-slate-gray pl-5 pr-5 max-sm:p-5 outline-none xs:border-none max-sm:border-slate-gray max-sm:rounded-full'
                    onKeyDown={e => {
                      if(e.key === 'Enter' || e.keyCode === 13 || e.which === 13){
                          setMyState(setQuery,{ keyword: e.target.value});
                          getSearchData('searchcourselist',  e.target.value);
                      }
                    }}
                    onFocus={()=>setSearchFocus(true)}
                    onChange={e=>{ getSearchData('searchcourselist',  e.target.value); setMyState(setQuery,{ keyword: e.target.value});}}
                    />
                 
                  { window.innerWidth > 450 ? <div className='flex max-sm:justify-end items-center max-sm:w-full'>
                      <span className="mr-3 searchIcon">{svg.app.searchIcon}</span>
                    </div> : state.keyword ? null : <div className='flex max-sm:justify-end items-center max-sm:w-full'>
                      <span className="mr-3 searchIcon">{svg.app.searchIcon}</span>
                    </div> }
                </div>
                { state.keyword && selectedSearchType.value === 'lesson' && searchFocus && searchLessonData &&   <div className='mc_dropdown_lesson absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <ul className='pt-1'>
                    {
                     searchLessonData.length > 0 ? searchLessonData.map((val,i)=> <li 
                     className={'text-gray-700 py-1  hover:bg-gray-100 text-gray-900'}
                   
                     key={i} onClick={()=>handleSingleLessonClick(val)} ><a className=" block px-4 py-1 text-sm flex items-center mc_dropMenu">{val.lesson_name}</a></li>) : <li className='p-2'>No lesson found.</li>
                    }
                  </ul>
                </div>}
              </div>}
              {/*<a href={() => false} className="mc_notiIcon">
                <Menu as={'div'} className="relative inline-block text-left">
                       <div>
                          <Menu.Button className="inline-flex w-full justify-center  rounded-full text-gray-900 shadow-sm">
                            {svg.app.notificationIcon} 
                          </Menu.Button>
                      </div> 
                       <Transition className="mc_notiFicationModal"
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <a href={() => false} className="mc_modalCloseIcon">
                            {svg.app.crossIcon}
                        </a>
                        <h3 className='text-base text-black font-medium py-5 px-5 flex'>Notifications <span className='mc_notiCount_blue ml-6'>12</span></h3>
                        <div className="p-3 mc_max_notification_dv">
                          <Menu.Item>
                            <div className='mc_notificationList flex items-center justify-center'>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'40px'} height={'40px'} className='rounded-full mr-3' />
                              </div>
                              <div className='mc_notiText_holder'>
                                <h6 className='text-sm font-semibold'>Franklin Commented on your post</h6>
                                <p className='text-xs font-medium text-gray-400'>2 min ago</p>
                              </div>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'48px'} height={'48px'} className='rounded-lg ml-3' />
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className='mc_notificationList flex items-center justify-center'>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'40px'} height={'40px'} className='rounded-full mr-3' />
                              </div>
                              <div className='mc_notiText_holder'>
                                <h6 className='text-sm font-semibold'>Franklin Commented on your post</h6>
                                <p className='text-xs font-medium text-gray-400'>2 min ago</p>
                              </div>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'48px'} height={'48px'} className='rounded-lg ml-3' />
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className='mc_notificationList flex items-center justify-center'>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'40px'} height={'40px'} className='rounded-full mr-3' />
                              </div>
                              <div className='mc_notiText_holder'>
                                <h6 className='text-sm font-semibold'>Franklin Commented on your post</h6>
                                <p className='text-xs font-medium text-gray-400'>2 min ago</p>
                              </div>
                              <div className='mc_batchText_dv flex justify-between items-center '>
                                  <a href={() => false} className='mc_btn' style={{color:brandSetting.theme_button_txt_color, }}>Follow</a>
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className='mc_notificationList flex items-center justify-center'>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'40px'} height={'40px'} className='rounded-full mr-3' />
                              </div>
                              <div className='mc_notiText_holder'>
                                <h6 className='text-sm font-semibold'>Franklin Commented on your post</h6>
                                <p className='text-xs font-medium text-gray-400'>2 min ago</p>
                              </div>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'48px'} height={'48px'} className='rounded-lg ml-3' />
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className='mc_notificationList flex items-center justify-center'>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'40px'} height={'40px'} className='rounded-full mr-3' />
                              </div>
                              <div className='mc_notiText_holder'>
                                <h6 className='text-sm font-semibold'>Franklin Commented on your post</h6>
                                <p className='text-xs font-medium text-gray-400'>2 min ago</p>
                              </div>
                              <div className='mc_batchText_dv flex justify-between items-center '>
                                  <a href={() => false} className='mc_btn' style={{color:brandSetting.theme_button_txt_color, }}>Follow</a>
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className='mc_notificationList flex items-center justify-center'>
                              <div className='mc_imgHolder_n'>
                                <img src={`${alfaUrl}/images/customer1.jpeg`} alt='img' width={'40px'} height={'40px'} className='rounded-full mr-3' />
                              </div>
                              <div className='mc_notiText_holder'>
                                <h6 className='text-sm font-semibold'>Franklin Commented on your post</h6>
                                <p className='text-xs font-medium text-gray-400'>2 min ago</p>
                              </div>
                              <div className='mc_batchText_dv flex justify-between items-center '>
                                  <a href={() => false} className='mc_btn' style={{color:brandSetting.theme_button_txt_color, }}>Follow</a>
                              </div>
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                </Menu> 
              </a>*/}
              <div className="mc_profileMenu z-50 relative">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center flex-wrap rounded-full text-gray-900 shadow-sm">
                        <span className='mc_profileImg_bg' style={{backgroundImage : `url(${(state.profile_img) ? state.profile_img : `${alfaUrl}/images/user.png`})`}}></span>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a href={() => false} onClick={(e)=>{
                                getFunnelUrl('profile', userData?.MembershipUser.funnel_id);
                                getProfile()
                              }}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm flex items-center mc_dropMenu'
                                )}
                                style={{color:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}
                              >
                                <span className='mc_innerMenuIcon flex mr-2' style={{fill:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}>
                                  {svg.app.profileMenuIcon}
                                  <span className='ml-2'>My Profile </span>
                                </span> 
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                        {slugName !='0xq22mj7' &&
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a href={() => false} onClick={(e)=>getFunnelUrl('change-password', userData?.MembershipUser.funnel_id)}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm flex items-center mc_dropMenu'
                                  )}
                                  style={{color:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}
                                >
                                  <span className='mc_innerMenuIcon flex mr-2' style={{fill:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}>
                                    {svg.app.keyIcon}
                                    <span className='ml-2'>Change Password</span>
                                  </span> 
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        }
                        
                     {memberShipUser.email == 'reviewaccess@flexifunnels.com' ? '' :  
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a href={() => false} onClick={(e)=>getFunnelUrl('my-plan', userData?.MembershipUser.funnel_id)}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'inline-block px-4 py-2 text-sm flex items-center mc_dropMenu'
                                )}
                                style={{color:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}
                                
                              >
                                <span className='mc_innerMenuIcon flex mr-2' style={{fill:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}>
                                  {svg.app.myPlansIcon}
                                  <span className='ml-2'> My Plan</span>
                                </span>
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      }
                        
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a href={() => false} 
                                // onClick={(e)=>getFunnelUrl('my-plan', userData?.MembershipUser.funnel_id)}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'inline-block px-4 py-2 text-sm flex items-center mc_dropMenu'
                                )}
                                style={{color:`${classNames(active ?  brandSetting.theme_color2 : "#374151")}`}}
                                onClick={()=> getInviteCodeData() }
                                
                              >
                                <span className='mc_innerMenuIcon flex mr-2' style={{fill:`${classNames(active ? state.hoverMenueColor : state.menuColor)}`}}>
                                  {svg.app.inviteCodeIcon} 
                                  <span className='ml-2'> Invite Code</span>
                                </span>
                              </a>
                            )}
                          </Menu.Item>
                        </div>

                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a href={() => false} onClick={() => {
                                Logout()
                              }}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm flex items-center mc_dropMenu'
                                )}
                              >
                              <span className='mc_innerMenuIcon flex mr-2 mc_logout'>
                                {svg.app.logoutIcon}
                                <span className='ml-2'> Logout</span>
                              </span>
                                
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
              </div>
              {/* <MobileView>
                <div className={`mc_switchMembership_btn ${switchButton == true ? 'slideOn' : ''}`} 
                  style={{color:brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'SwitchMembership' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}  onMouseEnter={(e)=> getButtonColor1('SwitchMembership')} onMouseLeave={(e)=> getButtonColor2('SwitchMembership')}>
                  
                  <a href={() => false} className={'mc_slideDv'} onClick={()=> setSwitchButton(!switchButton)} 
                    style={{fill:brandSetting.theme_button_txt_color}} 
                  >
                    {svg.app.switchMemberIcon}</a>
                    <TEDropdown className="flex justify-center">
                        <TERipple rippleColor="light">
                          <TEDropdownToggle
                            tag="a"
                            className='mc_btn'>
                            Switch Membership
                            <span className="ml-2 [&>svg]:w-5 w-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </TEDropdownToggle>
                        </TERipple>

                        <TEDropdownMenu>
                        <TEDropdownItem  onClick={()=>{
                              window.location.href= alfaUrl;
                              // navigate('/');
                              setSwitchButton(false)
                            }}>
                            <a href={() => false} className="mc_listMenu">
                            New Membership
                            </a>
                          </TEDropdownItem>
                          {Object.keys(switchMember).map((d1,i)=>{
                            return(
                              <TEDropdownItem key={i} onClick={()=>{
                                window.location.href=switchMember[d1]
                                setSwitchButton(false)
                              }}>
                                <a href={() => false} className="mc_listMenu">
                                  {d1}
                                </a>
                              </TEDropdownItem>
                            )
                          })}
                        </TEDropdownMenu>
                    </TEDropdown>
                </div>
              </MobileView> */}
            </div>
            
            <div className="lg:hidden sm:hidden md:hidden xs:hidden hidden mc_toggleBtn">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                {/* {svg.app.toggle} */}
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </nav>

          <Dialog as="div" className="lg:hidden sm:hidden xs:hidden md:hidden mc_dialogBox_menu" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-xs sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link to="/dashboard">
                  {/* <span className="sr-only">Your Company</span> */}
                  <img
                    className="h-8 w-auto"
                    src={brandSetting.logo}
                    alt="logo"
                    width={'170px'}
                    height={'55px'}
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <ul>
                    {navLinks.map((item, ind) => (
                      <li key={ind} className={`mc_menuList ${activeMenu == `${item.href}` ? ' mc_activeMenu' : ''} `} onClick={()=> setActiveMenu(item.href)}>
                        <NavLink to={`${item.href == '/dashboard' ? '/dashboard' : item.href}`}
                          className="text-sm text-slate-gray font-semibold py-3 w-fulll" style={{color: `${activeMenu == `${item.href}` || item.href == '/dashboard' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} 
                        >
                          <span>{item.label}</span>
                        </NavLink>
                      </li>
                    ))}
                    </ul>
                  </div>
                  {/* <div className="py-6">
                    <a href={() => false} onClick={() => {
                        Cookies.remove(`access_token_${slugName}`);
                          navigate("/login");
                        myStore.updateStoreData('userData' , {});
                      }}
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log Out
                    </a>
                  </div> */}
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      {location.pathname == '/my-learning' ? 
        <div className="mc_chevronsList bg-white py-3">
            <div className="max-container">
              <div className='custom_breadcrub'>
                 <ul>
                    <li> <Link to="/dashboard">Home</Link></li>
                    <li>{svg.app.arrowDownIcon}</li>
                    <li>{myCourse}</li>
                </ul>
              </div>
          </div>
        </div>
        : location.pathname == '/change-password' ? 
          <div className="mc_chevronsList bg-white py-3">
            <div className="max-container">
              <Breadcrumb
                  items={[
                    {
                      title: <Link to="/dashboard">Home</Link>,
                    },
                    {
                      title: 'Change Password',
                    },
                  ]}
                  separator = {svg.app.bradcamIcon}
                />
            </div>
          </div>
        : location.pathname == '/my-plan' ?  
        <div className="mc_chevronsList bg-white py-3">
          <div className="max-container">
            <Breadcrumb
                items={[
                  {
                    title: <Link to="/dashboard">Home</Link>,
                  },
                  {
                    title: 'My Plan',
                  },
                ]}
                separator = {svg.app.bradcamIcon}
              />
          </div>
        </div>
        : location.pathname == '/profile' ?  
          <div className="mc_chevronsList bg-white py-3">
            <div className="max-container">
              <Breadcrumb
                  items={[
                    {
                      title: <Link to="/dashboard">Home</Link>,
                    },
                    {
                      title: 'Profile',
                    },
                  ]}
                  separator = {svg.app.bradcamIcon}
                />
            </div>
          </div>
        :''
                  
        }
          <Routes>
            <Route exact path={'/dashboard'} element={<Dashboard/>}></Route> 
            <Route exact path={'/home'} element={<Home/>}></Route> 
          
            {/* My Courses Route Pages */}
            <Route exact path='/my-courses' element={<MyCourses/>}></Route> 
            <Route exact path='/my-learning' element={<MyLearnings/>}></Route> 
            <Route exact path='/course-details' element={<CourseDetails/>}></Route> 
           
            {/* Profile Inner Route Pages */}
            <Route exact path='/profile' element={<Profile/>}></Route> 
            <Route exact path='/change-password' element={<ChangePassword/>}></Route> 
            <Route exact path='/my-plan' element={<MyPlan/>}></Route> 
            
            {/* Event Route Pages */}
            {/* <Route exact path='/events/event-details' element={<EventDetails/>}></Route> 
            <Route exact path='/events/profile' element={<AttenderProfile/>}></Route>  */}


            {/* Other Route Pages */}
            {/* <Route exact path='/about-us' element={<AboutUs/>}></Route>  */}
            <Route exact path='/carear' element={<Carear/>}></Route> 
            <Route exact path='/blogs' element={<Blogs/>}></Route> 
            <Route exact path='/press' element={<Press/>}></Route> 
            <Route exact path='/affiliate' element={<Affiliate/>}></Route> 
            <Route exact path='/partnership' element={<Partnership/>}></Route> 
            <Route exact path='/refer-friend' element={<ReferFreiend/>}></Route> 
            <Route exact path='/memberships' element={<Memberships/>}></Route> 
            {/* <Route exact path='/become-teacher' element={<BecomeTeacher/>}></Route> 
            <Route exact path='/help-support' element={<HelpSupport/>}></Route> 
            <Route exact path='/free-classes' element={<FreeClasses/>}></Route>  */}
            {/* <Route exact path='member-signin/:slug' element={<Memberships/>} ></Route> */}
            <Route exact path='membership'>
              <Route exact path='help-support' element={<HelpSupport/>}/>
              <Route exact path='become-teacher' element={<BecomeTeacher/>}/>  
              <Route exact path='community' element={<Community/>}></Route>  
              <Route exact path='webinars' element={<Webinars/>}></Route>     
             </Route> 
            <Route exact path='/help' element={<Help/>}></Route> 
            <Route exact path='/privacy' element={<Privacy/>}></Route> 
            <Route exact path='/terms' element={<Terms/>}></Route> 
            {/* <Route path="*" element={<PageNotFound/>} /> */}
          </Routes>
      
          {/* <section className='sm:px-0 px-0 sm:pt-24 pt-12 mc_darkColor'> */}
            <Footer/>
          {/* </section> */}
        </>
        :pathname.includes("autologin") ?
          <Routes>
            <Route exact path={'/autologin/:token'} element={<AutoLogin/>}></Route> 
          </Routes>
        :(pathname == '/' || pathname == '/login') ? 
          <Routes>
            <Route exact path={'/'} element={<AuthPage/>}></Route> 
          </Routes>
        : pathname.includes('/events') ?
          <Routes>
            <Route exact path='events'>
              <Route path='/events/' element={<EventLogin/>}></Route> 
              <Route path='/events/profile' element={<EventProfile/>}></Route> 
            </Route>
          </Routes>
        : pathname.includes("resetpassword") ?
          <Routes>
            <Route exact path={'/resetpassword'} element={<ResetPassword/>}></Route> 
          </Routes>
      
        : tokenCookie != '' &&
          <Routes>
            <Route exact path={'*'} element={<PageNotFound/>}></Route> 
          </Routes>
        }



          <TEModal show={showGetInviteModal} setShow={setShowGetInviteModal} scrollable className='mc_modalHolder mc_inviteCodeModal_dv'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowGetInviteModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_textContentModal'>
                                    <div className='mc_moadlImage'>
                                        {/* <img src={`${alfaUrl}/images/inviteCodeImg.svg`} alt='getpoint' width={'310px'} height={'280px'}/> */}
                                        {/* <img src={`/assets/images/inviteCodeImg.svg`} alt='getpoint'/> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="414.096" height="317.907" viewBox="0 0 414.096 317.907">
                                          <g id="_13246824_5191077" data-name="13246824_5191077" transform="translate(0 -58.73)">
                                            <g id="Background_Complete" transform="translate(0 58.73)">
                                              <g id="Group_4140" data-name="Group 4140">
                                                <g id="Shadow--inject-214" transform="translate(46.47 299.157)">
                                                  <ellipse id="path--inject-214" cx="160.578" cy="9.375" rx="160.578" ry="9.375" fill="#f5f5f5"/>
                                                </g>
                                                <g id="Group_4138" data-name="Group 4138" transform="translate(32.801)">
                                                  <path id="Path_1407" data-name="Path 1407" d="M204.241,292.943H44.326a4.728,4.728,0,0,1-4.721-4.729V63.418a4.729,4.729,0,0,1,4.721-4.688H204.241a4.729,4.729,0,0,1,4.729,4.729h0V288.214A4.729,4.729,0,0,1,204.241,292.943ZM44.326,58.9a4.522,4.522,0,0,0-4.514,4.522v224.8a4.522,4.522,0,0,0,4.514,4.522H204.241a4.53,4.53,0,0,0,4.522-4.522V63.418a4.53,4.53,0,0,0-4.522-4.522Z" transform="translate(-39.605 -58.73)" fill="#e6e6e6"/>
                                                  <path id="Path_1408" data-name="Path 1408" d="M420.548,292.943H260.624a4.737,4.737,0,0,1-4.729-4.729V63.418a4.737,4.737,0,0,1,4.729-4.688H420.548a4.729,4.729,0,0,1,4.712,4.688v224.8A4.729,4.729,0,0,1,420.548,292.943ZM260.624,58.9a4.53,4.53,0,0,0-4.522,4.522v224.8a4.53,4.53,0,0,0,4.522,4.522H420.548a4.53,4.53,0,0,0,4.522-4.522V63.418a4.53,4.53,0,0,0-4.522-4.522Z" transform="translate(-76.765 -58.73)" fill="#e6e6e6"/>
                                                </g>
                                                <g id="Group_4139" data-name="Group 4139" transform="translate(37.045 274.529)">
                                                  <rect id="Rectangle_405" data-name="Rectangle 405" width="34.13" height="0.207" transform="translate(0 1.656)" fill={brandSetting.theme_color2}/>
                                                  <rect id="Rectangle_406" data-name="Rectangle 406" width="34.229" height="0.207" transform="translate(69.469)" fill={brandSetting.theme_color2}/>
                                                  <rect id="Rectangle_407" data-name="Rectangle 407" width="8.282" height="0.207" transform="translate(139.956)" fill={brandSetting.theme_color2}/>
                                                  <rect id="Rectangle_408" data-name="Rectangle 408" width="38.304" height="0.207" transform="translate(27.156 7.073)" fill={brandSetting.theme_color2}/>
                                                  <rect id="Rectangle_409" data-name="Rectangle 409" width="17.889" height="0.207" transform="translate(180.057 1.549)" fill={brandSetting.theme_color2}/>
                                                  <rect id="Rectangle_410" data-name="Rectangle 410" width="23.131" height="0.207" transform="translate(205.317 1.549)" fill={brandSetting.theme_color2}/>
                                                  <rect id="Rectangle_411" data-name="Rectangle 411" width="51.712" height="0.207" transform="translate(288.293 5.052)" fill={brandSetting.theme_color2}/>
                                                </g>
                                                <rect id="Rectangle_412" data-name="Rectangle 412" width="414.096" height="0.207" transform="translate(0 268.059)" fill="#ebebeb"/>
                                              </g>
                                              <g id="Group_4162" data-name="Group 4162" transform="translate(65.666 13.313)">
                                                <g id="Group_4160" data-name="Group 4160">
                                                  <g id="Group_4143" data-name="Group 4143" transform="translate(179.058)">
                                                    <g id="Group_4141" data-name="Group 4141">
                                                      <path id="Path_1409" data-name="Path 1409" d="M328.075,78.677l-2.064,28.385a4.068,4.068,0,0,1-1.154,2.559,4.114,4.114,0,0,1-2.987,1.311H299.1a3.578,3.578,0,0,1-3.6-3.871l2.064-28.385a4.215,4.215,0,0,1,4.141-3.871h22.768A3.564,3.564,0,0,1,328.075,78.677Z" transform="translate(-295.491 -74.805)" fill={brandSetting.theme_color2} opacity="0.8"/>
                                                    </g>
                                                    <g id="Group_4142" data-name="Group 4142" transform="translate(2.473 9.142)">
                                                      <path id="Path_1410" data-name="Path 1410" d="M320.321,93.424c-.293,4.187-3.32,7.581-6.761,7.581s-5.994-3.394-5.7-7.581,3.32-7.581,6.761-7.581S320.613,89.238,320.321,93.424Z" transform="translate(-300.085 -85.843)" fill="#fff"/>
                                                      <path id="Path_1411" data-name="Path 1411" d="M321.584,114.418H300.747c-1.321,0-2.236-.634-2.269-1.523-.048-1.288,6.709-6.877,13.119-6.393.023,0,.046,0,.07,0h.076a17.627,17.627,0,0,1,10.733,4.133C325.568,113.079,322.642,114.418,321.584,114.418Z" transform="translate(-298.478 -89.387)" fill="#fff"/>
                                                    </g>
                                                  </g>
                                                  <g id="Group_4151" data-name="Group 4151" transform="translate(85.463 0.844)">
                                                    <g id="Group_4144" data-name="Group 4144" transform="translate(0 18.159)">
                                                      <path id="Path_1412" data-name="Path 1412" d="M191.21,113.842a8.775,8.775,0,0,1-8.7-7.945,7.414,7.414,0,0,1,7.376-8.146,8.774,8.774,0,0,1,8.7,7.946A7.413,7.413,0,0,1,191.21,113.842Zm-.973-11.816a3.474,3.474,0,0,0-3.456,3.817,4.111,4.111,0,0,0,4.077,3.723,3.475,3.475,0,0,0,3.457-3.817A4.112,4.112,0,0,0,190.237,102.026Z" transform="translate(-182.481 -97.75)" fill={brandSetting.theme_color2}/>
                                                    </g>
                                                    <g id="Group_4150" data-name="Group 4150" transform="translate(4.42)">
                                                      <g id="Group_4145" data-name="Group 4145" transform="translate(0.038 2.172)">
                                                        <path id="Path_1413" data-name="Path 1413" d="M192.533,98.089l-3.051.038-1.618-19.642,3.051-.038Z" transform="translate(-187.864 -78.447)" fill={brandSetting.theme_color2}/>
                                                      </g>
                                                      <g id="Group_4149" data-name="Group 4149">
                                                        <g id="Group_4146" data-name="Group 4146">
                                                          <path id="Path_1414" data-name="Path 1414" d="M193.8,78.878l-5.729.072-.252-3.054,5.729-.071Z" transform="translate(-187.818 -75.824)" fill={brandSetting.theme_color2}/>
                                                        </g>
                                                        <g id="Group_4147" data-name="Group 4147" transform="translate(0.67 3.924)">
                                                          <path id="Path_1415" data-name="Path 1415" d="M194.265,83.616l-5.387.067-.252-3.054,5.387-.067Z" transform="translate(-188.627 -80.562)" fill={brandSetting.theme_color2}/>
                                                        </g>
                                                        <g id="Group_4148" data-name="Group 4148" transform="translate(1.82 9.46)">
                                                          <path id="Path_1416" data-name="Path 1416" d="M194.968,90.3l-4.7.059-.252-3.054,4.7-.059Z" transform="translate(-190.015 -87.246)" fill={brandSetting.theme_color2}/>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                  <g id="Group_4159" data-name="Group 4159" transform="translate(0 191.945)">
                                                    <g id="Group_4152" data-name="Group 4152" transform="translate(0 3.246)">
                                                      <path id="Path_1417" data-name="Path 1417" d="M79.305,317.345a7.485,7.485,0,0,0,7.346,6.866h69.974a6.3,6.3,0,0,0,6.4-6.129,7.077,7.077,0,0,0-.01-.737,7.485,7.485,0,0,0-7.345-6.857H85.692A6.315,6.315,0,0,0,79.305,317.345Z" transform="translate(-79.288 -310.488)" fill="#f0f0f0"/>
                                                    </g>
                                                    <g id="Group_4153" data-name="Group 4153" transform="translate(1.933 3.245)">
                                                      <path id="Path_1418" data-name="Path 1418" d="M163.419,318.081a4.377,4.377,0,0,1-.061.663,7.494,7.494,0,0,0-7.321-6.6l-74.415.116a6.372,6.372,0,0,1,4.47-1.772h69.974a7.267,7.267,0,0,1,7.354,7.594Z" transform="translate(-81.622 -310.487)" fill={brandSetting.theme_color2}/>
                                                    </g>
                                                    <g id="Group_4155" data-name="Group 4155" transform="translate(9.043 6.555)">
                                                      <g id="Group_4154" data-name="Group 4154">
                                                        <path id="Path_1419" data-name="Path 1419" d="M164.83,314.484l.234,3.347,3.137-1.162.711,1.652-3.2,1.057,2.238,2.808-1.446,1.058-2.178-2.918-1.74,2.918-1.549-1.058,1.815-2.808-3.322-1.057.479-1.642,3.329,1.12-.232-3.315h1.727Z" transform="translate(-102.117 -314.484)" fill="#fff"/>
                                                        <path id="Path_1420" data-name="Path 1420" d="M141.723,314.484l.234,3.347,3.137-1.162.711,1.652-3.2,1.057,2.238,2.808-1.446,1.058-2.178-2.918-1.74,2.918-1.549-1.058,1.815-2.808-3.322-1.057.479-1.642,3.329,1.12L140,314.484h1.727Z" transform="translate(-98.147 -314.484)" fill="#fff"/>
                                                        <path id="Path_1421" data-name="Path 1421" d="M118.616,314.484l.234,3.347,3.137-1.162.711,1.652-3.2,1.057,2.238,2.808-1.446,1.058-2.178-2.918-1.74,2.918-1.549-1.058,1.815-2.808-3.322-1.057.479-1.642,3.329,1.12-.232-3.315h1.727Z" transform="translate(-94.177 -314.484)" fill="#fff"/>
                                                        <path id="Path_1422" data-name="Path 1422" d="M95.509,314.484l.234,3.347,3.137-1.162.711,1.652-3.2,1.057,2.238,2.808-1.446,1.058L95,320.326l-1.74,2.918-1.549-1.058,1.815-2.808-3.322-1.057.479-1.642,3.328,1.12-.232-3.315h1.727Z" transform="translate(-90.207 -314.484)" fill="#fff"/>
                                                      </g>
                                                    </g>
                                                    <g id="Group_4158" data-name="Group 4158" transform="translate(57.566)">
                                                      <g id="Group_4156" data-name="Group 4156">
                                                        <path id="Path_1423" data-name="Path 1423" d="M152.058,307.4H148.8l.015-.828h3.261Z" transform="translate(-148.797 -306.569)" fill={brandSetting.theme_color2}/>
                                                      </g>
                                                      <g id="Group_4157" data-name="Group 4157" transform="translate(6.645)">
                                                        <path id="Path_1424" data-name="Path 1424" d="M167.465,307.4H156.82l.014-.828H167.48Z" transform="translate(-156.82 -306.569)" fill={brandSetting.theme_color2}/>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="Group_4161" data-name="Group 4161" transform="translate(157.436 164.644)" opacity="0.8">
                                                  <path id="Path_1425" data-name="Path 1425" d="M269.385,297.558l13.2,20.317,43.528-43.528a.431.431,0,0,0-.571-.644l-41.482,32.514-7.834-12.631Z" transform="translate(-269.385 -273.605)" fill={brandSetting.theme_color2}/>
                                                </g>
                                              </g>
                                            </g>
                                            <g id="Device_2" transform="translate(166.797 116.062)">
                                              <g id="Group_4163" data-name="Group 4163" transform="translate(17.158 0)">
                                                <path id="Path_1426" data-name="Path 1426" d="M253.372,127.955l-31.255,116.9h10.514l30.911-116.9Z" transform="translate(-222.117 -127.955)" fill="#fff" opacity="0.1"/>
                                                <path id="Path_1427" data-name="Path 1427" d="M272.965,127.955l-31.256,116.9h20.856l30.911-116.9Z" transform="translate(-225.483 -127.955)" fill="#fff" opacity="0.1"/>
                                              </g>
                                              <g id="Group_4180" data-name="Group 4180" transform="translate(0 3.385)">
                                                <g id="Group_4177" data-name="Group 4177" transform="translate(0 0.601)">
                                                  <g id="Group_4167" data-name="Group 4167" transform="translate(2.101 2.046)">
                                                    <g id="Group_4166" data-name="Group 4166">
                                                      <g id="Group_4165" data-name="Group 4165">
                                                        <g id="Group_4164" data-name="Group 4164">
                                                          <path id="Path_1428" data-name="Path 1428" d="M378.994,234.685l6.593-93.837a5.171,5.171,0,0,0-5.247-5.609l-163.762.142A6.139,6.139,0,0,0,210.543,141l-6.593,93.837a5.171,5.171,0,0,0,5.247,5.609l163.762-.142A6.14,6.14,0,0,0,378.994,234.685Z" transform="translate(-203.936 -135.239)" fill="#263238"/>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                  <g id="Group_4176" data-name="Group 4176">
                                                    <g id="Group_4175" data-name="Group 4175">
                                                      <path id="Path_1429" data-name="Path 1429" d="M376.457,232.214l6.593-93.837a5.171,5.171,0,0,0-5.247-5.609l-163.762.142a6.139,6.139,0,0,0-6.036,5.618l-6.593,93.837a5.17,5.17,0,0,0,5.247,5.609l163.762-.142A6.139,6.139,0,0,0,376.457,232.214Z" transform="translate(-201.399 -132.768)" fill={brandSetting.theme_color2}/>
                                                      <path id="Path_1430" data-name="Path 1430" d="M372.9,234.377a1.782,1.782,0,0,1-1.236.48L207.9,235a1.94,1.94,0,0,1-1.923-2.051l6.593-93.837a1.762,1.762,0,0,1,.561-1.189,1.787,1.787,0,0,1,1.237-.48l163.762-.142a1.94,1.94,0,0,1,1.923,2.051l-6.593,93.837A1.769,1.769,0,0,1,372.9,234.377Z" transform="translate(-202.186 -133.547)" fill="#fff" opacity="0.6"/>
                                                      <g id="Group_4169" data-name="Group 4169" transform="translate(53.688 31.741)">
                                                        <path id="Path_1431" data-name="Path 1431" d="M329.224,192.229H271.743a2.109,2.109,0,0,0-2.052,1.849l-.88,8.405a1.639,1.639,0,0,0,1.665,1.849h57.481a2.109,2.109,0,0,0,2.052-1.849l.88-8.405A1.639,1.639,0,0,0,329.224,192.229Z" transform="translate(-266.667 -174.725)" fill="#fff"/>
                                                        <g id="Group_4168" data-name="Group 4168" transform="translate(0 51.837)">
                                                          <path id="Path_1432" data-name="Path 1432" d="M278.137,233.685h-8.97a2.109,2.109,0,0,0-2.052,1.849l-.88,8.405a1.639,1.639,0,0,0,1.665,1.849h8.97a2.109,2.109,0,0,0,2.052-1.849l.879-8.405A1.638,1.638,0,0,0,278.137,233.685Z" transform="translate(-266.225 -233.685)" fill="#fff"/>
                                                          <path id="Path_1433" data-name="Path 1433" d="M296.838,233.685h-8.97a2.109,2.109,0,0,0-2.052,1.849l-.88,8.405a1.639,1.639,0,0,0,1.665,1.849h8.97a2.109,2.109,0,0,0,2.052-1.849l.879-8.405A1.638,1.638,0,0,0,296.838,233.685Z" transform="translate(-269.438 -233.685)" fill="#fff"/>
                                                          <path id="Path_1434" data-name="Path 1434" d="M315.539,233.685h-8.97a2.109,2.109,0,0,0-2.052,1.849l-.88,8.405a1.639,1.639,0,0,0,1.665,1.849h8.97a2.109,2.109,0,0,0,2.052-1.849l.88-8.405A1.638,1.638,0,0,0,315.539,233.685Z" transform="translate(-272.651 -233.685)" fill="#fff"/>
                                                          <path id="Path_1435" data-name="Path 1435" d="M334.24,233.685h-8.97a2.109,2.109,0,0,0-2.052,1.849l-.88,8.405A1.639,1.639,0,0,0,324,245.789h8.97a2.109,2.109,0,0,0,2.052-1.849l.88-8.405A1.638,1.638,0,0,0,334.24,233.685Z" transform="translate(-275.864 -233.685)" fill="#fff"/>
                                                        </g>
                                                        <path id="Path_1436" data-name="Path 1436" d="M280.467,224.608h35.957a3.591,3.591,0,0,0,3.495-3.15l.432-4.127a2.791,2.791,0,0,0-2.836-3.15H281.559a3.591,3.591,0,0,0-3.495,3.15l-.432,4.127A2.791,2.791,0,0,0,280.467,224.608Z" transform="translate(-268.182 -178.497)"/>
                                                        <path id="Path_1437" data-name="Path 1437" d="M331.725,171.094H274.244a2.109,2.109,0,0,0-2.052,1.849l-.88,8.405a1.639,1.639,0,0,0,1.665,1.849h57.481a2.109,2.109,0,0,0,2.052-1.849l.879-8.405A1.639,1.639,0,0,0,331.725,171.094Z" transform="translate(-267.097 -171.094)" fill="#fff"/>
                                                        <path id="Path_1438" data-name="Path 1438" d="M279.937,199.112a1.815,1.815,0,0,0-1.843-2.047,2.335,2.335,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.844,2.047A2.334,2.334,0,0,0,279.937,199.112Z" transform="translate(-267.872 -175.556)" fill="#263238"/>
                                                        <path id="Path_1439" data-name="Path 1439" d="M287.571,199.112a1.815,1.815,0,0,0-1.844-2.047,2.335,2.335,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.843,2.047A2.335,2.335,0,0,0,287.571,199.112Z" transform="translate(-269.183 -175.556)" fill="#263238"/>
                                                        <path id="Path_1440" data-name="Path 1440" d="M295.2,199.112a1.815,1.815,0,0,0-1.843-2.047,2.335,2.335,0,0,0-2.272,2.047,1.814,1.814,0,0,0,1.844,2.047A2.334,2.334,0,0,0,295.2,199.112Z" transform="translate(-270.494 -175.556)" fill="#263238"/>
                                                        <path id="Path_1441" data-name="Path 1441" d="M302.838,199.112A1.814,1.814,0,0,0,301,197.065a2.334,2.334,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.844,2.047A2.334,2.334,0,0,0,302.838,199.112Z" transform="translate(-271.806 -175.556)" fill="#263238"/>
                                                        <path id="Path_1442" data-name="Path 1442" d="M310.621,199.112a1.815,1.815,0,0,0-1.843-2.047,2.335,2.335,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.844,2.047A2.334,2.334,0,0,0,310.621,199.112Z" transform="translate(-273.144 -175.556)" fill="#263238"/>
                                                        <path id="Path_1443" data-name="Path 1443" d="M282.438,177.977a1.815,1.815,0,0,0-1.844-2.047,2.335,2.335,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.843,2.047A2.333,2.333,0,0,0,282.438,177.977Z" transform="translate(-268.301 -171.925)" fill="#263238"/>
                                                        <path id="Path_1444" data-name="Path 1444" d="M290.072,177.977a1.814,1.814,0,0,0-1.844-2.047,2.335,2.335,0,0,0-2.272,2.047,1.814,1.814,0,0,0,1.843,2.047A2.333,2.333,0,0,0,290.072,177.977Z" transform="translate(-269.613 -171.925)" fill="#263238"/>
                                                        <path id="Path_1445" data-name="Path 1445" d="M297.705,177.977a1.815,1.815,0,0,0-1.843-2.047,2.334,2.334,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.843,2.047A2.333,2.333,0,0,0,297.705,177.977Z" transform="translate(-270.924 -171.925)" fill="#263238"/>
                                                        <path id="Path_1446" data-name="Path 1446" d="M305.339,177.977a1.815,1.815,0,0,0-1.843-2.047,2.335,2.335,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.844,2.047A2.333,2.333,0,0,0,305.339,177.977Z" transform="translate(-272.236 -171.925)" fill="#263238"/>
                                                        <path id="Path_1447" data-name="Path 1447" d="M312.972,177.977a1.815,1.815,0,0,0-1.843-2.047,2.335,2.335,0,0,0-2.272,2.047,1.815,1.815,0,0,0,1.844,2.047A2.333,2.333,0,0,0,312.972,177.977Z" transform="translate(-273.547 -171.925)" fill="#263238"/>
                                                        <path id="Path_1448" data-name="Path 1448" d="M320.606,177.977a1.815,1.815,0,0,0-1.843-2.047,2.334,2.334,0,0,0-2.272,2.047,1.814,1.814,0,0,0,1.844,2.047A2.333,2.333,0,0,0,320.606,177.977Z" transform="translate(-274.859 -171.925)" fill="#263238"/>
                                                      </g>
                                                      <g id="Group_4174" data-name="Group 4174" transform="translate(83.002 9.332)">
                                                        <g id="Group_4173" data-name="Group 4173">
                                                          <g id="Group_4170" data-name="Group 4170" transform="translate(1.368)">
                                                            <path id="Path_1449" data-name="Path 1449" d="M312.967,147.909l-.448,4.245a.791.791,0,0,1-.769.692.613.613,0,0,1-.623-.692l.448-4.245a2.2,2.2,0,0,0-2.236-2.487h-1.526a2.842,2.842,0,0,0-2.76,2.487l-.388,3.943h-1.392l.388-3.943a4.425,4.425,0,0,1,4.3-3.873h1.526A3.432,3.432,0,0,1,312.967,147.909Z" transform="translate(-303.271 -144.036)"/>
                                                          </g>
                                                          <g id="Group_4172" data-name="Group 4172" transform="translate(0 7.016)">
                                                            <g id="Group_4171" data-name="Group 4171">
                                                              <path id="Path_1450" data-name="Path 1450" d="M312.711,153.454l-.873,8.271a1.079,1.079,0,0,1-1.051.947h-8.311a.838.838,0,0,1-.851-.947l.873-8.271a1.076,1.076,0,0,1,.531-.8,1.025,1.025,0,0,1,.52-.145h8.311a.875.875,0,0,1,.477.136A.839.839,0,0,1,312.711,153.454Z" transform="translate(-301.62 -152.507)"/>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                                <g id="Group_4178" data-name="Group 4178" transform="translate(138.7)">
                                                  <line id="Line_140" data-name="Line 140" x1="20.239" y2="0.12" transform="translate(0 0.055)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                  <line id="Line_141" data-name="Line 141" x1="7.153" y2="0.031" transform="translate(24.117)" fill="none" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                </g>
                                                <g id="Group_4179" data-name="Group 4179" transform="translate(5.328 4.472)" opacity="0.3">
                                                  <path id="Path_1451" data-name="Path 1451" d="M230.414,137.442h23.169l-23.555,97.582h-18.7a3.575,3.575,0,0,1-3.395-4.461Z" transform="translate(-207.832 -137.442)" fill="#fff"/>
                                                  <path id="Path_1452" data-name="Path 1452" d="M270.609,137.442h9.836l-23.555,97.582h-9.836Z" transform="translate(-214.571 -137.442)" fill="#fff"/>
                                                </g>
                                              </g>
                                            </g>
                                            <g id="Device_1" transform="translate(62.869 72.931)">
                                              <g id="Group_4193" data-name="Group 4193" transform="translate(0 0)">
                                                <g id="Group_4191" data-name="Group 4191" transform="translate(0 20.527)">
                                                  <g id="Group_4189" data-name="Group 4189" transform="translate(0.248)">
                                                    <g id="Group_4188" data-name="Group 4188">
                                                      <g id="Group_4181" data-name="Group 4181" transform="translate(0 0.001)">
                                                        <path id="Path_1453" data-name="Path 1453" d="M85,100.663h4.7a7.884,7.884,0,0,0-7.968,8.568l9.978,142.7a9.332,9.332,0,0,0,9.166,8.568h-4.7a9.332,9.332,0,0,1-9.166-8.568l-9.979-142.7A7.884,7.884,0,0,1,85,100.663Z" transform="translate(-76.347 -100.663)" fill="#407bff"/>
                                                        <path id="Path_1454" data-name="Path 1454" d="M84.86,100.663h4.7a7.884,7.884,0,0,0-7.968,8.568l9.978,142.7a9.332,9.332,0,0,0,9.166,8.568h-4.7c-4.731,0-9.5-3.835-9.829-8.568l-9.979-142.7C75.9,104.5,80.127,100.663,84.86,100.663Z" transform="translate(-76.21 -100.663)" fill="#263238"/>
                                                      </g>
                                                      <path id="Path_1455" data-name="Path 1455" d="M97.865,260.493h57.557a7.884,7.884,0,0,0,7.968-8.568l-9.978-142.7a9.333,9.333,0,0,0-9.166-8.568H86.689a7.884,7.884,0,0,0-7.968,8.568l9.978,142.7A9.333,9.333,0,0,0,97.865,260.493Z" transform="translate(-76.638 -100.662)" fill={brandSetting.theme_color2}/>
                                                      <g id="Group_4182" data-name="Group 4182" transform="translate(4.608 2.75)" opacity="0.6">
                                                        <path id="Path_1456" data-name="Path 1456" d="M144.619,103.982a6.5,6.5,0,0,1,6.375,5.962l9.978,142.695a5.192,5.192,0,0,1-5.273,5.674H98.142a6.5,6.5,0,0,1-6.375-5.962L81.788,109.657a5.192,5.192,0,0,1,5.273-5.674h57.558Z" transform="translate(-81.774 -103.982)" fill="#fff"/>
                                                      </g>
                                                      <g id="Group_4183" data-name="Group 4183" transform="translate(18.961 2.75)" opacity="0.3">
                                                        <path id="Path_1457" data-name="Path 1457" d="M133.271,258.314H110.315l18.932-154.332h18.475a5.83,5.83,0,0,1,4.256,1.833Z" transform="translate(-101.03 -103.982)" fill="#fff"/>
                                                        <path id="Path_1458" data-name="Path 1458" d="M104.269,258.314H99.1l18.932-154.332H123.2Z" transform="translate(-99.104 -103.982)" fill="#fff"/>
                                                      </g>
                                                      <g id="Group_4184" data-name="Group 4184" transform="translate(22.333 70.183)">
                                                        <line id="Line_142" data-name="Line 142" x2="41.692" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                        <line id="Line_143" data-name="Line 143" x2="32.657" transform="translate(5.378 6.165)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                      </g>
                                                      <g id="Group_4187" data-name="Group 4187" transform="translate(22.293 34.216)">
                                                        <g id="Group_4186" data-name="Group 4186" transform="translate(0 18.71)">
                                                          <path id="Path_1467" data-name="Path 1467" d="M112.445,171.7l.033,1.858H103.76l-.026-1.469,4.619-4.46c1.13-1.1,1.321-1.791,1.309-2.449-.018-1.082-.774-1.707-2.226-1.707a3.277,3.277,0,0,0-2.749,1.233l-1.559-1.183a5.365,5.365,0,0,1,4.461-1.959c2.568,0,4.247,1.3,4.283,3.4.02,1.149-.282,2.2-1.895,3.733l-3.107,3.007h5.575Z" transform="translate(-103.128 -161.567)" fill="#263238"/>
                                                          <path id="Path_1468" data-name="Path 1468" d="M124.128,170.05c.035,1.993-1.439,3.716-4.616,3.716a7.539,7.539,0,0,1-4.467-1.368l.916-1.707a5.6,5.6,0,0,0,3.483,1.166c1.571,0,2.489-.693,2.469-1.791-.018-1.065-.808-1.757-2.6-1.757h-1.082l-.026-1.5,2.629-3.193h-5.372l-.031-1.841h8.059l.025,1.469-2.813,3.413C122.941,166.941,124.1,168.275,124.128,170.05Z" transform="translate(-105.175 -161.602)" fill="#263238"/>
                                                          <path id="Path_1469" data-name="Path 1469" d="M137.331,170.166c.039,2.2-1.847,3.565-4.787,3.565s-4.838-1.368-4.877-3.565a2.9,2.9,0,0,1,1.843-2.821A2.756,2.756,0,0,1,128,164.862c-.036-2.027,1.716-3.295,4.335-3.295,2.636,0,4.449,1.267,4.485,3.295a2.645,2.645,0,0,1-1.46,2.484A3.038,3.038,0,0,1,137.331,170.166Zm-2.215-.067c-.021-1.166-1.047-1.892-2.669-1.892s-2.59.726-2.569,1.892c.021,1.2,1.014,1.909,2.635,1.909S135.137,171.3,135.116,170.1Zm-4.956-5.136c.017,1.014.874,1.656,2.259,1.656,1.4,0,2.254-.642,2.235-1.656-.018-1.064-.942-1.673-2.293-1.673S130.142,163.9,130.161,164.963Z" transform="translate(-107.344 -161.567)" fill="#263238"/>
                                                          <path id="Path_1470" data-name="Path 1470" d="M150.632,170.166c.039,2.2-1.847,3.565-4.787,3.565s-4.838-1.368-4.877-3.565a2.9,2.9,0,0,1,1.843-2.821,2.756,2.756,0,0,1-1.513-2.484c-.036-2.027,1.716-3.295,4.335-3.295,2.636,0,4.449,1.267,4.485,3.295a2.643,2.643,0,0,1-1.46,2.484A3.039,3.039,0,0,1,150.632,170.166Zm-2.215-.067c-.021-1.166-1.047-1.892-2.669-1.892s-2.59.726-2.569,1.892c.021,1.2,1.014,1.909,2.635,1.909S148.438,171.3,148.417,170.1Zm-4.957-5.136c.018,1.014.874,1.656,2.259,1.656,1.4,0,2.254-.642,2.235-1.656-.018-1.064-.942-1.673-2.294-1.673S143.443,163.9,143.461,164.963Z" transform="translate(-109.629 -161.567)" fill="#263238"/>
                                                        </g>
                                                        <text id="Enter_your_invite_code" data-name="Enter your invite code" transform="translate(19.694 7.111)" fontSize="6" fontFamily="Poppins-Medium, Poppins" fontWeight="500" letterSpacing="0.01em"><tspan x="-15" y="0">Enter your</tspan><tspan x="-16" y="6">invite code</tspan></text>
                                                      </g>
                                                    </g>
                                                  </g>
                                                  <g id="Group_4190" data-name="Group 4190" transform="translate(0 11.564)">
                                                    <line id="Line_144" data-name="Line 144" x2="1.468" y2="20.018" transform="translate(0.669 9.119)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                    <line id="Line_145" data-name="Line 145" x2="0.388" y2="5.283" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                  </g>
                                                </g>
                                                <g id="Group_4192" data-name="Group 4192" transform="translate(23.566)">
                                                  <line id="Line_146" data-name="Line 146" y1="12.188" transform="translate(15.739)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                  <line id="Line_147" data-name="Line 147" x1="6.091" y1="10.548" transform="translate(0 4.218)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                  <line id="Line_148" data-name="Line 148" x1="6.091" y2="10.553" transform="translate(25.387 4.218)" fill="none" stroke="#407bff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                                                </g>
                                              </g>
                                            </g>
                                            <g id="Plant" transform="translate(237.127 297.831)">
                                              <g id="Group_4198" data-name="Group 4198">
                                                <g id="Group_4197" data-name="Group 4197">
                                                  <path id="Path_1471" data-name="Path 1471" d="M312.8,351.759a176.476,176.476,0,0,0-5.026,51.422" transform="translate(-289.969 -348.176)" fill="none" stroke="#407bff" strokeWidth="1"/>
                                                  <g id="Group_4195" data-name="Group 4195" transform="translate(10.418)">
                                                    <g id="Group_4194" data-name="Group 4194">
                                                      <path id="Path_1472" data-name="Path 1472" d="M309.221,350.3c.037,1.546,1.424,7.013,2.971,6.976s2.667-5.565,2.63-7.112a2.8,2.8,0,1,0-5.6.136Z" transform="translate(-300.671 -347.433)" fill="#407bff"/>
                                                      <path id="Path_1473" data-name="Path 1473" d="M315.332,370.441c-.037-1.546-1.424-7.013-2.97-6.976s-2.668,5.565-2.629,7.112a2.8,2.8,0,1,0,5.6-.136Z" transform="translate(-300.759 -350.187)" fill="#407bff"/>
                                                      <path id="Path_1474" data-name="Path 1474" d="M301.767,363.867c1.546-.037,7.013-1.424,6.976-2.971s-5.565-2.667-7.112-2.63a2.8,2.8,0,0,0,.136,5.6Z" transform="translate(-298.898 -349.294)" fill="#407bff"/>
                                                      <path id="Path_1475" data-name="Path 1475" d="M321.9,357.756c-1.546.037-7.013,1.424-6.976,2.97s5.565,2.667,7.112,2.63a2.8,2.8,0,1,0-.136-5.6Z" transform="translate(-301.652 -349.206)" fill="#407bff"/>
                                                      <path id="Path_1476" data-name="Path 1476" d="M302.684,355.541c1.12,1.067,5.966,3.952,7.033,2.832s-2.049-5.821-3.169-6.888a2.8,2.8,0,0,0-3.864,4.056Z" transform="translate(-299.399 -347.996)" fill="#407bff"/>
                                                      <path id="Path_1477" data-name="Path 1477" d="M321.346,365.6c-1.12-1.067-5.965-3.952-7.033-2.832s2.049,5.821,3.169,6.888a2.8,2.8,0,0,0,3.864-4.056Z" transform="translate(-301.509 -350.024)" fill="#407bff"/>
                                                      <path id="Path_1478" data-name="Path 1478" d="M307.006,369.88c1.067-1.12,3.952-5.966,2.832-7.033s-5.82,2.049-6.888,3.169a2.8,2.8,0,0,0,4.056,3.864Z" transform="translate(-299.461 -350.044)" fill="#407bff"/>
                                                      <path id="Path_1479" data-name="Path 1479" d="M317.067,351.22c-1.067,1.12-3.952,5.965-2.832,7.033s5.821-2.049,6.888-3.169a2.8,2.8,0,0,0-4.056-3.864Z" transform="translate(-301.489 -347.934)" fill="#407bff"/>
                                                      <path id="Path_1480" data-name="Path 1480" d="M308.854,360.785a3.317,3.317,0,1,0,3.235-3.4A3.316,3.316,0,0,0,308.854,360.785Z" transform="translate(-300.608 -349.143)" fill="#fff"/>
                                                    </g>
                                                  </g>
                                                  <path id="Path_1481" data-name="Path 1481" d="M308.28,388.568s.572-8.458,5.214-9.8,6.346-3.151,6.346-3.151S319.37,388.731,308.28,388.568Z" transform="translate(-290.092 -352.276)" fill="#407bff"/>
                                                  <path id="Path_1482" data-name="Path 1482" d="M308.28,388.568s.572-8.458,5.214-9.8,6.346-3.151,6.346-3.151S319.37,388.731,308.28,388.568Z" transform="translate(-290.092 -352.276)" fill="#fff" opacity="0.5"/>
                                                  <path id="Path_1483" data-name="Path 1483" d="M305.141,396.386s-.572-8.458-5.214-9.8-6.346-3.151-6.346-3.151S294.05,396.548,305.141,396.386Z" transform="translate(-287.566 -353.619)" fill="#407bff"/>
                                                  <path id="Path_1484" data-name="Path 1484" d="M305.141,396.386s-.572-8.458-5.214-9.8-6.346-3.151-6.346-3.151S294.05,396.548,305.141,396.386Z" transform="translate(-287.566 -353.619)" fill="#fff" opacity="0.5"/>
                                                  <g id="Group_4196" data-name="Group 4196" transform="translate(0 49.061)">
                                                    <path id="Path_1485" data-name="Path 1485" d="M321.418,417.993c0,6.6-7.859,11.942-17.549,11.942s-17.549-5.342-17.549-11.942c0-3.338,2.012-6.361,5.267-8.522a19.96,19.96,0,0,1,6.725-2.8h11.114a20.014,20.014,0,0,1,6.882,2.907C319.472,411.74,321.418,414.705,321.418,417.993Z" transform="translate(-286.319 -406.672)" fill="#407bff"/>
                                                    <path id="Path_1486" data-name="Path 1486" d="M309.426,406.672a20.014,20.014,0,0,1,6.882,2.907c3.164,2.162,5.11,5.126,5.11,8.414,0,6.6-7.859,11.942-17.549,11.942s-17.549-5.342-17.549-11.942c0-3.338,2.012-6.361,5.267-8.522a19.96,19.96,0,0,1,6.725-2.8Z" transform="translate(-286.319 -406.672)" fill="#fff" opacity="0.8"/>
                                                    <path id="Path_1487" data-name="Path 1487" d="M307.57,407.97v5.9" transform="translate(-289.97 -406.895)" fill="none" stroke="#407bff" strokeWidth="1"/>
                                                  </g>
                                                  <path id="Path_1488" data-name="Path 1488" d="M317.98,406.45l-.932,2.9-.015.03H292.494l-.051-.155-.887-2.777Z" transform="translate(-287.219 -357.573)" fill="#407bff"/>
                                                </g>
                                                <path id="Path_1489" data-name="Path 1489" d="M319.791,421.705a18.947,18.947,0,0,1-6.741,5.8c-4.216,2.237-21.948,2.814-23.971-7.247-.028-.14-.075-.276-.1-.417a4.121,4.121,0,0,1,4.522-4.707,20.407,20.407,0,0,0,5.027-.282,47.235,47.235,0,0,1,19.089,1.024A3.815,3.815,0,0,1,319.791,421.705Z" transform="translate(-286.765 -358.924)" fill="#263238"/>
                                              </g>
                                            </g>
                                            <g id="Character_1_" transform="translate(127.846 117.808)">
                                              <g id="Group_4217" data-name="Group 4217">
                                                <g id="Group_4215" data-name="Group 4215">
                                                  <g id="Group_4199" data-name="Group 4199" transform="translate(0 219.443)">
                                                    <path id="Path_1490" data-name="Path 1490" d="M171.85,418.857a.972.972,0,0,0,.614-.728.571.571,0,0,0-.133-.57c-.639-.667-3.229-.194-3.522-.137a.171.171,0,0,0-.136.131.168.168,0,0,0,.068.174,8.838,8.838,0,0,0,1.681.957A2.122,2.122,0,0,0,171.85,418.857Zm.229-1.065a.238.238,0,0,1,.05.253.642.642,0,0,1-.4.493c-.47.169-1.368-.148-2.446-.854C170.327,417.524,171.743,417.437,172.079,417.792Z" transform="translate(-156.825 -398.84)" fill="#407bff"/>
                                                    <path id="Path_1491" data-name="Path 1491" d="M168.863,417.327c.793-.1,2.616-1.034,2.776-1.844a.588.588,0,0,0-.354-.655.906.906,0,0,0-.764-.063c-1,.342-1.805,2.249-1.84,2.33a.169.169,0,0,0,.022.168.181.181,0,0,0,.071.054A.215.215,0,0,0,168.863,417.327Zm2.253-2.2c.194.107.2.214.184.294-.1.494-1.311,1.26-2.162,1.5.3-.621.9-1.632,1.5-1.837a.566.566,0,0,1,.411,0A.788.788,0,0,1,171.115,415.123Z" transform="translate(-156.824 -398.411)" fill="#407bff"/>
                                                    <path id="Path_1492" data-name="Path 1492" d="M159.409,410.608l6.742,2.725,5.884-15.578-6.742-2.725Z" transform="translate(-155.234 -395.03)" fill="#ffb573"/>
                                                    <path id="Path_1493" data-name="Path 1493" d="M166.14,415.819l-7.575-3.062a.581.581,0,0,0-.727.236l-3.324,5.548a1.145,1.145,0,0,0,.588,1.617c2.659,1.023,4,1.385,7.327,2.731,2.049.828,4.956,2.248,7.786,3.391,2.768,1.119,4.13-1.549,3.056-2.274-4.817-3.254-5.008-5.133-6.173-7.324A1.894,1.894,0,0,0,166.14,415.819Z" transform="translate(-154.368 -398.068)" fill="#263238"/>
                                                    <path id="Path_1494" data-name="Path 1494" d="M172.122,405.677l-11.71-4.733,1.073-4.292,12.564,4.6Z" transform="translate(-155.406 -395.309)" fill="#407bff"/>
                                                  </g>
                                                  <g id="Group_4208" data-name="Group 4208" transform="translate(4.639)">
                                                    <path id="Path_1495" data-name="Path 1495" d="M184.085,186.32c-2.375,6.876-9.409,23.076-14.828,16.329a15.588,15.588,0,0,1-2.367-3.942c-2.3-5.676-2.866-14.869-3.158-16.879l-1.846-6.3a1.208,1.208,0,0,1-.05-.335l-.03-3.6c0-1.459,1.175-2.326,2.767-2.192,0,0,2.567-.3,2.567,1.234l.5,5.4h-.017c.258,1.884-.492,4.5-.05,6.226,1.651,6.485,3.933,6.776,5.367,13.469,1.992-4.826,5.568-13.186,5.568-13.186C180.918,182.844,184.869,184.053,184.085,186.32Z" transform="translate(-160.285 -136.818)" fill="#ffb573"/>
                                                    <path id="Path_1496" data-name="Path 1496" d="M175.761,206.115a4.27,4.27,0,0,1-7.014.239,15.717,15.717,0,0,1-2.292-4.3c-2.151-7.06-3.484-11.436-3.667-18.812a5.812,5.812,0,0,1,5.985-1.417l3.526,11.952s7.185-17.848,10.085-18.489c3.425-.75,4.851,2.3,6.293,6.062C189.715,184.053,180.44,198.9,175.761,206.115Z" transform="translate(-160.453 -137.814)" fill={brandSetting.theme_color2}/>
                                                    <g id="Group_4207" data-name="Group 4207">
                                                      <g id="Group_4206" data-name="Group 4206">
                                                        <path id="Path_1497" data-name="Path 1497" d="M210.625,153.745c-.812,4.143-2.637,13.18.262,15.944,0,0-1.132,4.2-8.83,4.2-8.464,0-4.045-4.2-4.045-4.2,4.62-1.1,4.5-4.531,3.7-7.749Z" transform="translate(-166.307 -134.133)" fill="#ffb573"/>
                                                        <path id="Path_1498" data-name="Path 1498" d="M197.335,141.468a.334.334,0,0,0,.147-.035.331.331,0,0,0,.147-.444,3.281,3.281,0,0,0-2.4-1.833.331.331,0,0,0-.076.657h0a2.617,2.617,0,0,1,1.883,1.472A.334.334,0,0,0,197.335,141.468Z" transform="translate(-165.964 -131.626)" fill="#263238"/>
                                                        <path id="Path_1499" data-name="Path 1499" d="M194.3,146.319a15.035,15.035,0,0,1-2.434,3.32,2.421,2.421,0,0,0,1.946.62Z" transform="translate(-165.448 -132.857)" fill="#ed893e"/>
                                                        <path id="Path_1500" data-name="Path 1500" d="M195.326,144.676c.119.551-.075,1.06-.436,1.138s-.748-.3-.867-.855.075-1.06.435-1.138S195.206,144.127,195.326,144.676Z" transform="translate(-165.814 -132.426)" fill="#263238"/>
                                                        <path id="Path_1501" data-name="Path 1501" d="M194.465,143.8l-1.4-.094S193.962,144.59,194.465,143.8Z" transform="translate(-165.654 -132.408)" fill="#263238"/>
                                                        <path id="Path_1502" data-name="Path 1502" d="M159.977,169.052v.017c0-.008-.008-.008-.008-.017Z" transform="translate(-159.969 -136.762)" fill="#ffb573"/>
                                                        <path id="Path_1503" data-name="Path 1503" d="M187.986,185.389c1.467-1.342,2.459-.942,3.134.125.667,3.1-3.284,9.594-4.876,12.053Z" transform="translate(-164.483 -139.423)" opacity="0.2"/>
                                                        <path id="Path_1504" data-name="Path 1504" d="M221.755,187.442c-.19,3.412-.513,7.71-1.035,13.119-.547,5.706-1.325,12.638-2.393,21.061-11.255,0-23.04-.174-28.515-.174.458-11.187.627-22.523-4.392-44.789a2.109,2.109,0,0,1,1.684-2.538,81.362,81.362,0,0,1,9.873-1.171,90.219,90.219,0,0,1,12.878,0c5.524.505,11.8,1.789,11.8,1.789S222.352,176.791,221.755,187.442Z" transform="translate(-164.333 -137.392)" fill={brandSetting.theme_color2}/>
                                                        <path id="Path_1505" data-name="Path 1505" d="M228.019,189.124c-.19,3.412-.514,7.71-1.035,13.119-3.031-3.512-5.9-15.6-4.986-19.736a27.162,27.162,0,0,1,5.888,5.963A6.038,6.038,0,0,1,228.019,189.124Z" transform="translate(-170.597 -139.074)" opacity="0.2"/>
                                                        <g id="Group_4202" data-name="Group 4202" transform="translate(51.868 41.009)">
                                                          <g id="Group_4200" data-name="Group 4200" transform="translate(34.15 20.41)">
                                                            <path id="Path_1506" data-name="Path 1506" d="M264.462,208.127l5.768-3.243,1.077,5.905s-4.888,1.873-7.476.561Z" transform="translate(-263.831 -204.337)" fill="#ffb573"/>
                                                            <path id="Path_1507" data-name="Path 1507" d="M275.421,205.441l.4,1.927a1.529,1.529,0,0,1-.638,1.576l-2.548,1.731-1.078-5.905,1.994-.5A1.528,1.528,0,0,1,275.421,205.441Z" transform="translate(-265.159 -204.224)" fill="#ffb573"/>
                                                          </g>
                                                          <g id="Group_4201" data-name="Group 4201">
                                                            <path id="Path_1508" data-name="Path 1508" d="M222.6,182.761c1.848,3.762,8.628,17.962,11.608,21.245l.583.612.292.3a4.66,4.66,0,0,0,.338.333,10.267,10.267,0,0,0,1.757,1.328,13.554,13.554,0,0,0,3.612,1.458,25.328,25.328,0,0,0,6.774.643,49.648,49.648,0,0,0,12.506-2.124l-.74-3.458c-1.909.162-3.915.28-5.856.312a46.578,46.578,0,0,1-5.756-.182,22.387,22.387,0,0,1-5.214-1.015,7.846,7.846,0,0,1-1.926-.972,3.8,3.8,0,0,1-.616-.549,2.415,2.415,0,0,1-.187-.214l-.216-.258-.432-.515c-2.288-2.865-8.326-16.677-10.138-20.13Z" transform="translate(-222.597 -179.58)" fill="#ffb573"/>
                                                          </g>
                                                        </g>
                                                        <path id="Path_1509" data-name="Path 1509" d="M256.759,208.959a20.39,20.39,0,0,1-15.528,1.509,20.772,20.772,0,0,1-11.669-9.452c-2.992-4.4-10.951-16.84-9.952-20.057,1.184-3.843,3.7-6.226,7.91-5.193,2.959.717,8.6,15.073,11.326,20.816,1.075,1.6,3.126,4.134,5.418,4.918,4.051,1.4,7.035.792,11.277.192.775.467.717,1.183,1.109,1.992A6.015,6.015,0,0,1,256.759,208.959Z" transform="translate(-170.201 -137.874)" fill={brandSetting.theme_color2}/>
                                                        <path id="Path_1510" data-name="Path 1510" d="M214.283,143.38c-1.27,6.812-1.663,9.721-5.566,12.815-5.87,4.654-13.958,1.6-14.748-5.45-.711-6.349,1.487-16.421,8.543-18.322A9.451,9.451,0,0,1,214.283,143.38Z" transform="translate(-165.789 -130.41)" fill="#ffb573"/>
                                                        <g id="Group_4203" data-name="Group 4203" transform="translate(29.455)">
                                                          <path id="Path_1511" data-name="Path 1511" d="M238.738,180.729c-1.3,1.482-1.71,2.34-3.516,3.144s-5.747.1-7-1.429c.406,1.193,2.778,3.337,4.02,3.5a7.034,7.034,0,0,1-8.663-1.524,4.484,4.484,0,0,0,2.948,3.238c-15.871.41-9.188-7.059-19.12-17.679-11-11.764-8.617-21.487-8.617-21.487a11.217,11.217,0,0,0-2.162-6.741c-1.59-2.037-1.126-4.787-.621-6.327.737-2.261,2.981-3.056,5.135-3.188.379-2.231,4.938-1.82,5.521-1.448.5.32.628.293,1.127-.027,1.121-.718,3.936-1.468,6.95.97,1.84,1.488,3.806,4.158,3.872,9.839.069,5.908-2.75,12.917,7.016,21.893C231.387,168.755,228.636,180.11,238.738,180.729Z" transform="translate(-195.535 -130.064)" fill="#263238"/>
                                                        </g>
                                                        <path id="Path_1512" data-name="Path 1512" d="M197.693,146.123a5.188,5.188,0,0,0-.893,3.763c.277,1.748,1.982,1.864,3.216.744,1.111-1.009,2.3-3.134,1.395-4.583A2.191,2.191,0,0,0,197.693,146.123Z" transform="translate(-166.287 -132.639)" fill="#ffb573"/>
                                                        <g id="Group_4204" data-name="Group 4204" transform="translate(2.836 84.059)">
                                                          <path id="Path_1513" data-name="Path 1513" d="M207.333,231.728s-3.382,47.544-7.2,78.9a77.261,77.261,0,0,1-8.46,26.894l-18.706,35.246-9.575-3.766s6.6-33.5,19.531-51.771c0,0-9.655-54.6,2.982-85.674Z" transform="translate(-163.393 -231.561)" fill="#263238"/>
                                                        </g>
                                                        <path id="Path_1514" data-name="Path 1514" d="M200.145,245.665s2.846,21.567,4.411,34.347C198.683,272.309,194.185,261.563,200.145,245.665Z" transform="translate(-166.389 -149.925)" opacity="0.2"/>
                                                        <g id="Group_4205" data-name="Group 4205" transform="translate(31.093 84.11)">
                                                          <path id="Path_1515" data-name="Path 1515" d="M244.928,424.3a.956.956,0,0,0,.2-.926.583.583,0,0,0-.389-.442c-.881-.295-2.945,1.317-3.178,1.5a.169.169,0,0,0-.058.178.172.172,0,0,0,.143.122,8.966,8.966,0,0,0,1.941.071A2.113,2.113,0,0,0,244.928,424.3Zm-.3-1.047a.241.241,0,0,1,.164.2.631.631,0,0,1-.116.618c-.335.365-1.28.5-2.567.373C242.949,423.822,244.158,423.094,244.623,423.252Z" transform="translate(-205.068 -264.484)" fill="#407bff"/>
                                                          <path id="Path_1516" data-name="Path 1516" d="M241.758,424.387c.653-.452,1.82-2.119,1.576-2.909a.6.6,0,0,0-.625-.416.9.9,0,0,0-.7.3c-.721.763-.526,2.818-.517,2.906a.169.169,0,0,0,.1.138.173.173,0,0,0,.089.014A.152.152,0,0,0,241.758,424.387Zm.942-2.985c.222,0,.278.1.3.175.148.481-.559,1.717-1.195,2.326a3.829,3.829,0,0,1,.448-2.312.561.561,0,0,1,.365-.185C242.648,421.4,242.674,421.4,242.7,421.4Z" transform="translate(-205.062 -264.169)" fill="#407bff"/>
                                                          <path id="Path_1517" data-name="Path 1517" d="M231.057,422.329l7.254-.7L236.1,405.153l-7.254.7Z" transform="translate(-202.895 -261.436)" fill="#ffb573"/>
                                                          <path id="Path_1518" data-name="Path 1518" d="M239.006,423.981l-8.149.785a.573.573,0,0,0-.531.543l-.3,6.434a1.164,1.164,0,0,0,1.289,1.158c2.837-.322,4.189-.618,7.773-.963,2.2-.213,5.449-.3,8.493-.59,2.978-.287,2.912-3.272,1.617-3.418-5.8-.655-6.867-2.228-8.94-3.627A1.918,1.918,0,0,0,239.006,423.981Z" transform="translate(-203.098 -264.669)" fill="#263238"/>
                                                          <path id="Path_1519" data-name="Path 1519" d="M220.369,231.727s9.28,56.739,11.068,90.351l.334,60.205-7.508,1.465s-10.879-38.41-12.13-60.61c-3.321-58.908-22.508-59-10.964-91.516Z" transform="translate(-197.512 -231.622)" fill="#263238"/>
                                                          <path id="Path_1520" data-name="Path 1520" d="M239.048,412.841l-12.434,1.7-1.26-4.774,13.288-1.728Z" transform="translate(-202.296 -261.932)" fill="#407bff"/>
                                                        </g>
                                                        <path id="Path_1521" data-name="Path 1521" d="M159.994,169.114h-.025l.008-.041C159.977,169.09,159.986,169.1,159.994,169.114Z" transform="translate(-159.969 -136.766)" fill="#ffb573"/>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                                <path id="Path_1529" data-name="Path 1529" d="M187.141,233.615a61.243,61.243,0,0,0-6.652,26.79c14.1,10.922,27.19-1.869,43.305.291-.562-7.278-3.035-20.745-5.971-27.429C207.174,233.717,197.2,232.655,187.141,233.615Z" transform="translate(-158.855 -147.783)" fill={brandSetting.theme_color1}/>
                                                <g id="Group_4216" data-name="Group 4216" transform="translate(28.882 82.664)">
                                                  <path id="Path_1530" data-name="Path 1530" d="M190.111,230.089s-1.137,2.917-.812,2.917h29.547c.254,0,.465-.124.48-.284l.254-2.522c.017-.175-.2-.323-.48-.323H190.57A.512.512,0,0,0,190.111,230.089Z" transform="translate(-189.241 -229.877)" fill="#fff"/>
                                                </g>
                                                <path id="Path_1531" data-name="Path 1531" d="M195.106,233.2h-.764c-.152,0-.268-.079-.258-.174l.359-3.276c.011-.1.143-.174.3-.174h.764c.152,0,.268.079.258.174l-.359,3.276C195.392,233.124,195.258,233.2,195.106,233.2Z" transform="translate(-161.191 -147.161)" fill="#407bff"/>
                                                <path id="Path_1532" data-name="Path 1532" d="M217.47,233.2h-.764c-.152,0-.268-.079-.258-.174l.359-3.276c.011-.1.143-.174.3-.174h.764c.152,0,.268.079.258.174l-.359,3.276C217.756,233.124,217.622,233.2,217.47,233.2Z" transform="translate(-165.033 -147.161)" fill="#407bff"/>
                                              </g>
                                            </g>
                                            <g id="Group_4218" data-name="Group 4218" transform="translate(98.346 107.472)">
                                              <g id="Group_4173-2" data-name="Group 4173" transform="translate(0 0)">
                                                <g id="Group_4170-2" data-name="Group 4170" transform="translate(0.012)">
                                                  <path id="Path_1449-2" data-name="Path 1449" d="M303.293,147.909l.448,4.245a.791.791,0,0,0,.769.692.613.613,0,0,0,.623-.692l-.448-4.245a2.2,2.2,0,0,1,2.236-2.487h1.526a2.842,2.842,0,0,1,2.76,2.487l.388,3.943h1.392l-.388-3.943a4.425,4.425,0,0,0-4.3-3.873h-1.527A3.432,3.432,0,0,0,303.293,147.909Z" transform="translate(-303.271 -144.036)"/>
                                                </g>
                                                <g id="Group_4172-2" data-name="Group 4172" transform="translate(0 7.016)">
                                                  <g id="Group_4171-2" data-name="Group 4171">
                                                    <path id="Path_1450-2" data-name="Path 1450" d="M301.625,153.454l.873,8.271a1.08,1.08,0,0,0,1.051.947h8.311a.838.838,0,0,0,.851-.947l-.873-8.271a1.076,1.076,0,0,0-.531-.8,1.025,1.025,0,0,0-.52-.145h-8.311a.876.876,0,0,0-.477.136A.839.839,0,0,0,301.625,153.454Z" transform="translate(-301.62 -152.507)"/>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                    </div>
                                    <h3 className='text-lg text-black font-semibold mb-3 text-center'>Mobile Invite Code</h3>
                                    <p className=' text-center'>With the below code, you can access your membership on your mobile device</p>
                                    <div className='mc_inviteCode_holder mt-5'>
                                      <div className='mc_currentInviteCode' 
                                        style={{
                                          borderColor : brandSetting.theme_color2, 
                                        }} 
                                        onClick={()=> {
                                          navigator.clipboard.writeText(state.inviteCode);
                                          toast.success('Invite Code Copied!')
                                        }}
                                      >
                                        {state.inviteCode}
                                        <TETooltip
                                            tag="a"
                                            title="Copy Invite Code"
                                           
                                            className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600 pointer-events-auto cursor-pointer"
                                          >
                                          <a href={() => false}>
                                            {svg.app.copyIcon}
                                          </a>
                                        </TETooltip>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    </>
    
  );
};

export default Nav;

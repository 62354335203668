import { Link, Route, Routes, useLocation, useNavigate, } from 'react-router-dom'
import EventLogin from './eventAuth'
import EventProfile from '../../components/events/profile'
import AttendanceList from '../../components/events/attendancelist'
import Agenda from '../../components/events/agenda'
import Speakers from '../../components/events/speaker'
import ChatsEvents from '../../components/events/chats'
import { useEffect, useState, useRef } from 'react'
import { appStore } from '../../zustand/store'
import svg from '../../components/svg'
import { setMyState } from '../../components/Comman'
import { ToastContainer } from 'react-toastify'
import MatchTags from '../../components/events/matchTags'
import io from "socket.io-client";
import config from '../../config';
import QRCode from '../../components/events/qrCode'
import Home from '../../components/events/home'
import Notification from '../notification'
import NotificationList from '../../components/events/notificationList'

import { Helmet } from 'react-helmet'
const apiUrl = config.REACT_CHAT_APIURL
const socke1t = io(apiUrl, {
    query: { data: "" },
    transports: ["websocket"],
});
function EventRoutes() {
    let alfaUrl = config.REACT_APP_BUCKET_URL
    const [socket, setsocket] = useState(socke1t)
    const [load, setload] = useState(false)
    const [state, setQuery] = useState({
        funnel_id: '',
        funnel_page_id: '',
        lesson_id: '',
        member_id: '',
        token: '',
        inviteCode: '',
        email: '',
        ticket_code: '',
        buttonColor: null,
        eventlist: [],
        speakerlist: [],
        attendeelist: [],
        pageType: null,
        isLoginEvent: false,
        // attendeedetails: [state.eventlist, state.speakerlist, state.attendeelist],

        bodyColor: '#F7f7f7',
        themeColor: '#28E9BE',
        textColor: '#4A5251',
        hoverColor: "Event Attendees",
        toggleMenu: false,
        // chatCount : [],
        notificationCount: null,
    })
    let [chatCount, setchatCount] = useState([])
    let [nCount, nsetCount] = useState(0)
    let currentPath = useLocation().pathname;
    let myStore = appStore(state => state);
    let userData = myStore.userData;
    let brandSetting = myStore.brandSetting;
    const menuRef = useRef();
 
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let eventType= urlParams.get("type");
    if(eventType){
        localStorage.setItem('eventType',eventType.toLowerCase())
    }
  

    let localType = localStorage.getItem('eventType');
  
    // let hoverEventColor = userData?.type ?  userData?.ticket_code.substring(0, 3) !== 'ucs' ? '#ffa200'  : '#22CCA6' : '#22CCA6';
    let hoverEventColor = '#22CCA6';
    useOutsideClick(menuRef, () => setMyState(setQuery, {
        toggleMenu: false
    }));

    function useOutsideClick(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {

                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },

            [ref, handler]
        );
    }

    useEffect(() => {
        socket.on("connect", function () {
            setload(true)
        });
        if (socket.connected) {
            setload(true)
        }
    }, [socket])

    const navigate = useNavigate();
  

    useEffect(() => {
        if(eventType){
            let currId =  localStorage.getItem(`${eventType}_data`)
            currId && localStorage.setItem('id',currId);
          }

    if (userData?.type != "event") { 
            navigate(eventType ? `/events?type=${eventType}` :`/events?type=${localType}`);
        } else {
            let expectedpath = ['match', 'chats', 'attendees', "push756878Notifi",];
            let defaultRedirect = expectedpath.some(val => val == window.location.href.split('/')[4]);
            if (!defaultRedirect) {
                localStorage.setItem('redirect', true);
                navigate('/events/home');
            }
        }
    }, [])
  
    useEffect(() => {
        if (userData?.type != "event") {
            navigate(eventType ? `/events?type=${eventType}` :`/events?type=${localType}` );
        } else { 
            if(!checkUserDetail()){ 
                navigate('/events/profile');
            }
    }

    }, [window.location.pathname])

    const checkUserDetail = ()=>{
        const requiredKeys = ['name', 'email', 'services_provide', 'designation', 'look_to_meet'];

        const missingKeys = requiredKeys.filter(key => !userData[key]);
       
        if (missingKeys.length) {
          return false
        }
        if (userData.profile_details) {
          const profileDetails = JSON.parse(userData.profile_details);
          const aboutMeIsEmpty = !profileDetails.about_me;
         
          if (aboutMeIsEmpty) {
            return false
          }
        }
        return true
    
    }


    useEffect(() => {

        getChatCount()
        if(window.location.pathname=="/events/notification-list")
        {
            nsetCount(0)
            return 
        }
        getNotificationCount()
    }, [window.location.pathname])

    let getHover1 = (e) => {
        // if ((userData?.type == "event" && userData?.email)) {
        setMyState(setQuery, {
            hoverColor: e,
        })
        // }
    }
    let getHover2 = (e) => { 
        // if ((userData?.type == "event" && userData?.email)) {
            const slugName = window.location.href.split('/')[4];
            if(slugName){
                if(slugName == 'attendees'){
                    setMyState(setQuery, {
                        hoverColor: 'Event Attendees',
                    })
                }else if(slugName == 'agenda'){
                    setMyState(setQuery, {
                        hoverColor: 'Event Agenda',
                    })
                }else if(slugName == 'speakers'){
                    setMyState(setQuery, {
                        hoverColor: 'Event Speakers',
                    })
                }else if(slugName == 'chats'){
                    setMyState(setQuery, {
                        hoverColor: 'Event Chats',
                    })
                }else if(slugName == 'match'){
                    setMyState(setQuery, {
                        hoverColor: 'Event Match',
                    })
                }else{
                    setMyState(setQuery, {
                        hoverColor: null,
                    })
                }

            }
      
        // }
    }
    let logoutUser = () => {
        myStore.updateStoreData('userData', {});
        localStorage.removeItem('redirect');
        localStorage.removeItem('id');
        localStorage.removeItem(`${localType}_data`);
        window.location.replace('/')
    }
    let getChatCount = () => {
        if (localStorage.getItem("id")) {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(apiUrl + "getChatCount?id=" + localStorage.getItem("id"), requestOptions)
                .then((response) => response.json())
                .then(async (result) => {
                    setchatCount(result.data.length)

                })
        }
    }
    let back = () => {
        let d1 = document.getElementById("gotoBack_dv")
        if (d1) {
            d1.click()
        }
    }
    let getNotificationCount = () => {
        if (localStorage.getItem("id")) {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(apiUrl + "getNotification?id=" + localStorage.getItem("id") + "&count=true", requestOptions)
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        nsetCount(result.data)
                    }

                })
        }
    }

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <title>Event App</title>
            </Helmet>
            <div id="eventPageLoader" className={'mainPageLoader eventLoader'}>
                <div className='mc_mainLoader_dv'>
                    <div className='mc_isLoaderDesign'></div>
                </div>
            </div>

            {userData?.network_user_id &&
                <>
                    <div id="mainHeaderMenu" className='mc_eventHeaderMenu' style={{ backgroundColor: state.bodyColor, color: state.textColor }}>
                        <Link to={'/events/home'} className='mc_homePage'>{svg.app.homeIcon}</Link>
                        <h3>
                            <span>Welcome</span>
                            {userData?.ticket_code?.substring(0, 3).toUpperCase()=="LWG" ? "LWA" :userData?.ticket_code?.substring(0, 3).toUpperCase() } - 2024
                        </h3>
                        <ul>
                            <li>
                                <Link className='mc_pushCount' to={'/events/notification-list'}>
                                    {svg.app.notifiIcon}
                                    {nCount != 0 && <span className='mc_msgCount'>{nCount}</span>}
                                </Link>
                                {/* <a href={void(0)}>{svg.app.notifiIcon}</a> */}
                            </li>
                            <li className='hasDropdown' onClick={() => setMyState(setQuery, {
                                toggleMenu: !state.toggleMenu,
                            })}>
                                <a href={void (0)} className='mc_profileIcon_show'>
                                    {userData.profile_img ?
                                        <div className='mc_profileHead_img'
                                            style={{
                                                backgroundImage: `url(${userData.profile_img})`,
                                            }}>
                                        </div>
                                        :
                                        <div className='mc_profileHead_img'>{userData?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
                                    }
                                </a>
                                <ul ref={menuRef} className={`mc_profile_dropdown ${state.toggleMenu == true ? 'showProfile' : ''}`}>
                                    <li>
                                        <Link to={'/events/profile'}>My Profile</Link>
                                    </li>
                                    <li>
                                        <Link to={'/events/qr-code'}>Event Ticket Code</Link>
                                    </li>
                                    {/* <li>
                                        <Link to={'/events/notification-list'}>Notification List</Link>
                                    </li> */}
                                    <li>
                                        <a href={void (0)} onClick={() => logoutUser()}>Logout</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </>
            }

            {!load &&
                <>
                    <div className='mc_eventBody' style={{ backgroundColor: '#f7f7f7' }}></div>
                </>}
            <Routes>
                <Route exact path='events'>
                    <Route path='/events' element={<EventLogin />}></Route>
                    <Route path='/events/push756878Notifi' element={<Notification title="Notifications" />}></Route>
                    <Route path='/events/home' element={<Home />}></Route>
                    <Route path='/events/profile' element={<EventProfile />}></Route>
                    <Route path='/events/profile/:id' element={<EventProfile />}></Route>
                    <Route path='/events/qr-code' element={<QRCode />}></Route>
                    <Route path='/events/notification-list' element={<NotificationList title="Notifications" />}></Route>
                    {load &&
                        <>
                            <Route path='/events/attendees' element={<AttendanceList className={`mc_attendanceList_dv`} socekt={socket} title={'Attendees'} />}></Route>
                            <Route path='/events/attendees/:id' element={<AttendanceList className={`mc_attendanceList_dv`} socekt={socket} title={'Attendees'} />}></Route>
                            <Route path='/events/chats' element={<ChatsEvents chatCount={setchatCount} className={`mc_chatsDv`} socekt={socket} title={'Chats'} />}></Route>
                            <Route path='/events/chats/:id' element={<ChatsEvents chatCount={setchatCount} className={`mc_chatsDv`} socekt={socket} title={'Chats'} />}></Route>
                            <Route path='/events/match' element={<MatchTags title={'Match'} socekt={socket} />}></Route>
                            <Route path='/events/match/:id' element={<MatchTags title={'Match'} socekt={socket} />}></Route>
                        </>
                    }

                    <Route path='/events/agenda' element={<Agenda className={`mc_agandaDv`} title={'Agenda'} />}></Route>
                    <Route path='/events/speakers' element={<Speakers className={`mc_speakerDv`} title={'Speakers'} />}></Route>
                    <Route path='/events/speakers/:id' element={<Speakers className={`mc_speakerDv`} title={'Speakers'} />}></Route>

                    <Route path='/events/attendee-profile/:id' element={<EventProfile />}></Route>

                </Route>
            </Routes>
            {userData?.type == "event" &&
                <div className='mc_mobileFooter' id="mainfooter">
                    <ul className='mc_footerIcon_list'>
                        <li>
                            {/* to={(userData?.type == "event" && userData?.network_user_id) ? '/events/attendees' : '#'} */}
                            <a className='mc_footItems'
                                style={{
                                    color: state.hoverColor == 'Event Attendees' ? hoverEventColor : '#666',
                                    fill: state.hoverColor == 'Event Attendees' ? hoverEventColor : '#666',
                                    stroke: state.hoverColor == 'Event Attendees' ? hoverEventColor : '#666',
                                    cursor: (userData?.type == "event" && userData?.email) ? 'pointer' : 'unset',
                                    // borderBottom: state.hoverColor == 'Event Attendees' ? '3px solid' : '3px solid transparent',
                                }}
                                onMouseEnter={(e) => getHover1('Event Attendees')}
                                onMouseLeave={(e) => getHover2('Event Attendees')}
                                onClick={() => {
                                    if (userData?.type == "event" && userData?.email) {
                                        navigate('/events/attendees')
                                    }
                                    back()
                                }}
                                aria-disabled
                            >
                                {svg.app.userProfile}
                                <span>Attendees</span>
                            </a>
                        </li>
                        <li>
                            <Link to={(userData?.type == "event" && checkUserDetail()) ? '/events/agenda' : '#'} className='mc_footItems'
                                style={{
                                    color: state.hoverColor == 'Event Agenda' ? hoverEventColor : '#666',
                                    fill: state.hoverColor == 'Event Agenda' ? hoverEventColor : '#666',
                                    stroke: state.hoverColor == 'Event Agenda' ? hoverEventColor : '#666',
                                    cursor: (userData?.type == "event" && userData?.email) ? 'pointer' : 'unset',
                                    // borderBottom: state.hoverColor == 'Event Agenda' ? '3px solid' : '3px solid transparent'
                                }}
                                onMouseEnter={(e) => getHover1('Event Agenda')}
                                onMouseLeave={(e) => getHover2('Event Agenda')}
                                onClick={() => setMyState(setQuery, {
                                    pageType: 'Agenda',
                                })}
                            >
                                {svg.app.AgendaIcon}
                                <span className='mc_activeBorder' style={{ backgroundColor: state.hoverColor == 'Event Agenda' ? hoverEventColor : '#666', }}></span>
                                <span>Agenda</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={(userData?.type == "event" && checkUserDetail()) ? '/events/speakers' : '#'} className='mc_footItems'
                                style={{
                                    color: state.hoverColor == 'Event Speakers' ? hoverEventColor : '#666',
                                    fill: state.hoverColor == 'Event Speakers' ? hoverEventColor : '#666',
                                    stroke: state.hoverColor == 'Event Speakers' ? hoverEventColor : '#666',
                                    cursor: (userData?.type == "event" && userData?.email) ? 'pointer' : 'unset',
                                    // borderBottom: state.hoverColor == 'Event Speakers' ? '3px solid' : '3px solid transparent' 
                                }}
                                onMouseEnter={(e) => getHover1('Event Speakers')}
                                onMouseLeave={(e) => getHover2('Event Speakers')}
                                onClick={() => {
                                    setMyState(setQuery, {
                                        pageType: 'Speakers',
                                    })
                                    let s1 = document.getElementById("backspeaker")
                                    if (s1) {
                                        s1.click()
                                    }
                                }}
                            >
                                {svg.app.speakersIcon}
                                <span>Speakers</span>
                            </Link>
                        </li>
                        <li>

                            <a className='mc_footItems'
                                style={{
                                    color: state.hoverColor == 'Event Chats' ? hoverEventColor : '#666',
                                    fill: state.hoverColor == 'Event Chats' ? hoverEventColor : '#666',
                                    // stroke: state.hoverColor == 'Event Chats' ? hoverEventColor : '#666', 
                                    cursor: (userData?.type == "event" && userData?.email) ? 'pointer' : 'unset',
                                    // borderBottom: state.hoverColor == 'Event Chats' ? '3px solid' : '3px solid transparent'  
                                }}
                                onMouseEnter={(e) => getHover1('Event Chats')}
                                onMouseLeave={(e) => getHover2('Event Chats')}
                                onClick={() => {
                                    if (userData?.type == "event" && checkUserDetail()) {
                                        navigate('/events/chats')
                                    }
                                    back()
                                }
                                }
                            >
                                {chatCount > 0 && <i className='mc_notification_count'> {chatCount} </i>}
                                {svg.app.chatIconFoot}
                                <span>Chats</span>
                            </a>
                        </li>
                        <li>
                            <a className='mc_footItems mc_matchIcon'
                                style={{
                                    stroke: state.hoverColor == 'Event Match' ? hoverEventColor : '#666',
                                    color: state.hoverColor == 'Event Match' ? hoverEventColor : '#666',
                                    fill: state.hoverColor == 'Event Match' ? hoverEventColor : '#666',
                                    cursor: (userData?.type == "event" && userData?.email) ? 'pointer' : 'unset',
                                    // borderBottom: state.hoverColor == 'Event Match' ? '3px solid' : '3px solid transparent' 
                                }}
                                onMouseEnter={(e) => getHover1('Event Match')}
                                onMouseLeave={(e) => getHover2('Event Match')}
                                onClick={() => {
                                    if (userData?.type == "event" && checkUserDetail()) {
                                        navigate('/events/match')
                                    }
                                    back()
                                }}
                            >
                                {svg.app.matchIcon}
                                <span>Match</span>
                            </a>
                        </li>
                    </ul>
                </div>
            }
            <ToastContainer />
        </>
    )
}

export default EventRoutes


function PageNotFound () {
    return (
        <div className="flex justify-center items-center h-screen">
        <div className=" text-center">
          <h1 className="mb-4 text-4xl">404</h1>
          <p>The page you are looking for does not exist.</p>
        </div>
      </div>
      
    )
}

export default PageNotFound 
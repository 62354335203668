import React, {useState, useEffect, useRef} from 'react'
import svg from '../svg';
import { useForm } from "react-hook-form"
import {NoDataWrapper, common , setMyState} from '../../components/Comman'; 
import { toast } from "react-toastify";
// import { UploadOutlined } from '@ant-design/icons';
// import { Button, Upload } from 'antd';
// import type { UploadFile } from 'antd/es/upload/interface';

const Uploadpost = (props) => {
    // console.log(props, 'postdata');
    const [buttonName, setButtonName] = useState("Play");
    const [audio, setAudio] = useState();

    const[state, setQuery]=useState({
        togglePost : false,
        upLoadFile : props.fileType,
        postTitle : '',
        postUploadFile : '',
        postContent : '',
        rating : '',
        createPost : false,
        postId : '',
        isPlay : false,
        page : 1,
        loader : false,
        show : false,
        profileImage : '',
        videoURL : '',
        audioUrl: '',
    });
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()
    
    const handledFileUpload=(e, page)=>{   
        // console.log(e.target.files[0], 'File');
        if(e.target?.files[0]){ 
            const objectURL = window.URL.createObjectURL(e.target.files[0]);
            // console.log("e.target.files",objectURL)
           
            setMyState(setQuery,{
                ...state,
                postUploadFile : objectURL,
            })
        }
    }
    const onSubmit = (data) => {
        // navigate('./home')
        console.log(data, 'FormPostDAta');
        if(data == 'undefined'){
            return;
            let uploadFile = data?.Upload[0];
            console.log(uploadFile, 'FormData');
            setMyState(setQuery,{
                ...state,
                createPost : !state.createPost,
                postUploadFile : uploadFile[0]?.name,
            })
        }
        if(data != ''){
            setMyState(setQuery,{
                ...state,
                createPost : !state.createPost,
                // postUploadFile : uploadFile[0]?.name,
            })
        }else{
            setMyState(setQuery,{
                ...state,
            })

        }
        // handledFileUpload(uploadFile)
    }  
    const getPostHandeled=(e, _id)=>{
        console.log(e, 'PostTetx', _id);
        // if(e.target.value != ''){
        //     alert('ksdsk')
        // }
        setMyState(setQuery,{
            ...state,
            togglePost : !state.togglePost,
            postContent: e.target.value,
        })
    }
    const getVideoUrl=(e)=>{
        // fetch(e.target.value).then(results => {
        //     const videosObj = results.json().then(console.log);
        //     // console.log(videosObj, 'videosObjvideosObjvideosObj');
            
        // })
        console.log(e, 'VideoUrl');
        setMyState(setQuery,{
            ...state,
            videoURL : e.target.value
        })
    }
    // useEffect(()=>{
    //     ''
    // }, [state.postUploadFile])
    // const createPostData=(event, postId)=>{
    //     setMyState(setQuery,{
    //         ...state,
    //         createPost : !state.createPost,
    //         postId : postId,
    //     })
    // }
    const [isVideo, setVideo] = useState();
    
    var a;
    useEffect(() => {
        if (a) {
            a.pause();
            a = null;
            setButtonName("Play");
        }
        if (audio) {
            a = new Audio(audio);
            a.onended = () => {
                setButtonName("Play");
            };
        }
    }, [audio]);

    const handleClick = () => {
        if (buttonName == "Play") {
            a.play();
            setButtonName("Pause");
        } else {
            a.pause();
            setButtonName("Play");
        }
    };
    const [fileInput, setFileInput] = useState('');
    const [uploadedURL, setUploadedURL] = useState('');
    const addFile = (e) => {
        console.log(e, 'Audio File');
        // if (e.target.files[0]) {
        //     setMyState(setQuery, {
        //         audioUrl : URL.createObjectURL(e.target.files[0])
        //     });  
        // }
        if(e.target?.files[0]){
            setMyState(setQuery, {
                audioUrl : URL.createObjectURL(e.target.files[0])
            }); 
            var mediaTag = document.createElement(state.upLoadFile == 'audio' ? 'audio' : 'video');
            mediaTag.preload = 'metadata';

            mediaTag.onloadedmetadata = function() {
                window.URL.revokeObjectURL(mediaTag.src);
                var duration = mediaTag.duration;
                console.log(duration);
                if(duration > process.env.MAX_UPLOAD_FILE_TIME){
                    toast.error(`Maximum allowed length of ${state.upLoadFile == 'audio' ? 'audio' : 'video'} is ${process.env.MAX_UPLOAD_FILE_TIME/60} Minutes.`);
                }else{
                    // setMyState(setQuery,{ 
                    //     fileInput : e.target.files,
                    // })
                    handleUploadFile(e);
                }
            }

            mediaTag.src = URL.createObjectURL(e.target?.files[0]);
        }
    };
    // For testing code //
    
    // const props.fileType = {
    //     action: '//jsonplaceholder.typicode.com/posts/',
    //     listType: 'picture',
    //     previewFile(file) {
    //       console.log('Your upload file:', file);
    //       // Your process logic. Here we just mock to the same file
    //       return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
    //         method: 'POST',
    //         body: file,
    //       })
    //         .then(res => res.json())
    //         .then(({ thumbnail }) => thumbnail);
    //     },
    //   };
    
    const handleUploadFile = (e) => {
      setUploadedURL('');
      const file = e.target.files[0];
      setFileInput(e.target.files);

      if (!file) return;
      const fileType = file.type;
      if (fileType.startsWith('audio/')) {
          if (state.upLoadFile == 'audio') {
              const url = URL.createObjectURL(file); 
              // console.log(url, "urlurl");
              setUploadedURL(url);
          } else {
              toast.error('Unsupported file type. Please select an video file.');
          }
      } else if (fileType.startsWith('video/')) {
          if (state.upLoadFile == 'video') {
              const url = URL.createObjectURL(file); 
              // console.log(url, "urlurl");
              setUploadedURL(url);
          } else {
              toast.error('Unsupported file type. Please select an audio file.');
          }
      } else {
          toast.error('Unsupported file type. Please select an audio or video file.');
      }
  }
  return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-wrap p-5'>
                <div className='mc_formImg_dv'>
                    <img src={'/assets/images/customer1.jpeg'} alt='customer' className='rounded-full mr-4' width={'46px'} height={'46px'}/>
                </div>
                <div className='mc_textArea_form'>
                    {/* <Upload {...props.fileType}>
                        <Button> Upload</Button>
                    </Upload> */}
                    {/* {state.togglePost == false ? 
                    <textarea rows={'3'} className='mc_textarea' placeholder='Post your thoughts' onChange={(e)=> getPostHandeled(e)}></textarea>
                    :  */}
                    <div className='mc_postForm'>
                        <textarea rows={'3'} className='mc_withoutBordered w-full'  {...register("TextArea", {required: true})}
                            //onChange={(e)=> getPostHandeled(e,  state.postId)}
                        ></textarea>
                        {errors.TextArea && <p className='mc_errortext'>This field is required.</p>}
                        {(state.upLoadFile == 'image' && state.postUploadFile == '') ? 
                            <div className='mc_fileUpload_form mt-3 p-6'>
                                <label className='mc_ucImage'>
                                    <input type='file' className='mc_withoutBordered' accessKey={state.upLoadFile} 
                                        {...register("Upload", {required: state.upLoadFile == 'image' ? true : false})}
                                        onChange={(e)=> handledFileUpload(e, state.page)}
                                    />
                                    <span className='mc_plusIcon'>+</span>
                                    <span className='text-sm text-gray-700 font-medium w-full'>Add your {state.upLoadFile} on drag and drop</span>
                                </label>
                                {errors.Upload && <p className='mc_errortext'>This field is required.</p>}
                            </div>
                        : state.postUploadFile != '' ?
                            <div className='mc_fileUpload_form mt-3'>
                                <a href={() => false} className='mc_closeImg' onClick={()=> setMyState(setQuery,{postUploadFile : ''})}>{svg.app.crossIcon}</a>
                                <img src={String(state.postUploadFile)} alt='upload' className='rounded-sm mx-auto block'/>
                                {/* <img src={`/assets/images/${state.postUploadFile}`} alt='upload' className='rounded-lg mx-auto block'/> */}
                            </div>
                        : state.upLoadFile == 'video' ?
                            <div className='mc_fileUpload_form mt-3 p-6'>
                                <label className='mc_ucImage'>
                                    {/* <input type='file' className='mc_withoutBordered' accessKey={state.upLoadFile} onChange={(e)=> handledFileUpload(e, state.page)}/> */}
                                    <span className='mc_plusIcon mc_videoIcon_dv'>
                                        <span className='mc_vidPlay_icon'></span>
                                    </span>
                                    <span className='text-sm text-gray-600 font-light w-full'>Enter your video URL below</span>
                                </label>
                                <div className='mc_formGroup mc_addInner_icon'>
                                    <input className='mc_input' type='text' placeholder='Paste your video URL' 
                                        {...register("VideoText", {required : state.upLoadFile == 'video' ? true : false})}
                                        value={state.videoURL}
                                        onChange={(e)=> getVideoUrl(e)}
                                    />
                                    <span>{svg.app.linkIcon}</span>
                                    <a href={()=>false} className='mc_crossInput' onClick={()=> setMyState(setQuery,{
                                        ...state, 
                                        videoURL : '',
                                    })}>{svg.app.crossIcon}</a>
                                </div>
                                {errors.VideoText && <p className='mc_errortext'>This field is required.</p>}
                                {state.videoURL && 
                                    <div className='flex items-center justify-center bg-gray-100 rounded-lg p-3 mt-3'>
                                        <div className='mc_imgLeft'>
                                            <img src={'/assets/images/customer1.jpeg'} alt='video' width='81px' height='64px'/>
                                        </div>
                                        <div className='mc_videoLink'>
                                            <h5 className='text-sm font-semibold text-black'>youth.be</h5>
                                            <p className='text-sm font-light text-black'>{state.videoURL}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        : state.upLoadFile == 'audio' &&                              
                            <div className='mc_fileUpload_form mt-3 p-6'>
                                {/* <button className='mc_btn' onClick={()=>handleClick()}>{buttonName}</button> */}
                                {/* { After Upload Audio File is Visibal } */}

                                {state.audioUrl.length ?  
                                    <>
                                        <div className='mc_audioHolder'>
                                            <div className='mc_uploadedAdio text-center'>
                                                <span className='text-center flex items-center justify-center mb-2'>{svg.app.greenTickCircle}</span>
                                                <p className='mb-1'>File Uploaded Successfully</p>
                                                <p>Drag and drop or <u className='color-themeColor'>browse file here</u></p>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center bg-gray-100 rounded-lg p-3 mt-3'>
                                            <div className='mc_imgLeft_icon'>
                                                {svg.app.audioFileIcon}
                                            </div>
                                            <div className='mc_videoLink_icon justify-between flex items-center'>
                                                <p className='text-sm font-light text-black'>{state.audioUrl}</p>
                                                <a href={()=> false} onClick={()=> setMyState(setQuery, {audioUrl : ''})}>{svg.app.crossIcon}</a>
                                            </div>
                                        </div> 
                                    </>
                                :   <label className='mc_ucImage'>
                                        {/* <input type='file' className='mc_withoutBordered' accessKey={state.upLoadFile} onChange={(e)=> addFile(e, state.page)}/> */}
                                        <input
                                                type='file'
                                                placeholder='Upload Video'
                                                id='uploadVideoFile'
                                                className='mc_withoutBordered'
                                                onChange={e => {
                                                    addFile(e, state.page)
                                                }} accept={state.upLoadFile == 'video' ? 'video/mp4' : 'audio/mp3,audio/wav'}
                                            />
                                        <span className='text-center w-full block mc_plusIcon mc_audioIcon_dv'>{svg.app.audioIcon}</span>
                                        <span className='text-sm text-gray-600 font-light w-full'>Click and Add your audio file or on drag and drop</span>
                                    </label>    
                                }

                                {/* { After Add Link in Video is Visibal } */}
                                {/* <div className='mc_formGroup mc_addInner_icon'>
                                    <input className='mc_input' type='text' placeholder='Paste your video URL' 
                                        // value={'https://youtu.be/B_p1lpkzkQY?si=L7Uxbwh9Quk4hj1M'}
                                    />
                                    <span>{svg.app.linkIcon}</span>
                                </div>
                                <div className='flex items-center justify-center bg-gray-100 rounded-lg p-3 mt-3'>
                                    <div className='mc_imgLeft'>
                                        <img src={'/assets/images/customer1.jpeg'} alt='video' width='81px' height='64px'/>
                                    </div>
                                    <div className='mc_videoLink'>
                                        <h5 className='text-sm font-semibold text-black'>youth.be</h5>
                                        <p className='text-sm font-light text-black'>https://youtu.be/B_p1lpkzkQY?si=L7Uxbwh9Quk…</p>
                                    </div>
                                </div> */}
                            </div>
                        
                        }
                            
                    </div>  
                    {/* } */}
                </div>
            </div>
            <div className='flex mt-3 p-5 mc_bordered_list items-center justify-between'>
                <div className='flex mr-3 items-center'>
                    <p className='text-sm text-black'>Add to <br/>your Post</p>
                    <div className='mr-2 ml-3'>
                        <label className={`mc_list ${state?.upLoadFile == 'image' ? 'activeIcon' : ''}`}>
                            <input className='mc_radioBtn' type='radio' name='fileType' onChange={()=> setMyState(setQuery,{ upLoadFile : 'image'})}/>
                            {svg.app.imageIcon}
                        </label>
                    </div>
                    <div className='mr-2'>
                        <label className={`mc_list ${state?.upLoadFile == 'video' ? 'activeIcon' : ''}`}>
                            <input className='mc_radioBtn' type='radio' name='fileType' onChange={()=> setMyState(setQuery,{ upLoadFile : 'video'})}/>
                            {svg.app.playVideoIcon}
                        </label>
                    </div>
                    <div className='mr-2'>
                        <label className={`mc_list ${state?.upLoadFile == 'audio' ? 'activeIcon' : ''}`}>
                            <input className='mc_radioBtn' type='radio' name='fileType' onChange={()=> setMyState(setQuery,{ upLoadFile : 'audio'})}/>
                            {svg.app.audioIcon}
                        </label>
                    </div>
                </div>
                <div className=''>
                    <button type='submit' className='mc_btn btn-base'>Create Post</button>
                </div>
            </div>
        </form>
    )
}

export default Uploadpost;
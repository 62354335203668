import React, { useEffect, useState } from 'react'
import svg from '../svg'
import { common, dataLoader, setMyState } from '../Comman'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { appStore } from '../../zustand/store';
import { speakerData, speakerLWGData } from './speakerData';

function Speakers(props) {
    let myStore = appStore(state => state);
    let brandSetting = myStore.brandSetting;
    let userData = myStore.userData;
    const [state, setQuery] = useState({
        bodyColor : '#F7f7f7',
        themeColor : '#28E9BE',
        textColor: '#4A5251',
        hoverColor: null,
        speakerList: [],
        dataLoading:false,
        speakerIndex : null,
    })
    let getHover1 = (e) => {
        setMyState(setQuery, {
            hoverColor: e,
        })
    }
    let getHover2 = (e) => {
        setMyState(setQuery, {
            hoverColor: null,
        })
    }

    const navigator = useNavigate();
    let { id } = useParams();
  
    useEffect(() => {
      if (!id) {
        setQuery({
          ...state,
          speakerIndex: null,
        });
        // navigator("/events/speakers");
      }
    }, [id]);
    useEffect(() => {
        let c1 = document.getElementById("mainfooter");
        let header = document.getElementById("mainHeaderMenu");
       
          if (c1.classList.contains("hidden")) {
            c1.classList.remove("hidden");
            header.classList.remove("hidden");
          }
        
      }, []);

    useEffect(() => {
        if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'ucs'){
            setMyState(setQuery, {
                speakerList: speakerData
            })
        }else if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'lwg'){
            setMyState(setQuery, {
                speakerList: speakerLWGData
            })
        }else{
            getSpeaker();
        }
       
    }, [])
    console.log(speakerData,"speakerData")
    const getSpeaker = () => {
        setMyState(setQuery, {
            dataLoading: true
        })
        common.getAPI({
            isLoader: true,
            url: 'get-speakerlist',
            method: 'POST',
            data: { event_id: userData.event_id }
        }, (resp) => {
            setMyState(setQuery, {
                dataLoading: false
            })
            if (resp.success == true) {
                setMyState(setQuery, {
                    speakerList: resp.speaker_list,
                })

            }
        });
    }

    const getSpeakerDetail=(i, detail)=>{

        setQuery({
            ...state,
            speakerIndex : detail,
        })
    }
    return (
        <>
        {state.speakerIndex ?
            <div className='mc_eventBody mc_profileScroller'>
            <div class="mc_componentData mc_userProfile_head  mc_speakerBg">
                <div class="mc_userProfileImage_holder">
                    <div class="mc_headTitle_dv">
                    <div class="mc_backBtn_dv">
                        <a href={() => false} id="backspeaker" onClick={()=> 
                            
                        {
                            setQuery({
                                ...state,
                                speakerIndex : null,
                            })
                            navigator("/events/speakers");
                        }}>
                        <span style={{ fill: state.themeColor }}>
                            {svg.app.backArrowBtn}
                        </span>
                        <span class="ml-2"></span>
                        </a>
                    </div>
                    <h2 class="flex items-center justify-center">Speaker Bio</h2>
                    </div>
                    <div class="mc_profilePic_holder">
                        <div class="mc_alfaProfile_img" 
                            style={{backgroundImage:` url(${state.speakerIndex.img})`}}>
                        </div>
                            <h3 class="text-sm font-semibold">{state.speakerIndex.speaker_name}</h3>
                            <p class="text-xs">{state.speakerIndex.about}</p>                     
                        </div>
                        {state.speakerIndex?.AboutPara && <p style={{marginTop:'10px',fontSize:'12px'}}>{state.speakerIndex.AboutPara}</p>}
                    </div>
                    <div class="px-4 mc_profileSection">
                        <div class="mc_eventProfilePage">
                            <div class="mc_profilePic">
                                <div class="mc_profileContent mt-3">                               
                                    <div class="mc_manageContent">
                                    <p dangerouslySetInnerHTML={{__html:state.speakerIndex.description}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className='mc_eventBody'
                style={{ backgroundColor: state.bodyColor, color: state.textColor }}
            >
                <div className='mc_headTitle_dv' >
                    <h2 style={{ color: state.themeColor }}>{props.title}</h2>
                </div>
                <div className='mc_componentData'>
                    <div className='mc_speakerList_dv mc_speakerList_scroller'>

                    {state.dataLoading ? [...Array(3)].map(count => <div key={count}>{dataLoader.eventLoader}</div>)  : 
                            state.speakerList.map((item, i) => (
                                <div className='mc_speakerData flex px-3' key={i}  onClick={()=> {
                                    // getSpeakerDetail(i, item);
                                    // navigator("/events/speakers/" + i);
                                }}>
                                    <div className='mc_holdImage mr-3'>
                                        <img src={item?.img} alt='Speaker Image' width={'100px'} height={'auto'} />
                                    </div>
                                    <div className='mc_holdContent'>
                                        <h5 className='text-sm font-semibold' style={{color: state.themeColor}}>
                                            {item?.speaker_name}
                                        </h5>
                                        <p className='text-sm font-light'>{item?.about}</p>
                                    </div>
                                </div>
                            ))
                        }

                            
                    </div>
                </div>
            </div>
            }
            
        </>
    )
}

export default Speakers;
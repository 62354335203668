import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import svg from '../svg'
import { setMyState, common, dataLoader } from '../Comman'
import { appStore } from '../../zustand/store';

export default function Home(props) {
    let myStore = appStore(state => state);
    let userData = myStore.userData;
    const [state, setQuery] = useState({
        bodyColor: '#f7f7f7',
        themeColor: '#0B9D7C',
        textColor: '#4A5251',
        hoverColor: null,
        userEventInfoList: [],
        dataLoading:false,
        daysTab : 'day1',
    })
    let getHover1 = (e) => {
        setMyState(setQuery, {
            hoverColor: e,
        })
    }
    let getHover2 = (e) => {
        setMyState(setQuery, {
            hoverColor: null,
        })
    }
    const getEventInfolist = () => {
        setMyState(setQuery, {
            dataLoading: true
        })
        common.getAPI({
            isLoader: true,
            url: 'event-details',
            method: 'POST',
            data: { event_id: userData.event_id },
        }, (resp) => {
            setMyState(setQuery, {
                dataLoading: false
            })
            if (resp.success == true) {
                setMyState(setQuery, {
                    userEventInfoList: resp.event_list
                })
            }
            console.log(resp, 'Response Home page Data');
        })
    }
    useEffect(() => {
        if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'ucs'){
            setMyState(setQuery, {
                userEventInfoList: [{
                    "date": "From Saturday , May 4, 2024, From 8 AM to Sunday , May 5 ,2024 6:00 PM",
                    "location": "Taj Hotel Yeshwantpur , Tumkur Rd, Yeshwanthpur Industrial Area, Phase 1, Yeswanthpur, Bengaluru, Karnataka 560022"
                }]
            })
        }else if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'lwg'){
            setMyState(setQuery, {
                userEventInfoList: [{
                    "date": "From Saturday , Aug 24, 2024, From 9 AM to Sunday , Aug 25 ,2024 2:10 PM",
                    "location": "Taj Hotel Yeshwantpur , Tumkur Rd, Yeshwanthpur Industrial Area, Phase 1, Yeswanthpur, Bengaluru, Karnataka 560022"
                }]
            })
        }else{ 
          getEventInfolist();
        }
    }, [])

    useEffect(() => {
        let c1 = document.getElementById("mainfooter");
        let header = document.getElementById("mainHeaderMenu");
       
          if (c1.classList.contains("hidden")) {
            c1.classList.remove("hidden");
            header.classList.remove("hidden");
          }
        
      }, []);


      const checkbanner=()=>{
        if(userData?.ticket_code)
        {
            if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'ucs'){
               return '/assets/images/banner_UCS.jpg' 
            }else if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'lwg'){
                return '/assets/images/eventLwgBanner.jpg'
            }else if(userData?.ticket_code.substring(0, 3).toLowerCase() === 'fgs'){
                return '/assets/images/mobile/eventbanner.png'
            }
        }else{
            return "'/assets/images/Event1.jpg'"
        }
    }
    return (
        <>
            <div className='mc_homeWrapper'>
                <div className='mc_bannerEvent'>
                    <img src={checkbanner()} alt='Banner Image' className='w-full'/>
                </div>
                <div className='mx_innerpage Wrapper pt-4 px-4 pb-0'>
                    <div className='mc_pageMenuList'>
                        <ul>
                            <li>
                                <Link to={'/events/attendees'} className='mc_listIcon_page'>
                                    <span className='mc_pageIconCircle mc_pinkIcon'>{svg.app.userProfile}</span>  
                                    <span className='mc_pageName_list'>Attendees {svg.app.arrowLeftIcon}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/events/agenda'} className='mc_listIcon_page'>
                                    <span className='mc_pageIconCircle mc_bluelIcon'>{svg.app.AgendaIcon}</span>  
                                    <span className='mc_pageName_list'>Agenda {svg.app.arrowLeftIcon}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/events/speakers'} className='mc_listIcon_page'>
                                    <span className='mc_pageIconCircle mc_yellowIcon'>{svg.app.speakersIcon}</span>  
                                    <span className='mc_pageName_list'>Speakers {svg.app.arrowLeftIcon}</span>
                                </Link>
                            </li>
                        </ul>
                        <div className='mc_bottomContent'>
                            <h3>Information</h3>
                            {state.userEventInfoList.map((t, i) => {
                                return (
                                <>
                                    <div  className='mc_dateTime flax items-center justify-center flex-wrapper mb-2'>
                                        <span className='mc_calangerList text-sm w-full'>
                                            {svg.app.clockIconList} 
                                            <span>{t.date}</span>
                                        </span>
                                    </div>
                                    <div className='mc_dateTime flax items-center justify-center flex-wrapper mb-2'>
                                        <span className='mc_calangerList text-sm w-full'>
                                            {svg.app.mapIcon} 
                                            <span>Dates are displayed in the event time zone (Asia/Kolkata).</span>
                                        </span>
                                    </div>
                                    {userData?.ticket_code.substring(0, 3).toLowerCase() != 'lwg' &&
                                    <div  className='mc_dateTime flax items-center justify-center flex-wrapper mb-2'>
                                        <span className='mc_calangerList text-sm w-full'>
                                            {svg.app.locationIcon} 
                                            <span>{t.location}</span>
                                        </span>
                                    </div>
                                }
                                </>
                                )}
                            )}
                        </div>
                    </div>
                </div>
            </div>

        
        </>
    )
}

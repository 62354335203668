import React, { useState, useRef, useEffect } from "react";
import { appStore } from "../../zustand/store";
import { common, setMyState } from "../Comman";
import svg from "../svg";
import {
    TECollapse,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
} from "tw-elements-react";
import { toast } from "react-toastify";
import config from "../../config";
import moment from "moment";
import EmojiBox from "../../emoji/EmojiBox";
import SingleComment from "./SingleComment";
// import EmojiBox from "../../emoji/EmojiBox";

export default function CommentsData(props) {
    // console.log("props",props)
    let alfaUrl = config.REACT_APP_BUCKET_URL
    const props_data = props?.postComments;
    const propsDataItems = props?.propsData;

    const l1 = props?.postComments || [];
    const [innerReplayPost , setinnerReplayPost]= useState(props?.postComments || []);
    const [mainPostArray, setMainPostArray] = useState(props?.mainPostData);
    const [activeInnerElement, setActiveInnerElement] = useState("");
    const currentTime = moment()
    let myStore = appStore(state => state);
    let brandSetting = myStore.brandSetting;
    let userData = myStore.userData.MembershipUser;
    const ref = useRef(null);
    const [ispostData, setIsPostData] = useState();
   
   

    const [state, setQuery] = useState({
        buttonColor : null,
        childCommentData : innerReplayPost,
        commentTitle : '',
        parent_id : innerReplayPost?.parent_id,
        post_id : innerReplayPost?.post_id,
        likeComment : null,
        total_reply_comment : 0,
        pageNo : 1,
    })
    
    const getColor1=(type)=>{
        setMyState(setQuery, {
            ...state,
            buttonColor : type,
        })
    }
    const getColor2=(type)=>{
        setMyState(setQuery, {
            ...state,
            buttonColor : null,
        })
    }
    const [replyCommentCount, setReplyCommentCount] = useState(0);

    useEffect(() => {
        setinnerReplayPost(props?.postComments || []);
    }, [props?.postComments]);

    const handleClick = (value , index, Childeindex) => {
        // setReplyCommentCount(index+1);
        if (value === activeInnerElement) {
            setActiveInnerElement("");
        } else {
            setActiveInnerElement(value);
        }
    };
    // const getReplyCount=()=>{
    //     const replyaComment = props?.postComments.find(items => items.comment_id == '');
    // }
    const handlePostInnerData = (text , post, parent, index) => {
        // return
        if(ref.current.value){
            // 👇️ access textarea value
            setIsPostData(text)
            setMyState(setQuery, {
                parent_id : parent,
                post_id : post,
            })
            getChildComments(ispostData,post,parent,index);
            
        }else{
            toast.error('Comment feild is required');
        } 
    }    
    const propsData = {
        funnel_id : propsDataItems.bodyData.funnel_id,
        funnel_page_id: propsDataItems.bodyData.funnel_page_id,
        lesson_id: propsDataItems.lessonId,
        member_id: propsDataItems.bodyData.member_id,
        page :1,
        token: propsDataItems.bodyData.token,
    }
    let [replyCount, setReplyCount] = useState(0);

    const getChildComments=(text, psid, pid, index)=>{ 
        const propsData1 = {
            funnel_id : propsDataItems.bodyData.funnel_id,
            funnel_page_id: propsDataItems.bodyData.funnel_page_id,
            lesson_id: propsDataItems.lessonId,
            member_id: propsDataItems.bodyData.member_id,
            parent_id : pid,
            post_id : psid,
            comment : text,
            token: propsDataItems.bodyData.token,
        }
        common.getAPI({
            method: "Post",
            url: "lesson-comment",
            data: propsData1,
        },
        async (resp) => {
            if(resp.success == true){

                props.updateCountOfReply(psid , pid ,{
                    ...resp,
                    created_at : resp.created_at,
                    profile_img : userData.profile_img,
                    name : userData.name,
                    tot_likes : 0,
                    total_reply_comment :  0,
                });
              
                setinnerReplayPost((d2)=>[
                    ...d2,{
                        ...resp,
                        created_at : resp.created_at,
                        profile_img : userData.profile_img,
                        name : userData.name,
                        tot_likes : 0,
                        total_reply_comment :  0,
                    },
                ])
                setReplyCount(innerReplayPost?.length+1);
                setIsPostData('');
                
                // let filterPost =  mainPostArray.find(post => post.parent_id === resp.comment_id);
                
                // let filterPost =  l1.find(post => post.comment_id === resp.parent_id);
                // let d7=[...innerReplayPost]
                // d7[props?.replyCount].total_reply_comment=d7[props?.replyCount].total_reply_comment+1;
                // setinnerReplayPost(d7);
                // setReplyCommentCount(index+1);
                // const replyaComment = props?.postComments.find(items => items.comment_id == pid);
            }
        })
    };
    
    let getLikeCommentData=(data, index, likeCounts)=>{
        const likepost = {
            funnel_id : propsDataItems.bodyData.funnel_id,
            funnel_page_id: propsDataItems.bodyData.funnel_page_id,
            lesson_id: propsDataItems.lessonId,
            member_id: propsDataItems.bodyData.member_id,
            type : 2,
            fk_id : data,
            like :likeCounts ? 0 : 1,
            token: propsDataItems.bodyData.token,
        }
        common.getAPI({
            method: "Post",
            url: "lesson-like",
            data: likepost,
        },
        async (resp) => {
            if(resp.msg){
                toast.error(resp.msg)
            }else{
                let d3=[...innerReplayPost]
                d3[index].tot_likes=  likeCounts ?   d3[index].tot_likes - 1  :  d3[index].tot_likes+1;
                d3[index].like = d3[index].like ? 0 : 1
                setinnerReplayPost(d3);
                return
            }
        })
    }
    const [editCommentText, setEditCommentText] = useState(``);
    const [editCommentTextData, setEditCommentTextData] = useState('');


    const handelEditComment=(edittext, editIndex)=>{
        setEditCommentText(editIndex);
        setEditCommentTextData(edittext.comment)

    }
    const getUpdateCommentData=(editCommentTextData, postId, parentId, commentId, length )=>{
        let editCommentPara = {
            funnel_id : propsDataItems.bodyData.funnel_id,
            funnel_page_id: propsDataItems.bodyData.funnel_page_id,
            lesson_id: propsDataItems.lessonId,
            member_id: propsDataItems.bodyData.member_id,
            parent_id : parentId,
            post_id : postId,
            comment_id: commentId,
            comment : editCommentTextData,
            token: propsDataItems.bodyData.token,
        }
        common.getAPI({
            method: "Post",
            url: "lesson-comment",
            data: editCommentPara,
        },
        async (resp) => { 
            // if(resp, 'Responce commentData');
            if(resp.success == true){
           
                setEditCommentText('');
                setEditCommentTextData('');
            
                let newUpdatedArr =  innerReplayPost.map((val)=>{
                      if(val?.comment_id == commentId){
                         return {...val,comment : resp.comment }                   
                      }
                      return val
                })
              
                setinnerReplayPost(newUpdatedArr);
                // setinnerReplayPost((d6)=>[
                //     ...d6,{
                //         comment : resp.comment,
                //     },
                // ])
            }else {
                toast.error(resp.message)
            }
        })
    }
    useEffect(()=>{
        // getAllPostCommentData();
    }, [innerReplayPost, l1]);
    

    // const getAllPostCommentData=()=>{
    //     common.getAPI({
    //         method: "Post",
    //         url: "getlesson-post-comment",
    //         data: propsData,
    //     },
    //     async (resp) => { 
    //         // // if(filterPost == )
    //         // if(resp.success == true){
    //         //     const fResponce = resp.post;

    //         // }else {
    //         //     toast.error(resp.message)
    //         // }
    //     })
    // }
    
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isCommentData, setIsCommentData] = useState()
    const [isCommentId, setIsCommentId] = useState();

    const getConfirmData=(ComID, ComData)=>{
        setIsCommentData(ComData);
        setIsCommentId(ComID);
        setShowConfirmationModal(true)
    }
    const getDeleteCommentData=(pId,items)=>{
        let deletePost = {
            funnel_id : propsDataItems.bodyData.funnel_id,
            funnel_page_id: propsDataItems.bodyData.funnel_page_id,
            lesson_id: propsDataItems.lessonId,
            member_id: propsDataItems.bodyData.member_id,
            id :pId,
            type : 'comment',
            token: propsDataItems.bodyData.token,
        }
        common.getAPI({
            method: "Post",
            url: "delete-post-comment",
            data: deletePost,
        },
        async (resp) => { 
            if(resp.success == true){
                setShowConfirmationModal(false)
                toast.success(resp.message);
                props.updateCountOnDelete(items.post_id , pId, items.total_reply_comment,items.parent_id);
                // let newUpdatedArr =  innerReplayPost.filter((val)=>val?.comment_id != pId)
                // setinnerReplayPost(newUpdatedArr);
            }else{
                toast.error(resp.message)
            }            
        })
    }
    // useEffect(()=>{
    //     getAllPostCommentData();
    // },[state.pageNo])

    // const [pageLoader, setPageLoader] =useState(false)
    // const getMoreData=(type)=>{
    //     setPageLoader(true)
    //     if(type == 'viewMore'){
    //         setMyState(setQuery,{
    //             pageNo : state.pageNo+1
    //         })
    //     }else{
    //         setMyState(setQuery,{
    //             pageNo : state.pageNo-1
    //         })
    //     }
    //     setTimeout(() => {
    //         setPageLoader(false)
    //     }, 1000);
    // }
  return (
    <>
        <div className={`comment-input-box ${props.className}`}>            
            <div className="block rounded-lg dark:text-neutral-50 mc_grayBox_reply mc_boxInnerComment">                
                <div className='flex flex-wrap py-2 mc_commentGrayBox_dv'>
                    <div className='mc_userDetails_dv flex flex-start items-start justify-start h-auto w-full'>
                        <img src={userData.profile_img ? userData.profile_img : `${alfaUrl}/images/user.png`} width={'36px'} height={'36px'} className='rounded-full mr-3'/>
                        <div className="mc_commentBox_text w-full">
                            <h5 className="text-sm text-black font-semibold mb-1">{userData.name}</h5>
                            <textarea ref={ref} rows="1" className="mc_textarea w-full px-4 py-4" placeholder="Type here.."
                                id={`mc_discussionComment_${props?.commentId}_${props.postId}`}
                                value={ispostData}
                                onChange={(e) => {
                                    setIsPostData(e.target.value);
                                }}
                                onSelect={(e)=> {
                                    setIsPostData(e.target.value)
                                }}
                                >                  
                            </textarea>
                            
                        </div>
                    </div>
                    <div className='flex sm:flex-none justify-end mt-4 w-full mc_attachedFile'>
                        <div className='flex items-center'>
                            <span className='mc_memberEmoji'>
                                <EmojiBox
                                    targetInputId={`#mc_discussionComment_${props?.commentId}_${props.postId}`}
                                    parentEmojiBoxclassName={"emojiBoxParent"}
                                    boxPlacement={"left"}
                                >
                                </EmojiBox>
                            </span>
                            <a href={() => false} className='mc_btn btn-base' 
                                onClick={() => {
                                    handlePostInnerData(ispostData, props.postId, props?.commentId, props?.postComments?.length, props.parent_id);
                                }}
                                style={{color : brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'PostBtn' ? brandSetting.theme_color2 : brandSetting.theme_color2}`}}
                                // onMouseEnter={()=> getColor1('PostBtn')} 
                                // onMouseLeave={()=> getColor2('PostBtn')}
                            >Send {svg.app.sendPostIcon}</a>
                        </div>
                    </div>
                </div>
                {innerReplayPost?.length && innerReplayPost.map((items, idx)=>{ 
                    return (
                        <div key={idx} className="mc_dottedBorder">
                        {editCommentText == `editData_${idx}` ? 
                            <div className='flex flex-wrap py-2 mc_commentGrayBox_dv'>
                                <div className='mc_userDetails_dv flex flex-start items-start justify-start h-auto w-full'>
                                    <img src={items.profile_img ? items.profile_img : `${alfaUrl}/images/user.png`} width={'36px'} height={'36px'} className='rounded-full mr-3'/>
                                    <div className="mc_commentBox_text w-full">
                                        <h5 className="text-sm text-black font-semibold mb-1">{items.profile_name}</h5>
                                        <textarea ref={ref} rows="1" className="mc_textarea w-full px-4 py-4" placeholder="Type here.."
                                            id={`mc_discussionComment_${props.commentId}`}
                                            value={editCommentTextData}
                                            onChange={(e) => {
                                                setEditCommentTextData(e.target.value);
                                            }}
                                            onSelect={(e)=> {
                                                setEditCommentTextData(e.target.value)
                                            }}
                                            >                                
                                        </textarea>
                                        
                                    </div>
                                    <div className='flex sm:flex-none justify-end w-full mc_attachedFile'>
                                        <div className='flex items-center'>
                                            <span className='mc_memberEmoji'>
                                                <EmojiBox
                                                targetInputId={`#mc_discussionComment_${props.commentId}`}
                                                parentEmojiBoxclassName={"emojiBoxParent"}
                                                boxPlacement={"left"}
                                                />
                                            </span>
                                            <a href={() => false} className='mc_btn btn-base' 
                                                onClick={() => {
                                                    getUpdateCommentData(editCommentTextData, items?.post_id, items?.parent_id, items?.comment_id, innerReplayPost?.length);
                                                    
                                                }}
                                                style={{color : brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'PostBtn' ? brandSetting.theme_color2 : brandSetting.theme_color2}`}}
                                                // onMouseEnter={()=> getColor1('PostBtn')} 
                                                // onMouseLeave={()=> getColor2('PostBtn')}
                                            >Send {svg.app.sendPostIcon}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='mc_userDetails_dv flex flex-start items-start justify-start h-auto'>
                                <img src={items?.profile_img ? items.profile_img : `${alfaUrl}/images/user.png`} width={'36px'} height={'36px'} className='rounded-full mr-3'/> 
                                <div className='mc_userName'>
                                    <h5 className="text-sm text-black font-semibold mb-1">{items.profile_name}</h5>
                                    <span className='text-gray-500 text-xs'>{items.created_at}</span>
                                    {/* <h5 className='text-sm mt-3 mb-3'>{items.comment}</h5> */}
                                </div>
                            </div>
                        }
                            <div className='mc_userDetails_dv flex mb-5 flex-start items-start justify-start h-auto'>
                                {/* {console.log(props, 'propspropsprops')} */}
                                <SingleComment setVMText={(val)=>props?.setVMText(val)} showVMText={props?.showVMText} items={items}/>
                                <div className='mc_userName'>
                                    {/* <p className='text-xs text-black mb-2'>{items.replyContent}</p> */}
                                    <p className='flex items-center mc_likeReply'>
                                        <a href={() => false} className='flex items-center innerthemeIcon font-semibold mr-3 text-xs'
                                            style={{color: brandSetting.theme_color2, fill: brandSetting.theme_color2 }}
                                            onClick={()=>{
                                                getLikeCommentData(items?.comment_id ? items?.comment_id : items?.post_id ,idx, items?.like);
                                             
                                                setEditCommentText('');
                                            }}
                                        >
                                            <span className={`mr-2 ${state.buttonColor == `innerLikeBtn_${idx}`? ' mc_true' : ''} `}  
                                                style={{fill:brandSetting.theme_color2, color: brandSetting.theme_color2}}
                                                onMouseEnter={()=> getColor1(`innerLikeBtn_${idx}`)} 
                                                onMouseLeave={()=> getColor2(`innerLikeBtn_${idx}`)}  
                                            >
                                                {(items.tot_likes && items.tot_likes != 0) ? svg.app.darkLikeIcon : svg.app.likeIcon}
                                            </span> Like {(items.tot_likes && items.tot_likes != 0) ? `(${items.tot_likes})` : ''}</a> 
                                        
                                        <a href={() => false} 
                                            className={`${activeInnerElement === `element_${idx}` && `font-semibold text-xs flex`
                                            } group relative flex items-center px-5 text-left text-xs font-semibold`}
                                            type="button"
                                            onClick={() => {
                                                handleClick(`element_${idx}`,idx, innerReplayPost[idx]);
                                                setEditCommentText('');
                                            }}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                            style={{color: brandSetting.theme_color2, fill: brandSetting.theme_color2 }}
                                        >
                                            <span className='mr-1 mc_chatIcon'style={{fill: brandSetting.theme_color2}}>
                                                {svg.app.replyIcon}
                                            </span> 
                                            Reply 
                                            <span className="ml-1 text-xs font-medium" style={{fill:brandSetting.theme_color2, }}>
                                                {/* {items?.childcmt?.length ? `(${items?.childcmt?.length})` : ''} */}
                                                {items.total_reply_comment ? `(${items.total_reply_comment})` : ''}
                                                {/* {state.total_reply_comment ? `(${state.total_reply_comment})` : ''} */}
                                                {/* {replyCount} */}
                                            </span>
                                            
                                        </a> 
                                        {userData.id == items.member_id &&
                                <>
                                        <a href={() => false} className={`mc_iconList px-5 flex items-center`}
                                            style={{color:brandSetting.theme_color2, fill:brandSetting.theme_color2}} 
                                            onClick={()=>{
                                                handelEditComment(items, `editData_${idx}`)
                                            }}
                                        >
                                            <span className={`mr-2`} style={{fill:brandSetting.theme_color2, color: brandSetting.theme_color2}}
                                                onMouseEnter={()=> getColor1(`EditBtn_${idx}`)} 
                                                onMouseLeave={()=> getColor2(`EditBtn_${idx}`)}  
                                            >
                                                {svg.app.editIcon}
                                            </span> 
                                            Edit 
                                        </a>
                                        <a href={() => false} className={`mc_iconList px-5 flex items-center`}
                                            style={{color:brandSetting.theme_color2, fill:brandSetting.theme_color2}} 
                                            onClick={()=>{
                                                // getDeleteCommentData(items.comment_id,items);
                                                getConfirmData(items.comment_id,items)
                                            }}
                                        >
                                            <span className={`mr-2`} style={{fill:brandSetting.theme_color2, color: brandSetting.theme_color2}}
                                                onMouseEnter={()=> getColor1(`deleteData_${idx}`)} 
                                                onMouseLeave={()=> getColor2(`deleteData_${idx}`)}  
                                            >
                                                {svg.app.deleteIcon}
                                            </span> 
                                            Delete 
                                        </a>
                                        
                                        {/* <span className='text-gray-500 text-xs'>{items.created_at}</span> */}
                                        </>
                                    }
                                    </p>
                                </div>
                            </div>
                        
                            <TECollapse
                                show={activeInnerElement === `element_${idx}`}
                                className="!rounded-b-none !shadow-none w-full mc_CommentReply"
                            >                          
                                <CommentsData setVMText={(val)=>props?.setVMText(val)} showVMText={props?.showVMText}   updateCountOfReply={props.updateCountOfReply} commentId={items.comment_id} postComments={items?.childcmt} className={'mc_discussionReply'} propsData={props.propsData} postId={items.post_id} updateCountOnDelete={props.updateCountOnDelete}/>
                            </TECollapse>
                        </div>
                        )
                    }) 
                }
                 
                
            </div>
        </div>


        <TEModal show={showConfirmationModal} setShow={setShowConfirmationModal} scrollable className='mc_modalHolder  mc_confermationModal'>
            <TEModalDialog centered className='mc_modalDialog '>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowConfirmationModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImage'>
                                    {svg.app.deleteBox /* <img src={`/assets/images/out-of-stock.png`} alt='unlock' width={'310px'} height={'280px'}/> */}
                                </div>
                                <div className='mc_textContentModal'>
                                    <h3 className='text-xl text-black font-bold mb-2'>Are you sure?</h3>
                                    <p className='text-sm text-black mb-3'>You want to delete this comment?</p>
                                    <div className="flex justify-between mc_confermationBtn">
                                        <div className='flex'>
                                            <button className="mr-2 mc_btn"
                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'No' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                onMouseEnter={(e)=> getColor1('No')} onMouseLeave={(e)=> getColor2('No')} 
                                                onClick={() => setShowConfirmationModal(false)}
                                            >
                                                No
                                            </button>
                                            
                                            <button className="mc_btn"
                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Yes' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                onMouseEnter={(e)=> getColor1('Yes')} onMouseLeave={(e)=> getColor2('Yes')} 
                                                onClick={()=> {
                                                    // getDeletePostData(getPosId);
                                                    
                                                    getDeleteCommentData(isCommentId,isCommentData)
                                                    // setDeletePost(true)
                                                }}
                                            >
                                                Yes  
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    </>
  );
}

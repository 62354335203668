'use client';

import { Course1, Course2, Course3, Course4, Course5, Course6, Course7, Course8, libraryImg1, libraryImg2,libraryImg3,libraryImg4, customer1, } from "../assets/images";
 
export const followersData = [
    {
        id:'1',
        userName: 'Jhon Don',
        followers : '100',
        memberCounts : '150',
        profilePic : customer1,
        points : '370',
        badges : '4'
    },
    {
        id:'2',
        userName: 'Meera Don',
        followers : '120',
        memberCounts : '110',
        profilePic : customer1,
        points : '330',
        badges : '5'
    },
    {
        id:'3',
        userName: 'Willem Richers',
        followers : '100',
        memberCounts : '250',
        profilePic : customer1,
        points : '320',
        badges : '6'
    },
    {
        id:'5',
        userName: 'Don Joni',
        followers : '210',
        memberCounts : '501',
        profilePic : customer1,
        points : '310',
        badges : '14'
    }   
]
export const cources = [
    {
        id: '1',
        imgURL : Course1,
        title : 'Red Team tools and environment',
        activities : '22',
        totalStudents : '35',
        progress : '45',
        points : '157',
        href : '/my-courses',
        modules: '6 Modules',
        createdDateTime : '14h 48 Min Start',
        endDateTime : '14h 48 Min Left',
        yourScore : '70',

    },
    {
        id: '2',
        imgURL : Course2,
        title : 'Red Team tools and environment2',
        activities : '42',
        totalStudents : '55',
        progress : '65',
        points : '177',
        href : '/my-courses',        
        modules: '6 Modules',
        createdDateTime : '14h 48 Min Start',
        endDateTime : '14h 48 Min Left',
        yourScore : '70',
    },
    {
        id: '3',
        imgURL : Course3,
        title : 'Red Team tools and environment3',
        activities : '43',
        totalStudents : '35',
        progress : '63',
        points : '137',
        href : '/my-courses',        
        modules: '6 Modules',
        createdDateTime : '14h 48 Min Start',
        endDateTime : '14h 48 Min Left',
        yourScore : '70',
    },
    {
        id: '4',
        imgURL : Course4,
        title : 'Red Team tools and environment4',
        activities : '143',
        totalStudents : '45',
        progress : '43',
        points : '137',
        href : '/my-courses',        
        modules: '4 Modules',
        createdDateTime : '4h 48 Min Start',
        endDateTime : '4h 48 Min Left',
        yourScore : '70',
    },
    {
        id: '5',
        imgURL : Course5,
        title : 'Introduction to Red Team tools and environment5',
        activities : '153',
        totalStudents : '55',
        progress : '100',
        points : '157',
        href : '/my-courses',        
        modules: 'All Modules Are Completed',
        createdDateTime : '5h 48 Min Start',
        endDateTime : '',
        yourScore : '75',
    },
    {
        id: '6',
        imgURL : Course6,
        title : 'Introduction to Red Team tools and environment6',
        activities : '163',
        totalStudents : '65',
        progress : '100',
        points : '167',
        href : '/my-courses',        
        modules: 'All Modules Are Completed',
        createdDateTime : '6h 48 Min Start',
        endDateTime : '',
        yourScore : '80',
        
        shareCertificateURL : 'sharedLink.com'
    },
    {
        id: '7',
        imgURL : Course7,
        title : 'Introduction to Red Team tools and environment7',
        activities : '173',
        totalStudents : '75',
        progress : '100',
        points : '17',
        href : '/my-courses',        
        modules: 'All Modules Are Completed',
        createdDateTime : '7h 48 Min Start',
        endDateTime : '',
        yourScore : '85',
    },
    {
        id: '8',
        imgURL : Course8,
        title : 'Introduction to Red Team tools and environment8',
        activities : '143',
        totalStudents : '45',
        progress : '100',
        points : '187',
        href : '/my-courses',        
        modules: 'All Modules Are Completed',
        createdDateTime : '8h 48 Min Start',
        endDateTime : '',
        yourScore : '90',
    },
]

export const sharedSocialUrl =[
    {
        id: '1',
        title : 'Facebook',
        url : 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpixabay.com',
        icon : '/assets/images/facebookIcon.png'
    },
    {
        id: '2',
        title : 'Twitter',
        url : 'https://twitter.com/intent/tweet?url=https%3A%2F%2Fpixabay.com&text=',
        icon : '/assets/images/twitterIcon.png'
    },
    {
        id: '3',
        title : 'Linkedin',
        url : 'http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fpixabay.com&title=',
        icon : '/assets/images/linkedinIcon.png'
    },
    // {
    //     title : 'Pinterest',
    //     url : 'http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fpixabay.com&media=&description=',
    //     icon : pinterestIcon
    // },
    {
        id: '4',
        title : 'Instagram',
        url : 'https://msng.link/o?https://pixabay.com/=ig',
        icon : '/assets/images/instagramIcon.png'
    },
]

export const libraryData = [
    {
        id: '1',
        title : 'Web Security',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : true,
        imgUrl : libraryImg1,
        price: '₹2500',
        lessonStatus : 'In-progress',
    },
    {
        id: '2',
        title : 'Network Security',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : false,
        imgUrl : libraryImg2,
        price: '₹2500',
        lessonStatus : 'Completed',
    },
    {
        id: '3',
        title : 'Binary Exploitation',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : true,
        imgUrl : libraryImg3,
        price: '₹2500',
        lessonStatus : 'Add-Library',
    },
    {
        id: '4',
        title : 'Introduction to Red Team tools and environment',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : false,
        imgUrl : libraryImg4,
        price: '₹2500',
        lessonStatus : 'Add-Whishlist',
    },
    {
        id: '5',
        title : 'Web Security',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : false,
        imgUrl : libraryImg1,
        price: '₹2500',
        lessonStatus : 'Completed',
    },
    {
        id: '6',
        title : 'Network Security',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : true,
        imgUrl : libraryImg2,
        price: '₹2500',
        lessonStatus : 'In-progress',
    },
    {
        id: '7',
        title : 'Binary Exploitation',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : false,
        imgUrl : libraryImg3,
        lessonStatus : 'In-progress',
    },
    {
        id: '8',
        title : 'Introduction to Red Team tools and environment',
        highlights : 'Community Highlights',
        students : '23,256',
        totalTime : '8h 12 min',
        whishList : false,
        imgUrl : libraryImg4,
        price: '₹2500',
        lessonStatus : 'Completed',
    },

]

export const postDataFilter=[
    {
        id : 1,
        postTitle : 'This is good try comments',
        userName : 'Jhone Doe',
        postContent : 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
        postLike : false,
        postLikesCount : 2,
        likesCount: 2,
        likeStatus : false,
        postType: 'birthday',
        postUrl: '',
        replys : [
            {
                userId : 1,
                userName : 'Meera',
                replyContent : 'The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 1,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
            {
                userId : 2,
                userName : 'jhone Doe',
                replyContent : '2 The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 2,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
        ]
    },
    {
        id : 2,
        postTitle : 'This is good try comments',
        userName : 'Meria Doe',
        postContent : 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
        postLike : false,
        postLikesCount : 5,
        likesCount: 2,
        likeStatus : false,
        postType: 'image',
        postUrl: '',
        replys : [
            {
                userId : 2,
                userName : 'Doe Meera',
                replyContent : 'The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 2,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
            {
                userId : 3,
                userName : 'Mr, Jhone Doe',
                replyContent : '2 The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 3,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
        ]
    },
    {
        id :3,
        postTitle : 'This is good try comments',
        userName : 'Meria Doe',
        postContent : 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
        postLike : false,
        postLikesCount : 5,
        likesCount: 2,
        likeStatus : false,
        postType: 'video',
        postUrl: '',
        replys : [
            {
                userId : 2,
                userName : 'Doe Meera',
                replyContent : 'The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 2,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
            {
                userId : 3,
                userName : 'Mr, Jhone Doe',
                replyContent : '2 The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 3,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
        ]
    },
    {
        id : 4,
        postTitle : 'This is good try comments',
        userName : 'Meria Doe',
        postContent : 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
        postLike : false,
        postLikesCount : 5,
        likesCount: 2,
        likeStatus : false,
        postType: 'audio',
        postUrl: '',
        replys : [
            {
                userId : 2,
                userName : 'Doe Meera',
                replyContent : 'The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 2,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
            {
                userId : 3,
                userName : 'Mr, Jhone Doe',
                replyContent : '2 The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 3,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
        ]
    },
    {
        id : 5,
        postTitle : 'This is good try comments',
        userName : 'Meria Doe',
        postContent : 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
        postLike : false,
        postLikesCount : 5,
        likesCount: 2,
        likeStatus : false,
        postType: 'congratulations',
        postUrl: '',
        replys : [
            {
                userId : 2,
                userName : 'Doe Meera',
                replyContent : 'The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 2,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
            {
                userId : 3,
                userName : 'Mr, Jhone Doe',
                replyContent : '2 The passage is attributed to an unknown typesetter in the 15th century who is thought to have.',
                likesCount : 3,
                postTime : 'less than a minute ago', 
                likeStatus : false,
            }, 
        ]
    },
]

export const courseSkills=[
    {
        id:'1',
        title: 'Marketing'
    },
    {
        id:'2',
        title: 'Business Growth'
    },
    {
        id:'3',
        title: 'Social Media'
    },
    {
        id:'4',
        title: 'Business Strategy'
    },
    {
        id:'5',
        title: 'Schedules'
    },
    {
        id:'6',
        title: 'Automation'
    },
    {
        id:'7',
        title: 'Account Creation'
    },
    {
        id:'8',
        title: 'Reports'
    },
    {
        id:'9',
        title: 'Maintanace'
    },
]

export const members=[
    {
        id: '0',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '350',
        badges : '4',
        memberRank : '',
        datetime: '7Day',
        position: '1st',
    },
    {
        id: '1',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '300',
        badges : '4',
        memberRank : '',
        datetime: '',
        position: '2nd',
    },
    {
        id: '2',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '250',
        badges : '4',
        memberRank : '',
        datetime: '1Month',
        position: '3rd',
    },
    {
        id: '3',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '200',
        badges : '4',
        memberRank : '',
        datetime: '7Day',
        position: '4th',
    },
    {
        id: '4',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '150',
        badges : '4',
        memberRank : '',
        datetime: '1Month',
        position: '5th',
    },
    {
        id: '5',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '100',
        badges : '4',
        memberRank : '',
        datetime: '7Day',
        position: '6th',
    },
    {
        id: '6',
        profilePic : customer1,
        memberName : 'Jhone Carter',
        points : '50',
        badges : '4',
        memberRank : '',
        datetime: '1Month',
        position: '7th',
    },
]

export const communityPost=[
    {
        id: '1',
        title: 'Post title here...',
        subTitle: 'Sub title here..',
        description : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        postUrl: '',
        postType: '',
        likes: '100',  
        reposts: '23',
        comments: '5',
        userDetails : [{
            uId: '1',
            name: 'Meria Doe',
            followers: '230',
            posts:'300',
        }],
        time: '0 mnts ago'
    },
    {
        id: '2',
        title: 'Post title here...',
        subTitle: 'Sub title here..',
        description : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        postUrl: '',
        postType: '',
        likes: '100',  
        reposts: '23',
        comments: '5',
        userDetails : [{
            uId: '1',
            name: 'Meria Doe',
            followers: '230',
            posts:'300',
        }],
        time: '0 mnts ago'
    },
    {
        id: '3',
        title: 'Post title here...',
        subTitle: 'Sub title here..',
        description : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        postUrl: '',
        postType: '',
        likes: '100',  
        reposts: '23',
        comments: '5',
        userDetails : [{
            uId: '1',
            name: 'Meria Doe',
            followers: '230',
            posts:'300',
        }],
        time: '0 mnts ago'
    },
    {
        id: '4',
        title: 'Post title here...',
        subTitle: 'Sub title here..',
        description : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
        postUrl: '',
        postType: '',
        likes: '100',  
        reposts: '23',
        comments: '5',
        userDetails : [{
            uId: '1',
            name: 'Meria Doe',
            followers: '230',
            posts:'300',
        }],
        time: '0 mnts ago'
    }
]
// export courcestypes = [
//     {
//         id: '1',
//         type: '',
//     }
// ]
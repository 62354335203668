import React, {useEffect, useState, useRef} from 'react';
import { appStore } from '../../zustand/store';
import config from '../../config';
import { common, dataLoader, setMyState } from '../Comman';
import svg from '../svg';
import CommentsData from './CommentData';
import { toast } from 'react-toastify';
import moment from 'moment';
import EmojiBox from '../../emoji/EmojiBox';
import {
    TECollapse,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
} from "tw-elements-react";
import SingleComment from './SingleComment';


function DiscussionTab(props) {
    let alfaUrl = config.REACT_APP_BUCKET_URL
    let myStore = appStore(state => state);
    // let userData = myStore.userData;
    let userData = myStore.userData.MembershipUser;
    let brandSetting = myStore.brandSetting;
    const[showVMText,setVMText] = useState([]);

    const postRef = useRef();
    const [state, setQuery] = useState({
        rating : '',
        postId : '',
        lessonData : '',
        buttonColor : null,
        postTitle: '',
        pageNo : 1,
        limit : 10,
        post_id: '',
    });
    const [activeElement, setActiveElement] = useState("");
    const [totlepost ,settotlepost] =useState(0)
    const handleClick = (value, postComment_Data) => {

        if (value === activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(value);
        }
    };
    const getColor1=(type)=>{
        setMyState(setQuery, {
            ...state,
            buttonColor : type,
        })
    }
    const getColor2=(type)=>{
        setMyState(setQuery, {
            ...state,
            buttonColor : null,
        })
    }
    const [ispostData, setIsPostData] = useState();
    const [postDataFilter, setPostDataFilter] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [loadCount, setLoadCount] = useState(0);
    
    const handlePostData = (postText) => {    
        setEditTextData('')
        if(ispostData){
            setIsPostData(postText)
            getPostData()
        }else{
            toast.error('Comment feild is required');
        }
    }; 
    const propsData = {
        funnel_id : props.bodyData.funnel_id,
        funnel_page_id: props.bodyData.funnel_page_id,
        lesson_id: props.lessonId,
        member_id: props.bodyData.member_id,
        post_title: ispostData,
        page :state.pageNo,
        token: props.bodyData.token,
    }
    const currentTime = moment();
    const getPostData=()=>{
        setEditTextData('')
        common.getAPI({
            method: "Post",
            url: "lesson-post",
            data: propsData,
        },
        async (resp) => {   
            // setPostDataFilter(resp);
            if(resp.success == true){
                settotlepost((d1)=>d1+1)
                setPostDataFilter((d1)=>[{...resp,
                    created_at : resp.created_at,
                    profile_img : userData.profile_img,
                },...d1,])
                setIsPostData('');
            }else{
                toast.error("Error")
            }
        })
    }
    const getPostListData=(type,p=1)=>{
        const propsData1 = {
            funnel_id : props.bodyData.funnel_id,
            funnel_page_id: props.bodyData.funnel_page_id,
            lesson_id: props.lessonId,
            member_id: props.bodyData.member_id,
            post_title: ispostData,
            page :p!=0 ? p : state.pageNo ,
            token: props.bodyData.token,
        }
        // console.log({propsData1})

        setPageLoader(true)
        setEditTextData('')
        common.getAPI({
            method: "Post",
            url: "getlesson-post-comment",
            data: propsData1,
        },
        async (resp) => { 
            if(resp.success == true){
                settotlepost(resp.total_post)
                if(type == 'viewMore'){
                    setPostDataFilter(prevItems => [...prevItems, ...resp.post])
                }else{
                    setPostDataFilter(resp.post)
                    // console.log(postDataFilter, 'mc_fileTimeline_dv');
                }
                setTotalCount(resp.total_post);
                
            }
            setPageLoader(false)
        })
    }
    const [pageLoader, setPageLoader] =useState(false)
    const getMoreData=(type, oldPost)=>{
        setPageLoader(true)
        if(type == 'viewMore'){
            let p=state.pageNo+1
            setMyState(setQuery,{
                pageNo : state.pageNo+1
            })
            getPostListData(type,p);
        }else if(type == 'deletePost'){
            // let p=state.pageNo
            setMyState(setQuery,{
                pageNo : 1,
            })
            getPostListData(type,1);
        }
        
    }
    useEffect(()=>{
        getPostListData('All');
    },[])

   

    let getLikePostData=(data, likCount)=>{
        // if(likCount == 1){
        //     likCount = 0,
        // }
        setEditTextData('')
        const likepost = {
            funnel_id : props.bodyData.funnel_id,
            funnel_page_id: props.bodyData.funnel_page_id,
            lesson_id: props.lessonId,
            member_id: props.bodyData.member_id,
            type : 1,
            fk_id : data,
            like : likCount == 1 ? 0 : 1,
            token: props.bodyData.token,
        }

        common.getAPI({
            method: "Post",
            url: "lesson-like",
            data: likepost,
        },
        async (resp) => {
            if(resp.msg){
                toast.error(resp.msg)
            }
            getPostListData();
        })
    }
    const [showEditDataModal, setShowEditDataModal] = useState(``);
    const [editTextData, setEditTextData] = useState('');

    const getEditData=(edittext, editIndex)=>{
        setShowEditDataModal(editIndex);
        setEditTextData(edittext.post_title)
    }
    const updateEditPostData=(editId, index, postTitle)=>{
        let editpostPara = {
            funnel_id : props.bodyData.funnel_id,
            funnel_page_id: props.bodyData.funnel_page_id,
            lesson_id: props.lessonId,
            member_id: props.bodyData.member_id,
            post_title: postTitle,
            post_id :editId,
            token: props.bodyData.token,
        }
        // console.log(editpostPara, 'editCommentPara');
        // return
        common.getAPI({
            method: "Post",
            url: "lesson-post",
            data: editpostPara,
        },
        async (resp) => { 
            setShowEditDataModal('');
            setEditTextData('');
            getPostListData('updatePost');
            // setPostDataFilter((d5)=>[...d5,{
            //     post_title : resp.post_title,
            // },])
            // setIsPostData[index].post_title
            // console.log(resp, postDataFilter, 'post_title' );
        })
    }
    const [getPosId, setGetPosId] = useState('')
    const getConfirmModal=(posId)=>{
        setShowConfirmationModal(true)
        setGetPosId(posId)
    }
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    // const [deletePost, setDeletePost ] = useState(false);
    const getDeletePostData=(pId)=>{
        let deletePost = {
            funnel_id : props.bodyData.funnel_id,
            funnel_page_id: props.bodyData.funnel_page_id,
            lesson_id: props.lessonId,
            member_id: props.bodyData.member_id,
            id :pId,
            type : 'post',
            token: props.bodyData.token,
        }
       
        common.getAPI({
            method: "Post",
            url: "delete-post-comment",
            data: deletePost,
        },
        async (resp) => { 
            setGetPosId('')
            if(resp.success == true){
                toast.success(resp.message)
                setShowConfirmationModal(false);
                getPostListData('deletePost', 1);
                // setDeletePost(false);
            }else{
                toast.error(resp.message)
            }
        })
    }
    
    const updateCountOfReply = (postId,comId,data,type) =>{
        let dummyData = postDataFilter;
        let newArr= dummyData.map((val)=>{ 
            if(val?.post_id == postId){
                if(comId){
                    let newComment = updateComments(val?.comment, comId, data);                   
                    return {
                        ...val, tot_comments:val?.tot_comments + 1 ,comment:newComment
                    }
                }else{
                    return {
                        ...val, tot_comments:val?.tot_comments ? val?.tot_comments  + 1 : 1, comment:val?.comment ? [...val?.comment ,data] : [data]
                    }
                }
            }else{
                return val
            }
        })
  
       setPostDataFilter(newArr);
    }


    const updateComments = (comments, commId, data) => { 
        return comments.map(comment => {
          if (comment.comment_id === commId) {
           
            comment.childcmt = [...(comment.childcmt || []),  data];
          
            comment.total_reply_comment = (comment.total_reply_comment || 0) + 1;
          } else if (comment.childcmt && comment.childcmt.length > 0) {
           
            comment.childcmt = updateComments(comment.childcmt, commId, data);
            
            comment.total_reply_comment = comment.childcmt.length;
          }
          return comment;
        });
      };

    const updateCountOnDelete = (pId,cId, tot_rep,parent_id) => { 
            let dummyData = postDataFilter;
                
            let newArr= dummyData.map((val)=>{

                if(val.post_id == pId){
                
                        let newComment = filterComments(val.comment, cId, tot_rep,parent_id);
                        return {
                            ...val, tot_comments:val.tot_comments - (tot_rep + 1 )  ,comment:newComment
                        }
                
                }else{
                    return val
                }
            })
        
        let data =  newArr.map((val)=>{
            if(val.post_id == pId){
                let newComment = updateCountComments(val.comment, cId, tot_rep)
                    
                        return {
                            ...val,comment:newComment
                        }
                
            }else{
                return val
            }
        
        })
        setPostDataFilter(data);
    }

    const updateCountComments = (comments, commId, tot_rep) => { 
        return comments.map(comment => {
        if (comment.childcmt && comment.childcmt.length > 0) {
        
            comment.childcmt = updateCountComments(comment.childcmt, commId,tot_rep );
            
            comment.total_reply_comment = comment.childcmt.length;
        }

        return comment;
        });
    };


    const filterComments = (commentsArr, commId, tot_rep,parent_id) => {
        return commentsArr.filter(comment => {
            if (comment.comment_id === commId) {
                
                // updateParentCommentCounts(commentsArr, comment.parent_id, tot_rep);
                return false;
            } else if (comment.childcmt && comment.childcmt.length > 0) {
                comment.childcmt = filterComments(comment.childcmt, commId, tot_rep);
                // comment.total_reply_comment -= tot_rep;
                return true;
            }
            return true;
        });
    };

  return (
    <>
        <div className='col-span-3 mc_moboilCol_12'>
            <div className='mc_heading flex sm:flex-none justify-between mc_titleRow mt-4'>
                {/* <h4 className='text-black text-base font-semibold'>Transcripts For The Lessons</h4> */}
                {/* <a href={() => false} className='mc_btn text-sm'>Start a new discussion</a> */}
            </div>

            <div className='flex flex-wrap py-2 mc_commentGrayBox_dv'>
                <div className='mc_userDetails_dv flex flex-start items-start justify-start h-auto w-full'>
                    <img src={userData.profile_img ? userData.profile_img : `${alfaUrl}/images/user.png`} width={'50px'} height={'50px'} className='rounded-full mr-3'/>
                    <div className="mc_commentBox_text w-full">
                        <h5 className="text-sm text-black font-semibold mb-2">{userData.name}</h5>
                        <textarea 
                            id='mc_discussionPost'
                            ref={postRef} 
                            rows="2" className="mc_textarea w-full px-4 py-4" placeholder="Type here.."
                            value={ispostData}
                            onChange={(e) => {
                                setIsPostData(e.target.value);
                            }}
                            onSelect={(e)=> {
                                setIsPostData(e.target.value)
                            }}
                        >
                        </textarea>
                        <div className='flex sm:flex-none justify-end mt-4 w-full mc_attachedFile'>
                            <div className='flex items-center'>
                                <span className='mc_memberEmoji'>
                                    <EmojiBox
                                    targetInputId={`#mc_discussionPost`}
                                    parentEmojiBoxclassName={"emojiBoxParent"}
                                    boxPlacement={"left"}
                                    />
                                </span>
                                <a href={() => false} className='mc_btn btn-base' 
                                    style={{color : brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'PostBtn' ? brandSetting.theme_color2 : brandSetting.theme_color2}`}}
                                    // onMouseEnter={()=> getColor1('PostBtn')} 
                                    // onMouseLeave={()=> getColor2('PostBtn')}
                                    onClick={(e)=>{
                                        handlePostData()
                                    }}
                                >Send {svg.app.sendPostIcon}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className='text-base font-bold text-black'>Comments [{postDataFilter?.length}]</h2>
            {postDataFilter?.length ? postDataFilter.map((postData, postIndex)=>{
                return( 
                <div key={postIndex} className='mc_commentGrayBox_dv w-full mt-5'>
                    <div id="accordionExample">
                        {showEditDataModal == `EditData_${postIndex}` ?
                            <div className='mc_commentGrayBox_dv'>
                                <div className='mc_userDetails_dv flex flex-start items-start justify-start h-auto w-full'>
                                    <img src={postData.profile_img ? postData.profile_img : `${alfaUrl}/images/user.png`} width={'50px'} height={'50px'} className='rounded-full mr-3'/>
                                    <div className="mc_commentBox_text w-full">
                                        <h5 className="text-sm text-black font-semibold mb-2">{postData.profile_name}</h5>
                                        <textarea 
                                            id={`mc_discussionPost_${postData?.post_id}`}
                                            ref={postRef} 
                                            rows="1" className="mc_textarea w-full px-4 py-4" placeholder="Type here.."
                                            value={editTextData}
                                            onChange={(e) => {
                                                setEditTextData(e.target.value)
                                            }}
                                            onSelect={(e)=> {
                                                setEditTextData(e.target.value)
                                            }}
                                        >
                                        </textarea>
                                        <div className='flex sm:flex-none justify-end mt-4 w-full mc_attachedFile'>
                                            <div className='flex items-center'>
                                                <span className='mc_memberEmoji'>
                                                    <EmojiBox
                                                    targetInputId={`#mc_discussionPost_${postData?.post_id}`}
                                                    parentEmojiBoxclassName={"emojiBoxParent"}
                                                    boxPlacement={"left"}
                                                    />
                                                </span>
                                                <a href={() => false} className='mc_btn btn-base' 
                                                    style={{color : brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'PostBtn' ? brandSetting.theme_color2 : brandSetting.theme_color2}`}}
                                                    // onMouseEnter={()=> getColor1('PostBtn')} 
                                                    // onMouseLeave={()=> getColor2('PostBtn')}
                                                    onClick={()=>{
                                                        updateEditPostData(postData?.post_id, postIndex, editTextData)
                                                    }}
                                                >Send {svg.app.sendPostIcon}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className='flex flex-wrap items-center'>
                                <div className='mc_userDetails_dv flex'>
                                    <img src={postData.profile_img ? postData.profile_img : `${alfaUrl}/images/user.png`} alt="img" width={'50px'} height={'50px'} className='rounded-full mr-3'/>
                                    <div className='mc_userName'>
                                        <h5 className='text-sm font-bold'>{postData.profile_name}</h5>
                                        <span className='text-gray-500 text-xs' style={{fill:brandSetting.theme_color2}}>{postData.created_at}</span>
                                    </div>
                                </div>
                                <SingleComment setVMText={(val)=>setVMText(val)} showVMText={showVMText} items={postData}/>
                                {/* <p className='text-sm text-black mt-3 mb-3 w-full'>{postData.post_title}</p> */}
                            </div>
                        
                        }
                        <div className='flex sm:flex-none justify-between'>
                            <div className='flex items-center mc_likeReply'>
                                <a href={() => false} className={`mc_iconList mr-8 flex items-center`}
                                    style={{color:brandSetting.theme_color2}} 
                                    onClick={()=>{
                                        getLikePostData(postData?.comment_id ? postData?.comment_id : postData?.post_id, postData?.like);
                                    }}
                                >
                                    <span className={`mr-2 ${postData.like==1 ? 'mc_true' : ''} `}  
                                        style={{fill: brandSetting.theme_color2, color: brandSetting.theme_color2}}
                                        onMouseEnter={()=> getColor1(`LikeBtn_${postIndex}`)} 
                                        onMouseLeave={()=> getColor2(`LikeBtn_${postIndex}`)}  
                                    >
                                        {postData?.like == 1 ? svg.app.darkLikeIcon : svg.app.likeIcon}
                                    </span> 
                                    Like <span className="ml-1 text-xs font-medium">
                                        {(postData.tot_likes && postData.tot_likes != 0) ? `(${postData.tot_likes})` : ''}
                                    </span>
                                </a>
                                <a href={() => false} id="headingOne"
                                    className={`${activeElement === `element_${postIndex}`} group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 text-left text-sm text-neutral-800 transition hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
                                    type="button"
                                    onClick={() => handleClick(`element_${postIndex}`, postData?.comment ? postData?.comment : 0)}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                    style={{
                                        fill:brandSetting.theme_color2, 
                                        color:brandSetting.theme_color2
                                    }}
                                    onMouseEnter={()=> getColor1(`ReplyBtn_${postIndex}`)} 
                                    onMouseLeave={()=> getColor2(`ReplyBtn_${postIndex}`)}
                                >
                                    <span className={`mr-2`} style={{fill : brandSetting.theme_color2}}>{svg.app.replyIcon}</span> 
                                    Reply 
                                    <span className="ml-1 text-xs font-medium" style={{fill:brandSetting.theme_color2, }}>
                                        {postData?.tot_comments > 0 ? `(${postData?.tot_comments})` : ''}
                                    </span>
                                </a>
                                {userData.id == postData.member_id &&
                                <>
                                <a href={() => false} className={`mc_iconList px-5 flex items-center`}
                                    style={{color:brandSetting.theme_color2, fill:brandSetting.theme_color2}} 
                                    onClick={()=>{
                                        getEditData(postData, `EditData_${postIndex}`)
                                    }}
                                >
                                    <span className={`mr-2`} style={{fill:brandSetting.theme_color2, color: brandSetting.theme_color2}}
                                        onMouseEnter={()=> getColor1(`EditBtn_${postIndex}`)} 
                                        onMouseLeave={()=> getColor2(`EditBtn_${postIndex}`)}  
                                    >
                                        {svg.app.editIcon}
                                    </span> 
                                    Edit 
                                </a>
                                <a href={() => false} className={`mc_iconList px-5 flex items-center`}
                                    style={{color:brandSetting.theme_color2, fill:brandSetting.theme_color2}} 
                                    onClick={()=>{
                                        getConfirmModal(postData?.post_id)
                                        // setShowConfirmationModal(true);
                                        // getDeletePostData(postData?.post_id)
                                    }}
                                >
                                    <span className={`mr-2`} style={{fill:brandSetting.theme_color2, color: brandSetting.theme_color2}}
                                        onMouseEnter={()=> getColor1(`DeleteBtn_${postIndex}`)} 
                                        onMouseLeave={()=> getColor2(`DeleteBtn_${postIndex}`)}  
                                    >
                                        {svg.app.deleteIcon}
                                    </span> 
                                    Delete 
                                </a>
                            </>
                            }
                            </div>
                            {/* <div className='flex items-center'>
                                <a href={() => false} className='mc_iconList mr-8 flex items-center color-themeColor' 
                                    // onClick={()=> {
                                    //     let data = [...state.postDataFilter];
                                    //     data[postIndex].likeStatus = (data[postIndex].likeStatus == false ? true : false);
                                    //     setMyState(setQuery,{
                                    //         ...state,
                                    //         postDataFilter : data,
                                    //     })
                                    // }}
                                >
                                    <span className='mr-2'>{svg.app.redHeartIcon}</span> 
                                    {postData.tot_likes}
                                </a>
                                <a href={() => false} className='mc_iconList flex items-center color-themeColor' 
                                    //onClick={()=>setUsersCount((postUser) => postUser + 1)} 
                                >
                                    <span className='mr-2'>{svg.app.groupIcon}</span> 
                                    {postData.tot_comments}
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <TECollapse
                        show={activeElement === `element_${postIndex}`}
                        className="!mt-0 !rounded-b-none !shadow-none mc_CommentReply_outer"
                    >
                        <CommentsData setVMText={(val)=>setVMText(val)} showVMText={showVMText} commentId={0} postComments={postData?.comment} replyCount={postData?.comment?.length} mainPostData={postData} className={'mc_discussionReply'} propsData={props} postId={postData.post_id} updateCountOfReply={updateCountOfReply} updateCountOnDelete={updateCountOnDelete}/>
                    </TECollapse>
                </div>
                )
            })
            :''
            }
            {pageLoader == true ? dataLoader.commonLoader : '' }
            <div className={`mc_loadMore_holder mt-5`.concat(String(postDataFilter.length >= totlepost)=="true"  ? " hide" : "")}>
                {(postDataFilter?.length && (String(postDataFilter.length >= totlepost)=="false")) &&
                    <a href={() => false} className='mc_viewMore' 
                        onClick={()=>{
                           getMoreData('viewMore', postDataFilter);
                        }} 
                    >
                        View More
                    </a>
                }
                {/* {state.pageNo > 1 &&
                    <a href={() => false} className='mc_viewMore' 
                        onClick={()=>{
                            setMyState(setQuery,{
                                pageNo : state.pageNo-1
                            })
                            getMoreData('backList');
                        }} 
                    >
                        Back
                    </a>
                } */}
            </div>
        </div>
        <TEModal show={showConfirmationModal} setShow={setShowConfirmationModal} scrollable className='mc_modalHolder  mc_confermationModal'>
            <TEModalDialog centered className='mc_modalDialog '>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowConfirmationModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImage'>
                                    {svg.app.deleteBox /* <img src={`/assets/images/out-of-stock.png`} alt='unlock' width={'310px'} height={'280px'}/> */}
                                </div>
                                <div className='mc_textContentModal'>
                                    <h3 className='text-xl text-black font-bold mb-2'>Are you sure?</h3>
                                    <p className='text-sm text-black mb-3'>You want to delete this post?</p>
                                    <div className="flex justify-between mc_confermationBtn">
                                        <div className='flex'>
                                            <button className="mr-2 mc_btn"
                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'No' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                onMouseEnter={(e)=> getColor1('No')} onMouseLeave={(e)=> getColor2('No')} 
                                                onClick={() => setShowConfirmationModal(false)}
                                            >
                                                No
                                            </button>
                                            
                                            <button className="mc_btn"
                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Yes' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                onMouseEnter={(e)=> getColor1('Yes')} onMouseLeave={(e)=> getColor2('Yes')} 
                                                onClick={()=> {
                                                    getDeletePostData(getPosId);
                                                    // setDeletePost(true)
                                                }}
                                            >
                                                Yes  
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    </>
  )
}

export default DiscussionTab
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import svg from '../components/svg'
import { setMyState, common, dataLoader } from '../components/Comman'
import { appStore } from '../../src/zustand/store';

export default function Home(props) {
    // console.log(props, 'eventId');
    
    let myStore = appStore(state => state);
    let userData = myStore.userData;

    // console.log(userData?.event_id, 'userData?.event_id');
    // return;

    useEffect(()=>{
        let c1=document.getElementById("mainfooter")
       if(c1.classList.contains("hidden"))
       {
        c1.classList.remove("hidden")
       }
      },[])
    
    const [state, setQuery] = useState({
        bodyColor: '#f7f7f7',
        themeColor: '#0B9D7C',
        textColor: '#4A5251',
        hoverColor: null,
        userEventInfoList: [],
        dataLoading:false,
        daysTab : 'day1',
    })
    let getHover1 = (e) => {
        setMyState(setQuery, {
            hoverColor: e,
        })
    }
    let getHover2 = (e) => {
        setMyState(setQuery, {
            hoverColor: null,
        })
    }
    const getEventInfolist = () => {
        setMyState(setQuery, {
            dataLoading: true
        })
        common.getAPI({
            isLoader: true,
            url: 'event-details',
            method: 'POST',
            data: { event_id: userData.event_id },
        }, (resp) => {
            setMyState(setQuery, {
                dataLoading: false
            })
            if (resp.success == true) {
                setMyState(setQuery, {
                    userEventInfoList: resp.event_list
                })
            }
            // console.log(state.userEventInfoList,resp, 'userEventInfoList');
        })
    }
    useEffect(() => {
        getEventInfolist()
    }, [])
    return (
        <>
            <div className='mc_homeWrapper'>
                <div className='mc_bannerEvent'>
                    <img src='/assets/images/mobile/eventbanner.png' alt='Banner Image' className='w-full'/>
                </div>
                <div className='mx_innerpage Wrapper p-4'>
                    <div className='mc_pageMenuList'>
                        <ul>
                            <li>
                                <Link to={'/events/attendees'} className='mc_listIcon_page'>
                                    <span className='mc_pageIconCircle mc_pinkIcon'>{svg.app.userProfile}</span>  
                                    <span className='mc_pageName_list'>Attendees {svg.app.arrowLeftIcon}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/events/agenda'} className='mc_listIcon_page'>
                                    <span className='mc_pageIconCircle mc_bluelIcon'>{svg.app.AgendaIcon}</span>  
                                    <span className='mc_pageName_list'>Agenda {svg.app.arrowLeftIcon}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/events/speakers'} className='mc_listIcon_page'>
                                    <span className='mc_pageIconCircle mc_yellowIcon'>{svg.app.speakersIcon}</span>  
                                    <span className='mc_pageName_list'>Speakers {svg.app.arrowLeftIcon}</span>
                                </Link>
                            </li>
                        </ul>
                        <div className='mc_bottomContent'>
                            <h3>Information</h3>
                            {state.userEventInfoList.map((t, i) => {
                                return (
                                <>
                                    <div  className='mc_dateTime flax items-center justify-center flex-wrapper mb-4'>
                                        <span className='mc_calangerList text-sm w-full'>
                                            {svg.app.clockIconList} 
                                            <span>{t.date}</span>
                                        </span>
                                    </div>
                                    <div className='mc_dateTime flax items-center justify-center flex-wrapper mb-4'>
                                        <span className='mc_calangerList text-sm w-full'>
                                            {svg.app.mapIcon} 
                                            <span>Dates are displayed in the event time zone (Asia/Kolkata).</span>
                                        </span>
                                    </div>
                                    <div  className='mc_dateTime flax items-center justify-center flex-wrapper mb-4'>
                                        <span className='mc_calangerList text-sm w-full'>
                                            {svg.app.locationIcon} 
                                            <span>{t.location}</span>
                                        </span>
                                    </div>
                                </>
                                )}
                            )}
                        </div>
                    </div>
                </div>
            </div>

        
        </>
    )
}

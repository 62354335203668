
'use client';

import React, { useState, useEffect } from 'react'
import svg from '../../components/svg';
// import { TEInput } from 'tw-elements-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { common, setMyState } from '../../components/Comman';
import { appStore } from '../../zustand/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
// import { Toast } from 'react-toastify/dist/components';
// import { PasswordChecklist } from '../../components/checkPassword';

export default function ResetPassword({ ...props }) {
    // const [pathname,setpath]=useState(window.location.pathname)
    // useEffect(()=>{
    //     console.log("routename",location.pathname) 
    //   },[location])
    const slugName = window.location.href.split('/')[4];
    // const currentPath = useLocation().pathname
    const navigate = useNavigate();

    let myStore = appStore(state => state);
    let appData = myStore.appData;
    let brandSetting = myStore.brandSetting;
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamData = urlParams.get('id');
    const urlParamDataToken = urlParams.get('token');
    // const decoded=atob(urlParamData);
    // const decodedToken=atob(urlParamDataToken);
    // console.log(urlParamDataToken, '<= urlParamDataToken =>', urlParamData);

    // const decodedDataId = JSON.parse(decoded)
    // const decodedDataToken = JSON.parse(decodedToken)

    // const myParam = decodedData.fId;
    //   const myParam = JSON.stringify({fId :appData.funnel_id})
    //   const encoded=btoa(myParam);


    //   console.log(myStore, 'myStoremyStoremyStore');
    const location = useLocation();
    //   const [pathname,setpath]=useState(window.location.search)
    useEffect(() => {
        //   console.log("locationlocationlocationlocation",location.pathname) 
    }, [location])


    // let authType = 'reset_password';

    let [isProcess, setIsProcess] = useState(false);
    // let [isMatchPassword , checkMatchPassword ] = useState(false); 

    const [state, setQuery] = useState({
        name: '',
        email: '',
        password: '',
        resetPassword: false,
        forgotStatus: false,
        loginStatus: true,
        funnel_id: appData.funnel_id,
        confirmpassword: '',
        message: '',
        role: '',
        brnad_logo: '',
        buttonColor: null,
    })
    // const notify = () => toast("Wow so easy!");
    let getButtonColor1 = (type) => {
        setMyState(setQuery, { buttonColor: type })
    }
    let getButtonColor2 = (type) => {
        setMyState(setQuery, { buttonColor: null })
    }
    let [error, setError] = useState({
        email: '',
        password: false,
        confirmpassword:false,
        funnel_id: '',
    });

    const handleInputChange = value => {
      setMyState(setQuery, {
        [value.name]: value.value,
      })
      setError({...error, [value.name]:false})
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getResetPassword(e, 'reset_password');
        }
    };
    const getResetPassword = (e, type) => {
        e.stopPropagation();
        e.preventDefault();

        let data = {
            password: state.password,
            confirmpassword: state.confirmpassword,
        }
        //   console.log(e,type, data, 'get Reset Password Data');
        //   return 
        if (data.password == '') {
            setError({
                ...error,
                password: true,
            });
            return
        } else if (data.confirmpassword == '') {
            setError({
                ...error,
                confirmpassword: true
            });
            return
        } 
        if (data.password !== data.confirmpassword) {
            toast.error("Password should be same.")
            return
        }       
        
        let funnelId = urlParamData;
        let token = urlParamDataToken;
        const body = {
            funnel_id: funnelId,
            token: token,
            password: data.password,
            confirmpassword: data.confirmpassword,
        }

        if (token != '') {
            common.getAPI({
                method: "Post",
                url: "reset_password",
                data: body,
            },
                async (resp) => {
                    if (resp.success) {
                        setMyState(setQuery, { 
                            password: '', 
                            confirmPassword: '', 
                        });
                        setError({
                            ...error,
                            password: false,
                            confirmPassword:false
                        });
                        toast.success(resp.message);
                        setTimeout(() => {
                            navigate('/login')
                        }, 1000);

                    } else if (resp?.password?.length) {
                        toast.error(resp.password[0]);
                    } else if (resp?.confirmnewpassword?.length) {
                        toast.error(resp.confirmnewpassword[0]);
                    } else {
                        toast.error(resp.message);
                    }
                });
        }
    }
    

    let tokenCookie = Cookies.get(`access_token_${slugName}`) ? Cookies.get(`access_token_${slugName}`) : false;

    return (<>
        <Helmet>
            <title>Reset Password</title>
            <link rel="shortcut icon" href={appData.favicon_url} type="image/png/ico" />
        </Helmet>
        <ToastContainer />
        <div className='mc_authWrapper relative' style={{ backgroundImage: `url(${brandSetting.bgimg})`, backgroundColor: brandSetting.bgcolor, backgroundSize: brandSetting.bgtype }}>
            {/* <div className='mc_overlayBg'></div> */}
            <div className='mc_vertical_content relative'>
                <div className='mx-auto my-auto flex item-center justicy-center px-5'>
                    <form className='mc_formDv' onSubmit={(e) => getResetPassword(e, 'reset_password')}>
                        <div className='mc_formLogo flex w-full flex-col gap-6'>

                            {
                                brandSetting.logo ?
                                    <img src={brandSetting.logo} alt='Logo' width={'250px'} height={'50px'} />
                                    :
                                    <div className='mc_logoText' style={{ color: `${state.buttonColor == 'Logo' ? brandSetting.theme_color2 : brandSetting.theme_color1}` }}>Logo</div>
                            }
                            <p className='mc_formText text-xl font-semibold text-black text-center w-56 mx-auto mb-3'>Reset Password</p>
                            <div className='w-full mc_formGroup relative'>
                                <input type='password' className='mc_input' variant="standard" label="New Password"
                                    value={state.password}
                                    onChange={(e) => handleInputChange(e.target) }
                                    name="password"
                                    autoComplete="off"
                                    onFocus={() => getButtonColor1('newPasswordLabel')}
                                    onBlur={() => getButtonColor2('newPasswordLabel')}
                                    onKeyDown={handleKeyDown}
                                />
                                <label className='mc_authLabel' style={{ color: `${state.buttonColor == 'newPasswordLabel' ? brandSetting.theme_color2 : brandSetting.theme_color1}`, transform: `${state.buttonColor == 'newPasswordLabel' ? 'scale(0.8)' : 'scale(1)'}`, top: `${state.buttonColor == 'newPasswordLabel' ? '5px' : '8px'}` }}
                                >New Password</label>

                                <span>{svg.app.passwordIcon}</span>
                                {error.password && <p className='mc_errortext'>Password field is required</p>}
                            </div>

                            <div className='w-full mc_formGroup relative'>
                                <input 
                                 value={state.confirmpassword}
                                 name="confirmpassword"
                                type='password' 
                                className='mc_input' 
                                variant="standard" 
                                label="Confirm Password" 
                                onChange={(e) => handleInputChange(e.target) }
                                autoComplete="off" 
                                onFocus={() => getButtonColor1('ConfirmPasswordLabel')} 
                                onBlur={() => getButtonColor2('ConfirmPasswordLabel')} 
                                onKeyDown={handleKeyDown} />
                                <label className='mc_authLabel' style={{ color: `${state.buttonColor == 'ConfirmPasswordLabel' ? brandSetting.theme_color2 : brandSetting.theme_color1}`, transform: `${state.buttonColor == 'ConfirmPasswordLabel' ? 'scale(0.8)' : 'scale(1)'}`, top: `${state.buttonColor == 'ConfirmPasswordLabel' ? '5px' : '8px'}` }}
                                >Confirm Password</label>
                                <span>{svg.app.passwordIcon}</span>

                                {error.confirmpassword && <p className='mc_errortext'>Password field is required</p>}
                            </div>
                            <div className='w-full'>
                                <button type="submit" className="mc_btn mc_formBtn w-100" style={{ color: brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'Continue' ? brandSetting.theme_color2 : brandSetting.theme_color1}` }} onMouseEnter={(e) => getButtonColor1('Continue')} onMouseLeave={(e) => getButtonColor2('Continue')}>{isProcess ? 'Processing...' : 'Continue'}</button>
                                {/* {
                                isMatchPassword?
                                : 
                                    <button disabled type="button" className="mc_btn mc_formBtn w-100" 
                                        onClick={()=> resetPassword('reset_password', myState)}
                                        style={{color:brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'Continue' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}} onMouseEnter={(e)=> getButtonColor1('Continue')} onMouseLeave={(e)=> getButtonColor2('Continue')}>{isProcess ? 'Processing...' : 'Continue'}</button> 
                            }  */}
                                {/* <button type="button" className='mc_btn mc_formBtn text-center' 
                                onClick={()=> resetPassword( "reset_password", state.password)}
                            >
                                {isProcess ? 'Processing...' : 'Reset Password'}
                            </button> */}
                                {/* <a href={() => false} className='mc_backToLoginBtn w-full text-center text-black text-lg font-semibold mx-auto inline-block mt-3' onClick={()=> setMyState(setQuery,{
                                resetPassword : false,
                            })}>Back to Login</a> */}
                                {tokenCookie != '' ?
                                    <a href={() => false} className='mc_backToLoginBtn w-full text-center text-black text-lg font-semibold mx-auto inline-block mt-3' onClick={() => navigate("/dashboard")} style={{ color: `${state.buttonColor == 'Back to Dashboard' ? brandSetting.theme_color2 : brandSetting.theme_color1}` }} onMouseEnter={(e) => getButtonColor1('Back to Dashboard')} onMouseLeave={(e) => getButtonColor2('Back to Dashboard')}>Back to Dashboard</a>
                                    :
                                    <a href={() => false} className='mc_backToLoginBtn w-full text-center text-black text-lg font-semibold mx-auto inline-block mt-3' onClick={() => navigate("/login")} style={{ color: `${state.buttonColor == 'Back to Login' ? brandSetting.theme_color2 : brandSetting.theme_color1}` }} onMouseEnter={(e) => getButtonColor1('Back to Login')} onMouseLeave={(e) => getButtonColor2('Back to Login')}>Back to Login</a>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}

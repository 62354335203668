import React, {useState, useEffect, useRef} from 'react'
import svg from '../svg';
import { postDataFilter } from '../../pages/index';
import { Link } from 'react-router-dom';
import {
    TECollapse, 
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEDropdown,
    TEDropdownToggle,
    TEDropdownMenu,
    TEDropdownItem,
} from "tw-elements-react";
import CommentsData from '../../components/comments/CommentData';
import {NoDataWrapper, common , setMyState} from '../../components/Comman'; 




const Createpost = (props) => {
    // console.log(props, 'Props');
    const[state, setQuery]=useState({
        togglePost : false,
        upLoadFile : '',
        postUploadFile : '',
        postDataFilter : postDataFilter,
        rating : '',
        createPost : false,
        postId : '',
        isPlay : false,
        page : 1,
        loader : false,
        show : false,
        profileImage : '',
    });
    const handledFileUpload=(e, page)=>{    
        // console.log(e.target.files[0], 'FIle');
        const objectURL = window.URL.createObjectURL(e.target.files[0]);
        // console.log("e.target.files",objectURL)
        setMyState(setQuery,{
          postUploadFile : objectURL,
        })
      }
      const createPostData=(event, postId)=>{
        setMyState(setQuery,{
          ...state,
          createPost : !state.createPost,
          postId : postId,
        })
      }
      const toggleShow = (event, postIndex) => {
        // setShow(!show);
        setMyState(setQuery,{
          ...state,
          show : true,
          postId : postIndex,
        })
      }
    const ref = useRef(null);
    let fetchPostData=()=>{
      let elem=document.getElementById("scroller")
      let targetScrollHeight = elem.scrollHeight - elem.clientHeight;
      if (targetScrollHeight == elem.scrollTop){
        let p = state.page + 1;
        setMyState(setQuery,{
          ...state,
          page : p,
        })
        fetchDataFromPost(true,p)
      }
    }
    const fetchDataFromPost=(load=false, page)=>{
      // console.log('dsdsd');
      // setLoader(true)
      setMyState(setQuery,{
        ...state, 
        loader : true,
        postDataFilter : postDataFilter,
      })
    }
    useEffect(()=>{
      fetchPostData()
    },[postDataFilter])
  return (
    <>
        <div className='mt-4'>
            <div className='mc_postData_dv'>
                <div className='flex flex-wrap p-5 relative'>
                    <TEDropdown className='mc_dropdown_wrapper'>
                        <TEDropdownToggle className="mc_dotIcon_btn">
                          <span>{svg.app.dotsIcon}</span>
                        </TEDropdownToggle>

                        <TEDropdownMenu className='mc_dropdownMenu'>
                          <TEDropdownItem>
                              <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                <span className='mr-2'>{svg.app.editIcon}</span> Edit Post
                              </Link>
                          </TEDropdownItem>
                          <TEDropdownItem>
                              <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                <span className='mr-2'>{svg.app.deleteIcon}</span>  Delete Post
                              </Link>
                          </TEDropdownItem>
                          <TEDropdownItem>
                              <Link to="/" className="flex items-center w-full px-4 py-2 text-sm text-left text-black">
                                <span className='mr-2'>{svg.app.pinIcon}</span> Pin Post
                              </Link>
                          </TEDropdownItem>
                        </TEDropdownMenu>
                    </TEDropdown>
                    <div className='mc_formImg_dv'>
                        <img src={'/assets/images/customer1.jpeg'} alt='customer' className='rounded-full mr-4' width={'42px'} height={'42px'}/>
                    </div>
                    <div className='mc_userDetail'>
                        <h6>Vijayaragavan Ramachari</h6>
                        <p className='flex text-xs'>230 Followers | 300 Posts <span className='ml-3 mc_clockGray mr-1'>{svg.app.clockIcon}</span><span className='text-gray-400'> 0 mnts ago</span></p>
                    </div>
                </div>
                <div className='flex flex-wrap px-5'>
                
                <p className='text-sm w-full mb-5'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups. Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                {state.postUploadFile &&
                  <div className='mb-6 w-full mc_fileUpload_form'>
                    <img src={String(state.postUploadFile)} alt='upload' className='rounded-lg mx-auto block'/>
                  </div>
                }
                </div>
            </div>
            <div className='mc_postData_dv_list p-5'>
                <div className='flex flex-wrap items-center justify-around w-full'>
                  <a href={() => false} className='mc_iconList mr-8 flex items-center text-gray-500' 
                      onClick={()=>{
                          let data = [...state.postDataFilter];
                          data[state.postId+1].postLike = (data[state.postId+1].postLike == false ? true : false);
                          // let postData = data[state.postId+1].postLike;
                          // console.log(data[state.postId+1], 'data[i].postDataFilter');
                          setMyState(setQuery,{
                              ...state,
                              postDataFilter : data,
                          })
                      }}
                  >
                      {state.postLike == true ? 
                          <span className={`mr-2 mc_true`}>{svg.app.darkLikeIcon}</span> 
                      :
                          <span className='mr-2'>{svg.app.likeIcon}</span> 
                      }
                      {/* <span className='mr-2'>{svg.app.likeIcon}</span> */}
                      Like
                  </a>
                  <a href={() => false} className={`mc_iconList flex items-center mr-8 text-gray-500`} onClick={(e)=>toggleShow(e, state.postId+1)}>
                      <span className='mr-2'>{svg.app.replyIcon}</span> 
                      Comment
                  </a>
                  {/* <a href={() => false} className='mc_iconList flex items-center mr-8 text-gray-500'>
                      <span className='mr-2'>{svg.app.replyIcon}</span> 
                      Send
                  </a> */}
                  <p className='text-gray-500 text-sm font-light'>100 Likes | 23 Reposts | 0 Comments</p>
                </div>
                <div className='flex w-full items-center mc_commentBox_dv'>
                    <TECollapse show={state.show} className=' w-full'>
                        <CommentsData post={state.createPost}/>
                    </TECollapse>
                </div>
            </div>
        </div>
    </>
  )
}

export default Createpost
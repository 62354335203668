import React, {useEffect, useRef, useState} from 'react'
import { common, setMyState } from './Comman';
import { appStore } from '../zustand/store';
import Cookies from 'js-cookie';
import svg from './svg';
import { toast } from 'react-toastify';
import {LoadingOutlined} from '@ant-design/icons'
import { Spin } from 'antd';
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
    TETooltip,
} from "tw-elements-react";

const SingleNote = ({ inxNote, noteData, parsedNotes, setShowConfirmationModal,showVMText,setVMText ,setEditNoteId , editNoteId }) => {

    const[viewfullNotes,setViewfullNotes] = useState([]);

    const noteRef = useRef(null);

    useEffect(() => {
        const checkClamp = () => {
            const lineHeight = parseInt(window.getComputedStyle(noteRef.current).getPropertyValue('line-height'));
            const maxHeight = lineHeight * 4; 
            const actualHeight = noteRef.current.scrollHeight;
            
            if(actualHeight > maxHeight){ 

                    setVMText(curr => {
                        const newArray = Array.isArray(curr) ? curr : [];
                        if (!newArray.includes(noteData?.lesson_note_id)) {
                            return [...newArray, noteData?.lesson_note_id];
                        }
                        return newArray;
                    });
          
            }else{ 
                setVMText(curr => {
                    const newArray = Array.isArray(curr) ? curr : [];
                    if (newArray.includes(noteData?.lesson_note_id)) {
                        return newArray.filter(id => id !== noteData?.lesson_note_id)
                    }
                    return newArray;
                });
                setViewfullNotes(curr => {
                    const newArray = Array.isArray(curr) ? curr : [];
                    if (newArray.includes(noteData?.lesson_note_id)) {
                        return newArray.filter(id => id !== noteData?.lesson_note_id)
                    }
                    return newArray;
                })
            }
        };
        checkClamp();
        window.addEventListener('resize', checkClamp);
        return () => {
            window.removeEventListener('resize', checkClamp);
        };
    }, [parsedNotes?.note]);
   

    const handleToggleNote = (noteId) => {
        setViewfullNotes(curr => {
            if (curr.includes(noteId)) {
                return curr.filter(id => id !== noteId);
            } else {
                return [...curr, noteId];
            }
        });
    };
    return (
        <>
           <p style={{ whiteSpace: 'pre-line' }} ref={noteRef} className={`text-sm text-neutral-900 dark:text-neutral-900 break-words ${viewfullNotes.includes(noteData?.lesson_note_id) ? '' : 'note_short'}`} id={`note${inxNote}`}>
           {
            editNoteId !== noteData?.lesson_note_id ?  <>  
            <a href={() => false}  className="mc_editIcon" onClick={(e) =>setEditNoteId(noteData?.lesson_note_id , parsedNotes?.note)}>  
            <TETooltip
                     title="Edit"
                     className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600 pointer-events-auto cursor-pointer"
                   > {svg.app.editIcon} </TETooltip>
        </a>                       
           
            <a href={() => false}  className="mc_crossIcon" onClick={(e) =>setShowConfirmationModal(noteData?.lesson_note_id)}>
            <TETooltip
                            title="Delete"
                            className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600 pointer-events-auto cursor-pointer"> {svg.app.deleteIcon}</TETooltip>
                            </a>                
             </> : null
           } 
               
                {parsedNotes?.note} 
            </p>
            {showVMText.includes(noteData?.lesson_note_id) &&  !viewfullNotes.includes(noteData?.lesson_note_id) && <span className="text-neutral-900" onClick={()=> handleToggleNote(noteData?.lesson_note_id)}>View More</span>}

            {viewfullNotes.includes(noteData?.lesson_note_id) &&  <span className="text-neutral-900" onClick={()=> handleToggleNote(noteData?.lesson_note_id)}>Hide</span>}
        </>
    );
};

function LessonNotes(props) {
    const slugName = window.location.href.split('/')[4];
    let myStore = appStore(state => state);
    let userData = myStore.userData;
    const memberShipUser = userData.MembershipUser;
    let brandSetting = myStore.brandSetting;
    const[showConfirmationModal,setShowConfirmationModal] = useState(false);
    // const noteList = props.noteData;
    let tokenCookie = Cookies.get(`access_token_${slugName}`) ? Cookies.get(`access_token_${slugName}`): false;
    const [state, setQuery] = useState({
        funnel_id: memberShipUser.funnel_id,
        member_id: memberShipUser.member_id,
        funnel_page_id: props.myParam,
        token: tokenCookie,
        noteTextarea : '',
        noteDateList : '',
        btnLoader:false,
        editNoteTextarea:'',
        editLoader:false
    })
    const[showVMText,setVMText] = useState([]);
    const[editNoteId,setEditNoteId] = useState();

    const textareaRef = useRef(null);
    const edittextareaRef = useRef(null);
    const getNoteSaveData = (noteText,time,type = false) => { 
        // console.log(noteText,time,type, 'GetNOTES');
        if(noteText != ''){
            let notes =  JSON.stringify({time :time ? time : formatTime(window.videoDuration), note : noteText})
            let apiData = {
                funnel_id: memberShipUser.funnel_id,
                member_id: memberShipUser.id,
                funnel_page_id: props.myParam,
                lesson_id : props.lessonId,
                token: tokenCookie,
                notes :notes
            };
            if(editNoteId && type == 'edit'){
                apiData.lesson_note_id  = editNoteId;
            }
            if(!editNoteId){
            setMyState(setQuery, {
                btnLoader : true,
            })
            }
            if(type == 'edit'){
                setMyState(setQuery, {
                    editLoader : true,
                })
            }
            common.getAPI({
                method: "Post",
                url: "save-lesson-notes",
                data: apiData,
            },
            async (resp) => { 
                setMyState(setQuery, {
                    btnLoader : false,
                    editLoader:false
                })
                if(resp.success == false){
                    toast.error(resp.message);
                }else{
                    toast.success(resp.message);
                    setMyState(setQuery, {
                        noteTextarea : '',
                        editNoteTextarea:''
                    })
                    setEditNoteId(false);
                    getNotesListData()
                } 
            });
        }else{
            toast.error('Note feild is required.')
        }
    };
    const handleDeleteNote=(noteId)=>{
        let apiData = {
            funnel_id: memberShipUser.funnel_id,
            funnel_page_id: props.myParam,
            lesson_id : props.lessonId,
            member_id: memberShipUser.id,
            lesson_note_id : noteId,
            token: tokenCookie,
        };
        common.getAPI({
            method: "Post",
            url: "delete-lesson-notes",
            data: apiData,
        },
        async (resp) => { 
            if (resp.success==true) {
                toast.success(resp.message);
                let dummyData = showVMText;
                let filterData = dummyData.filter(val =>val != noteId);
                setVMText(filterData);
                getNotesListData();
            }else{
                toast.error(resp.message);
            }
        })
    }
    const getNotesListData=()=>{
        let apiData = {
            funnel_id: memberShipUser.funnel_id,
            funnel_page_id: props.myParam,
            lesson_id : props.lessonId,
            member_id: memberShipUser.id,
            token: tokenCookie,
        };
        common.getAPI({
            method: "Post",
            url: "get-lesson-notes",
            data: apiData,
        },
        async (resp) => { 
            if(resp.success == false){
                toast.error(resp.message);
                setMyState(setQuery, {
                    noteDateList : '',
                    noteTextarea : '',
                })
            }else{
                            
                setMyState(setQuery, {
                    noteDateList : resp.notes_list,
                    noteTextarea : '',
                })
                if(resp.notes_list.length == 0){
                    setVMText([]);
                }
            }
        });
    }
    useEffect(()=>{
        getNotesListData()
    },[])

    // Function to convert seconds to minutes and seconds
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    
    let currentDuration = formatTime(window.videoDuration);
    // console.log(currentDuration, 'currentDuration');
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setMyState(setQuery, {
                noteTextarea: state.noteTextarea + '\n', 
            });
            if (textareaRef.current) {
                textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
            }
        }
    };
    
    const getColor1=(type)=>{
        setMyState(setQuery, {
            ...state,
            buttonColor : type,
        })
    }
    const getColor2=(type)=>{
        setMyState(setQuery, {
            ...state,
            buttonColor : null,
        })
    }

    const handleEditId = (id ,note)=>{
        setEditNoteId(id);
        setMyState(setQuery, {
            editNoteTextarea : note,
        })
    }
    const customLoadingIcon = <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />;

  return (
    <>
        <div className='col-span-3 mc_moboilCol_12'>
            <h4 className='text-base text-black mt-3 font-bold mb-4'>My Notes For The Lessons</h4>
            <div className='flex w-full justify-end flex-wrap'>
                <textarea rows={'4'} value={state.noteTextarea} className="mc_textarea w-full px-4 py-4" placeholder='Type your notes and save..'  ref={textareaRef}
                    onChange={(e)=> {
                        // getNoteTextData(e.target.value);
                        setMyState(setQuery, {
                            noteTextarea : e.target.value
                        })
                        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
                    }}
                    onKeyDown={handleKeyDown}
                ></textarea>
                <div className='flex w-full justify-end flex-wrap mt-4'>
                    <button className='mc_btn btn-base justify-center'
                        style={{color : brandSetting.theme_button_txt_color, backgroundColor: `${state.btnLoader ? '#999' : brandSetting.theme_color2}`,maxWidth:'135px',width:"100%"}}
                        disabled={state.btnLoader}
                        onClick={()=>getNoteSaveData(state.noteTextarea, currentDuration, 'save' )}
                    >{state.btnLoader && <Spin size="small" indicator={customLoadingIcon}/>} {state.btnLoader ? '' : "Save Note"}  </button>
                </div>
            </div>
            {
                state.noteDateList?.length > 0 && <>
                 <div className='mt-4 mc_noteHead'>
                <h5 className='text-black text-bold text-sm-13 pb-3 font-bold'>My Notes</h5>
            </div>
            <div className='mc_fileTimeline_dv'>
                <ol className="border-l border-neutral-300 dark:border-neutral-500">
                    {/* <!--First item--> */}
                    {state.noteDateList?.length ? state.noteDateList?.map((noteData, inxNote)=>{
                        var notesObj = { ...noteData }; 
                        var notesString = noteData?.notes; // Your JSON string

                        if (notesString.trim() !== "") {
                            try {
                                
                                var parsedNotes = JSON.parse(notesString);
                                notesObj.notes = parsedNotes;
                            } catch (error) {
                                console.error("Error parsing JSON:", error);
                            }
                        }
                        return (
                            <li key={inxNote}>
                                <div className="flex-start flex items-center pt-0 pb-8">
                                    <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                        <span>{svg.app.fileBoard}</span> {parsedNotes?.time}
                                    </div>
                                    <div className='mc_maxTimeLine_w'>
                                        {editNoteId == noteData?.lesson_note_id && 
                                            <div className='flex w-full justify-end flex-wrap'>
                                                <textarea rows={'4'} className="mc_textarea w-full px-4 py-4" placeholder='Type your notes and save..'
                                                    ref={edittextareaRef} value={state.editNoteTextarea}
                                                    onChange={(e)=> {
                                                            setMyState(setQuery, {
                                                            editNoteTextarea : e.target.value
                                                        })
                                                        edittextareaRef.current.scrollTop = edittextareaRef.current.scrollHeight;
                                                    }}
                                                ></textarea>
                                                <div className='flex w-full justify-end flex-wrap mt-4 gap-3'>
                                                    <button href={() => false} className='mc_btn btn-base justify-center'  disabled={state.editLoader}
                                                        style={{color : brandSetting.theme_button_txt_color, backgroundColor:`${state.editLoader ? '#999' : brandSetting.theme_color2}`,maxWidth:'135px',width:"100%"}}
                                                        onClick={()=>getNoteSaveData(state.editNoteTextarea ,parsedNotes?.time,"edit")}
                                                    >
                                                        {state.editLoader && <Spin size="small" indicator={customLoadingIcon}/>} {state.editLoader ? '' : "Save Note"}
                                                    </button>

                                                    <a href={() => false} className='mc_btn btn-base'
                                                        style={{color : brandSetting.theme_button_txt_color, backgroundColor: brandSetting.bgcolor}}
                                                        onClick={()=>{ 
                                                            setEditNoteId(false);
                                                            setMyState(setQuery, {
                                                            editNoteTextarea : ''
                                                        })}}
                                                    >Cancel</a>
                                                </div>
                                            </div>
                                        }
                                        <SingleNote inxNote={inxNote} noteData={noteData} parsedNotes={parsedNotes} setShowConfirmationModal={(note)=>setShowConfirmationModal(note)} setVMText={(val)=>setVMText(val)} showVMText={showVMText} editNoteId={editNoteId} setEditNoteId={handleEditId}/>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                :''
                }
                    {/* <li>
                        <div className="flex-start flex items-center pt-3 pb-8">
                            <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                <span>{svg.app.fileBoard}</span> 00:34
                            </div>
                            <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                <a href={() => false} className="mc_crossIcon">{svg.app.crossIcon}</a>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="flex-start flex items-center pt-3 pb-8">
                            <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                <span>{svg.app.fileBoard}</span> 00:34
                            </div>
                            <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                <a href={() => false} className="mc_crossIcon">{svg.app.crossIcon}</a>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="flex-start flex items-center pt-3 pb-8">
                            <div className="-ml-[13px] h-[25px] w-[25px] rounded-full text-black font-light block items-center justify-center mc_timelineBg_white">
                                <span>{svg.app.fileBoard}</span> 00:34
                            </div>
                            <p className="text-sm text-neutral-900 dark:text-neutral-900 mc_maxTimeLine_w">
                                <a href={() => false} className="mc_crossIcon">{svg.app.crossIcon}</a>
                                Richard McClintock, a Latin scholar from Hampden-Sydney College, is credited with discovering the source behind the ubiquitous filler text. In seeing a sample of lorem ipsum, his interest was piqued by consectetur—a genuine, albeit rare, Latin word. Consulting a Latin dictionary led McClintock to a passage from De Finibus Bonorum et Malorum (“On the Extremes of Good and Evil”), a first-century B.C. text from the Roman philosopher Cicero.
                            </p>
                        </div>
                    </li> */}
                </ol>
                {/* <NoDataWrapper
                        className="group relative bg-white box-shadow rounded-md mc_courseBox" 
                        blockCount="1" 
                        height="440"
                        width="1200" 
                        section="projectLoader"
                        title={''}                       
                    /> */}
            </div>
                </>
            }
           
        </div>

        <TEModal show={showConfirmationModal} setShow={setShowConfirmationModal} scrollable className='mc_modalHolder  mc_confermationModal'>
            <TEModalDialog centered className='mc_modalDialog '>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowConfirmationModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImage'>
                                    {svg.app.deleteBox /* <img src={`/assets/images/out-of-stock.png`} alt='unlock' width={'310px'} height={'280px'}/> */}
                                </div>
                                <div className='mc_textContentModal'>
                                    <h3 className='text-xl text-black font-bold mb-2'>Are you sure?</h3>
                                    <p className='text-sm text-black mb-3'>You want to delete this note?</p>
                                    <div className="flex justify-between mc_confermationBtn">
                                        <div className='flex'>
                                            <button className="mr-2 mc_btn"
                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'No' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                onMouseEnter={(e)=> getColor1('No')} onMouseLeave={(e)=> getColor2('No')} 
                                                onClick={() => setShowConfirmationModal(false)}
                                            >
                                                No
                                            </button>
                                            
                                            <button className="mc_btn"
                                                style={{ color: brandSetting.theme_button_txt_color, backgroundColor : `${state.buttonColor == 'Yes' ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                                                onMouseEnter={(e)=> getColor1('Yes')} onMouseLeave={(e)=> getColor2('Yes')} 
                                                onClick={()=> {
                                                    handleDeleteNote(showConfirmationModal);
                                                    setShowConfirmationModal(false)
                                                }}
                                            >
                                                Yes  
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    </>
  )
}



export default LessonNotes

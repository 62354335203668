export const speakerData = [
    
        {
            "speaker_name": "Mr. Gopal Krishnan",
            "about": "Creator of Binge Marketing Method",
            "img": "https://img.flexifunnels.com/images/5/gopal21500_k5otu_500.webp",
            "description": "<ul><li>Gopal Krishnan is the founder of EduTech startups Lifewheel and BingeClass and the host of the Unicorn Awards Ceremony, widely regarded as the Oscars of the Digital Coaching Industry.</li><li>His “Binge Marketing System using WhatsApp Class Funnel’ for growing coaching businesses has impacted over 1.5L+ lives.</li><li>Over the past 7 years, Gopal has been the guide and practitioner-teacher for coaches, consultants, and course creators who want more leads, more sales, and more students in their community.</li><li>He shows coaches how to build their leadership brands with a WhatsApp class and scale their coaching business to seven figures and beyond.</li><li>His WhatsApp Class Funnel is built around converting educators or coaches to leaders and equipping them to motivate the masses to take action, without getting overwhelmed by technology.</li><li>Gopal is on a mission to replace commonplace coaching with powerful leadership by eliminating cold calling and high-pressure chasing.</li><li>Gopal lives by the mantra “Every Coach is a Unicorn Coach” and is on a mission to transform every coach into a high-impact, high-influence, and highly inspiring leader.</li></ul>"
        },
        {
            "speaker_name": "Mr. Santhosh Govindaswamy",
            "about": "CEO of Bingeclass",
            "img": "https://img.flexifunnels.com/images/5/santhosh_u5nzg_500.webp",
            "description": "<ul><li>Santhosh Govindaswamy Is The Founder Of Edutech Startup Bingeclass And Video Marketing Brand Outside Edge</li><li>Santhosh Govindaswamy’s “Interactive Digital Coaching” for improving show-up rates and conversions is phenomenal.</li><li>He shows online coaches, consultants, course creators, and experts how to 10X their show-up rates and sales and revenue with simple software like OBS and Canva and scale their coaching business to 7 figures and more.</li><li>Over the last 7 years, Santhosh has been the guide for all coaches who want to enhance their customer experience through immersive and interactive tools and videos, to get more engaged, happy, and long-term customers.</li><li>Santhosh Has Worked Till Now With Over 1000+ Coaches Who Have Implemented The Interactive Digital Coaching Model and experienced amazing results.</li><li>His coaching Funnel is built around converting everyday coaches to market leaders in their niche by equipping them with video marketing tools, without getting overwhelmed with expensive and complex technology.</li><li>Santhosh is on a mission to replace boring, bland, and uninteresting online sessions with powerful, entertaining, and engaging sessions.</li></ul>"
        },
        {
            "speaker_name": "Mr. Nirmal Chandran",
            "about": "Co-Founder & COO of LifeWheel",
            "img": "https://img.flexifunnels.com/images/5/nirmal2500_mznzg_500.webp",
            "description": "<ul><li>Nirmal Chandran is the COO & Co-founder of LifeWheel, ₹12 Crore  online academy that helps subscribers to initiate mini-habits that can literally change the course of their lives.</li><li>He is a Marketing specialist, Life Coach, and Facebook Ads Expert driven by a passion to transform Millions of lives.</li><li>Lifewheel provides highly useful & easily consumable 5-day courses you can learn by spending just 60 minutes per day, at just the click of a button, without searching for URLs or stored data.</li><li>The brand stands as a leader in India, boasting some of the highest success rates in transforming the lives of learners.</li><li>Lifewheel currently has over 14 coaches impacting lives through a wide range of programs like gardening, manifestation, gut reset, breath mastery, spirituality, and more.</li><li>Through LifeWheel, Nirmal has currently impacted over 1.2 L+ lives around the World.</li></ul>"
        },
        {
            "speaker_name": "Mr. Karthik Ramani",
            "about": "Co-Founder of Flexifunnels",
            "img": "https://img.flexifunnels.com/images/943/i5nzk_1080_Mr.KarthikRamaniPANELIST1.png",
            "description": "<ul><li>He has generated several crores of rupees from domestic as well as international product launches.</li><li>He has done multiple million-dollar launches online. At one point he was generating so much revenue in the international market that his photograph was listed on the home page of Paypal India.</li><li>He has generated ~$1,000+/day consistently in affiliate commissions through email marketing for over 5+ years.</li></ul>"
        },
        {
            "speaker_name": "Dr. Arokiaswamy Velumani",
            "about": "Founder of Thyrocare Technologies Ltd.",
            "img": "https://img.flexifunnels.com/images/5/speakers500_e2mjm_500.webp",
            "description": "<ul><li>Farmer, Scientist, Entrepreneur, Storyteller, and Mass Mentor</li><li>Created 100s of Millionaires with over 45+ years of experience</li><li>Founder of Thyrocare Technologies Ltd., the largest thyroid testing laboratory</li><li>Founder and managing director of Nueclear Healthcare Ltd. (NHL)</li></ul>"
        },
        {
            "speaker_name": "Mr. Saurabh Bhatnagar",
            "about": "Co-Founder & CEO of Flexifunnels",
            "img": "https://img.flexifunnels.com/images/943/u4mze_700_axnzu700Layer3copy3.webp",
            "description": "<ul><li>He is India's Leading Sales & Business Coach.</li><li>Trained 7,00,000+ people, he is a 4X TEDX Speaker, 8X Josh Talk Speaker, author of a National Bestseller Book with over 36,500+ readers.</li><li>His insights & guidance have helped multiple people & businesses hit 8 Figures Online.</li></ul>"
        },
        
            {
                "speaker_name": "Mr. Rahul Bhatnagar",
                "about": "India's Leading Sales & Business Coach",
                "img": "https://img.flexifunnels.com/images/943/k5ode_634_uyoda634Layer21.webp",
                "description": "<ul><li>Also known as \"Automation & Funnel King\".</li><li>He has done sales of over Rs 50 Crores+ of his own products online.</li><li>Brain behind some of Facebook’s highest ROI ad campaigns & automated business funnels with the highest conversion numbers.</li><li>Helped 1000s of entrepreneurs to generate 7, 8 & 9 Fig. online through his programs, courses & software products.</li></ul>"
            },
            {
                "speaker_name": "Mr. Sorav Jain",
                "about": "Instagram Growth & Digital Marketing Coach",
                "img": "https://img.flexifunnels.com/images/5/speakers5500_gymte_500.webp",
                "description": "<ul><li>Founder of EchoVME, a digital marketing agency and Digital Scholar, India's First Agency-Based Digital Marketing Institute</li><li>Handled 250+ Brands and Trained 119,075+ people</li><li>Digital Marketing Trainer, Speaker, Author and social media consultant with over 521K followers</li></ul>"
            },
            {
                "speaker_name": "Mr. Swastik Nandakumar",
                "about": "Peak Performance Coach",
                "img": "https://img.flexifunnels.com/images/5/speakers6500_kzotq_500.webp",
                "description": "<ul><li>India's own Business Growth Expert and a Peak Performance Coach.</li><li>Trained 12K + Business Owners</li><li>Dedicated to transforming entrepreneurs, coaches, consultants, and service providers by working on high performance, accessing flow states, and aligning their work with their true values.</li></ul>"
            },
            {
                "speaker_name": "Mitesh & Indu Khatri",
                "about": "Law Of Attraction Coaches",
                "img": "https://img.flexifunnels.com/images/5/speakers7500_kxode_500.webp",
                "description": "<ul><li>Mitesh Khatri is India's top 5 Corporate trainer, Motivational Speaker and Leadership training expert.</li><li>Indu Khatri is the Co-Author of Best-sellers “Awaken The Leader In You” and the principle Content Designer for Leadership & Transformational Trainings.</li><li>On a mission to help you Manifest Daily to Master Your Subconscious Mind, Attract 10x Better Health, Relationship, Money & Career in 30 Days.</li></ul>"
            },
            {
                "speaker_name": "Mr. Ankit Neerav",
                "about": "Peak Performance Coach",
                "img": "https:\/\/img.flexifunnels.com\/images\/5\/c0odk_600_Ankit.png",
                "description": "<ul><li>Pioneer in the self-improvement space in India with path-breaking concepts and tools like the Perspective Exercise, the R.O.P.E.S framework, the Time Hacking framework, Conscious Disbalance, Mindful Acceptance, Audio-based Archetype Access, the FINERGY concept and much more.</li><li>Over 30,000 people joined this WhatsApp-based action-based training.</li><li>Courses, training & live events loved by people from all walks of life.</li></ul>"
            },
            {
                "speaker_name": "Dr. Manjunath M.S",
                "about": "Mind Performance Coach",
                "img": "https://img.flexifunnels.com/images/5/speakers9500_iwnju_500.webp",
                "description": "<ul><li>Leading Memory & Mind Performance Coach and Motivational Speaker in India.</li><li>Inspiring top Executives, Business owners, Professionals and students to excel in their professional and personal excellence.</li><li>A.P.J. Abdul Kalam Award of Excellence and Memory Management Expert Award winner.</li><li>Trained over 50,000 professionals, business owners & Students.</li></ul>"
            },
            {
                "speaker_name": "Dr. Rajitha Vanga",
                "about": "Happiness Coach",
                "img": "https://img.flexifunnels.com/images/5/speakers10500_yyoty_500.webp",
                "description": "<ul><li>Life Transformation Coach | Founder & CEO · RV Life Coaching</li><li>Transformed 10,000+ lives and filled them with happiness, fulfillment, and satisfaction.</li><li>Passionate about empowering others to become Life Coaches themselves, equipping them with the knowledge and skills to impact lives and create lasting change in their communities.</li></ul>"
            },
            {
                "speaker_name": "Dr. Savitha Suri",
                "about": "Ayurveda Physician, PCOS Coach",
                "img": "https://img.flexifunnels.com/images/5/speakers11500_czotu_500.webp",
                "description": "<ul><li>Experienced chief consultant who helps women to regularize periods/reset fertility/in PCOD and PCOS/Ayurveda techniques.</li><li>Impacted 10,000+ lives and reached 14K followers on Instagram in under 3 months by implementing video marketing strategies.</li><li>Experienced healthcare professional skilled in customer service, meditation, herbal medicine, chronic illness, and yoga.</li></ul>"
            },
            {
                "speaker_name": "Dr. Sandeep Gupta",
                "about": "Wealth Acceleration Coach",
                "img": "https://img.flexifunnels.com/images/5/speaker1500_izmtc_500.webp",
                "description": "<ul><li>Teaches how one can accelerate wealth by decreasing the liability and boosting the revenue.</li><li>15 years of experience in banking and financial Industry.</li><li>Created an easy step-by-step proven method to optimize your wealth in his ‘Wealth Accelerator Blueprint’.</li></ul>"
            },
            {
                "speaker_name": "Mr. Divyanshu Damani",
                "about": "Co-Founder & CEO at TagMango (YC W20)",
                "img": "https://img.flexifunnels.com/images/5/speaker500_a0mta_500.webp",
                "description": "<ul><li>Forbes 30 Under 30 Asia</li><li>Keynote Speaker, Digital Creator, 100 mil+ views</li><li>An entrepreneur, a social media influencer, a keynote speaker, a passionate scuba diver & skier</li></ul>"
            },
            {
                "speaker_name": "Mr. Dharaneetharan G D",
                "about": "Founder at 21 DC community",
                "img": "https://img.flexifunnels.com/images/5/dharaneetharan500_q3nje_500.webp",
                "AboutPara":' A community for people who are aiming for Holistic Success & Lifestyle and socialeagle, a consulting agency for business growth, branding through videos and digital entrepreneurship',
                "description": "<ul><li>Social media influencer with over 300K followers on Instagram</li><li>Worked with 200+ Clients Globally| Leading a 20K+ action-takers community</li></ul>"
            }, 
            {
                "speaker_name": "Mr. SAGAR ACHHRA",
                "about": "Founder of Funnels Traffic and Digital Marketing Expert ",
                "img": "https://img.flexifunnels.com/images/943/c1mje_1079_SagarPhoto.png",
                "description": "<ul><li>Founder of Funnels Traffic helping businesses monetize their audience and achieve exponential growth in sales, profits, and revenue.</li><li>Performance Marketing and Digital Marketing Expert </li><li>30 Lakhs + Leads Generated | 40 Cr + Revenue created for Clients</li></ul>"
            }, 
            {
                "speaker_name": "Mr. PRASHEEK GAIKWAD",
                "about": "Hypnotist, life coach and corporate trainer",
                "img": "/assets/images/eventsimg/prasheek_speaker.jpg",
                "description": "<ul><li>Youngest hypnotist in Pune, life coach and corporate trainer.</li><li>Over 5 years of experience in the field.</li><li>His core competency includes programs on Business Management, Leadership Skills, Soft Skills, Time Management, Stress & Strain Management and Hypnotism Stage Show.</li></ul>"
            },
        
        
    
]


export const speakerLWGData = [
    
    {
        "speaker_name": "Swami Sukhabodhananda",
        "about": "Spiritual Guru",
        "img": "/assets/images/lwgspeakers/1.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "DR. Harish Warrier",
        "about": "Ayurvedic Practitioner $ Gut Reset Coach",
        "img": "/assets/images/lwgspeakers/2.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Dr. Kalyani",
        "about": "Parenting Coach",
        "img": "/assets/images/lwgspeakers/3.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Yukti K Mehandiratta",
        "about": "Leadership Coach",
        "img": "/assets/images/lwgspeakers/4.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Aariesh Krishnamurthi",
        "about": "Horiculturist",
        "img": "/assets/images/lwgspeakers/5.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Pradeep Acharya",
        "about": "Brain Gymmer & Memory Coach",
        "img": "/assets/images/lwgspeakers/6.png",
        "description": "<ul><li></li></ul>"
    },
    
    {
        "speaker_name": "Aakriti Khosla",
        "about": "Mandala Coach & Art Therapist",
        "img": "/assets/images/lwgspeakers/7.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Sandip Dash",
        "about": "Happiness & chakra Healing Coach",
        "img": "/assets/images/lwgspeakers/8.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Infinity Karthik",
        "about": "Super Brain & Productivity Coach",
        "img": "/assets/images/lwgspeakers/9.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Badarinath Gella",
        "about": "Wellness Coach",
        "img": "/assets/images/lwgspeakers/10.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Kashesh Chhabbria",
        "about": "Manifestation Coach & Psychologist",
        "img": "/assets/images/lwgspeakers/11.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Archna Sharma",
        "about": "Sleep Coach & Thought Teachnologist",
        "img": "/assets/images/lwgspeakers/12.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Dr. Shivani",
        "about": "Focus Coach & Dentist",
        "img": "/assets/images/lwgspeakers/13.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Nirmal Chandran",
        "about": "Co-Founder | Life Wheel",
        "img": "/assets/images/lwgspeakers/14.png",
        "description": "<ul><li></li></ul>"
    },
    {
        "speaker_name": "Nirmal Chandran",
        "about": "Co-Founder | Life Wheel",
        "img": "/assets/images/lwgspeakers/15.png",
        "description": "<ul><li></li></ul>"
    },
    // {
    //     "speaker_name": "Lifewheel ",
    //     "about": "Life Wheel",
    //     "img": "/assets/images/lwgspeakers/lifewheelposter.png",
    //     "description": "<ul><li></li></ul>"
    // },
]

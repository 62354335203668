import React, { useEffect, useState } from 'react'
import config from '../../config'
import { common ,dataLoader } from '../Comman';
import svg from "../svg";
export default function NotificationList(props) {
    let [list ,setlist ] =useState([])
    const apiUrl = config.REACT_CHAT_APIURL;
    let alfaUrl = config.REACT_APP_BUCKET_URL
    let id =localStorage.getItem("id")
    const [load ,setload]=useState(false)
    const [userpag, setuserpag] = useState({
        page: 1,
        limit: 10,
      });


      useEffect(()=>{
        let header = document.getElementById("mainHeaderMenu");
        let c1=document.getElementById("mainfooter")
       if(header.classList.contains("hidden")){
        header.classList.remove("hidden");
       }
    
       if(c1.classList.contains("hidden")){
        c1.classList.remove("hidden");
       }
      },[])
      
    useEffect(()=>{
        getAllUserDataList(id)

    },[])
    const [state, setQuery] = useState({
      bodyColor: "#F7F7F7",
      themeColor: "#22CCA6",
      textColor: "#C8D3E8",
      hoverColor: null,
    })
    const getAllUserDataList=async(id,page=null,load1=null)=>{
        setload(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
        
        fetch( apiUrl +"getNotification?id="+id+`&page=${page ? page : userpag.page}&limit=${userpag.limit}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            
            if(result.status){
                if(load1)
                {
                    setlist([...list ,...result.data])

                }else{
                    setlist(result.data)
                }
                
                console.log(result.data)
            }
          })
          .catch((error) => console.error(error));
          setload(false)
    }

    function formatAMPM(date) {
        var todaysDate = new Date();
        date = new Date(date);
        if (date.toDateString() != todaysDate.toDateString()) {
          return common.dateFormatter(date);
        }
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
      }

      let fetchData = (e) => {
       if(load)
       {
        return
       }
        let elem = document.getElementById(e.target.id);
        let targetScrollHeight = elem.scrollHeight - elem.clientHeight;
        if (targetScrollHeight == elem.scrollTop) {
          let c1 = parseInt(userpag.page) + 1;
          setuserpag({
            ...userpag,
            page: c1,
          });
          getAllUserDataList(id,c1,true)
          return;
        }
      };
    return (
        <>
            <div className='mc_notificationList_dv'  id="element"  onScroll={(e) => fetchData(e)}>
            <div className="mc_headTitle_dv">
              <h2 style={{ color: "#000" }}>
                {props.title}{" "}
                <span
                  className="mc_titleCount"
                  style={{ color: state.themeColor }}
                ></span>
              </h2>
            </div>
            {load ? dataLoader.commonLoader :
              <div className="chat-list " >
                    {list.length>0 ?  list.map((d1)=>{
                        let d3=d1?.data?.[0]
                        return(<>
                             <a href={void (0)} className="flex align-items-center">
                                <div className="flex-shrink-0 mc_profileBg_img" style={{backgroundImage : `url(/assets/images/mobile/1024.png)`}} ></div>
                                <div className="flex-grow-1 ms-3 mc_chatContent_holder">
                                    <div className='mc_chatUser_content'>
                                        <h3>
                                            {d3?.title} 
                                    
                                        </h3>
                                        <p>{d3?.description}</p>
                                    
                                    </div>
                                    <span className={`mc_ChatIcon_list`}>
                                        {formatAMPM(d1?.Create_at)}
                                    
                                    </span>
                                </div>
                            </a>
                        </>)
                    })
                :<div className="mc_congrats_dv">
                <div className="mc_completeModal text-center">
                  <div>{svg.app.nodataFound}</div>
                  <h4 className="text-lg font-medium text-black my-3">
                    No Notification found!
                  </h4>
                </div>
              </div>
                }
               
                </div>
                    }
            </div>
        </>
    )
}
import React from 'react'
import svg from '../svg'
import { Link } from 'react-router-dom'

const CourseCreator = (props) => {
    // console.log(props, 'PropsData');
    return (
        <>
            <div className='mc_recordBox mc_whiteBox mc_moboilCol_12'>
                <div className='flex items-center'>
                    <h2 className='text-5xl font-semibold color-themeColor mr-3'>434</h2>
                    <p className='text-semibold text-black'>Total students <br/>already enrolled</p>
                </div>
                <div className='flex items-center justify-between mc_borseredBox my-5 p-3'>
                    <div className='mc_rateText'>
                        <h5 className='text-sm font-bold flex'>4.6 <span className='mx-3'>{svg.app.ratedStar}</span> <span className='text-sm font-light'>(986 reviews)</span></h5>
                    </div>
                    <a href={() => false} className='text-sm font-100'>View</a>
                </div>
                <div className='flex items-center my-5'>
                    <span className='mr-4'>{svg.app.clockIcon}</span>
                    <div className=''>
                        <p className='text-sm text-black'>10 Modules, 34 Lessons</p>
                        <p className='text-sm text-black'>3h 45 Min</p>
                    </div>
                </div>
                <div className='mc_grayBox_user'>
                    <div className='flex items-center p-5'>
                        <span className='mr-4'>
                            <img src={'/assets/images/customer1.jpeg'} className='rounded-full' alt='studio' width={'54px'} height={'54px'}/>
                        </span>
                        <div className=''>
                            <p className='text-sm text-gray-400 mb-1'>Course Created By</p>
                            <p className='text-sm text-black'>Vijayaragavan Ramachari</p>
                        </div>
                    </div>
                    <div className='mc_viewBtn px-5 py-2'>
                        <Link to="/my-learning" className="flex items-center justify-between text-black font-semibold text-xs">View Profile
                            <span className=''>{svg.app.slideLeft}</span>
                        </Link>
                    </div>
                </div>

                <div className='mt-4'>
                    <p className='flex items-center text-sm text-black'><span className='text-gray-500'>Course Created</span> : 12 Mar, 2023</p>
                </div>
                <div className='mt-3 mb-3'>
                    <div className='flex items-center text-sm text-black'> 
                        <span className='text-gray-500 flex'>
                            Note : ipsum, or lipsum as it is sometimes Snown,Lorem ipsum, or lipsum
                        </span>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default CourseCreator
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { appStore } from '../../zustand/store';
import svg from '../svg'
import { common, dataLoader, setMyState } from '../Comman'
import config from '../../config';
import io from "socket.io-client";
import EmojiBox from "../../../src/emoji/EmojiBox";
import { toast } from 'react-toastify';
import Notification from '../../pages/notification';


const apiUrl = config.REACT_CHAT_APIURL
const AttendanceList = (props) => {
  const [messagecount ,setmessagecount]=useState(0)
  const [chatlock ,setChatlock] =useState(false)
  const [sname ,setsname] =useState("")
  const [userpag,setuserpag] =useState({
    page :1,
    limit : 10
  })
  const socket = props.socekt;
  let alfaUrl = config.REACT_APP_BUCKET_URL
  let myStore = appStore(state => state);
  let AttendeeUserdata = myStore.userData;
  const [senduser, setsenduser] = useState("");
  // return;

  const navigator = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (!id) {
      setsenduser('')
      setChatlock(false)
      sessionStorage.setItem("chatid", "");
      sessionStorage.setItem("chatkey", "");
      setuserpag({
        page :1,
        limit : 10
      });
    }
  }, [id]);



  useEffect(()=>{
    sessionStorage.setItem("chatid", "");
    sessionStorage.setItem("chatkey", "");
  },[])
 
  const [loader, setloader] = useState("")
  const [state, setQuery] = useState({
    bodyColor: '#F7F7F7',
    themeColor: '#22CCA6',
    textColor: '#C8D3E8',
    hoverColor: null,
    attendanceListData: AttendeeUserdata,
    showProfile: false,
    AttendeeList: [],
    toggleChat: false,
    showChatbox: false,
    showProfile: false,
    dataLoading: true
  })

  let getHover1 = (e) => {
    setMyState(setQuery, {
      hoverColor: e,
    })
  }
  let getHover2 = (e) => {
    setMyState(setQuery, {
      hoverColor: null,
    })
  }
  const getAttendeeProfile = () => {
    setMyState(setQuery, {
      showProfile: !state.showProfile,
    })
    navigator('/events/attender-profile');
  }


  useEffect(()=>{
    setuserpag({
      ...userpag ,
      page : 1
    })
    getAllUserData(null ,1)
  },[sname])



  const [file, setfile] = useState(null)
  const [users, setuser] = useState([]);
  const [modal, setmodal] = useState(false);
  const [mload ,setmload] =useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [messagelist, setmessagelist] = useState([]);
  const [sndPerMes, setPermes] = useState("");

  const [currentchatkey, setchatkey] = useState("");
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  useEffect(() => {
    updatekey(socket.id);
    if (socket != "") {
      socket.on("connect_error", (err) => {
        // the reason of the error, for example "xhr poll error"
        console.log(err);

        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);

        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
      });
      socket.on("connect", function () {
        console.log("socket.id", socket.id)

      });
      socket.on("users", (user) => {
        let data = [...user]
        setuser((u1) => {
          let d1 = [...u1]
          for (let i = 0; i < d1.length; i++) {
            let c1 = data.filter((d2) => d2._id == d1[i]._id)
            if (c1.length > 0) {
              if (sessionStorage.getItem("chatkey") == d1[i]._id) {
                sessionStorage.setItem("chatid", c1[0].chatId);
                setchatkey(c1[0].chatId);
              }
              d1[i].status = "online"
              d1[i].chatId = c1[0].chatId
            } else {
              d1[i].status = "offline"
            }
          }
          return d1
        })
      });
      socket.on("seenmessagetime", (data, id) => {
        if (id == localStorage.getItem('id')) {
          setmessagelist((existingItems) => {
            return existingItems.map((element, j) => {
              if (element?.seen == 0) {
                return {
                  ...element,
                  seen: 1
                };
              } else {
                return element;
              }
            });
          });
        }
      });


      socket.on("typeing", (data) => {
        // console.log("typeing",data)
        if(data.id == sessionStorage.getItem("chatkey"))
        {
          setIsTyping(true);
        }
      });
      socket.on("stop_typeing1", () => {
        setIsTyping(false);
      });
      socket.on("receive_message1", (data) => {
        
        setuser((d11) => {
          let l = d11.findIndex((d1) => d1._id == data.insert.SenderId)
          // console.log(d11[l])
          if (d11[l]?.data?.length > 0) {
            d11[l].data[0].lastText.text = data.insert.Text
          }
          return d11
        })
        if (data.alldata.senderid == sessionStorage.getItem("chatkey")) {
          setChatlock(false)
          setmessagelist((list) => {
            return [...list, { ...data.alldata.id, seen: 1 }]
          });

          socket.emit("seenmessage", data, sessionStorage.getItem("chatid"));
          let myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              mid: data.alldata?.id?._id
            }),
            redirect: "follow",
          };
          fetch(apiUrl + "seenmessage", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {
              console.log({ result })
            })
            .catch((error) => console.log("error", error));
        }
      });

      socket.on("seenmessage", (data) => {
        setmessagelist((existingItems) => {
          return existingItems.map((element, j) => {
            return {
              ...element,
              seen: 1,
            };

          });
        });
      });
    }
  }, []);

  const getAllUserData = (load=null) => {
    if(load==null)
    {
      setMyState(setQuery, {
        dataLoading: true
      })
    }
    let evnt=AttendeeUserdata?.ticket_code.substring(0, 3).toLowerCase() 
    common.getAPI({
      isLoader: true,
      url: 'match-tag-list',
      method: 'POST',
      data: { event_id:evnt == 'ucs' ? "22530" : evnt == 'lwg' ? "22531":  AttendeeUserdata.event_id, ticket_code: AttendeeUserdata.ticket_code },
    }, (resp) => {
      if(!resp?.userdata){
        setMyState(setQuery, {
          dataLoading: false
        })
      }
      if (resp?.success) {
        let userdata = resp.userdata
        let t1 = []
        userdata.forEach(element => { 
          if (element.network_user_id) {
            t1.push(String(element.network_user_id))
          }
        });   
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({ list: t1, eventname  : AttendeeUserdata?.ticket_code.substring(0, 3).toLowerCase()}),

        };
        fetch(apiUrl + "getAllUserData", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setloader("")
            setMyState(setQuery, {
              dataLoading: false
            })
            if(load!=null)
            {
              setuser([...users,...result.data])
        
            }else{
              // console.log(localStorage.getItem("id"), "local");
              setuser(result.data);
            }  
          
            setTimeout(() => {
              socket.emit("fetchuser", {
                id: localStorage.getItem("id"),
              });
            }, 100);
          
          })
          .catch((error) => console.log("error", error));


      }

    })

  }


  const typingHandler = (e) => {
    if (e.target.value == "") {
      return;
    }
    if (!typing) {
      setTyping(true);
      socket.emit("typing", {
        id: localStorage.getItem("id"),
        room: currentchatkey,
      });
    }
    var timerLength = 3000;
    setTimeout(() => {
      if (typing) {
        socket.emit("stop_typing", {
          id: localStorage.getItem("id"),
          room: currentchatkey,
        });
        setTyping(false);
      }
    }, timerLength);
  };

  const selectuser = (i) => {
    let myHeaders = new Headers();
    setCurrentUser({ name: i?.name, designation: i?.designation, profile_img: i?.profile_img })
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      sid: i._id,
      rid: localStorage.getItem("id"),
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setsenduser(i._id);
    sessionStorage.setItem("chatid", i.chatId);
    sessionStorage.setItem("chatkey", i._id);
    setchatkey(i.chatId);
    fetch(apiUrl + "recevemessage", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if(result?.chatLock && result?.allowMessage==0 && result?.id==localStorage.getItem("id"))
        {
          setChatlock(result.chatLock)
        }
        setmessagecount(result?.count)
        const reversed = result.data.reverse();
        setmessagelist(result.data);
        setmodal(true);
        socket.emit("seenmessagetime", i.chatId, i._id);
        setloader("isopen")
        setTimeout(() => {
          let element = document.getElementById('#element');
          if (element) {
            element.scrollTop = element.scrollHeight + 100;
          }

        }, 50);
        setTimeout(() => {
          setloader("")
        }, 1000);

      })
      .catch((error) => console.log("error", error));
  };
  const send = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let url = ""
    let input = document.querySelector("#postContent");
    if (file == null && input.value == "") {
      return
    }
    if (file) {
      const formdata = new FormData();
      formdata.append("file", file);
      const requestOptions1 = {
        method: "POST",
        body: formdata,
      };
      await fetch(apiUrl + "fileUpload", requestOptions1)
        .then((response) => response.json())
        .then((result) => {
          url = result.url
        })
        .catch((error) => console.error(error));
    }

    let raw = {
      sender: localStorage.getItem("id"),
      recever: senduser,
      message: input.value,
    }
    if (url) {
      raw = {
        ...raw,
        media_url: url
      }
    }
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(apiUrl + "sendmessage", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(!result.status)
        {
          toast.error(result?.messge)
          return
        }
        let insert = result.data;
        let myid = result.data2;
        let alldata = {
          senderid: localStorage.getItem("id"),
          recever: senduser,
          messge: input.value,
          seen: 0,
          id: insert,
          media_url: url
        };
        let d = [...messagelist, insert];
        let m1=d.filter((r)=>r.SenderId===localStorage.getItem("id"))
        if(d.length==2 && m1.length==2)
        {
          setChatlock(true)
        }   
        setuser((d11) => {
          let l = d11.findIndex((d1) => d1._id == senduser)
          if (d11[l].data?.length > 0) {
            d11[l].data[0].lastText.text = input.value
          }
          return d11
        })
        setmessagelist(d);
        socket.emit("private_send_message", {
          sndPerMes,
          currentchatkey,
          alldata,
          insert,
          myid
        });
        setTimeout(() => {
          input.value = ""
        }, 1000);
        setloader("isopen")
        let element = document.getElementById('#element');
        if (element) {
          // console.log("element.scrollHeight +50", element.scrollHeight)
          element.scrollTop = element.scrollHeight + 100;
        }
        setloader("")
        setPermes("");
      })
      .catch((error) => console.log("error", error));
  };
  const updatekey = (key) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      id: localStorage.getItem("id"),
      key: key,
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(apiUrl + "updatekey", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == true) {
          getAllUserData()

        }
      })
      .catch((error) => console.log("error", error));
  };


  const fileDownload = (url) => {
    let aElement = document.createElement("a")
    aElement.href = url
    aElement.setAttribute('target', '_blank');
    aElement.click();
  }
  
  let fetchData = (e,cb,rev=null) => {
    let elem = document.getElementById(e.target.id)
    let targetScrollHeight = elem.scrollHeight - elem.clientHeight;
    
    if ((targetScrollHeight-220) <= elem.scrollTop && rev ==null) {
      let c1=parseInt(userpag.page) +1
      setuserpag({
        ...userpag,
        page : c1
      })
      cb("load",c1)
      return
    }

    if(rev!=null){
      if(elem.scrollTop==0){
        if(messagelist.length<messagecount)
        {
        let c1=parseInt(userpag.page) +1
        setmload(true)
        setuserpag({
          ...userpag,
          page :c1
        })
        cb("load",c1)
      }
    }
    }
  }

const messageLoad =()=>{
  setuserpag({
    ...userpag,
    page : parseInt(userpag.page) +1
  })
  
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let raw = JSON.stringify({
    sid:senduser,
    rid: localStorage.getItem("id"),
    page : userpag.page+1,
    limit:userpag.limit
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  fetch(apiUrl + `recevemessage`, requestOptions)
    .then((response) => response.json())
    .then(async (result) => {
      // setmload(false)
      if(result.data && result.data.length){
        let d1=messagelist.length
        const reversed = result.data.reverse();
        setmessagelist([...reversed,...messagelist]);
         let d3=document.getElementById("message_"+messagelist[0]._id)
         setmessagecount(result?.count)
         if(d3)
         {
          d3.scrollIntoView(()=>{
            console.log("scroll")
          })
         }
      }

      setTimeout(() => {
        setmload(false)
      }, 3000);
 
    })
}
function formatAMPM(date) {
  var todaysDate = new Date();
  date=new Date(date)
  if(date.toDateString() != todaysDate.toDateString()) {
    return  common.dateFormatter(date)
  }
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

  useEffect(() => {
    let c1 = document.getElementById("mainfooter");
    let header = document.getElementById("mainHeaderMenu");

    if (senduser) {
      if(!c1.classList.contains("hidden")) {
        c1.classList.add("hidden");
        header.classList.add("hidden");
      }
    } else {
      if(c1.classList.contains("hidden")) {
        c1.classList.remove("hidden");
        header.classList.remove("hidden");
      }
    }
  }, [senduser]);
  
  return (
    <>
      {/* <Notification/> */}
      <div id="eventPageLoader" className={`mainPageLoader eventLoader ${loader}`}>
        <div className='mc_mainLoader_dv'>
          <div className="mc_isLoaderDesign"></div>
        </div>
      </div>
      <div className={`mc_eventBody ${senduser != "" ? 'bg_whatsApp' : ''}`} style={{ backgroundColor: state.bodyColor, color: state.textColor }}>
        {senduser != "" ?  <div className='mc_backBtn_dv'>
          <a onClick={() => {
            setsenduser('')
            setChatlock(false)
            sessionStorage.setItem("chatid", "");
            sessionStorage.setItem("chatkey", "");
            setuserpag({
              page :1,
              limit : 10
            });
            navigator("/events/match/");
          }
          } id='gotoBack_dv'>
            <span style={{ fill: '#000' }}>{svg.app.backArrowBtn} </span> <span className="ml-2"></span>
          </a>
        </div>:<>
        {/* <div className='searchBox_dv'>
          <input value={sname} onChange={(e)=>{
            setsname(e.target.value)
          }}   type='text' placeholder='Search' className='mc_input'/>
          <span>{svg.app.searchIcon}</span>
        </div> */}
          <div className='mc_headTitle_dv' >
            <h2 style={{ color: '#000' }}>{props.title}  <span className='mc_titleCount' style={{color : state.themeColor}}></span></h2>
          </div>
        </>
        }


        {/* <div className="message-area">
              <div className="chat-area">
                  {!state.showChatbox ? 
                    <div className='mc_eventListHolder'>

                        {state.AttendeeList?.map((items, index)=>{

                          return(  
                            <div className='mc_eventList' key={index}>
                              <div className='mc_manageList_content w-full'>
                                <div className='mc_eventImg'>
                                  <img src={items.profile_img ? items.profile_img : `${alfaUrl}/images/user.png`} width={'50px'} height={'50px'} alt='User Image'/>
                                </div>
                                <div className='mc_contentManage'>
                                  <Link to={`/events/attendee-profile/${items.network_user_id}`} style={{color : `${state.hoverColor == `AttendeeName${index}}` ? state.themeColor : state.textColor }`}}
                                    onMouseEnter={(e)=> getHover1(`AttendeeName${index}}`)} 
                                    onMouseLeave={(e)=> getHover2(`AttendeeName${index}}`)}
                                    onClick={()=> getAttendeeProfile()}
                                  >{items.name} {items.network_user_id == AttendeeUserdata?.network_user_id && '(You)' }</Link>
                                  <p className='text-xs'>{items.email}</p>
                                  <p className='text-xs'>{items.designation}</p>
                                  <p className='mt-2'></p>
                                </div>
                              </div>
                              { items?.network_user_id != AttendeeUserdata?.network_user_id &&  <div className='mc_chatIcon_list'>
                                <a href={() => false} className='mc_chatBox_icon'
                                  onClick={()=> setMyState(setQuery, {
                                    showChatbox : !state.showChatbox,
                                  })}
                                >{svg.app.chateIcon}</a>
                              </div>
                              }
                            </div>
                          )
                        })}

                    </div>
                    :
                  // <div className={`chatlist ${state.toggleChat == true  ? "showUserList mobileHead" : 'showUserList'}`}>
                  //     <div className="modal-dialog-scrollable">
                  //         <div className="modal-content">
                              
                  //             <div className={`chat-lists`}>
                  //                 <div className="chat-list">
                  //                     <a href={() => false}  className="flex align-items-center">
                  //                         <div className="flex-shrink-0">
                  //                             <img className="img-fluid rounded" src={`/assets/images/eventsimg/user1.jpg`} alt="user img" width={'45px'}/>
                  //                             <span className="active"></span>
                  //                         </div>
                  //                         <div className="flex-grow-1 ms-3">
                  //                             <h3>Mehedi Hasan</h3>
                  //                             <p>front end developer</p>
                  //                         </div>
                  //                     </a>
                  //                 </div>
                  //             </div>
                  //         </div>
                  //     </div>
                  // </div>

                  <div className="chatbox showbox">
                      <div className="modal-dialog-scrollable">
                          <div className="modal-content">
                              <div className="msg-head">
                                  <div className="row">
                                      <div className="col-8">
                                          <div className="flex align-items-center">
                                              <div className="flex flex-shrink-0"/>
                                                  <img className="img-fluid rounded" src={`/assets/images/eventsimg/user1.jpg`} alt="user img" width={'45px'}/>
                                                  <div className="flex-grow-1 ms-3">
                                                      <h3>Mehedi Hasan</h3>
                                                      <p>front end developer</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="modal-body">
                                  <div className="msg-body">
                                      <ul>
                                          <li className="sender">
                                              <p> Hey, Are you there? </p>
                                              <span className="time">10:06 am</span>
                                          </li>
                                          <li className="sender">
                                              <p> Hey, Are you there? </p>
                                              <span className="time">10:16 am</span>
                                          </li>
                                          <li className="repaly">
                                              <p>yes!</p>
                                              <span className="time">10:20 am</span>
                                          </li>
                                          <li className="sender">
                                              <p> Hey, Are you there? </p>
                                              <span className="time">10:26 am</span>
                                          </li>
                                          <li className="sender">
                                              <p> Hey, Are you there? </p>
                                              <span className="time">10:32 am</span>
                                          </li>
                                          <li className="repaly">
                                              <p>How are you?</p>
                                              <span className="time">10:35 am</span>
                                          </li>
                                          <li>
                                              <div className="divider">
                                              <h6>Today</h6>
                                              </div>
                                          </li>

                                          <li className="repaly">
                                              <p> yes, tell me</p>
                                              <span className="time">10:36 am</span>
                                          </li>
                                          <li className="repaly">
                                              <p>
                                                  <video width="320" height="240" controls src="/assets/video/demo.mp4" type="video/mp4"></video>
                                                  yes... on it
                                              </p>
                                              <span className="time">junt now</span>
                                          </li>
                                          <li className="repaly">

                                              <p>
                                                  <img src='/assets/images/Course6.jpg' alt='Image'/>
                                                  yes... on it
                                                  <span className='sendTick doubleTick'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 24 24">
                                                          <path d="M10.367 14.423a.6.6 0 0 1 0-.848l6.712-6.712a.6.6 0 0 1 .848.848l-6.711 6.712a.6.6 0 0 1-.849 0zm12.184-7.56a.6.6 0 0 0-.848 0L11.2 17.37l-4.026-4.021a.6.6 0 0 0-.849.848l1.71 1.71-1.463 1.463-4.021-4.021a.6.6 0 0 0-.851.851l4.445 4.446a.6.6 0 0 0 .849 0l1.887-1.888 1.887 1.888a.6.6 0 0 0 .849 0L22.551 7.711a.6.6 0 0 0 0-.848z"></path>
                                                      </svg>
                                                  </span>
                                                  <span className='sendTick'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 24 24">
                                                          <path d="M18.488 6.43a.75.75 0 0 1 .081 1.058l-8.571 10a.75.75 0 0 1-1.139 0l-3.428-4a.75.75 0 1 1 1.138-.976l2.86 3.336 8.002-9.336a.75.75 0 0 1 1.057-.081z"></path>
                                                      </svg>
                                                  </span>
                                              </p>
                                              <span className="time">junt now</span>
                                          </li>
                                          <li className="sender">
                                              <p className="chat-bubble">
                                                  <span className="typing">
                                                      <b className="dot"></b>
                                                      <b className="dot"></b>
                                                      <b className="dot"></b>
                                                  </span>
                                              </p>
                                          </li>
                                      </ul>
                                  </div>
                              </div>

                              <div className="send-box">
                                  <div className="send-btns">
                                      <div className="attach">
                                          <div className="button-wrapper">
                                              <span className="label">
                                                  {svg.app.plusIconChat}
                                              </span>
                                              <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File"/>
                                          </div>
                                      </div>
                                  </div>
                                  <form action="">
                                      <div className='input_holder relative'>
                                          <input type="text" className="mc_input" aria-label="message…" placeholder="Write message…"/>
                                          <span>{svg.app.emojiIcon}</span>
                                      </div>

                                      <button type="button" className='mc_btn text-center center'><i className="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                                  </form>

                              </div>
                          </div>
                      </div>
                }
                  </div>
            </div> */}

        <div className="message-area">
          {/* <a href={() => false} >{svg.app.backArrowBtn}</a> */}
          <div className="chat-area"  >
            {/* <!-- chatlist --> */}

            {senduser == "" ?
              <div className='mc_eventListHolder matchScreen' id="scroller" >

                {state.dataLoading ? [...Array(3)].map(count => <div key={count}>{dataLoader.eventLoader}</div>) : users.length > 0 ? users?.map((items, index) => {
                  return (
                    <>
                    {items.name &&
                    <div className={`mc_eventList ${items.network_user_id == AttendeeUserdata.network_user_id && 'mc_itsMe'}`} key={index}>
                      <div className='mc_manageList_content w-full'>
                      {items?.profile_img ?
                        <div className='mc_eventImg mc_profileBg_img' style={{backgroundImage: `url(${items?.profile_img})`}}>
                          {/* <img src={items?.profile_img || `${alfaUrl}/images/user.png`} width={'50px'} height={'50px'} alt='User Image' /> */}
                        </div>
                        : 
                            <div className='mc_alfaProfile_img'>{AttendeeUserdata?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
                        }
                        <div className='mc_contentManage'>
                          <Link to={`/events/attendee-profile/${items.network_user_id}`} style={{ color: `${state.hoverColor == `AttendeeName${index}}` ? '#000' : '#000'}` }}
                            onMouseEnter={(e) => getHover1(`AttendeeName${index}}`)}
                            onMouseLeave={(e) => getHover2(`AttendeeName${index}}`)}
                          >{items.name} {items.network_user_id == AttendeeUserdata.network_user_id && '(You)'}</Link>
                          {/* <p className='text-xs'>{items?.email}</p> */}
                          <p className='text-xs' style={{color: state.themeColor}}>{items?.designation}</p>
                          {/* <p className='mt-2'>{items?.profile_details?.about_me}</p> */}
                        </div>
                      </div>
                      {items.network_user_id != AttendeeUserdata.network_user_id && <div className='mc_chatIcon_list'>
                        <a href={void (0)} className='mc_chatBox_icon'
                          onClick={() =>{ 
                            selectuser(items);
                            navigator("/events/match/" + items._id);

                          }}
                        >
                          
                          <span className='mr-2 mc_chatBtn_box'>
                            {svg.app.chatIcon_list} Let's Chat
                          </span> 
                          <span className='mc_arrowRight'>{svg.app.chatRightArrow}</span>
                          </a>
                      </div>}
                    </div>
                    
                        }
                        </>
                  )
                }) :

                  <div className='mc_congrats_dv'>
                    <div className='mc_completeModal text-center'>
                      <div>{svg.app.nodataFound}</div>
                      <h4 className='text-lg font-medium text-black my-3'>No match profile found.</h4>
                    </div>
                  </div>
                }

              </div>
              :
              // <div className={`chatlist ${state.toggleChat == true  ? "showUserList mobileHead" : 'showUserList'}`}>
              //     <div className="modal-dialog-scrollable">
              //         <div className="modal-content">

              //             <div className={`chat-lists`}>
              //                 <div className="chat-list">
              //                     <a href={() => false}  className="flex align-items-center">
              //                         <div className="flex-shrink-0">
              //                             <img className="img-fluid rounded" src={`/assets/images/eventsimg/user1.jpg`} alt="user img" width={'45px'}/>
              //                             <span className="active"></span>
              //                         </div>
              //                         <div className="flex-grow-1 ms-3">
              //                             <h3>Mehedi Hasan</h3>
              //                             <p>front end developer</p>
              //                         </div>
              //                     </a>
              //                 </div>
              //             </div>
              //         </div>
              //     </div>
              // </div>

              <div className="chatbox showbox">

                <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="msg-head">
                      <div className="row">
                        <div className="col-8">
                          <div className="flex align-items-center">
                          {currentUser?.profile_img ?
                            <div className="flex flex-shrink-0 mc_profileBg_img" style={{backgroundImage: `url(${currentUser?.profile_img})`}} />
                            : 
                            <div className='mc_alfaProfile_img'>{AttendeeUserdata?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
                          }
                            {/* <img className="img-fluid rounded" src={currentUser?.profile_img || `${alfaUrl}/images/user.png`} alt="user img" width={'45px'} /> */}
                            <div className="flex-grow-1 ms-3">
                              <h3>{currentUser?.name}</h3>
                              <p>{currentUser?.designation}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="#element" onScroll={(e)=>{
                    fetchData(e,messageLoad ,"rev")
                  }} className="modal-body">
                    <div className="msg-body">
                      <ul>
                        {mload && 
                          <li className='mc_chatLoader'>
                          Loading... <div className="mc_loadMore_fv">
                              <span></span>
                            </div>
                          </li>
                        }
                    
                        {messagelist && messagelist.map((i,j) => {
                          return (
                            <>
                              <li id={"message_" +i._id}
                                className={
                                  localStorage.getItem("id") == i.SenderId
                                    ? "repaly"
                                    : "sender"
                                }
                              >
                                <p>
                                  {" "}
                                  {i?.MediaMeta?.url && (
                                    <div
                                      className="chatbox_file"
                                      onClick={() => {
                                        fileDownload(i?.MediaMeta?.url);
                                      }}
                                    >
                                      <img
                                        src="/assets/images/file.png"
                                        alt="Img File"
                                        width={"50px"}
                                      />
                                      File
                                    </div>
                                  )}
                                  {i.Text}
                                  {localStorage.getItem("id") ==
                                    i.SenderId && (
                                      <>
                                        {i.seen == 1 ? (
                                          <span className="sendTick doubleTick">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              version="1.1"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="20"
                                              height="20"
                                              x="0"
                                              y="0"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M10.367 14.423a.6.6 0 0 1 0-.848l6.712-6.712a.6.6 0 0 1 .848.848l-6.711 6.712a.6.6 0 0 1-.849 0zm12.184-7.56a.6.6 0 0 0-.848 0L11.2 17.37l-4.026-4.021a.6.6 0 0 0-.849.848l1.71 1.71-1.463 1.463-4.021-4.021a.6.6 0 0 0-.851.851l4.445 4.446a.6.6 0 0 0 .849 0l1.887-1.888 1.887 1.888a.6.6 0 0 0 .849 0L22.551 7.711a.6.6 0 0 0 0-.848z"></path>
                                            </svg>
                                          </span>
                                        ) : (
                                          <span className="sendTick">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              version="1.1"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="20"
                                              height="20"
                                              x="0"
                                              y="0"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M18.488 6.43a.75.75 0 0 1 .081 1.058l-8.571 10a.75.75 0 0 1-1.139 0l-3.428-4a.75.75 0 1 1 1.138-.976l2.86 3.336 8.002-9.336a.75.75 0 0 1 1.057-.081z"></path>
                                            </svg>
                                          </span>
                                        )}
                                      </>
                                    )}
                                  <span className="time">{formatAMPM(i.CreateAt)}</span>
                                </p>
                              </li>
                            </>
                          );
                        })}
                        {istyping && (
                          <li>
                            <div className="chat-bubble">
                              <div className="typing">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </div>
                            </div>
                          </li>
                        )}
                        {/* <li className="mc_loadMore_fv">
                          <div>
                            <span></span>
                          </div>
                        </li> */}
                      </ul>
                    </div>

                  </div>

                  <div className="send-box">
                    {/* <div className="send-btns">
                                      <div className="attach">
                                          <div className="button-wrapper">
                                              <span className="label">
                                                  {svg.app.plusIconChat}
                                              </span>
                                              <input  onChange={(e) => {
                                setfile(e.target.files[0]);
                              }} type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File"/>
                                          </div>
                                         
                                      </div>
                                  </div> */}
                                          {!chatlock ?
                    <form action="">
                      <div className='input_holder relative'>
                        <textarea type="text" id="postContent" className="mc_input mc_textarea" aria-label="message…" onChange={(e) => typingHandler(e)} placeholder="Write message…" />

                        <span>
                          <EmojiBox
                            targetInputId="#postContent"
                            parentEmojiBoxclassName={"emojiBoxParent"}
                            boxPlacement={"left"}
                          />
                        </span>
                      </div>

                      <button onClick={() => {
                        send();
                      }} type="button" className='mc_btn text-center center'>{svg.app.sendPapperIcon}</button>
                    </form>
                    : <div className='donnotmessage text-black text-center'>You can send a maximum of two messages till you get a response. Please Wait till you receive a response. You will be able to continue chatting without limits, Once you receive a Response.</div> 
                      }

                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AttendanceList
import { useState, useEffect, useRef } from 'react';
// import { setAttributes } from 'video.js/dist/types/utils/dom';


window.videoDuration = 0;
const VideoPlayer = (props) => {
//  console.log(props, 'InnerVideoProps');
  const [currentTimeCount, setCurrentTimeCount] = useState(0);
  const playerRef = useRef(null);

  const [embedCode , setembedCode]=useState("")


  useEffect(()=>{
    if(embedCode!=props.embedCode){
        setembedCode(props.embedCode)
      }
  },[props.embedCode])

  // let modifiedEmbedCode = props.embedCode;
  // // Add autoplay attribute if it's an iframe
  // if (modifiedEmbedCode.includes('<iframe')) {
  //   modifiedEmbedCode = modifiedEmbedCode.replace('<iframe', '<iframe autoplay="true"');
  // }
  // const [embedCodeUrl, setEmbedCodeUrl] = useState('')
  // setEmbedCodeUrl(modifiedEmbedCode);
  // console.log(embedCodeUrl, 'embedCodeUrl');

  const [embedCodeUrl, setEmbedCodeUrl] = useState('')
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5 // Trigger when 50% of the player is visible
    };
    if (props.embedCode) {

      let d1=document.getElementById("#UniqeId")
      let s1 = document.createElement("script");
      if(!d1){
        s1.type = "text/javascript";
        s1.id="#UniqeId"
        s1.src = 'https://player.vdocipher.com/v2/api.js';
        document.head.appendChild(s1);
      }
     
      s1.onload = function() {
      let embedHtml = document.getElementById('embedDv');
      var iframeElement = embedHtml.querySelector('iframe');
      let player = new window.VdoPlayer.getInstance(iframeElement);

      player.video.addEventListener('play', function () {
        const { currentTime } = player.video; 
        window.videoDuration = currentTime;
        setCurrentTimeCount(window.videoDuration)
      });

      player.video.addEventListener("timeupdate", () => {
        const { currentTime } = player.video; 
        window.videoDuration = currentTime;
        setCurrentTimeCount(window.videoDuration)
      });
    }
  
  }
    
  }, [currentTimeCount]);
  window.videoDuration = currentTimeCount

  // console.log(window.videoDuration, 'currentTimeCount');
  // const playerRef = useRef(null);

  
  return (

    <div id='vdo-player' className={props.embedCode ? "" : 'error_mes_video_not_ava'}>
      {
        props.embedCode ? <> 
        {/* <iframe ref={playerRef} title="Embedded Video" srcDoc={embedCode} frameBorder="0" allowFullScreen /> */}
        <div ref={playerRef} id='embedDv' dangerouslySetInnerHTML={{ __html: props.embedCode }} /> 
        </>
        : <div>
          <h1 className='text-white text-5xl mb-1'>Sorry</h1>
          <h2 className='text-white text-2xl'>This video is currently unavailable.</h2>
        </div>
      }
    </div>
  );
};

export default VideoPlayer;

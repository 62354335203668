import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


import { appStore } from '../../zustand/store';
import { common, dataLoader, setMyState } from '../Comman';

import { toast } from 'react-toastify';
import svg from '../svg';
import config from '../../config';


function EventProfile(props) {
  let alfaUrl = config.REACT_APP_BUCKET_URL

  let myStore = appStore(state => state);

  const userData = myStore.userData;

  let profileDetails = userData?.profile_details;
  let parseProfile = profileDetails && JSON.parse(profileDetails);
  const navigate = useNavigate()
  let { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [f1, setfile1] = useState(null)
  const [f2, setfile2] = useState(null)
  const [state, setQuery] = useState({
    bodyColor: '#F7f7f7',
    themeColor: '#22CCA6',
    textColor: '#4A5251',
    hoverColor: null,
    name: '',
    email: '',
    designation: '',
    about_me: '',
    company_name: '',
    look_to_meet: '',
    services_provide: '',
    userDetail: {},
    formDetails: {},
    showEdit: true,
    pageLoading: false,
    uploadProfileName: ''
  });

  useEffect(() => {
    if (!id) {
      setIsEdit(false);
    }
  }, [id])

  useEffect(() => {
    let header = document.getElementById("mainHeaderMenu");
    let c1 = document.getElementById("mainfooter")
    // header.classList.add("hidden");

    if (!c1.classList.contains("hidden")) {
      c1.classList.add("hidden")
      header.classList.add("hidden");
    }

  }, [])
  let getHover1 = (e) => {
    setMyState(setQuery, {
      hoverColor: e,
    })
  }
  let getHover2 = (e) => {
    setMyState(setQuery, {
      hoverColor: null,
    })
  }

  useEffect(() => {

    if (id) {

      getProfile();
    } else {
      setMyState(setQuery, {
        userDetail: {
          name: userData?.name,
          email: userData?.email,
          services_provide: userData?.services_provide,
          designation: userData?.designation,
          look_to_meet: userData?.look_to_meet,
          company_name: parseProfile?.company_name,
          website: parseProfile?.url,
          about_me: parseProfile?.about_me,
          profile_img: userData?.profile_img,
          network_user_id: userData?.network_user_id
        },
        formDetails: {
          name: userData?.name ? userData?.name : state.formDetails.name,
          email: userData?.email ? userData?.email : state.formDetails.email,
          services_provide: userData?.services_provide ? userData?.services_provide : state.formDetails.services_provide,
          designation: userData?.designation ? userData?.designation : state.formDetails.designation,
          look_to_meet: userData?.look_to_meet ? userData?.look_to_meet : state.formDetails.look_to_meet,
          company_name: parseProfile?.company_name ? parseProfile?.company_name : state.formDetails.company_name,
          website: parseProfile?.url ? parseProfile?.url : state.formDetails.url,
          about_me: parseProfile?.about_me ? parseProfile?.about_me : state.formDetails.about_me,
          profile_img: userData?.profile_img ? userData?.profile_img : state.formDetails.profile_img,
          network_user_id: userData?.network_user_id ? userData?.network_user_id : state.formDetails.network_user_id,
        },
      });
      const requiredKeys = ['name', 'email', 'services_provide', 'designation', 'look_to_meet'];

      const missingKeys = requiredKeys.filter(key => !userData[key]);
      if (missingKeys.length) {
        setIsEdit(true);
      }
    }
  }, [id])

  const getProfile = () => {
    common.getAPI({
      isLoader: true,
      url: 'get-attendeeprofile',
      method: 'POST',
      data: { network_user_id: id }
    }, (resp) => {

      if (resp.success == true) {
        let response = resp.user_data;
        let parsedata = JSON.parse(response.profile_details);
        if (id != userData.network_user_id) {
          setMyState(setQuery, { showEdit: false })
        }
        setMyState(setQuery, {
          userDetail: {
            name: response.name,
            email: response.email,
            services_provide: response.services_provide,
            designation: response.designation,
            look_to_meet: response.look_to_meet,
            profile_img: response.profile_img,
            network_user_id: response.network_user_id,
            company_name: parsedata.company_name,
            website: parsedata.url,
            about_me: parsedata.about_me,
          },
          formDetails: {
            name: response.name,
            email: response.email,
            services_provide: response.services_provide,
            designation: response.designation,
            look_to_meet: response.look_to_meet,
            profile_img: response.profile_img,
            network_user_id: response.network_user_id,
            company_name: parsedata.company_name,
            website: parsedata.url,
            about_me: parsedata.about_me,
          },
        })


      }
    });
  }

  const profileUpdateCheck = () => {
    const requiredKeys = ['name', 'email', 'services_provide', 'designation', 'look_to_meet'];

    const missingKeys = requiredKeys.filter(key => !userData[key]);
    console.log({ missingKeys }, "misss");
    if (missingKeys.length) {
      toast.error('Please fill out all required fields.', { toastId: 'toast' });
      return false
    }
    if (userData.profile_details) {
      const profileDetails = JSON.parse(userData.profile_details);
      const aboutMeIsEmpty = !profileDetails.about_me;
      console.log(aboutMeIsEmpty,"aboutMeIsEmpty");
      if (aboutMeIsEmpty) {
        toast.error('Please fill out all required fields.', { toastId: 'toast' });
        return false
      }
    }
    return true

  }

  const validationCheck = () => {
    const requiredKeys = ['name', 'email', 'services_provide', 'designation', 'look_to_meet', 'about_me',];

    const missingKeys = requiredKeys.filter(key => !state.formDetails[key]);
    // console.log({missingKeys});
    if (missingKeys.length) {
      toast.error('Please fill out all required fields.', { toastId: 'toast' });
      return false
    }
    return true

  }

  const updateProfile = (e) => {
    e.preventDefault();

    if (validationCheck()) {
      let { name, email, designation, look_to_meet, services_provide, company_name, about_me, website, network_user_id, profile_img } = state.formDetails
      // console.log(state.formDetails, 'state.formDetails');

      const s = state.formDetails?.services_provide;
      const l = state.formDetails?.look_to_meet;
      var sp = s.lastIndexOf(",");
      var lm = l.lastIndexOf(",");
      var servicesProvide = s.substring(0, sp)
      var lookMeet = l.substring(0, lm)

      console.log(servicesProvide, lookMeet, 'lookMeet.lookMeet');

      let data = {name: name.trim(), email, designation, look_to_meet, services_provide, network_user_id, profile_img };
      data['profile_details'] = { "company_name": company_name, "about_me": about_me, "url": website };
      // return a;

      common.getAPI({
        isLoader: true,
        url: 'update-attendeeprofiles',
        method: 'POST',
        data: data
      }, (resp) => {

        if (resp.success == true) {
          let d1 = {
            ...data,
            eventname  : userData?.ticket_code.substring(0, 3).toLowerCase(),
            profile_details: JSON.stringify(data.profile_details)
          }
          myStore.updateStoreData('userData', { ...userData, ...state.formDetails, profile_details: JSON.stringify(data.profile_details) });
          const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(d1)
          };
          setIsEdit(false)
          fetch(`${config.REACT_CHAT_APIURL}updateUser`, requestOptions).then((res) => res.json()).then(resp => console.log(resp)).catch((err) => console.log(err, "err"));

          setMyState(setQuery, {
            userDetail: { ...state.formDetails }, isEdit: false
          })
          toast.success(resp.message, { toastId: 'toast' });
        }
      });
    }
  }

  /******************************************/
  const [inputLook, setInputLook] = useState('');
  const [inputServices, setInputServices] = useState('');

  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = inputLook.trim();
    if ((key === 'Enter' || key === ',') && e.target.value.length) {
      e.preventDefault();
      console.log({ inputLook })
      let d1 = state.formDetails[e.target.id]
      let t1 = d1 == "" ? [] : d1.split(",")
      t1.push(e.target.value)
      t1 = t1.join(",")
      // console.log(t1, 'T1');
      setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.id]: t1 } })
      setTimeout(() => {
        setInputLook('');
      }, 100);
      let l1 = document.getElementById(e.target.id)
      if (l1) {
        l1.value = ''
      }
    }

    if (key === "Backspace" && !inputLook.length && isKeyReleased) {
      const tagsCopy = state.formDetails[e.target.id].split(",");
      tagsCopy.pop();
      console.log({ tagsCopy })
      e.preventDefault();
      setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.id]: tagsCopy.join(",") } })
      setInputLook("");
    }

    setIsKeyReleased(false);
  };
  const deleteTag = (e, value, id) => {
    e.preventDefault()
    let d1 = state.formDetails[id].split(",")
    d1.splice(value, 1)
    console.log({ d1 })
    setMyState(setQuery, { formDetails: { ...state.formDetails, [id]: d1.join(",") } })
  }
  const onKeyUp = () => {
    setIsKeyReleased(true);
  }
  const onChangeTag = (e) => {
    if ((e.key == "Enter" || e.key == ",") && inputLook.trim() != "") {
      const { value } = e.target;
      setInputLook(value);
    }
  };
  /******************************************/
  const handleImageChange = (event) => {

    setMyState(setQuery, { pageLoading: true })
    const file = event.target.files[0];
    setfile1(file.name)
    setfile2(file.type)
    // console.log(JSON.stringify(event.target.files),"ssssssssddddddd",event.target.files,"------------")
    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      // setMyState(setQuery, { pageLoading: true });
      var createElem = document.createElement(`img`);
      createElem.src = URL.createObjectURL(file);
      createElem.onload = (e) => {
        var mediaMeta = {
          duration: createElem.duration,
          width: createElem.width,
          height: createElem.height,
        };
        if (file) {
          const reader = new FileReader();
          var myHeaders = new Headers();

          // myHeaders.append("authorization", `Bearer ${tokenCookie}`);
          var formdata = new FormData();
          formdata.append("pageLoadingpageLoading", file, "");
          // formdata.append("userId", state.memberId);
          formdata.append("width", createElem.width);
          formdata.append("height", createElem.height);
          formdata.append("type", file.type);
          formdata.append("name", file.name);
          formdata.append("cloud_type", "2");
          formdata.append("upload_type", "2");
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
          };
          fetch("https://img.myflxfnls.com/upload-img", requestOptions).then(response => response.json())
            .then(result => {
              setMyState(setQuery, { pageLoading: false })
              if (result?.results?.imgUrl) {

                setMyState(setQuery, {
                  formDetails: { ...state.formDetails, profile_img: result?.results?.imgUrl },
                  uploadProfileName: result?.results?.name
                })
              }
            })
            .catch(error => console.log('error', error));
            setMyState(setQuery, {
              formDetails: { 
                ...state.formDetails, 
                profile_img: reader.result,
                pageLoading: false, 
              }
            })
        }
      }
    } else {
      toast.error('File type not allowed.')
    }
  };
  return (
    <div className='mc_eventBody mc_profileScroller'
      style={{ backgroundColor: state.bodyColor, color: state.textColor }}
    >
      <div className='mc_componentData mc_userProfile_head'>
        <div className='mc_userProfileImage_holder'>
          {!isEdit ? <><div className='mc_headTitle_dv' >
            <div className='mc_backBtn_dv'>
              <a onClick={
                () => {
                  navigate(-1);
                }
                //  navigate('/events/attendees')
              }>
                <span style={{ fill: state.themeColor }}>{svg.app.backArrowBtn} </span> <span className="ml-2"></span>
              </a>
            </div>

            <h2 className='flex items-center justify-center' style={{ color: state.themeColor }}>
              {userData.network_user_id == state.userDetail.network_user_id && "My Profile"}
              {userData.network_user_id != state.userDetail.network_user_id && "Attendee Profile"}
            </h2>
            {userData.email && (state.showEdit) &&
              <a href={void (0)} className='ml-2'
                style={{ fill: state.themeColor }}
                onClick={() => {
                  setIsEdit(!isEdit);
                  navigate("/events/profile/edit");
                }}
              >
                {svg.app.editProfileIcon}
              </a>
            }
          </div>
            <div className='mc_profilePic_holder'>
              {state.userDetail.profile_img ?
                <div className='mc_alfaProfile_img'
                  style={{
                    backgroundImage: `url(${state.userDetail.profile_img})`,
                  }}
                >
                </div>
                : <div className='mc_alfaProfile_img'>{userData?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
              }
              {/* <img src={state.userDetail.profile_img ? state.userDetail.profile_img : `${alfaUrl}/images/user.png`} alt='Profile Image' width={'100px'} height={'100px'} /> */}
              <h3 className='text-sm font-semibold'>{state.userDetail.name}</h3>
              {userData.network_user_id == state.userDetail.network_user_id && <p className='text-xs'>{state.userDetail.email}</p>}
              <p className='mt-2 text-xs' style={{ color: state.themeColor }}>{state.userDetail.designation}</p>
            </div>
          </>
            : <>
              <div className='mc_backBtn_dv'>
                <a onClick={
                  () => {
                    if (profileUpdateCheck()) {
                      navigate(-1);
                    }

                  }
                  //  navigate('/events/attendees')
                }>
                  <span style={{ fill: state.themeColor }}>{svg.app.backArrowBtn} </span> <span className="ml-2"></span>
                </a>
              </div>
            </>
          }
        </div>


        {!isEdit ? <div className='px-4 mc_profileSection'> <div className='mc_eventProfilePage'>
          <div className='mc_profilePic'>
            <div className='mc_profileContent mt-3'>
              <div className='mc_manageContent'>
                <h3 className='text-justify'><span>About Me</span> {state.userDetail.about_me}</h3>
                <h3><span>Look To Meet</span>
                  <div className='flex flex-wrap'>
                    {state.userDetail?.look_to_meet && state.userDetail?.look_to_meet.split(',').map((tag, index) => {
                      let c1=tag.trim()
                      return(<>
                      {c1 &&
                      <div className="inputTag" key={index}>
                        {c1}
                      </div>
                       }
                      </>)
                    
                      })}
                  </div>
                  {/* {state.userDetail.look_to_meet} */}
                </h3>
                <h3><span>Services I Provide</span>
                  <div className='flex flex-wrap'>
                    {state.userDetail?.services_provide && state.userDetail?.services_provide.split(',').map((v, i) => {
                      let c1=v.trim()
                      return(<>
                      {c1 &&
                      <div className="inputTag" key={i}>
                        {c1}
                      </div>
                        }
                      </>)
                    })}
                  </div>
                </h3>
                <h3><span>Company Name</span>  {state.userDetail.company_name}</h3>
                <h3 className='mc_copyUrl_holder'><span>Website 
                  {state.userDetail.website && 
                  <a href={() => false} 
                  onClick={()=> {
                    navigator.clipboard.writeText(state.userDetail.website)
                    toast.success('URL Copied!') 
                  }
                } style={{fill : state.themeColor}}>{svg.app.copyIcon}
                </a>}
                 </span> <spam className="mc_copyText" style={{ color: state.themeColor, fontWeight: '400' }}>{state.userDetail.website} </spam>  </h3>

              </div>
            </div>
          </div>
        </div>
        </div>
          : <div className='px-4 pt-5 w-full'>
            <form className='mc_eventForm'
              onSubmit={updateProfile}
            >
              <div className='mc_eventProfileForm'>
                <div className='mc_updateprofile_head'>
                  <h4>Complete Your Profile</h4>
                </div>
                <div className='mc_formGroup'>
                  <label>Name <sup className='require-star'>*</sup></label>
                  <input type='text' className='mc_input' name='name' placeholder='' value={state.formDetails?.name} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name]: e.target.value } })} />
                </div>
                <div className='mc_formGroup'>
                  <label>Email Address <sup className='require-star'>*</sup></label>
                  <input type='email' className='mc_input' name='email' placeholder='' value={state.formDetails?.email} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name]: e.target.value } })} />
                </div>
                <div className='mc_formGroup'>
                  <label>Designation <sup className='require-star'>*</sup></label>
                  <input type='text' className='mc_input' name='designation' placeholder='' value={state.formDetails?.designation} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name]: e.target.value } })} />
                </div>
                <div className='mc_formGroup'>
                  <label>About Me <sup className='require-star'>*</sup></label>
                  <input type='text' className='mc_input' name='about_me' placeholder='' value={state.formDetails?.about_me} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name]: e.target.value } })} />
                </div>
                <div className='mc_formGroup'>
                  <label>Company Name</label>
                  <input type='text' className='mc_input' name='company_name' placeholder='' value={state.formDetails?.company_name} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name]: e.target.value } })} />
                </div>

                <div className='mc_formGroup'>
                  <label>Looking To Meet <sup className='require-star'>*</sup> <span className='mc_superTag'>(Please enter the details, separated by commas.)</span></label>
                  <input type='text' className='mc_input' name='look_to_meet' placeholder='Like minded people, FB ads experts' value={state.formDetails?.look_to_meet} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name] : e.target.value } })}/>
                </div>

                <div className='mc_formGroup'>
                  <label>Services I Provide <sup className='require-star'>*</sup><span className='mc_superTag'>(Please enter the details, separated by commas.)</span></label>
                  <input type='text' className='mc_input' name='services_provide' placeholder='Funnel Building, email services' value={state.formDetails?.services_provide} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name] : e.target.value } })}/>
                </div>
                {/* <div className='mc_formGroup'>
                  <label>Looking To Meet <sup className='require-star'>*</sup></label>
                  <div className='mc_input mc_tagdv relative'>
                    <div className='mc_tagInput_holder'>
                      <div className='clickInput_holder'>
                        {state.formDetails?.look_to_meet && state.formDetails?.look_to_meet.split(',').map((tag, index) => (
                          <div className="inputTag" key={index}>
                            {tag}
                            <button onClick={(e) => deleteTag(e,index,"look_to_meet")}>x</button>
                          </div>
                        ))}
                        </div>
                        <input id="look_to_meet"  onKeyDown={onKeyDown} onKeyUp={onKeyUp} onChange={onChangeTag} className='mc_input_transperant' />
                    </div>
                  </div>
                </div>
                
                <div className='mc_formGroup'>
                  <label>Services I Provide <sup className='require-star'>*</sup></label>
                  <div className='mc_input mc_tagdv relative'>
                    <div className='mc_tagInput_holder'>
                      <div className='clickInput_holder'>
                        {state.formDetails?.services_provide && state.formDetails?.services_provide.split(',').map((val, i) => (
                          <div  className="inputTag" key={i}>
                            {val}
                            <button onClick={(e) => deleteTag(e,i,"services_provide")}>x</button>
                          </div>
                        ))}
                        </div>
                        <input  id="services_provide" onKeyDown={onKeyDown} onKeyUp={onKeyUp} onChange={onChangeTag} className='mc_input_transperant' />
                    </div>
                  </div>
                </div> */}

                <div className='mc_formGroup'>
                  <label>Website </label>
                  <input type='url' className='mc_input' name='website' placeholder='' value={state.formDetails?.website} onChange={(e) => setMyState(setQuery, { formDetails: { ...state.formDetails, [e.target.name]: e.target.value } })} />
                </div>

                <div className='mc_formGroup'>
                  <label>Upload Profile Image</label>
                  <div class="p-image mb-4">
                    <label htmlFor='profileImage' className='uploadProfile_Label w-full'>
                      <input type="file" id='profileImage' accept="image/*" onChange={handleImageChange} />
                      <span className='uploadIcom'>{svg.app.uploadProfileIcon}{state.uploadProfileName ? state.uploadProfileName : 'Upload Profile Image'}</span>
                    </label>
                    {state.formDetails.profile_img && (
                      <div className="circle">
                        <div className="profile-pic" style={{ backgroundImage: `url(${state.formDetails.profile_img})` }}></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className='mc_formGroup'>
                  <label>Upload Profile Picture</label>
                  <input type='file' className='mc_input' name='profile_img ' placeholder='' onChange={(e) => setMyState(setQuery, { profile_img: e.target.value })} />
                </div> */}
              <div className='mc_formGroup mt-2'>
                <button type='submit' className='mc_btn'
                  style={{ color: '#fff', backgroundColor: `${state.buttonColor == `Event Login` ? '#22CCA6' : '#111827'}` }}
                // onMouseEnter={(e)=> getButtonColor1('Event Login')} 
                // onMouseLeave={(e)=> getButtonColor2('Event Login')}
                >
                  Update
                </button>
              </div>
            </form>
          </div>}
      </div>
      {state.pageLoading ? dataLoader.commonLoader : ''}
    </div>
  )
}

export default EventProfile;

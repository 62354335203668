import React, { useEffect, useState } from "react";
import { setMyState ,common} from "../components/Comman";
import { appStore } from "../zustand/store";
import svg from "../components/svg";
import config from "../config";
import { toast } from 'react-toastify';
import {  dataLoader } from '../components/Comman';
import Select from 'react-select'
function Notification() {
  const  vcode=110001
  const apiUrl = config.REACT_CHAT_APIURL;
  let myStore = appStore((state) => state);
  let brandSetting = myStore.brandSetting;
  let userData = myStore.userData;
  let limit =10
  let [page,setpage]=useState(1)
  const[title,setTitle]= useState('');
  const[description,setDescription]= useState('');
  const[url,setUrl]= useState('');
  const[userlist,setuserlist]= useState([]);
  const[selectuserlist,setselectuserlist]= useState([]);
  const[code,setcode]= useState("");
  const[verify,setverify]= useState(false);
  const [load ,setload]=useState(false)
  const [image ,setImage] =useState(null)
  const [selectop,setselectop]=useState("all")
  const [state, setQuery] = useState({
    bodyColor: "#F7f7f7",
    themeColor: "#28E9BE",
    textColor: "#4A5251",
    hoverColor: null,
    ScheduleBox: false,
  });
  let getColor1 = (e) => {
    setMyState(setQuery, {
      hoverColor: e,
    });
  };
  let getColor2 = (e) => {
    setMyState(setQuery, {
      hoverColor: null,
    });
  };
  const handleImageChange = (event) => {
    
    const file = event.target.files[0];
    if(file.type=='image/jpeg' ||  file.type=='image/png' ){
      setload(true)
    var createElem = document.createElement(`img`);
    console.log({file})
    createElem.src = URL.createObjectURL(file);
    createElem.onload = (e) => {
        var mediaMeta = {
            duration: createElem.duration,
            width: createElem.width,
            height: createElem.height,
        };
        if (file) {
          const reader = new FileReader();
            var myHeaders = new Headers();
            var formdata = new FormData();
            formdata.append("file", file, "");
            formdata.append("width", createElem.width);
            formdata.append("height", createElem.height);
            formdata.append("type", file.type);
            formdata.append("name", file.name);
            formdata.append("cloud_type", "2");
            formdata.append("upload_type", "2");
            var requestOptions = {      
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
            };
    
        fetch("https://img.myflxfnls.com/upload-img", requestOptions).then(response => response.json())
          .then(result =>{
              console.log({result})
              if(result?.results?.imgUrl){
                setImage(result?.results?.imgUrl)
                setload(false)
              }
          })
          .catch(error => console.log('error', error));
                setMyState(setQuery, {
                  profileImage : reader.result
                })
            }
        }
    }else{
      toast.error('File type not allowed.')
    }
  };
  useEffect(()=>{
setselectuserlist([])
  },[selectop])
  const pushNotification = (e) => {
    e.preventDefault();

    if(!(title && description ))
    {
      toast.error("Require all fields")
      return 
    }
    // setload(true)
    let e1=[]
    console.log({selectuserlist})
   selectuserlist.forEach((d4)=>{
    e1.push(d4.value)
   })

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        title,
        description,
        image,
        list : e1,
        eventname: userData?.ticket_code.substring(0, 3).toLowerCase()
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(  apiUrl +"sendPushNotification", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.status){
            toast.success(result?.message)
          }else{
            toast.error(result?.message)
          }
          setTitle("")
          setDescription("")
          setUrl("")
          setselectuserlist([])
          setImage(null)
          setload(false)
        })
        .catch((error) => console.error(error));
        };
        const  codeValidate=(e)=>{
          e.preventDefault()
          if(code==vcode)
          {
            toast.success("Verification complete")
            setverify(true)
          }else{
            toast.error("Verification failed")
          }
        }

const getAllUserData =(load=null,p1=null)=>{
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };
  
  fetch(  apiUrl +"getUserFireBase?page="+(p1  ? p1 : page)+"&limit="+limit + `&eventname=${userData?.ticket_code.substring(0, 3).toLowerCase()}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if(result.status){
        let a1=[]
        let d1= result.data
        d1.forEach(element => {
          a1.push({
            value : element._id,
            label : element.name
          })
        });
        if(load)
        {
          setuserlist(e=>[...e,...a1])
        }else{
          setuserlist(a1)
        }
       

      }
      console.log(result.data)
    })
    .catch((error) => console.error(error));
}


useEffect(()=>{
  getAllUserData()
},[])


const handleMenuScroll = async ({ target }) => {
  // console.log(target.scrollHeight - target.scrollTop ,target.clientHeight,"dddddddddddddddddd")
  if (target.scrollHeight - target.scrollTop === target.clientHeight) {

    setpage((d1)=>d1+1)
      getAllUserData(true,page+1)
  }
};

    const handleMultiSelectBox = (d1) => {
            setselectuserlist(d1)
        }
        return (
    <>
    {verify==false ? 
    <>
        <div className="mc_validationCode_wrapper">
          <div className="mc_notifyCode_dv">
              <div className='mc_eventProfileForm'>
                  <h2 className="mc_headeing_dv">Valitation verification</h2>
                  <p>With the below code, you can access your event notification on your mobile device</p>
                  <div className='mc_formGroup'>
                    <input type='text' className='mc_input' name='verify_code' value={code} onChange={(e)=>setcode(e.target.value)} placeholder='Verification Code'/>
                  </div>
                  <div className='mc_formGroup mt-2'>
                  <button onClick={(e)=>{
                    codeValidate(e)
                  }} type='button' className='mc_btn'
                    style={{ color: '#fff', backgroundColor: '#22CCA6' }}
                  >
                    Verify
                  </button>
                </div>
              </div>
          </div>
        </div>
    </> 
    :
      <div
        className="mc_eventBody"
        style={{ backgroundColor: state.bodyColor, color: state.textColor }}
      >
        <div className="mc_headTitle_dv px-0">
          <h2 style={{ color: state.themeColor }}>Notification</h2>
        </div>
        <div className="mc_notification_wrapper mc_userProfile_head">
          <form
            action=""
            className="mc_pushNofification"
            onSubmit={pushNotification}
          >
            <div className="mc_formGroup mb-3">
              <label>Title</label>
              <input
                className="mc_input mb-2 mt-1"
                placeholder="Notification Title"
                type="text" onChange={(e)=>setTitle(e.target.value)} value={title}
              />
            </div>
            <div className="mc_formGroup mb-3">
              <label>Message</label>
              <textarea
                className="mc_input mb-2 mt-1 mc_textarea w-full py-3 px-3"
                placeholder="Notification Message"
                rows="5" onChange={(e)=>setDescription(e.target.value)} value={description}
              ></textarea>
            </div>
            <div className='mc_formGroup mb-3'>
                <label>URL </label>
                <input type='url' className='mc_input' name='url' placeholder='' onChange={(e) => setUrl(e.target.value)} />
            </div>
            {/* <div class="p-image mc_formGroup mb-4">
              <label
                htmlFor="profileImage"
                className="uploadProfile_Label w-full"
              >
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                <span className="uploadIcom">
                  {svg.app.imageIcon} Upload Image
                </span>
              </label>
              {image && ( 
                            <div className="circle">
                                <div className="profile-pic" style={{backgroundImage: `url(${image})`}}></div>
                            </div>
                        )}
            </div> */}
            {/* <div className='mc_formGroup mb-3'>
                        <label>Attendees</label>
                        <input className='mc_input mt-2' placeholder='Notification Title' type='text'/>
                    </div> */}
            {state.ScheduleBox == true && (
              <div className="flex mc_formCrossIcon">
                <div className="mc_formGroup mb-3">
                  <label>Date & Time</label>
                  <input
                    className="mc_input mb-2 mt-1"
                    placeholder="Notification Title"
                    type="date"
                  />
                </div>
                <a
                  href={void 0}
                  className="mc_hidefeild"
                  onClick={() =>
                    setMyState(setQuery, {
                      ScheduleBox: !state.ScheduleBox,
                    })
                  }
                >
                  {" "}
                  {svg.app.crossIcon}
                </a>
              </div>
            )}

            <div className="mc_formGroup mb-3">
              <label htmlFor="sendToAll">
                <input id="sendToAll" checked={selectop == "all" ? true : false}  onChange={()=>{
                  setselectop("all")
                }} type="radio" name="select_all" />
                <span className="ml-2 mr-5">Select All</span> 
              </label>

              <label htmlFor="sendToAttendee">
                <input id="sendToAttendee"  checked={selectop != "all" ? true : false}   onChange={()=>{
                  setselectop("select")
                }} type="radio" name="select_all" />
                <span className="ml-2">Select Attendee</span> 
              </label>
            </div>
          {selectop!="all" && 
               <Select
                isMulti
                options={userlist}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectuserlist}
                onChange={(e) => handleMultiSelectBox(e)}
                // onMenuScrollToBottom={handleMenuScroll}
                placeholder="Select Attendee"
                // onInputChange={handleInputChange}
                // isLoading ={facebookpageload}
              />
}
            <div className="mc_formGroup flex">
              <button
                className="mc_btn mt-2"
                placeholder="Notification Title"
                type="Submit"
                style={{
                  color: state.bodyColor,
                  backgroundColor:
                    state.buttonColor == "Submit Notification"
                      ? "#fff"
                      : state.themeColor,
                }}
                onMouseEnter={(e) => getColor1("Submit Notification")}
                onMouseLeave={(e) => getColor2("Submit Notification")}
              >
                Send Now
              </button>
              {/* {state.ScheduleBox != true && (
                <button
                  className="mc_btn ml-2 mt-2"
                  type="button"
                  style={{
                    color: state.bodyColor,
                    backgroundColor:
                      state.buttonColor == "Submit Scheduled"
                        ? state.themeColor
                        : "#4A5251",
                  }}
                  onMouseEnter={(e) => getColor1("Submit Scheduled")}
                  onMouseLeave={(e) => getColor2("Submit Scheduled")}
                  onClick={() =>
                    setMyState(setQuery, {
                      ScheduleBox: !state.ScheduleBox,
                    })
                  }
                >
                  Schedule
                </button>
              )} */}
            </div>
          </form>
        </div>
      </div>
    }
      {load ? dataLoader.commonLoader : ''}
    </>
  );
}

export default Notification;

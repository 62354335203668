
import svg from "../components/svg";

export const navLinks = [
    { href: "/dashboard", label: "Home" },
    // { href: "/community", label: "Community" },
    // { href: "/my-courses", label: "My Courses" },
    // { href: "/webinars", label: "Webinars" },
    // { href: "/my-learning", label: "My Learning" },
];

export const footerLinks = [
    {
        id: '1',
        title: "Company",
        links: [
            { name: "About Us", link: "/about-us" },
            { name: "Carear", link: "/carear" },
            { name: "Press", link: "/press" },
            { name: "Blogs", link: "/blogs" },
            { name: "Affiliate", link: "/affiliate" },
            { name: "Partnership", link: "/partnership" },
        ],
    },
    {
        id: '2',
        title: "General",
        links: [
            { name: "Refer a Friend", link: "/refer-friend" },
            { name: "Memberships", link: "/memberships" },
            { name: "Free Classes", link: "/free-classes" },
            { name: "Become a Teacher", link: "/become-teacher" },
            { name: "Help & support", link: "/help-support" },
        ],
    },
    // {
    //     title: "Get in touch",
    //     links: [
    //         { name: "customer@nike.com", link: "mailto:customer@nike.com" },
    //         { name: "+92554862354", link: "tel:+92554862354" },
    //     ],
    // },
];

export const socialMedia = [
    { id:'11', src: '/assets/icons/facebook.svg', alt: "facebook logo" },
    { id:'22', src: '/assets/icons/twitter.svg', alt: "twitter logo" },
    { id:'33', src: '/assets/icons/instagram.svg', alt: "instagram logo" },
];

import React from 'react'

const LatestFollowers = (props) => {
    
    // console.log(props, 'LatestFollowers');
    let followList = props.followData;
    // console.log(followList, props.followData, 'FollowersData');
    return (
        <>
            <div className='mc_listAccorfian'>
                {followList.length ? followList.map((list, index)=>{
                    // console.log(list, 'FollowList');
                    return (
                        <div key={index} className='mc_borseredBoxModal flex flex-wrap py-3'>
                            <div className='flex justify-center items-center w-full relative'>
                                <span className='mr-4'>
                                    <img src={`${list.profilePic}`} className='rounded-full' alt='studio' width={'40px'} height={'40px'}/>
                                </span>
                                <div className='mc_ueserLists_dv w-full'>
                                    <div className='flex justify-between items-center text-gray-500 w-full'>
                                        <div className=''>
                                            <p className='text-sm text-black font-semibold'>{list.userName}</p>
                                            <span className='text-xs text-black mt-1'>{list.memberCounts} Members, <b className='font-semibold'>Points: {list.points}, </b> {list.badges} Badges</span>
                                        </div>
                                        <div className='mc_batchText_dv flex justify-between items-center '>
                                            <a href={() => false} className='mc_btn'>Follow</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) 
                : 
                    <div className='mc_whiteBox mt-4 p-5'>
                        <div className='flex items-center'>
                            <span className='mr-2'>
                                {/* {svg.app.usersIcon} */}
                                <img src='/assets/images/userFollow.png' alt='img'/>
                            </span>
                            <p className='text-sm text-black text-medium font-bold'>My Latest Followers</p>
                        </div>
                        <div className='mc_profileList'>
                            <ul className='flex mt-3'>
                                <li className='mx-1'>
                                    <a href={()=> false}>
                                        <img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/>
                                    </a>
                                </li>
                                <li className='mx-1'>
                                    <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                                </li>
                                <li className='mx-1'>
                                    <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                                </li>
                                <li className='mx-1'>
                                    <a href={()=> false}><img src={'/assets/images/profile.jpeg'} alt="profileimage" className="rounded-full" width={'40px'} height={'40px'}/></a>
                                </li>
                                <li className='mx-1'>
                                    <a href={() => false} className='mc_circleDark rounded-full flex items-center justify-center' 
                                        //onClick={()=> setFollowersModal(true)}
                                    >
                                        15+
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>

        </>
    )

}

export default LatestFollowers
import React, { useEffect, useState } from 'react'
import { common } from './Comman'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';




const InviteCode = () => {
  const deviceName =  new URLSearchParams(window.location.search);
  // const urlDeviceData = deviceName.get('device-name') || null;
  const urlDeviceData = 'http://localhost:3000/membership/9xXWX1rX/login';
  // console.log(window.frames.self,Object.keys(window.localStorage), 'device name');
  // return;
  function getmemberFunnel(){
    // console.log(window.location.href, document, 'window.location.href');
    document.getElementById('mc_funnelOverlay').style.display = 'none';
    
  }
  function backtoOverlay(){
    document.getElementById('mc_funnelOverlay').style.display = 'block';
  }
  useEffect(()=>{
    let style=document.createElement("style")
    style.innerHTML=`body{
        margin: 0px;
        padding: 0px;
        font-family: 'Poppins';
    }
    div#splashScreen {
        position: absolute;
        top: 0;
        z-index: 999;
        left: 0;
        bottom: 0;
    }
    .mc_mobileScreen {
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }
    .innerCenter_dv >img {
      width: 100px;
    }
    .mc_logoText_span {
      display: block;
      position: relative;
      z-index: 11;
      color: #fff;
    }
    .mc_logoText_span b{

    }
    .mc_inviteCode_form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 50px;
    }
    .mc_inviteCode_form > img {
      width: 250px;
      margin: auto;
    }
    .mc_noneImage {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .mc_backbtn{
        display: block;
        color: #fff;
        margin-top: 20px;
        text-align: center;
        width: 100%;
        text-decoration: none;
    }
    .mc_textcontent {
        margin-top: 80px;
        text-align: center;
        max-width: 290px;
    }
    .mc_textcontent > img {
      width: 100px;
      margin: auto;
    }
    .mc_textcontent > p {
      text-align: center;
      font-size: 17px;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin-top: 20px;
    }
    .mc_textcontent input.mc_input {
        padding: 0px 45px 0 20px;
        line-height: 40px;
        height: 45px;
        margin-top: 20px;
        margin-bottom: 25px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border: 1px solid #D0D0D0;
        border-radius: 5px;
        width: calc(100%);
        max-width: 442px;
    }
    .mc_textcontent .mc_btn {
        background: #2CCD4F 0% 0% no-repeat;
        width: 100%;
        height: 48px;
        border-radius: 3px;
        border: 0px;
        width: 100%;
        color: #fff;
        position: relative;
        font-size: 17px;
        text-align: left;
        padding: 0px 20px;
    }
    .mc_textcontent .mc_btn span {
        position: absolute;
        right: 15px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        display: block;
        width: 10px;
        top: 17px;
        height: 10px;
    }
    .formInput {
      position: relative;
    }
    .formInput > span {
      position: absolute;
      right: 10px;
      top: 30px;
    }
    .erroeMsg {
      margin-top: 10px;
      font-size: 13px;
      color: #ff5858;
      display: block;
    }
    /**************** Member overlay Ship Css ***********/
    .mc_overlayFunnel {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 99;
    }
    .mc_membershipfunnel {
        width: 100%;
        height: 33.33%;
        align-items: center;
        justify-content: center;
        display: inline-block;
        flex-wrap: wrap;
        text-align: center;
    }
    .mc_membershipfunnel button {
        text-align: center;
        font-weight: 300;
        font-size: 16px;
        border-radius: 2px;
        margin: 20px auto 30px;
        padding: 0px 25px;
        cursor: pointer;
        line-height: 45px;
        border: 0px;
        max-width: 225px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .mc_membershipfunnel img {
        max-width: 350px;
    }
    .mc_flexDv {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    .mc_membershipfunnel a span, .mc_membershipfunnel button span {
        line-height: 7px;
        margin-left: 10px;
    }
    .mc_funnelFlexi{
        background-image: url('images/flexiBg.png');
    }
    .mc_funnelGrow{
        background-image: url('images/growBg.png');
    }
    .mc_funnelFlexi, .mc_funnelGrow{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;    
        background-color: #fff;
    }
    .mc_funnelGrow button{
        background-color: #00FFC6;
        color: #000000;
    }
    .mc_funnelFlexi button{
        background-color: #28C64A;
        color: #000000;
    }
    .mc_unicorn{
      background-image: url('/assets/images/Event1.jpg');
  }
  .mc_funnelFlexi, .mc_unicorn{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;  
      background-color: #fff;
  }
  .mc_unicorn button{
      background-color: #ffa200;
      color: #000000;
  }
    @media(max-width: 400px){
        .mc_membershipfunnel img {
            max-width: 270px;
        }
        .mc_textcontent .mc_btn{
          font-size:14px;
        }
        .mc_unicorn .mc_flexDv img{
          max-width: 200px;
        }
    }`
    document.head.append(style)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let devicename= urlParams.get("device-name")
    let firebase_token= urlParams.get("firebase_token")
    if(devicename && firebase_token ){
        localStorage.setItem("userDevice", JSON.stringify({
          firebase_token,
          devicename
        }))
    }
  },[])


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const members = queryParams.get('members');

  const [errorCode, setErrorCode ] = useState('');
  const [isProcess, setIsProcess ] = useState(false);

  let getInviteCode = () => {
    setTimeout(() => {
        setErrorCode('');
    }, 2000);
    let t = document.getElementById('inviteCode_id').value;
    let body = {
        invite_code : t 
    }
    if(t.trim()===''){
        setErrorCode('Please enter invite code.');
        return;
    }
    setIsProcess(true);
    common.getAPI({
        method: "Post",
        url: "getinvitelogin",
        data:body,
    },(resp) => {
        setIsProcess(false);
        let {success = false , message = '' } = resp;
        setErrorCode(message);
        if(success){
          let inviteUrl = resp.funnel_data[0].step_url;
          let url= inviteUrl + (urlDeviceData ? "?device-name=" + deviceName.get('device-name') : "" )
          if(members){
            url = url + (urlDeviceData ? `&members=${members}` : `?members=${members}` )
          }
          let d1= localStorage.setItem(`redirect_url`, url )
          // console.log(url, d1, inviteUrl, 'InviteUrl')
          window.location.replace(url);
        }else {
          setErrorCode(resp.message);
        }
    })
      // let url =  `http://localhost:3000/membership/${t}/login`;
      // if(members){
      //   url = url +`?members=${members}`
      // }
      // window.location.replace(url);
    }
    const goToEnvitePage=(type)=>{
      window.location.replace(`https://event-tickets.flexifunnels.com/events?type=${type}`);
      // window.location.replace(`${window.location.href}events?type=${type}`);
    }

    window.addEventListener("load", (event) => {
      setTimeout(() => {
        document.getElementById('eventPageLoader').style.display = 'none';
      }, 1500);
    });
    
  return (<>   
    <div id="eventPageLoader"  className={'mainPageLoader'}>
        <div className='mc_isLoaderDesign'></div>
    </div>
   <div className="mc_overlayFunnel" id="mc_funnelOverlay">
   <div class="mc_membershipfunnel mc_unicorn" style={{backgroundImage:`url(url(/assets/images/bannerEvent.jpeg)`}}>
            <div class="mc_flexDv"  style={{width: '100%', textAlign: 'center'}}>
                <img src='/assets/images/LifeWheel.png' alt='Event Logo' />
                <button class="mc_btn"  onClick={()=>goToEnvitePage('LWG')}>
                    Access Event
                </button>
            </div>
        </div>
        <div className="mc_membershipfunnel mc_funnelGrow" style={{backgroundImage : `url(/assets/images/mobile/growBg.png)`}}>
            <div className="mc_flexDv" style={{width: '100%', textAlign: 'center'}}>
                <img src='/assets/images/mobile/eventLogo.png' alt='Event Logo' />
                <button className="mc_btn" href="https://event-tickets.flexifunnels.com/events/" onClick={()=>goToEnvitePage('FGS')}>
                    Access Event
                </button>
            </div>
        </div>
        <div className="mc_membershipfunnel mc_funnelFlexi" style={{backgroundImage : `url(/assets/images/mobile/flexiBg.png)`}}>
            <div className="mc_flexDv" style={{width: '100%', textAlign: 'center'}}>
                <img src='/assets/images/mobile/logoFlexi.png' alt='Member Logo' />
                <button className="mc_btn" type="button" onClick={()=>getmemberFunnel()}>
                    Access Membership 
                </button>
            </div>
        </div>
    </div>
    <div className="mc_inviteCode" id="inviteCode_screen">
        <div className='mc_mobileScreen' style={{backgroundImage : `url(/assets/images/mobile/splashBg.png)`}}>
            <div className='innerCenter_dv'>
                <div className='mc_inviteCode_form'>
                    <img src='/assets/images/mobile/logoFlexi.png' alt='Logo'/>
                    <div className='mc_noneImage'>
                        <div className='mc_textcontent'>
                            <img src='/assets/images/mobile/noun-password.png' alt='noun-password'/>
                            <p>Enter Your Invite Code and Access Your Membership</p>
                            <div className='formInput'>
                                <input type='text' placeholder='Enter your invite code' name='invitcode' className='mc_input' id="inviteCode_id"/>
                                <span><img src='/assets/images/mobile/noun-lock.png' alt='lock'/></span>                            
                            </div>
                            <button type='button' className='mc_btn mt-4' disabled={isProcess} onClick={()=> getInviteCode()}>{isProcess ? "Processing...": "Access My Membership"} <span></span></button>
                            {/* <a href={void(0)} className='mc_btn mt-3' onClick={()=> goToEnvitePage()}>Back</a> */}
                            <span className="erroeMsg">{errorCode}</span>
                        </div>
                        <a href="javascript:void(0)" className="mc_backbtn" onClick={()=>backtoOverlay()}>Back to Home </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>

  ) 
}

export default InviteCode
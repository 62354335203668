import { create } from 'zustand';
import {persist} from 'zustand/middleware';
const slugName = window.location.href.split('/')[4];

let courseStore = (set, get) => ({
   slug_name: '',
   play : false,
   courseData : [],
   memberData : '',
   activetab :"",
   duration :0,
   userData : {}, 
   appData : {},
   footerData : {},
   brandSetting : {},
   searchList:{
      search_keyword : '',
      apiName : '',
      success : false,
   },
   searchLessonData:[],
   profileImg : '',
   myVideo : [],
   script : null,
   videoTime : 0,

   updateStoreData : async(key , data) => {
      set({[key] : data})
   },

   logout :async (key, data) => {
      set({
         slug_name: '',
         play : false,
         courseData : [],
         memberData : '',
         activetab :"",
         duration :0,
         userData : {}, 
         appData : {},
         footerData : {},
         searchList:{
            search_keyword : '',
            apiName : '',
            success : false,
         },
         myVideo : [],
    });
      
    }
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let eventType= urlParams.get("type") || localStorage.getItem("eventType");

if( window.location.href.split('/')[3].split('?')[0] == 'events'){
   if(eventType){
      courseStore = persist(courseStore, { 
         name: `event-${eventType.toLowerCase()}`, 
         // storage: createJSONStorage(() => sessionStorage) 
      });
   }else{
      courseStore = persist(courseStore, { 
         name: `event-storage`, 
         // storage: createJSONStorage(() => sessionStorage) 
      });
      
   }
   
}else{
   courseStore = persist(courseStore, { 
      name: `course-storage_${slugName}`, 
      // storage: createJSONStorage(() => sessionStorage) 
   });
   
}

export const appStore = create(courseStore);
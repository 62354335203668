import React, {  useState ,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { appStore } from '../../zustand/store';
import { common, setMyState } from '../../components/Comman';
import { toast } from 'react-toastify';
import config from '../../config';

function EventLogin(props) {
  let myStore = appStore(state => state);

  let brandSetting = myStore.brandSetting;
  let userData = myStore.userData;


  const navigate = useNavigate();

  const [state, setQuery] = useState({
    funnel_id: '',
    funnel_page_id: '',
    lesson_id: '',
    member_id: '',
    token: '',
    inviteCode: '',
    email: '',
    ticket_code: '',
    buttonColor: null,
    eventlist: [],
    speakerlist: [],
    attendeelist: [],
    loginBtnLoder:false,

    bodyColor: '#111827',
    // themeColor: '#00FFC6',
    textColor: '#C8D3E8',
    // logo:'/assets/images/eventsimg/logo.png'
    
  })
    
 

     const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let eventType;
      
      // if(urlParams){
      //   eventType = urlParams?.get("type").toLowerCase();
      // }
      let localType = localStorage?.getItem('eventType').toLowerCase();

      if(!eventType){
        if(localType) {
          eventType = localType;
        } 
      }
    
      let themeColor = (eventType == 'ucs' || eventType == 'lwg') ? '#ffa200' : '#00FFC6';
      let logo = eventType == 'ucs'  ? '/assets/images/unicorn_logo.png' : eventType == 'lwg'  ? '/assets/images/LifeWheel.png'   : '/assets/images/eventsimg/logo.png';

     useEffect(()=>{
    
      let data3 = document.getElementById("mainPageLoader")
    setTimeout(() => {
         if (data3?.classList?.contains("isopen")) {
           data3.classList.remove("isopen")
         }
       }, 2000);

     },[eventType])


     useEffect(() => {

        if (userData?.type != "event") {
            navigate('/events');
        } else {
          
            let expectedpath = ['match', 'chats','attendees'];
            let defaultRedirect = expectedpath.some(val => val == window.location.href.split('/')[4]);
            if (!defaultRedirect) {
                localStorage.setItem('redirect', true);
                navigate('/events/home');
            }
          
        }

    }, [])
  let getButtonColor1 = (type) => {
    setMyState(setQuery, { buttonColor: type })
  }
  let getButtonColor2 = (type) => {
    setMyState(setQuery, { buttonColor: null })
  }
  
  let eventInputs = {
    ticket_code: state.ticket_code,
  }

  const getHandleLogin = (e, data) => {

    e.preventDefault();

    if (data.ticket_code == '' || data.ticket_code == 'undefined') {
      toast.error('Ticket Code field is required.', { toastId: 'toast' });
      return false
    }
    if(data.ticket_code.substring(0, 3).toLowerCase() != eventType ){
      toast.error('Invalid Ticket Code.', { toastId: 'toast' });
      return false
    }
    if (data) {
      setMyState(setQuery, { loginBtnLoder: true })
      
      common.getAPI({
        isLoader: true,
        url: 'attendee-login',
        method: 'POST',
        data: data
      }, (resp) => {
        if (resp.success == true) {
          myStore.updateStoreData('userData', { ...resp.user_data,type: 'event' });
          if (resp.user_data) {
            let d1={...resp.user_data,  eventname  : resp?.user_data?.ticket_code.substring(0, 3).toLowerCase() }
            let st=localStorage.getItem("userDevice")
            if(st){
              d1={
                ...d1,
                userDevice : JSON.parse(st),
                eventname  : 'ucs'
              }
            }
            const requestOptions = {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(d1)
          };
          fetch(`${config.REACT_CHAT_APIURL}updateUser`, requestOptions).then((res1)=> res1.json()).then((resp1)=>{
            localStorage.setItem('id',resp1.data._id); 
            localStorage.setItem(`${resp1.data?.ticket_code.substring(0, 3).toLowerCase()}_data`,resp1.data._id);           
            const requiredKeys = ['name', 'email', 'services_provide', 'designation', 'look_to_meet'];
            const missingKeys = requiredKeys.filter(key => !resp?.user_data[key]);
            setMyState(setQuery, { loginBtnLoder: false })
            if(resp1?.data){
              if(missingKeys.length > 0){ 
                navigate('/events/profile')
              }else{
                navigate('/events/home')
              }
            }
           
          }).catch((err)=>
            console.log(err,"err")
          );
          }
          
        }else{
          setMyState(setQuery, { loginBtnLoder: false })
          toast.error("Invalid Ticket Code.")
          // if(resp?.message=="Unauthorized"){
          // }
        }
      });

    }
  }
  
  function backToEvents(){
    window.location.replace('https://membershipdata.flexifunnels.com/');
    // window.location.replace('http://localhost:3000/');
  }
  return (
    <>
      <Helmet>
        <title>Event Login</title>
      </Helmet>


      {
      // (eventType || localType) ?
        (!userData?.type) && <div className='mc_eventLogin'
          style={{
            backgroundImage: `url(${brandSetting.bgimg ? brandSetting.bgimg : '/assets/images/bgImage.png'})`,
            backgroundColor: brandSetting.bgcolor,
            backgroundSize: brandSetting.bgtype,
            background: `${brandSetting.bgcolortype == 'color' ? brandSetting.bgcolor : brandSetting.bggradientColor} url(${brandSetting.bgimg}) ${brandSetting.bgcolor} 100% 100% / ${brandSetting.bgtype} `
          }}
        >
          <div className='mc_eventlogo'>
            {/* <NavLink to={'/'}> */}
            <img src={logo} alt='Event Logo' style={{maxHeight: eventType == 'ucs' ? '120px' : '50px', margin: eventType == 'ucs' ? '0 auto 3rem' : '0 auto 5rem'}} />
            {/* </NavLink> */}
            <form className='mc_eventForm' onSubmit={(e) => getHandleLogin(e, eventInputs)}>
              {/* <div className='mc_formGroup'>
                <label>Email Address</label>
                <input type='email' className='mc_input' name='event_email' placeholder='' onChange={(e) => setMyState(setQuery, { email: e.target.value })} />
              </div>
              <span className='mc_OptionalInput'>Or</span> */}
              <div className='mc_formGroup'>
                <label style={{color: '#fff'}}>Ticket Purchase Code</label>
                <input type='text' className='mc_input' name='event_code' placeholder='' onChange={(e) => setMyState(setQuery, { ticket_code: e.target.value })} />
              </div>
              <div className='mc_formGroup'>
                <button type='submit' className='mc_btn'
                disabled={state.loginBtnLoder}
                  style={{ color: '#111827', backgroundColor: `${state.buttonColor == `Event Login` ? '#fff' : themeColor}` }}
                  onMouseEnter={(e) => getButtonColor1('Event Login')}
                  onMouseLeave={(e) => getButtonColor2('Event Login')}
                >
                   { state.loginBtnLoder ?  "Processing..." : "Login"}
                </button>

                <a className='mc_linkBack' href={() => false} onClick={()=> backToEvents()}>Back to Home</a>
              </div>
            </form>
          </div>
        </div>
        // :null
      }

      {/* {showAttendanceList == false ?
        <div className='mc_eventLogin'
          style={{
            backgroundImage: `url(${brandSetting.bgimg})`,
            backgroundColor: brandSetting.bgcolor,
            backgroundSize: brandSetting.bgtype,
            background: `${brandSetting.bgcolortype == 'color' ? brandSetting.bgcolor : brandSetting.bggradientColor} url(${brandSetting.bgimg}) ${brandSetting.bgcolor} 100% 100% / ${brandSetting.bgtype} `
          }}
        >
          <div className='mc_eventlogo'>
          
            <img src={'/assets/images/eventsimg/logo.png'} alt='Event Logo' />
          
            <form className='mc_eventForm'
              onSubmit={(e) => getHandleLogin(e, eventInputs)}
            >
              <div className='mc_formGroup'>
                <label>Email Address</label>
                <input type='email' className='mc_input' name='event_email' placeholder='' onChange={(e) => setMyState(setQuery, { email: e.target.value })} />
              </div>
              <span className='mc_OptionalInput'>Or</span>
              <div className='mc_formGroup'>
                <label>Ticket Purchase Code</label>
                <input type='text' className='mc_input' name='event_code' placeholder='' onChange={(e) => setMyState(setQuery, { ticket_code: e.target.value })} />
              </div>
              <div className='mc_formGroup'>
                <button type='submit' className='mc_btn'
                  style={{ color: '#111827', backgroundColor: `${state.buttonColor == `Event Login` ? '#fff' : '#00FFC6'}` }}
                  onMouseEnter={(e) => getButtonColor1('Event Login')}
                  onMouseLeave={(e) => getButtonColor2('Event Login')}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        : state.pageType == 'Attendees' ?
          <AttendanceList className={`mc_attendanceList_dv`} title={'Attendees'} userData={state.eventAuthData} />
          : state.pageType == 'Agenda' ?
            <Agenda className={`mc_agandaDv`} title={'Agenda'} userData={state.eventAuthData} />
            : state.pageType == 'Speakers' ?
              <Speakers className={`mc_speakerDv`} title={'Speakers'} userData={state.eventAuthData} />
              : state.pageType == 'Chats' ?
                <ChatsEvents className={`mc_chatsDv`} title={'Chats'} userData={state.eventAuthData} />
                : state.pageType == 'Profile' &&
                <AttenderProfile className={`mc_profileDv`} title={'Chats'} userData={state.eventAuthData} />
      } */}

    </>
  )
}

export default EventLogin;

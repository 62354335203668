import React, { useEffect, useRef, useState } from 'react'

function SingleComment(props) {
    // console.log(props, 'propspropspropspropspropsprops');
    const [viewfullComments, setViewfullComments] = useState([]);
    const commentsRef = useRef(null)
    let activeId = props.items?.comment_id ? props.items?.comment_id : props.items?.post_id;

    useEffect(() => {

        const checkClamp = () => {
            const lineHeight = parseInt(window.getComputedStyle(commentsRef.current).getPropertyValue('line-height'));
            const maxHeight = lineHeight * 4;
            const actualHeight = commentsRef.current.scrollHeight;
           
            if (actualHeight > maxHeight) {

                props.setVMText(curr => {
                    const newArray = Array.isArray(curr) ? curr : [];
                    if (!newArray?.includes(activeId)) {
                        return [...newArray, activeId];
                    }
                    return newArray;
                });

            } else {

                props.setVMText(curr => {
                    const newArray = Array.isArray(curr) ? curr : [];
                    if (newArray?.includes(activeId)) {
                        return newArray.filter(id => id !== activeId)
                    }
                    return newArray;
                });
                setViewfullComments(curr => {
                    const newArray = Array.isArray(curr) ? curr : [];
                    if (newArray?.includes(activeId)) {
                        return newArray.filter(id => id !== activeId)
                    }
                    return newArray;
                })
            }
        };
        checkClamp();
        window.addEventListener('resize', checkClamp);
        return () => {
            window.removeEventListener('resize', checkClamp);
        };
    }, []);

    const handleToggleNote = (noteId) => {
        setViewfullComments(curr => {
            if (curr?.includes(noteId)) {
                return curr.filter(id => id !== noteId);
            } else {
                return [...curr, noteId];
            }
        });
    };

    // console.log(props.showVMText, "showVMText",activeId);
    return (
        <>

            <h5 style={{ whiteSpace: 'pre-line' }} className={`text-sm mt-3 mb-3 w-full ${viewfullComments?.includes(activeId) ? '' : 'note_short'}`} ref={commentsRef}>{props.items?.post_title ? props.items?.post_title : props.items?.comment}</h5>

            {props.showVMText?.includes(activeId) && !viewfullComments?.includes(activeId) && <a href={() => false} className="mc_viewMoreBtn text-neutral-900 text-sm" onClick={() => handleToggleNote(activeId)}>View More</a>}

            {viewfullComments?.includes(activeId) && <a href={() => false} className="mc_viewMoreBtn text-neutral-900 text-sm" onClick={() => handleToggleNote(activeId)}>Hide</a>}

        </>
    )
}

export default SingleComment
import  { useEffect, useState } from 'react'
import svg from './svg'
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
} from "tw-elements-react";
import config from '../config';
import { NoDataWrapper, common, setMyState } from './Comman';
import { appStore } from '../zustand/store';
import Cookies from "js-cookie";


function Goals(props) {

 const slugName = window.location.href.split('/')[4];
  let alfaUrl = config.REACT_APP_BUCKET_URL;
  let myStore = appStore(state => state);
  let userData = myStore.userData;
  const memberShipUser = userData.MembershipUser;
  let brandSetting = myStore.brandSetting;
  let tokenCookie = Cookies.get(`access_token_${slugName}`)
  ? Cookies.get(`access_token_${slugName}`)
  : false;

  const [showModalTopRight, setShowModalTopRight] = useState(false)
  const [showModalBottomRight, setShowModalBottomRight] = useState(false)
  const [showModalCircle, setShowModalCircle] = useState(false);
  const [showModal,setShowModal] = useState(false);
  const [showModalTriangleModal, setShowModalTriangleModal] = useState(false);



  const [state, setQuery] = useState({ 
    rating : '',
    goalList:[],
    badgeList:[],
    showGoalSettingPopUp:false,
    goalsettingData:{},
    watchAudioGoals: false,
    watchVideoGoals: false,
    buttonColor : null,
    goalListId : null,
 })
  const getColor1=(type)=>{
    setMyState(setQuery, {
        ...state,
        buttonColor : type,
    })
  }
  const getColor2=(type)=>{
    setMyState(setQuery, {
        ...state,
        buttonColor : null,
    })
  }
  useEffect(()=>{
    getGoals();
    // getBadge();
  },[])

    const  getGoals = (l_id)=>{
        let apiData = {
            funnel_id: memberShipUser.funnel_id,
            member_id: memberShipUser.id,
            funnel_page_id : props.funnelPageId,
            lesson_id : props.lessonId,
            token: tokenCookie,
        };
      
        common.getAPI({
            method: "Post",
            url: "goallist",
            data: apiData,
        },
        async (resp) => { 
            if(resp.status){
                setMyState(setQuery,{
                    ...state,
                    goalList : resp.goallist,
                })
                
            }
        });
    }

    // const  getBadge = (l_id)=>{
    //     let apiData = {
    //         funnel_id: memberShipUser.funnel_id,
    //         member_id: memberShipUser.id,
    //         funnel_page_id : props.funnelPageId,
    //         token: tokenCookie,
    //     };
      
    //     common.getAPI({
    //         method: "Post",
    //         url: "badgelist",
    //         data: apiData,
    //     },
    //     async (resp) => { 
    //         if(resp.status){
    //             // setMyState(setQuery,{
    //             //     ...state,
    //             //     badgeList : resp.badgeList,
    //             // })
                
    //         }
    //     });
    // }

  const getRatedData=(event)=>{
    if(state.rating == event){
        setMyState(setQuery,{
            ...state,
            rating : '',
        })
    }else{
        setMyState(setQuery,{
            ...state,
            rating : event,
        })
    }
}

  const getGoalsAudioVideo=(goalsData, type)=>{
    // console.log(goalsData, 'goals, goal_id');
    if(type == 'Audio'){
      setMyState(setQuery, {
        watchAudio : true,
        watchVideo : false,
      })
    }else{
      setMyState(setQuery, {
        watchAudio : false,
        watchVideo : true,
      })
    }
  }
  const getRightModalPoints=(type, golesData)=>{
    if(`GoalList`+golesData.goal_id == type){
      setMyState(setQuery, {
        goalListId : type,
        goalsettingData:golesData,
        showGoalSettingPopUp :true,
      })
    }else{
      setMyState(setQuery, {
        goalListId : null,
        goalsettingData:'',
        showGoalSettingPopUp :false,
      })
    }
  }
  return (
    <>
      <div className="col-span-3 mc_moboilCol_12">
        {/* <h4 className='text-base text-black mt-3 font-bold'>Transcripts For The Lessons</h4> */}
        <div className="mc_goalBox_dv">

          {
            state.goalList.length > 0 ? state.goalList.map((goal,i)=>{
              let goalSetting = JSON.parse(goal.goal_settings);
            return(
              <div className="mc_goalList_box flex flex-wrap mb-8" key={i}>
              {goalSetting.goal_info_type == 1 ?
                <button className='mc_goals_watch_btn' onClick={()=> {
                  setMyState(setQuery,{
                      // showGoalSettingPopUp :true,
                      goalsettingData:goalSetting,
                  });
                  getGoalsAudioVideo(goalSetting, 'Audio');
                }}
                  style={{color:brandSetting.theme_color1, backgroundColor : state.buttonColor == 'AudioBtn' ?  brandSetting.theme_color2 : 'transparent'}}  
                  onMouseEnter={()=> getColor1('AudioBtn')} 
                  onMouseLeave={()=> getColor2('AudioBtn')}
                >
                  
                  <span className='watchBtn_mc' 
                    style={{
                      fill : brandSetting.theme_color1
                    }}
                  >{svg.app.watchAudio} Watch Audio</span> 
                
                </button>
                : goalSetting.goal_info_type == 2 ?
                <button className='mc_goals_watch_btn' onClick={()=> {
                  setMyState(setQuery,{
                      // showGoalSettingPopUp :true,
                      goalsettingData:goalSetting,
                  });
                  getGoalsAudioVideo(goalSetting, 'Video');
                }}
                  style={{color:brandSetting.theme_color1, backgroundColor : state.buttonColor == 'VideoBtn' ?  brandSetting.theme_color2 : 'transparent'}}  
                  onMouseEnter={()=> getColor1('VideoBtn')} 
                  onMouseLeave={()=> getColor2('VideoBtn')}
                >
                <span className='watchBtn_mc' 
                  style={{
                    fill : brandSetting.theme_color1
                  }}
                >{svg.app.watchVideo} Watch Video</span>
                </button>
              : ''
              }
              <div className="flex flex-wrap w-full items-start mc_innerWhite_dv">
                <span className="rounded-full">
                  <img
                  //   src={`${alfaUrl}/images/customer1.jpeg`}
                  src={`/assets/images/Course1.jpg`}
                    className="rounded-full"
                    alt="studio"
                    style={{width:'92px',height:'92px',objectFit:'cover'}}
                  />
                </span>
                <div className="mc_goalContent">
                  <h3 className="text-sm text-black font-semibold">
                    {goal.goal_name}
                  </h3>
                  {/* max-w-80 */}
                  <p className="text-gray-900 text-sm mt-4 line-height-20">
                  {goal.goal_desc}
                  </p>
                  {
                    state.watchAudio && goalSetting.goal_info_type == 1? 
                    <>
                      <div className='mc_goalsSetting_dv mt-4'>
                        <audio src={goalSetting.goal_audio_url} controls></audio>
                      </div>
                    </>
                    : state.watchVideo && goalSetting.goal_info_type == 2 ?
                    <>
                      <div className='mc_goalsSetting_dv mt-4'>
                        <iframe width="660" height="315" src={goalSetting.goal_video_url} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen controls></iframe>
                      </div>
                    </>
                    : ''
                  }
                </div>
              </div>
              <div className="mc_innerGray_dv flex flex-wrap justify-between w-full p-4 bg-gray-100">
                <a
                  href={void 0}
                  className="mc_batchHolder flex items-center"
                  // onClick={() => setShowModalCircle(true)}
                >
                  <span className="mc_batch mr-3" style={{fill : `${(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true)? brandSetting.theme_color2 : brandSetting.theme_color1}`}}>{svg.app.bluebatchIcon}</span>
                  <span className="text-sm text-gray-900">
                    <b className="text-sm text-black font-medium flex items-center" style={{
                      color : `${(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true)? brandSetting.theme_color2 : brandSetting.theme_color1}`,
                      fill : `${(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true)? brandSetting.theme_color2 : brandSetting.theme_color1}`
                    }}>
                      {goal.goal_points} Points {(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true)? <span className='ml-2' style={{fill : `${(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true)? brandSetting.theme_color2 : brandSetting.theme_color1}`}}>{svg.app.greenTick}</span> : ''}
                    </b>
                  </span>
                 
                </a>
                <div className="mc_batchHolder flex items-center">
                  <a
                    href={()=> void(0)}
                    className={`flex mc_goal_text}`}
                    onClick={() => {
                      getRightModalPoints(`GoalList${goal.goal_id}`, goal);
                      
                      // setMyState(setQuery,{
                      //   showGoalSettingPopUp :true,
                      //   goalsettingData:goal,
                      // });
                    }}
                    style={{color : `${(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true) ? brandSetting.theme_color2 : brandSetting.theme_color1}`}}
                  >
                    {(state.goalListId == `GoalList${goal.goal_id}` && state.showGoalSettingPopUp == true) && goal.show==0 ? (
                      <span className="mr-2 mc_tick_themecolorSvg">{svg.app.greencircleIcon}</span>
                    ) : (
                      <span className="mr-2 mc_whiteCircle"></span>
                    )}
                    Yes! I took the action
                  </a>
                </div>
              </div>
            </div>
            )})
            :
            // <p>No goals available.</p>
            <NoDataWrapper
                        className="group relative bg-white box-shadow rounded-md mc_courseBox" 
                        blockCount="1" 
                        height="440"
                        width="1200" 
                        section="projectLoader"
                        title={'Goals not available.'}                       
                    />
          }
       
          {/* <div className="mc_goalList_box flex flex-wrap mb-8">
            <div className="flex flex-wrap w-full items-start mc_innerWhite_dv">
              <span className="rounded-full">
                <img
              
                  src={`/assets/images/Course2.jpg`}
                  className="rounded-full"
                  alt="studio"
                  style={{width:'92px',height:'92px',objectFit:'cover'}}
                />
              </span>
              <div className="mc_goalContent">
                <h3 className="text-sm text-black font-semibold">
                  The goal heading text will appear here
                </h3>
                <p className="text-gray-900 text-sm mt-4 max-w-80 line-height-20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut dabore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  saboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="mc_innerGray_dv flex flex-wrap justify-between w-full p-4 bg-gray-100">
              <a
                href={void 0}
                className="mc_batchHolder flex items-center"
                onClick={() => setShowModalBottomRight(true)}
              >
                <span className="mc_batch mr-3">{svg.app.batchPoints}</span>
                <span className="text-sm text-gray-900">
                  <b className="text-sm text-black font-medium flex items-center">
                    5x Points
                  </b>
                </span>
               
              </a>
              <div className="mc_batchHolder flex items-center">
                <a
                  href={void 0}
                  className={`flex mc_goal_text ${showModal && 'mc_active_goal'}`}
               
                  onClick={() => setShowModal(true)}
                >
                  {showModal == false ? (
                    <span className="mr-2 mc_whiteCircle"></span>
                  ) : (
                    <span className="mr-2">{svg.app.greencircleIcon}</span>
                  )}{' '}
                  Done it!
                </a>
              </div>
            </div>
          </div>
          <div className="mc_goalList_box flex flex-wrap mb-8">
            <div className="flex flex-wrap w-full items-start mc_innerWhite_dv">
              <span className="rounded-full">
                <img
               
                  src={`/assets/images/Course3.jpg`}
                  className="rounded-full"
                  alt="studio"
                  style={{width:'92px',height:'92px',objectFit:'cover'}}
                />
              </span>
              <div className="mc_goalContent">
                <h3 className="text-sm text-black font-semibold">
                  The goal heading text will appear here
                </h3>
                <p className="text-gray-900 text-sm mt-4 max-w-80 line-height-20 mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut dabore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  saboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <div className="mc_commentBox  max-w-80">
                  <p className="text-gray-900 text-base line-height-20">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut dabore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco saboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
            <div className="mc_innerGray_dv flex flex-wrap justify-between w-full p-4 bg-gray-100">
              <div className="mc_batchHolder flex items-center">
                <span className="mc_batch mr-3">{svg.app.greenStar}</span>
                <a
                  href={void 0}
                  className="text-sm text-gray-900"
               
                >
                  <b className="text-sm text-black font-medium flex items-center">
                    2x Points
                  </b>
                </a>
                
              </div>
              <div className="mc_batchHolder flex items-center">
                <a
                  href={void 0}
                  className={`flex mc_goal_text ${showModalTriangleModal && 'mc_active_goal'}`}
                  onClick={() => setShowModalTriangleModal(true)}
                >
                  {showModalTriangleModal == false ? (
                    <span className="mr-2 mc_whiteCircle"></span>
                  ) : (
                    <span className="mr-2">{svg.app.greencircleIcon}</span>
                  )}{' '}
                  Done it!
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
     

      {/* <!--TopRight Sidebar Modal--> */}
      <TEModal show={state.showGoalSettingPopUp} setShow={()=>setMyState(setQuery,{showGoalSettingPopUp:false,goalsettingData:{}})} className='mc_topRightSidebar mc_getPoints_wrapper'>
            <TEModalDialog
            position="top-right"
            theme={{
                show: "translate-x-0 opacity-100",
                hidden: "translate-x-[100%] opacity-0",
            }}
            className='mc_sidebarModal'
            >
                  <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() =>setMyState(setQuery,{showGoalSettingPopUp:false,goalsettingData:{}})} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                <TEModalContent>
                    {/* <!--Modal body--> */}
                    <TEModalBody className='mc_getPoints'>
                       
                        {/* <img src={`${alfaUrl}/images/greenBatch.png`} className='mb-3' alt="img" width={'85px'} height={'85px'}/> */}
                        <img src='/assets/images/greenBatch.png' alt='Icon' width={'85px'} height={'85px'}/>
                   
                        <p className='text-sm text-gray-500 font-semibold mt-3'>Total Points</p>
                        <h1 className='text-5xl'>{state.goalsettingData?.goal_points}</h1>
                        <p className='text-sm text-gray-500 font-semibold'>of 100</p>
                        <div className='mc_yellowBg_box'>
                            <span className='mt-3'>{svg.app.grayBatch}</span>
                            {/* <span className='mc_circleIcon'>{svg.app.videoStrem}</span> */}

                            <p className='text-sm text-gray-black font-semibold'>Still you have to get</p>
                            <div className='text-5xl text-gray-black font-semibold flex flex-wrap text-center items-center justify-center mc_fluidtext'>
                                {Math.round(100 - state.goalsettingData?.goal_points )}
                                <span className='text-sm text-gray-black font-semibold w-full'>Points</span>
                            </div>
                            <div className='mc_bottomContent mt-8'>
                                {/* <img src={`${alfaUrl}/images/batchIcon.png`}  alt="img" width={'85px'} height={'85px'}/> */}
                               <img src='/assets/images/baseStart.png' alt='Icon' width={'85px'} height={'85px'}/>
                                 <p className='text-sm text-gray-black font-semibold'>Earn points as you take action</p>
                            </div>
                        </div>
                    </TEModalBody>
                    
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        {/* <!-- BottomRight Circle Modal--> */}
        <TEModal show={showModalBottomRight} setShow={setShowModalBottomRight} className='mc_bottomRightSidebar mc_getPoints_wrapper'>
            <TEModalDialog
            position="bottom-right"
            theme={{
                show: "translate-x-0 opacity-100",
                hidden: "translate-x-[100%] opacity-0",
            }}
            className='mc_sidebarModal_circle'
            >
                <TEModalContent className='mc_modalContent'>
                    <div className='mc_rightManImg'>
                        {/* <img src={`${alfaUrl}/images/men1.png`} alt="img"/> */}
                    </div>
                    {/* <!--Modal body--> */}
                    <TEModalBody className='mc_getCirclePoints' style={{backgroundImage: `url(${alfaUrl}/images/particalPopup.png)`}}>
                        {/* <h1>UserPoints</h1> */}
                        {/* <img src={`${alfaUrl}/images/greenBatch.png`}  alt="img" width={'65px'} height={'75px'}/> */}
                        
                        <p className='text-sm text-gray-500 font-semibold'>Total Points</p>
                        <h1 className='text-5xl'>60</h1>
                        <p className='text-sm text-gray-500 font-semibold'>of 100</p>
                        <div className='mc_bottomContent mt-12'>
                            {/* <img src={`${alfaUrl}/images/baseStart.png`}  alt="img" width={'85px'} height={'85px'}/> */}
                            <p className='text-sm text-gray-black font-semibold mt-2'>Earn points as you take action</p>
                        </div>
                    </TEModalBody>
                    
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        {/* <!-- BottomRight Circle Modal--> */}
        <TEModal show={showModalCircle} setShow={setShowModalCircle} className='mc_bottomRightSidebar mc_getPoints_wrapper'>
            <TEModalDialog
            position="bottom-right"
            theme={{
                show: "translate-x-0 opacity-100",
                hidden: "translate-x-[100%] opacity-0",
            }}
            className='mc_modalRight_circle'
            >
                <TEModalContent className='mc_modalContent'>
                    {/* <!--Modal body--> */}
                    <div className='mc_rightManImg mc_rightImg_modal'>
                        {/* <img src={`${alfaUrl}/images/pop1.png`} alt="img"/> */}
                    </div>
                    <TEModalBody className='mc_getCirclePoints' style={{backgroundImage:`url(${alfaUrl}/images/particle21.png`}}>
                        
                        <div className='mc_congrats_dv' style={{backgroundImage:`url(${alfaUrl}/images/particel12.png`}}>
                            <span>{svg.app.tickGreenIcon}</span>
                            <h2 className='mc_modalTitle'>Congratulations!</h2>
                            <p>You have achieved</p>
                            <span className='mc_getPointsNo'>200</span>
                            <p>points for the next level badge</p>
                            {/* <img src={`${alfaUrl}/images/batch1.png`} alt='batch' width={'250px'} height={'220px'}/> */}
                            <div className='mb-6'>
                                <a href={() => false} className='mc_whiteBtn'>Collect My Badge</a>
                            </div>
                        </div> 
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        {/* <!-- BottomRight Triangle Modal--> */}
        <TEModal show={showModalTriangleModal} setShow={setShowModalTriangleModal} className='mc_bottomTriangleModal mc_getPoints_wrapper'>
            <TEModalDialog
            position="bottom-right"
            theme={{
                show: "translate-x-0 opacity-100",
                hidden: "translate-x-[100%] opacity-0",
            }}
            className='mc_sidebarModal_Triangle'
            >
                <TEModalContent className='mc_modalContent'>
                    <div className='mc_rightManImg'>
                        {/* <img src={`${alfaUrl}/images/men1.png`} alt="img"/> */}
                    </div>
                    <TEModalBody className='mc_getCirclePoints' style={{backgroundImage: `url(${alfaUrl}/images/particalPopup.png)`}}>
                        {/* <img src={`${alfaUrl}/images/greenBatch.png`}  alt="img" width={'65px'} height={'75px'} /> */}
                        
                        <p className='text-sm text-gray-500 font-semibold mt-3'>Total Points</p>
                        <h1 className='text-5xl'>60</h1>
                        <p className='text-sm text-gray-500 font-semibold'>of 100</p>
                        <div className='mc_bottomContent mt-12'>
                            {/* <img src={`${alfaUrl}/images/baseStart.png`}  alt="img" width={'85px'} height={'85px'}/> */}
                            <p className='text-sm text-gray-black font-semibold'>Earn points as you take action</p>
                        </div>

                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>


        <TEModal show={showModal} setShow={setShowModal} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowModal(false)} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    {/* <!--Modal body--> */}
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='text-center w-full mc_ratedModal'>
                                <h2 className='text-black font-semibold text-2xl mb-2'><span className='color-themeColor mr-2'>Hello!</span>Rate your course</h2>
                                <p className='text-gray-400 font-medium text-sm'>Your ratings will be helpful for us to improve our course quality</p>
                                <h3 className='text-base font-semibold text-black mt-8 mb-4'>Expectations Met?</h3>
                                <div className='flex flex-wrap justify-center items-center mc_emojyList'>
                                    <a href={() => false} className={`mc_grayBtn flex flex-wrap justify-btween items-center ${state.rating == 'Exceeded' ? 'isActiveRate' : ''}`} onClick={(e)=> getRatedData('Exceeded')}>
                                        <span>{svg.app.nounEmojiExceeded}</span>
                                        <span className='text-gray-500 font-medium text-sm'>Exceeded</span>
                                    </a>
                                    <a href={() => false} className={`mc_grayBtn flex flex-wrap justify-btween items-center ${state.rating == 'Yes' ? 'isActiveRate' : ''}`} 
                                        onClick={(e)=> getRatedData('Yes')}>
                                        <span>{svg.app.nounEmojiYes}</span>
                                        <span className='text-gray-500 font-medium text-sm'>Yes</span>
                                    </a>
                                    <a href={() => false} className={`mc_grayBtn flex flex-wrap justify-btween items-center ${state.rating == 'Somewhat' ? 'isActiveRate' : ''}`} onClick={(e)=> getRatedData('Somewhat')}>
                                        <span>{svg.app.nounEmojiSomewhat}</span>
                                        <span className='text-gray-500 font-medium text-sm'>Somewhat</span>
                                    </a>
                                    <a href={() => false} className={`mc_grayBtn flex flex-wrap justify-btween items-center ${state.rating == 'Not Really' ? 'isActiveRate' : ''}`} onClick={(e)=> getRatedData('Not Really')}>
                                        <span>{svg.app.nounEmojiNotReally}</span>
                                        <span className='text-gray-500 font-medium text-sm'>Not Really</span>
                                    </a>
                                </div>
                                <div className='mt-4 w-full text-center'>
                                {state.rating != '' ?  
                                    <span className='w-full text-center flex flex-wrap justify-center'>{svg.app.ratingStarsColored}</span> 
                                : 
                                    <span className='w-full text-center flex flex-wrap justify-center'>{svg.app.ratingStars}</span> 
                                }
                                <h3 className='text-base font-semibold text-black mt-8 mb-4 w-full'>What are the main reasons for your rating?</h3>
                                    <div className="w-full">
                                        {/* <textarea className='mc_commentBox text-gray-900 text-base line-height-20 w-full' rows={'5'} placeholder='Type your comments..'
                                            value={(state.rating != '') && 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut dabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco saboris nisi ut aliquip ex ea commodo consequat.'}
                                        >

                                        </textarea> */}

                                        <div className='mc_btnHolder mt-5'>
                                            <button className={`mc_grayBtn_submit ${state.rating != '' ? 'isActiveBtn' : ''}`}>Submit Your Review</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2></h2>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>


{/* Goal setting audio video popup */}
        {/* <TEModal show={state.showGoalSettingPopUp} setShow={()=>setMyState(setQuery,{showGoalSettingPopUp:false,goalsettingData:{}})} scrollable className='mc_modalHolder'>
            <TEModalDialog centered className='mc_modalDialog'>
                <TEModalContent className='mc_modalContent'>
                    <a href={() => false} className="mc_modalCloseIcon box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() =>setMyState(setQuery,{showGoalSettingPopUp:false,goalsettingData:{}})} aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </a>
                    <TEModalBody >
                        <div className='mc_modalBody'>
                            <div className='mc_modalInnerContent flex flex-wrap items-center'>
                                <div className='mc_moadlImageGoals'>
                                  { 
                                  state.goalsettingData?.goal_info_type == 1 ? <audio src={state.goalsettingData.goal_audio_url} controls></audio> : state.goalsettingData?.goal_info_type == 2 ? 
                                  <>
                                  <div className='mc_goalModalCoverImage' style={{
                                    backgroundImage : `url(${state.goalsettingData?.goal_cover_image})`,
                                  }}>
                                    <div className='mc_goalModalBannerImage' style={{
                                      backgroundImage : `url(${state.goalsettingData?.goal_banner_image})`,
                                    }}>
                                    </div>
                                  </div>
                                  <iframe width="830" height="315" src={state.goalsettingData?.goal_video_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen controls></iframe>
                                  
                                  </>
                                  : 'No Data found!!' 
                                }
                                </div>
                             
                            </div>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal> */}

     
    </>
  )
}

export default Goals

// import headerLogo from './header-logo.svg'
// import Course1 from './Course1.jpg'
// import Course2 from './Course2.jpg'
// import Course3 from './Course3.jpg'
// import Course4 from './Course4.jpg'
// import Course5 from './Course5.jpg'
// import Course6 from './Course6.jpg'
// import Course7 from './Course7.jpg'
// import Course8 from './Course8.jpg'
// import mainLogo from './logo.png'
// import profileImg from './profile.jpeg'

// import facebookIcon from './facebookIcon.png'
// import instagramIcon from './instagramIcon.png'
// import linkedinIcon from './linkedinIcon.png'
// import twitterIcon from './twitterIcon.png'
// // import pinterestIcon from './pinterestIcon.png'

// import libraryImg1 from './libraryimg1.jpg'
// import libraryImg2 from './libraryimg2.jpg'
// import libraryImg3 from './libraryimg3.jpg'
// import libraryImg4 from './libraryimg4.jpg'
// import bgImage from './bg.jpg'

let headerLogo = "/assets/images/libraryimg1.jpg";
let Course1 = "/assets/images/libraryimg1.jpg";
let Course2 = "/assets/images/libraryimg1.jpg";
let Course3 = "/assets/images/libraryimg1.jpg";
let Course4 = "/assets/images/libraryimg1.jpg"; 
let Course5 = "/assets/images/libraryimg1.jpg"; 
let Course6 = "/assets/images/libraryimg1.jpg"; 
let Course7 = "/assets/images/libraryimg1.jpg"; 
let Course8 = "/assets/images/libraryimg1.jpg";
let mainLogo = "/assets/images/libraryimg1.jpg";
let profileImg = "/assets/images/libraryimg1.jpg";
let facebookIcon = "/assets/images/libraryimg1.jpg"; 
let instagramIcon = "/assets/images/libraryimg1.jpg"; 
let linkedinIcon = "/assets/images/libraryimg1.jpg"; 
let twitterIcon = "/assets/images/libraryimg1.jpg"; 
let libraryImg1 = "/assets/images/libraryimg1.jpg"; 
let libraryImg2 = "/assets/images/libraryimg1.jpg";
let libraryImg3 = "/assets/images/libraryimg1.jpg";
let libraryImg4 = "/assets/images/libraryimg1.jpg";
let bgImage = "/assets/images/libraryimg1.jpg"; 
let customer1 = "/assets/images/customer1.jpeg";

export {
    headerLogo,
    
    Course1,
    Course2,
    Course3,
    Course4, 
    Course5, 
    Course6, 
    Course7, 
    Course8,

    mainLogo,
    profileImg,

    facebookIcon, 
    instagramIcon, 
    linkedinIcon, 
    twitterIcon, 
    // pinterestIcon, 
    libraryImg1, 
    libraryImg2,
    libraryImg3,
    libraryImg4,
    bgImage,
    customer1,
}

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { appStore } from "../../zustand/store";
import { common, setMyState, authAction, dataLoader } from "../Comman";
import svg from "../svg";
import config from "../../config";
import io from "socket.io-client";
import EmojiBox from "../../../src/emoji/EmojiBox";
import { toast } from "react-toastify";

const apiUrl = config.REACT_CHAT_APIURL;

function ChatsEvents(props) {

  const navigator = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (!id) {
      setChatlock(false);
      setsenduser("");
      sessionStorage.setItem("chatid", "");
      sessionStorage.setItem("chatkey", "");
    }
  }, [id]);

  const socket = props.socekt;
  const [mload, setmload] = useState(false);
  const [chatcount, setchatcount] = useState(0);
  const [messagecount ,setmessagecount]=useState(0)
  const [userpag, setuserpag] = useState({
    page: 1,
    limit: 10,
  });
  const [msgpag, setmsgpag] = useState({
    page: 1,
    limit: 10,
  });
  let alfaUrl = config.REACT_APP_BUCKET_URL;
  let myStore = appStore((state) => state);
  let AttendeeUserdata = myStore.userData;
  let brandSetting = myStore.brandSetting;
  const [loader, setloader] = useState("");
  const [chatlock, setChatlock] = useState(false);
  const [state, setQuery] = useState({
    bodyColor: "#F7f7f7",
    themeColor: "#28E9BE",
    textColor: "#4A5251",
    hoverColor: null,
    attendanceListData: AttendeeUserdata,
    showProfile: false,
    AttendeeList: [],
    toggleChat: false,
    showChatbox: false,
    dataLoading: true,
  });
  let getHover1 = (e) => {
    setMyState(setQuery, {
      hoverColor: e,
    });
  };
  let getHover2 = (e) => {
    setMyState(setQuery, {
      hoverColor: null,
    });
  };
  const getAttendeeProfile = () => {
    setMyState(setQuery, {
      showProfile: !state.showProfile,
    });
    navigator("/events/attender-profile");
  };
  
  const [file, setfile] = useState(null);
  const [users, setuser] = useState([]);
  const [modal, setmodal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [messagelist, setmessagelist] = useState([]);
  const [sndPerMes, setPermes] = useState("");
  const [senduser, setsenduser] = useState("");
  const [currentchatkey, setchatkey] = useState("");
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [refresh, setrefresh] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("chatid", "");
    sessionStorage.setItem("chatkey", "");
    checkCount();
  }, []);

  useEffect(() => {
    let c1 = document.getElementById("mainfooter");
    let header = document.getElementById("mainHeaderMenu");
    if (senduser) {
      if (!c1.classList.contains("hidden")) {
        c1.classList.add("hidden");
        header.classList.add("hidden");
      }
    } else {
      if (c1.classList.contains("hidden")) {
        c1.classList.remove("hidden");
        header.classList.remove("hidden");
      }
    }
  }, [senduser]);
  const checkCount = () => {
    setuser((d11) => {
      let c1 = d11.filter((items) => {
        let id = items._id;
        if (items.unreadCounts[id]) {
          return items;
        }
      });
      props.chatCount(c1.length);
      return d11;
    });
  };
  useEffect(() => {
    updatekey(socket.id);
    if (socket != "") {
      socket.on("connect", function () {
        console.log("socket.id", socket.id);
      });
      socket.on("users", (user) => {
        let data = [...user];
        // console.log({ data });
        setuser((u1) => {
          let d1 = [...u1];
          // console.log({ d1 });
          for (let i = 0; i < d1.length; i++) {
            let c1 = data.filter((d2) => d2._id == d1[i]._id);
            if (c1.length > 0) {
              if (sessionStorage.getItem("chatkey") == d1[i]._id) {
                sessionStorage.setItem("chatid", c1[0].chatId);
                setchatkey(c1[0].chatId);
              }
              d1[i].status = "online";
              d1[i].chatId = c1[0].chatId;
            } else {
              d1[i].status = "offline";
            }
          }
          return d1;
        });
      });
      socket.on("seenmessagetime", (data, id) => {
        if (id == localStorage.getItem("id")) {
          setmessagelist((existingItems) => {
            return existingItems.map((element, j) => {
              if (element?.seen == 0) {
                return {
                  ...element,
                  seen: 1,
                };
              } else {
                return element;
              }
            });
          });
        }
      });

      socket.on("typeing", (data) => {
        if (data.id == sessionStorage.getItem("chatkey")) {
          setIsTyping(true);
        }
      });

      socket.on("stop_typeing1", () => {
        setIsTyping(false);
      });

      socket.on("receive_message1", (data) => {
        // console.log(
        //   data,
        //   ",,,,,",
        //   data.alldata.senderid,
        //   sessionStorage.getItem("chatkey")
        // );
        if (data.alldata.senderid == sessionStorage.getItem("chatkey")) {
          setChatlock(false);
          setuser((d11) => {
            let l = d11.findIndex((d1) => d1._id == data.insert.SenderId);
            if (d11[l]) {
              d11[l].lastText.text = data.insert.Text;
              d11[l].unreadCounts = {};
            }
            return d11;
          });
          setmessagelist((list) => {
            return [...list, { ...data.alldata.id, seen: 1 }];
          });
          socket.emit("seenmessage", data, sessionStorage.getItem("chatid"));
          let myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              mid: data.alldata?.id?._id,
            }),
            redirect: "follow",
          };
          let element = document.getElementById("#element");
          if (element) {
            element.scrollTop = element.scrollHeight + 100;
          }
          fetch(apiUrl + "seenmessage", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {
              // console.log({result})
            })
            .catch((error) => console.log("error", error));
        } else {
          setuser((d11) => {
            let l = d11.findIndex((d1) => d1._id == data.insert.SenderId);
            // console.log({ l }, "index", d11[l]);
            if (d11[l]) {
              d11[l].lastText.text = data.insert.Text;
              d11[l].unreadCounts = {
                [d11[l]._id]: d11[l]?.unreadCounts[d11[l]._id]
                  ? d11[l]?.unreadCounts[d11[l]._id] + 1
                  : 1,
              };
            }
            let a = [...d11];
            let c1 = d11.filter((items) => {
              let id = items._id;
              // console.log(
              //   "items.unreadCounts[items._id]",
              //   items.unreadCounts[id]
              // );
              if (items.unreadCounts[id]) {
                return items;
              }
            });
            props.chatCount(c1.length);
            return a;
          });
        }
      });

      socket.on("seenmessage", (data) => {
        setmessagelist((existingItems) => {
          return existingItems.map((element, j) => {
            return {
              ...element,
              seen: 1,
            };
          });
        });
      });
    }
  }, []);

  const getAllUserData = (load = null, page = 1) => {
    if (load == null) {
      setMyState(setQuery, {
        dataLoading: true,
      });
    }
    setloader("isopen");
    let requestOptions = {
      method: "GET",
      headers: {},
      redirect: "follow",
    };
    fetch(
      apiUrl +
        `getAllUserData?type=converstion&id=${localStorage.getItem(
          "id"
        )}&page=${page}&limit=${userpag.limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let ndata = [];
        setchatcount(result?.count)
        result.data.forEach((element) => {
          let u1 = element.data.find(
            (c1) => c1._id != localStorage.getItem("id")
          );
          let p1 = {
            cid: element._id,
            ...u1,
            lastText: element.lastText,
            unreadCounts: element.unreadCounts,
          };
          ndata.push(p1);
        });
        if (load == null) {
          setuser(ndata);
          setMyState(setQuery, {
            dataLoading: false,
          });
        } else {
          setuser([...users, ...ndata]);
        }
        setloader("");
        socket.emit("fetchuser", {
          id: localStorage.getItem("id"),
        });
      })
      .catch((error) => console.log("error", error));
  };

  const typingHandler = (e) => {
    if (e.target.value == "") {
      return;
    }
    if (!typing) {
      setTyping(true);
      socket.emit("typing", {
        id: localStorage.getItem("id"),
        room: currentchatkey,
      });
    }
    var timerLength = 3000;
    setTimeout(() => {
      if (typing) {
        socket.emit("stop_typing", {
          id: localStorage.getItem("id"),
          room: currentchatkey,
        });
        setTyping(false);
      }
    }, timerLength);
  };

  const selectuser = (i) => {
    let myHeaders = new Headers();
    setCurrentUser({
      name: i?.name,
      designation: i?.designation,
      profile_img: i?.profile_img,
    });
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      sid: i._id,
      rid: localStorage.getItem("id"),
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setsenduser(i._id);
    sessionStorage.setItem("chatid", i.chatId);
    sessionStorage.setItem("chatkey", i._id);
    setchatkey(i.chatId);
    setuser((d11) => {
      let l = d11.findIndex((d1) => d1._id == i._id);
      if (d11[l]) {
        d11[l].unreadCounts = {};
      }
      return d11;
    });
    fetch(apiUrl + "recevemessage", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (
          result?.chatLock &&
          result?.allowMessage == 0 &&
          result?.id == localStorage.getItem("id")
        ) {
          setChatlock(result.chatLock);
        }
        const reversed = result.data.reverse();
        setmessagecount(result?.count)
        setmessagelist(result.data);
        setmodal(true);

        socket.emit("seenmessagetime", i.chatId, i._id);
        setTimeout(() => {
          let element = document.getElementById("#element");
          if (element) {
            element.scrollTop = element.scrollHeight + 100;
          }
        }, 50);
        setTimeout(() => {
          setloader("");
        }, 1000);
        checkCount();
      })
      .catch((error) => console.log("error", error));
  };
  const send = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let url = "";
    let input = document.querySelector("#postContent");
    if (file == null && input.value == "") {
      return;
    }
    if (file) {
      const formdata = new FormData();
      formdata.append("file", file);
      const requestOptions1 = {
        method: "POST",
        body: formdata,
      };
      await fetch(apiUrl + "fileUpload", requestOptions1)
        .then((response) => response.json())
        .then((result) => {
          url = result.url;
        })
        .catch((error) => console.error(error));
    }

    let raw = {
      sender: localStorage.getItem("id"),
      recever: senduser,
      message: input.value,
    };
    if (url) {
      raw = {
        ...raw,
        media_url: url,
      };
    }
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(apiUrl + "sendmessage", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.status) {
          toast.error(result?.messge);
          return;
        }
        let insert = result.data;
        let myid = result.data2;
        let alldata = {
          senderid: localStorage.getItem("id"),
          recever: senduser,
          messge: input.value,
          seen: 0,
          id: insert,
          media_url: url,
        };
        let d = [...messagelist, insert];
        let m1 = d.filter((r) => r.SenderId === localStorage.getItem("id"));
        if (d.length == 2 && m1.length == 2) {
          setChatlock(true);
        }
        setuser((d11) => {
          let l = d11.findIndex((d1) => d1._id == senduser);
          if (d11[l]) {
            d11[l].lastText.text = input.value;
            d11[l].unreadCounts = {};
          }
          return d11;
        });
        setmessagelist(d);
        socket.emit("private_send_message", {
          sndPerMes,
          currentchatkey,
          alldata,
          insert,
          myid,
        });
        setTimeout(() => {
          input.value = "";
        }, 100);
        let element = document.getElementById("#element");
        if (element) {
          element.scrollTop = element.scrollHeight + 100;
        }
        setPermes("");
      })
      .catch((error) => console.log("error", error));
  };
  const updatekey = (key) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      id: localStorage.getItem("id"),
      key: key,
    });
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(apiUrl + "updatekey", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == true) {
          getAllUserData();
          // setTimeout(() => {

          // }, 100);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const fileDownload = (url) => {
    let aElement = document.createElement("a");
    aElement.href = url;
    aElement.setAttribute("target", "_blank");
    aElement.click();
  };

  let fetchData = (e, cb, rev = null) => {
    let elem = document.getElementById(e.target.id);
    let targetScrollHeight = elem.scrollHeight - elem.clientHeight;
    console.log({loader})
    if ((targetScrollHeight-220) <= elem.scrollTop && rev == null && loader=="") {
      
      if(users.length<chatcount)
      {
        setuserpag({
          ...userpag,
          page: parseInt(userpag.page) + 1,
        });
        cb("load", parseInt(userpag.page) + 1);
      }
     
    }

    if (rev != null) {
      if (elem.scrollTop == 0) {
        let c1 = parseInt(msgpag.page) + 1;
        if(messagelist.length<messagecount)
        {
          setmload(true);
          setmsgpag({
            ...msgpag,
            page: c1,
          });
          cb("load", c1);
        }

      }
    }
  };

  const messageLoad = (d1,p1) => {
  

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      sid: senduser,
      rid: localStorage.getItem("id"),
      page: p1,
      limit: userpag.limit,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(apiUrl + `recevemessage`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.data && result.data.length) {
          let d1 = messagelist.length;
          const reversed = result.data.reverse();
          setmessagelist([...reversed, ...messagelist]);
          setmessagecount(result?.count)
          let d3 = document.getElementById("message_" + messagelist[0]._id);
          if (d3) {
            d3.scrollIntoView();
          }
        }
        setTimeout(() => {
          setmload(false);
        }, 3000);
      });
  };

  function formatAMPM(date) {
    var todaysDate = new Date();
    date = new Date(date);
    if (date.toDateString() != todaysDate.toDateString()) {
      return common.dateFormatter(date);
    }
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  return (
    <>
      <div id="eventPageLoader" className={`mainPageLoader eventLoader ${loader}`}>
        <div className='mc_mainLoader_dv'>
          <div className="mc_isLoaderDesign"></div>
        </div>
      </div>
      <div
        className={`mc_eventBody ${senduser != "" ? "bg_whatsApp" : ""}`}
        style={{ backgroundColor: state.bodyColor, color: state.textColor }}
      >
        {senduser != "" && (
          <div className="mc_backBtn_dv">
            <a
              onClick={() => {
                setmsgpag({
                  page: 1,
                  limit: 10,
                });
                setChatlock(false);
                setsenduser("");
                sessionStorage.setItem("chatid", "");
                sessionStorage.setItem("chatkey", "");
                navigator("/events/chats");

              }}
              id="gotoBack_dv"
            >
              <span style={{ fill: state.themeColor }}>
                {svg.app.backArrowBtn}{" "}
              </span>{" "}
              <span className="ml-2"></span>
            </a>
          </div>
        )}

        <div className="mc_componentData">
          <div className={`mc_headTitle_dv ${state.showChatbox != true ? "padd_none" : ''}`}>
            {state.showChatbox == true ? (
              <a
                href={void 0}
                className="flex mr-3 items-center"
                style={{ fill: state.themeColor }}
                onClick={() =>
                  setMyState(setQuery, {
                    showChatbox: false,
                  })
                }
              >
                {svg.app.backArrowBtn} <span className="ml-2"></span>
              </a>
            ) : (
              <h2 style={{ color: state.themeColor }}>
                {senduser == "" && props.title}{" "}
                <span
                  className="mc_titleCount"
                  style={{ color: state.themeColor }}
                ></span>
              </h2>
            )}
          </div>

          <div className="message-area">
            {/* <a href={void(0)} >{svg.app.backArrowBtn}</a> */}
            <div className="chat-area mc_chatmsg_box">
              {state.dataLoading
                ? [...Array(3)].map((count) => (
                    <div key={count}>{dataLoader.eventLoader}</div>
                  ))
                : senduser == "" &&
                  users.length == 0 && (
                    <div class="mc_eventListHolder">
                      <div className="mc_congrats_dv">
                        <div className="mc_completeModal text-center">
                          <div>{svg.app.nodataFound}</div>
                          <h4 className="text-xl font-bold my-3">
                            No chats found!
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}

              {/* <!-- chatlist --> */}
              {senduser == "" ? (
                <div
                  className="mc_eventListHolder mc_scrollBar_inner"
                  id="scroller"
                  onScroll={(e) => {
                    fetchData(e, getAllUserData);
                  }}
                >
                  <div className={`chatlist`}>
                    <div className={`chat-lists`}>
                      {users?.map((items, index) => {
                        let count = null;
                        if (items.unreadCounts) {
                          count = items.unreadCounts[items._id];
                        }

                        return (
                          <div
                            className="chat-list"
                            onClick={() => {
                              selectuser(items);
                              navigator("/events/chats/" + items._id);
                            }}
                          >
                            <a
                              href={void 0}
                              className="flex align-items-center"
                            >
                              {items?.profile_img ? 
                              <div className="flex-shrink-0 mc_profileBg_img" style={{backgroundImage: `url(${items?.profile_img})`}}>
                                {/* <img
                                  className="img-fluid circle"
                                  src={
                                    items?.profile_img ||
                                    `${alfaUrl}/images/user.png`
                                  }
                                  alt="user img"
                                  width={"52px"}
                                /> */}
                                <span
                                  className={
                                    items.status == "online" ? "active" : ""
                                  }
                                ></span>
                              </div>
                              : 
                                <div className='mc_alfaProfile_img'>{AttendeeUserdata?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
                              }
                              <div className="flex-grow-1 ms-3 mc_chatContent_holder">
                                <div className="mc_chatUser_content">
                                  <h3>
                                    {items.name}{" "}
                                    {count && (
                                      <span className="mc_countUnread">
                                        {count}
                                      </span>
                                    )}
                                  </h3>
                                  <p>{items?.designation}</p>
                                  <p>
                                    {items.lastText?.text} {}
                                  </p>
                                </div>
                                <span
                                  className={`mc_ChatIcon_list ${
                                    items.status == "online"
                                      ? "isActiveUser"
                                      : "offline_user"
                                  }`}
                                >
                                  {svg.app.chatListIcon}
                                  <i className="isOnline_user"></i>
                                </span>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                // <div className={`chatlist ${state.toggleChat == true  ? "showUserList mobileHead" : 'showUserList'}`}>
                //     <div className="modal-dialog-scrollable">
                //         <div className="modal-content">

                //             <div className={`chat-lists`}>
                //                 <div className="chat-list">
                //                     <a href={void(0)}  className="flex align-items-center">
                //                         <div className="flex-shrink-0">
                //                             <img className="img-fluid rounded" src={`/assets/images/eventsimg/user1.jpg`} alt="user img" width={'45px'}/>
                //                             <span className="active"></span>
                //                         </div>
                //                         <div className="flex-grow-1 ms-3">
                //                             <h3>Mehedi Hasan</h3>
                //                             <p>front end developer</p>
                //                         </div>
                //                     </a>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>

                <div className="chatbox showbox">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="msg-head">
                        <div className="row">
                          <div className="col-8">
                            <div className="flex align-items-center">
                              {currentUser?.profile_img ? 
                              <div className="flex flex-shrink-0 mc_profileBg_img" style={{backgroundImage: `url(${currentUser?.profile_img || `${alfaUrl}/images/user.png`})`}} />
                              : <div className='mc_alfaProfile_img'>{AttendeeUserdata?.ticket_code.substring(0, 3).toLowerCase() == 'ucs'  ? svg.app.defualtUCSIcon  : svg.app.defualtIcon}</div>
                              }
                              {/* <img
                                className="img-fluid rounded"
                                src={
                                  currentUser?.profile_img ||
                                  `${alfaUrl}/images/user.png`
                                }
                                alt="user img"
                                width={"45px"}
                              /> */}
                              <div className="flex-grow-1 ms-3">
                                <h3>{currentUser?.name}</h3>
                                <p>{currentUser?.designation}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="#element"
                      onScroll={(e) => {
                        fetchData(e, messageLoad, "rev");
                      }}
                      className="modal-body"
                    >
                      <div className="msg-body">
                        <ul>
                          {mload && (
                            <li className="mc_chatLoader">
                              Loader{" "}
                              <div className="mc_loadMore_fv">
                                <span></span>
                              </div>
                            </li>
                          )}
                          {messagelist &&
                            messagelist.map((i, j) => {
                              return (
                                <>
                                  <li
                                    id={"message_" + i._id}
                                    className={
                                      localStorage.getItem("id") == i.SenderId
                                        ? "repaly"
                                        : "sender"
                                    }
                                  >
                                    <p>
                                      {" "}
                                      {i?.MediaMeta?.url && (
                                        <div
                                          className="chatbox_file"
                                          onClick={() => {
                                            fileDownload(i?.MediaMeta?.url);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/file.png"
                                            alt="Img File"
                                            width={"50px"}
                                          />
                                          File
                                        </div>
                                      )}
                                      {i.Text}
                                      {localStorage.getItem("id") ==
                                        i.SenderId && (
                                        <>
                                          {i.seen == 1 ? (
                                            <span className="sendTick doubleTick">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="20"
                                                height="20"
                                                x="0"
                                                y="0"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M10.367 14.423a.6.6 0 0 1 0-.848l6.712-6.712a.6.6 0 0 1 .848.848l-6.711 6.712a.6.6 0 0 1-.849 0zm12.184-7.56a.6.6 0 0 0-.848 0L11.2 17.37l-4.026-4.021a.6.6 0 0 0-.849.848l1.71 1.71-1.463 1.463-4.021-4.021a.6.6 0 0 0-.851.851l4.445 4.446a.6.6 0 0 0 .849 0l1.887-1.888 1.887 1.888a.6.6 0 0 0 .849 0L22.551 7.711a.6.6 0 0 0 0-.848z"></path>
                                              </svg>
                                            </span>
                                          ) : (
                                            <span className="sendTick">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="20"
                                                height="20"
                                                x="0"
                                                y="0"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M18.488 6.43a.75.75 0 0 1 .081 1.058l-8.571 10a.75.75 0 0 1-1.139 0l-3.428-4a.75.75 0 1 1 1.138-.976l2.86 3.336 8.002-9.336a.75.75 0 0 1 1.057-.081z"></path>
                                              </svg>
                                            </span>
                                          )}
                                        </>
                                      )}
                                      <span className="time">
                                        {formatAMPM(i.CreateAt)}
                                      </span>
                                    </p>
                                  </li>
                                </>
                              );
                            })}
                          {istyping && (
                            <li>
                              <div class="chat-bubble">
                                <div class="typing">
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                  <div class="dot"></div>
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {!chatlock ? (
                      <div className="send-box">
                        <form action="">
                          <div className="input_holder relative">
                            <textarea
                              type="text"
                              id="postContent"
                              className="mc_input mc_textarea"
                              aria-label="message…"
                              onChange={(e) => typingHandler(e)}
                              placeholder="Write message…"
                            />
                            <span>
                              <EmojiBox
                                targetInputId="#postContent"
                                parentEmojiBoxclassName={"emojiBoxParent"}
                                boxPlacement={"left"}
                              />
                            </span>
                          </div>

                          <button
                            onClick={() => {
                              send();
                            }}
                            type="button"
                            className="mc_btn text-center center"
                          >
                            {svg.app.sendPapperIcon}
                          </button>
                        </form>
                      </div>
                    ) : (
                      <div className="donnotmessage text-black text-center">
                        You can send a maximum of two messages till you get a
                        response. Please Wait till you receive a response. You
                        will be able to continue chatting without limits, Once
                        you receive a Response.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatsEvents;

'use client';

import React, {useState , useCallback, useEffect} from 'react';
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {common, setMyState, authAction} from '../../components/Comman';
import { appStore } from '../../zustand/store';
import Cookies from "js-cookie";

const AutoLogin=()=> {

    const slugName = window.location.href.split('/')[4];
    const navigate = useNavigate();

    let myStore = appStore(state => state);
    let appData = myStore.appData;
    let brandSetting = myStore.brandSetting;

    let tokenCookie = Cookies.get(`access_token_${slugName}`)? Cookies.get(`access_token_${slugName}`): false;

    // console.log(tokenCookie, brandSetting, appData, myStore, navigate);
    useEffect(()=>{
        common.getAPI({
            method: "Post",
            url: "autologin",
            data: tokenCookie,
        },(resp) => {
            // if(resp.success == true){
            //     setTimeout({
            //         // navigate('/dashboard');
            //     }, 3000)
            // }
        });
    },[])
    return (
        <>
            <Helmet>
                <title>Automatic Login</title>
            </Helmet>

            <div className='mc_authloginPage'>
                {/* <h1>Automatic Login</h1> */}
                <div class="mc_waveCenter">
                    <div className='mc_textProcess'>Processing </div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                </div>
            </div>
        </>
    )
}

export default AutoLogin;
import Cookies from "js-cookie";
import { appStore } from '../zustand/store';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthPage from '../pages/auth/[auth]';
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import ResetPassword from "../pages/auth/resetpassword";
import { MobileView, isMobile } from 'react-device-detect';
import {
    TEDropdown,
    TEDropdownToggle,
    TEDropdownMenu,
    TEDropdownItem,
    TERipple,
} from "tw-elements-react"
import svg from "./svg";
import config from "../config";
import { common, setMyState } from './Comman';
import EventLogin from "../pages/events/eventAuth";
import EventRoutes from "../pages/events/eventRoutes";
import { Helmet } from "react-helmet";
import { Base64 } from 'js-base64';

let Layout = function ({ children }) {
    const slugName = window.location.href.split('/')[4];
    let myStore = appStore(state => state);
    let { access_token = false } = myStore.userData, //reduxData
        accToken = Cookies.get(`access_token_${slugName}`) || false, //cookies token
        isLogin = accToken && access_token === accToken,
        currentPath = useLocation().pathname,
        isAuthPage = currentPath == '/',
        isRestricted = currentPath != '/';

    let alfaUrl = config.REACT_APP_BUCKET_URL
    let appData = myStore.appData;
    let brandSetting = myStore.brandSetting;
    const navigate = useNavigate();
    const location = useLocation();
    const [switchMember, setswitchmember] = useState([])
    const [switchButton, setSwitchButton] = useState(false);
    const [switchToggle, setSwitchToggle] = useState(false)

    const [state, setQuery] = useState({
        funnel_id: '',
        buttonColor: null,
        mobileDevice: false,
    })

    useEffect(() => {
        //   console.log(slugName, accToken, 'slugNameslugName');

        if (accToken && slugName) {
            const decoded = jwtDecode(accToken);
            // console.log(Date.now() , decoded.exp * 1000,"Date.now() >= decoded.exp * 1000")
            if (Date.now() >= decoded.exp * 1000) {
                Cookies.remove(`access_token_${slugName}`);
                myStore.logout()
                navigate("/login")
                return
            }
        } else{
            if (isLogin && isAuthPage) {
                navigate("/dashboard")
                return
            }
        }


    }, [])

    //  console.log(currentPath, 'currentPathcurrentPath');
    useEffect(() => {
        let data = localStorage
        let d4 = []
        let currentslug = window.location.href?.split("/")[4]
        // console.log(currentslug, 'currentslug');
        if (data) {
            Object.keys(data).forEach((dat, i) => {
                let d2 = {}
                if (dat.includes("course-storage_")) {
                    if (data[dat]) {
                        let dm = JSON.parse(data[dat])
                        if (dm?.state?.appData?.name) {
                            let host = window.location.href                      
                            let slugNamurl = host.split('/')
                           
                            slugNamurl[4] = dat.replace("course-storage_", '')
                            if (slugNamurl[4] && slugNamurl[4] != "undefined") {
                                // console.log({slugNamurl} ,"xxxxxxxxxx") 
                                let n1 = slugNamurl.join("/")                          
                                // console.log({n1} ,{d2})
                                // console.log(d2,"ddddddddddd")
                                d2[dm?.state?.appData?.name] = n1

                                d4.push(d2)
                            }
                        }
                    }
                }
            })
            // setswitchmember(d4)
        }
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setMyState(setQuery, {
            mobileDevice: isMobile,
        })
    }, [location])

    
    const queryParams = new URLSearchParams(location.search);
    const members = queryParams.get('members');


    useEffect(() => {       
        let memberArr = members || Cookies.get("memberShip") ;   
        let parseArr = (memberArr && memberArr !== 'undefined') ? JSON.parse(Base64.decode(memberArr)) : [];   
        let obj = {}
        let host = window.location.href;
        let slugNamurl = host.split('/');
        // console.log(slugNamurl,"slugNamurl");
        if(myStore.appData.name){
            let baseUrl = host.split('?')[0];
            // console.log(baseUrl,"bbbaseurlllll");
            obj[myStore.appData.name] = baseUrl
            
            const hasNameInArr = parseArr.some(urlObj => Object.values(urlObj).some(url => url.includes(myStore.slug_name)));
            if (!hasNameInArr) { 
                // console.log(" adddd");
                parseArr.push(obj);          
                let memberCookie = Base64.encode(JSON.stringify(parseArr));
                Cookies.set(`memberShip`, memberCookie)
            }else{ 
                // console.log("updaten " , );
                Cookies.set(`memberShip`, memberArr)
            }
        }
        setswitchmember(parseArr);
    }, [location,myStore.appData.name])


    let getButtonColor1 = (type) => {
        setMyState(setQuery, { buttonColor: type })
    }

    let getButtonColor2 = (type) => {
        setMyState(setQuery, { buttonColor: null })
    }

    let newMemberShipCall = () => {

        let slugArr = Cookies.get(`memberShip`) || members;

        // let alfaUrl = "http://localhost:3000";

        if(slugArr){
            window.location.href = alfaUrl + `?members=${slugArr}`;
        }else{
            window.location.href = alfaUrl
        }
        
        setSwitchButton(false)
    }

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
            </Helmet>
            {currentPath.includes('/events') ?
                <EventRoutes /> :
                    isLogin ?
                    <>
                        {children}
                    </>
                    : currentPath.includes('/resetpassword') ?
                        <ResetPassword />
                        :
                        <AuthPage />
            }
            {(!currentPath.includes('/events') && brandSetting.switch_membership_status != 1) &&
                <MobileView>
                    <div className={`mc_switchMembership_btn ${switchButton == true ? 'slideOn' : ''}`}
                        style={{ color: brandSetting.theme_button_txt_color, backgroundColor: `${state.buttonColor == 'SwitchMembership' ? brandSetting.theme_color2 : brandSetting.theme_color1}` }} onMouseEnter={(e) => getButtonColor1('SwitchMembership')} onMouseLeave={(e) => getButtonColor2('SwitchMembership')}>

                        <a href={void (0)} className={'mc_slideDv'} onClick={() => setSwitchButton(!switchButton)}
                            style={{ fill: brandSetting.theme_button_txt_color }}
                        >
                            {svg.app.switchMemberIcon}
                        </a>
                        <TEDropdown className={`flex justify-center`}>
                            <TERipple rippleColor="light">
                                <a href={void (0)} onClick={() => setSwitchToggle(!switchToggle)}
                                    // tag="a"
                                    className='mc_btn'>
                                    Switch Membership
                                    <span className="ml-2 [&>svg]:w-5 w-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </TERipple>

                            <ul className={`mc_switchmenu ${switchToggle == true ? 'isOpenSwitch' : ''}`}>
                                <li onClick={() => newMemberShipCall()}>
                                    <a href={void (0)} className="mc_listMenu">
                                        New Membership
                                    </a>
                                </li>
                                {switchMember.map((d1, i) => {
                                    let d2 = Object.keys(d1)
                                    return (
                                        <li key={i} onClick={() => {
                                            window.location.href = d1[d2[0]] + `?members=${Cookies.get("memberShip")}`
                                            setSwitchButton(false)
                                        }}>
                                            <a href={void (0)} className="mc_listMenu">
                                                {d2}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </TEDropdown>
                    </div>
                </MobileView>
            }
        </>
    );
}

export default Layout;
